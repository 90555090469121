import { selectedSensorIconLabelLayer, selectedSensorIconLayer } from '@components/map/layers/sensors/sensor.layer';
import { selectedTargetLayer, targetCrossLayer } from '@components/map/layers/targets/target.layer';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectEvent } from '@hooks/useSelectEvent';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { SelectedFeatureTypeEnum } from '@redux/maps/maps.reducer';
import { selectSelectedFeatureKeyByMapId } from '@redux/maps/maps.selectors';
import { selectSelectedSensorFeatures } from '@redux/situation/monitoring.selectors';
import { selectSelectedTargetFeatures } from '@redux/situation/situation.selectors';
import { Feature, FeatureCollection } from 'geojson';
import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

function SelectionLayer() {
  const { mapId, selectableLayers } = useMapContext();
  useSelectEvent(selectableLayers);

  const selectedFeatureKey = useAppSelector((state) => selectSelectedFeatureKeyByMapId(state, mapId));
  const selectedTargetFeatures = useSelectorWithMapId(selectSelectedTargetFeatures);
  const selectedSensorFeatures = useSelectorWithMapId(selectSelectedSensorFeatures);

  if (!selectedFeatureKey) {
    return null;
  }

  const replaceGeometryCoordinates = (features: Feature[]) => {
    return features.map((feature) => {
      const newCoordinates = feature.properties?.value.tempCoordinates;
      if (newCoordinates) {
        return {
          ...feature,
          geometry: {
            ...feature.geometry,
            coordinates: newCoordinates,
          },
        };
      }
      return feature;
    });
  };

  const getSelectedFeatures = () => {
    switch (selectedFeatureKey.type) {
      case SelectedFeatureTypeEnum.TARGET:
        return selectedTargetFeatures;
      case SelectedFeatureTypeEnum.SENSOR:
        return replaceGeometryCoordinates(selectedSensorFeatures);
      default:
        return [];
    }
  };

  const selectedFeatures: FeatureCollection = {
    type: 'FeatureCollection',
    features: getSelectedFeatures(),
  };

  return (
    <Source id={SourceNameEnum.SELECTION} type="geojson" data={selectedFeatures} generateId>
      <Layer key={LayerNameEnum.SENSOR_ICON_SELECTION} {...selectedSensorIconLayer} />
      <Layer key={LayerNameEnum.SENSOR_LABEL_SELECTION} {...selectedSensorIconLabelLayer} />
      <Layer key={LayerNameEnum.TARGET_CROSSES} {...targetCrossLayer} />
      <Layer key={LayerNameEnum.TARGET_SELECTION} {...selectedTargetLayer} />
    </Source>
  );
}

export default memo(SelectionLayer);
