import { updateAuthent, updateHasBeenInitialized } from '@redux/authent/authent.reducer';
import { addMessages } from '@redux/chat/chat.reducer';
import { updateConfiguration } from '@redux/config/config.reducer';
import { updateNotifications } from '@redux/data/data.reducer';
import { updateLogs } from '@redux/global/global.reducer';
import { updateReplay } from '@redux/replay/replay.reducer';
import { updateSensors, updateSensorsByAppCode } from '@redux/sensors/sensors.reducer';
import { SituationState } from '@redux/situation/situation.reducer';
import { updateUtm } from '@redux/utm/utm.reducer';
import { UnknownAction } from '@reduxjs/toolkit';
import patch from 'json-touch-patch';

import { GlobalConfiguration, SituationMessage } from '@/types/c2/c2.types';
import {
  ActionsMap,
  CrudSseAction,
  SseActionEnum,
  SseMessageEvent,
  SseModuleTypeEnum,
  SseTypeEnum,
} from '@/types/c2/sse.types';

export function isCrudSseAction(value: SseActionEnum): value is CrudSseAction {
  return [SseActionEnum.CREATE, SseActionEnum.UPDATE, SseActionEnum.DELETE].includes(value);
}

export function getStoreActionBySseMessage<T>(
  type: SseTypeEnum,
  action: CrudSseAction,
  data: T,
): UnknownAction | null | undefined {
  const actionFunction = ActionsMap[type][action];
  return actionFunction ? (actionFunction(data) as UnknownAction) : actionFunction;
}

export function modelSseHandler(message: SseMessageEvent, site: string): UnknownAction[] {
  const data = JSON.parse(message.data);
  const [action, ...rest] = message.lastEventId.split('_');
  const type = rest.join('_');

  if (isCrudSseAction(action as SseActionEnum)) {
    if (type in SseTypeEnum) {
      const actionToDo = getStoreActionBySseMessage(type as SseTypeEnum, action as CrudSseAction, data);
      if (actionToDo) {
        return [actionToDo];
      } else if (actionToDo === undefined) {
        console.error(`Behaviour for action ${type}/${action} is not implemented (received from SSE)`);
      }
      return [];
    }
    console.warn(`The type ${type} is missing in the sse model handler`);
  } else if (action === SseActionEnum.INIT) {
    const {
      users,
      profiles,
      sites,
      roles,
      authentSettings,
      groups,
      drones,
      pilots,
      flightPlans,
      flyZones,
      flyRoutes,
      flySections,
      home,
      messages,
      mergingConfiguration,
      noFlyZones,
      noFlyZones3d,
      initializationMaskingZones,
      noNeutralizationZones,
      pointOfInterests,
      zoneOfInterests,
      noInitZones,
      videoConfiguration,
      videoStreams,
      gridConfigurations,
      layouts,
      sensors,
      perimeterCameras,
      sensorStorage,
      replayEvents,
      replayManualPeriods,
      replayStorageConfiguration,
      replaySettings,
      globalScreenConfigurations,
      roundConfigurations,
      platforms,
      logs,
      equipments,
      ongoingNotifications,
      procedureModels,
    } = data[site] as GlobalConfiguration;

    switch (type) {
      case SseModuleTypeEnum.AUTHENT: {
        return [updateAuthent({ users, profiles, roles, sites, groups, authentSettings }), updateLogs(logs)];
      }
      case SseModuleTypeEnum.CHAT: {
        return [addMessages(messages), updateLogs(logs)];
      }
      case SseModuleTypeEnum.CONFIG: {
        return [
          updateConfiguration({
            home,
            mergingConfiguration,
            noFlyZones,
            noFlyZones3d,
            initializationMaskingZones,
            noNeutralizationZones,
            pointOfInterests,
            zoneOfInterests,
            noInitZones,
            videoConfiguration,
            videoStreams,
            gridConfigurations,
            layouts,
            globalScreenConfigurations,
            roundConfigurations,
            platforms,
            procedureModels,
          }),
          updateLogs(logs),
        ];
      }
      case SseModuleTypeEnum.DATA: {
        return [updateNotifications(ongoingNotifications)];
      }
      case SseModuleTypeEnum.REPLAY: {
        return [
          updateReplay({ replayEvents, replayManualPeriods, replayStorageConfiguration, replaySettings }),
          updateLogs(logs),
        ];
      }
      case SseModuleTypeEnum.SENSOR: {
        return [updateSensorsByAppCode({ sensors, perimeterCameras, equipments, sensorStorage })];
      }
      case SseModuleTypeEnum.UTM: {
        return [updateUtm({ drones, pilots, flightPlans, flyZones, flySections, flyRoutes })];
      }
      case SseModuleTypeEnum.GLOBAL: {
        return [
          updateAuthent({ users, profiles, roles, sites, groups, authentSettings: authentSettings }),
          updateUtm({ drones, pilots, flightPlans, flyZones, flySections, flyRoutes }),
          updateSensors({ sensors, perimeterCameras, equipments, sensorStorage }),
          updateConfiguration({
            home,
            mergingConfiguration,
            noFlyZones,
            noFlyZones3d,
            initializationMaskingZones,
            noNeutralizationZones,
            pointOfInterests,
            zoneOfInterests,
            noInitZones,
            videoConfiguration,
            videoStreams,
            gridConfigurations,
            layouts,
            globalScreenConfigurations,
            roundConfigurations,
            platforms,
            procedureModels,
          }),
          updateReplay({ replayEvents, replayManualPeriods, replayStorageConfiguration, replaySettings }),
          updateHasBeenInitialized(true),
          addMessages(messages),
          updateLogs(logs),
          updateNotifications(ongoingNotifications),
        ];
      }
      default:
        console.error('Following SseModuleTypeEnum not handled!! -> ', type);
    }
  }
  return [];
}

export function eventToSituation(currentSituation: SituationState, data: string): SituationState {
  const situationMessage = JSON.parse(data) as SituationMessage;
  if (situationMessage.fullSituation) {
    return JSON.parse(situationMessage.json) as SituationState;
  } else if (currentSituation.version < situationMessage.version) {
    const patches = JSON.parse(situationMessage.json);
    return patch(currentSituation, patches);
  } else {
    return currentSituation;
  }
}
