import { Center, Text } from '@chakra-ui/react';
import { memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';

import { VideoProtocolEnum } from '@/types/commons/commons.types';

import OvenMediaPlayer from './OvenMediaPlayer';
import { ReplayPlayer } from './ReplayPlayer';
import SLDPPlayer from './SLDPPlayer';

export interface PlayerProps {
  src: string;
  id: string;
  displayMessageCentered?: boolean;
  type?: VideoProtocolEnum | null;
  replayMode?: boolean;
  isPaused?: boolean;
}

function Player({ replayMode = false, displayMessageCentered = true, ...props }: Readonly<PlayerProps>) {
  return (
    <ErrorBoundary
      fallback={
        <Center width="100%" height="100%" backgroundColor="black">
          <Text transform={displayMessageCentered ? undefined : 'translateY(100px)'}>
            <FormattedMessage id="cameras.playerCrash" />
          </Text>
        </Center>
      }
      resetKeys={[props.src]}
    >
      {replayMode ? (
        <ReplayPlayer {...props} />
      ) : props.type === VideoProtocolEnum.OMP ? (
        <OvenMediaPlayer {...props} className={displayMessageCentered ? undefined : 'lower-message'} />
      ) : (
        <SLDPPlayer {...props} className={displayMessageCentered ? undefined : 'lower-message'} />
      )}
    </ErrorBoundary>
  );
}

export default memo(Player);
