import { Position } from '@turf/helpers';

import { Coordinates } from '@/types/commons/commons.types';
import { SolidPolygon3d } from '@/types/config/config.types';

export const POLYGON_VALID: Coordinates[] = [
  { latitude: 0, longitude: 0, type: '2D' },
  { latitude: 10, longitude: 10, type: '2D' },
  { latitude: 20, longitude: 20, type: '2D' },
  { latitude: 0, longitude: 0, type: '2D' },
];
export const POLYGON_WITH_INVALID_POINT: Coordinates[] = [
  { latitude: 0, longitude: 0, type: '2D' },
  { latitude: 10, longitude: 390, type: '2D' },
  { latitude: 20, longitude: 20, type: '2D' },
  { latitude: 0, longitude: 0, type: '2D' },
];

export const POLYGON_NOT_CLOSED: Coordinates[] = [
  { latitude: 0, longitude: 10, type: '2D' },
  { latitude: 10, longitude: 10, type: '2D' },
  { latitude: 20, longitude: 20, type: '2D' },
  { latitude: 0, longitude: 0, type: '2D' },
];

export const POLYGON_VALID_COMPLEX: Coordinates[] = [
  {
    longitude: 2.359219845726463,
    latitude: 48.745212764515486,
    type: '2D',
  },
  {
    longitude: 2.364570852711174,
    latitude: 48.74462467770937,
    type: '2D',
  },
  {
    longitude: 2.3649329509281842,
    latitude: 48.74242702926466,
    type: '2D',
  },
  {
    longitude: 2.361841705038521,
    latitude: 48.74165318651416,
    type: '2D',
  },
  {
    longitude: 2.3628408278965685,
    latitude: 48.74318317267185,
    type: '2D',
  },
  {
    longitude: 2.359917220070335,
    latitude: 48.74328929715196,
    type: '2D',
  },
  {
    longitude: 2.360567655756447,
    latitude: 48.74422229856117,
    type: '2D',
  },
  {
    longitude: 2.359219845726463,
    latitude: 48.745212764515486,
    type: '2D',
  },
];

export const POLYGON_VALID_COMPLEX_MARGIN: Coordinates[] = [
  {
    longitude: 2.3584976773089728,
    latitude: 48.74547449359238,
    type: '2D',
  },
  {
    longitude: 2.3648189489771276,
    latitude: 48.74477978905253,
    type: '2D',
  },
  {
    longitude: 2.3652261928419147,
    latitude: 48.74230806183113,
    type: '2D',
  },
  {
    longitude: 2.361337315244765,
    latitude: 48.74133453679988,
    type: '2D',
  },
  {
    longitude: 2.3624364241994718,
    latitude: 48.74301767018193,
    type: '2D',
  },
  {
    longitude: 2.3595025406197605,
    latitude: 48.74312415759823,
    type: '2D',
  },
  {
    longitude: 2.3602455911942783,
    latitude: 48.74419002376672,
    type: '2D',
  },
  {
    longitude: 2.3584976773089728,
    latitude: 48.74547449359238,
    type: '2D',
  },
];

export const POLYGON_VALID_COMPLEX_MARGIN_DIFF: Position[][] = [
  [
    [2.3584976773089728, 48.74547449359238],
    [2.3602455911942783, 48.74419002376672],
    [2.3595025406197605, 48.74312415759823],
    [2.3624364241994718, 48.74301767018193],
    [2.361337315244765, 48.74133453679988],
    [2.3652261928419147, 48.74230806183113],
    [2.3648189489771276, 48.74477978905253],
    [2.3584976773089728, 48.74547449359238],
  ],
  [
    [2.359219845726463, 48.745212764515486],
    [2.364570852711174, 48.74462467770937],
    [2.3649329509281842, 48.74242702926466],
    [2.361841705038521, 48.74165318651416],
    [2.3628408278965685, 48.74318317267185],
    [2.359917220070335, 48.74328929715196],
    [2.360567655756447, 48.74422229856117],
    [2.359219845726463, 48.745212764515486],
  ],
];

export const POLYGON_3D_VALID: SolidPolygon3d = {
  polygonFaces: [
    {
      polygon: [
        { latitude: 0, longitude: 0, altitude: 0, type: '3D' },
        { latitude: 0, longitude: 1, altitude: 0, type: '3D' },
        { latitude: 1, longitude: 1, altitude: 0, type: '3D' },
        { latitude: 1, longitude: 0, altitude: 0, type: '3D' },
        { latitude: 0, longitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 1, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 0, type: '3D' },
      ],
    },
  ],
};

export const POLYGON_3D_VALID_2D_RAW_PROJECTION: Coordinates[] = [
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 1, latitude: 0, type: '2D' },
  { longitude: 1, latitude: 1, type: '2D' },
  { longitude: 0, latitude: 1, type: '2D' },
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 1, type: '2D' },
  { longitude: 1, latitude: 1, type: '2D' },
  { longitude: 1, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 1, type: '2D' },
  { longitude: 0, latitude: 1, type: '2D' },
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 1, latitude: 0, type: '2D' },
  { longitude: 1, latitude: 1, type: '2D' },
  { longitude: 1, latitude: 1, type: '2D' },
  { longitude: 1, latitude: 0, type: '2D' },
  { longitude: 1, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 1, latitude: 0, type: '2D' },
  { longitude: 1, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 0, type: '2D' },
  { longitude: 0, latitude: 1, type: '2D' },
  { longitude: 0, latitude: 1, type: '2D' },
  { longitude: 1, latitude: 1, type: '2D' },
  { longitude: 1, latitude: 1, type: '2D' },
  { longitude: 0, latitude: 1, type: '2D' },
];

export const POLYGON_3D_WITH_INVALID_POINT: SolidPolygon3d = {
  polygonFaces: [
    {
      polygon: [
        { latitude: 0, longitude: 0, altitude: 1, type: '3D' },
        { latitude: 10, longitude: 390, altitude: 1, type: '3D' },
        { latitude: 20, longitude: 20, altitude: 1, type: '3D' },
        { latitude: 0, longitude: 0, altitude: 1, type: '3D' },
        { latitude: 0, longitude: 0, altitude: 1, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 1, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 0, type: '3D' },
      ],
    },
  ],
};

export const POLYGON_3D_NOT_CLOSED: SolidPolygon3d = {
  polygonFaces: [
    // On ne met pas la face du bas
    {
      polygon: [
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 1, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 0, type: '3D' },
        { longitude: 1, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 1, type: '3D' },
        { longitude: 0, latitude: 0, altitude: 0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 1, type: '3D' },
        { longitude: 1, latitude: 1, altitude: 0, type: '3D' },
        { longitude: 0, latitude: 1, altitude: 0, type: '3D' },
      ],
    },
  ],
};

export const POLYGON_3D_WITHOUT_ALTITUDE: Coordinates[] = [
  { latitude: 0, longitude: 0, type: '3D' },
  { latitude: 0, longitude: 1, type: '3D' },
  { latitude: 1, longitude: 1, type: '3D' },
  { latitude: 1, longitude: 0, type: '3D' },
  { latitude: 0, longitude: 0, type: '3D' },

  { longitude: 0, latitude: 0, type: '3D' },
  { longitude: 0, latitude: 1, type: '3D' },
  { longitude: 1, latitude: 1, type: '3D' },
  { longitude: 1, latitude: 0, type: '3D' },
  { longitude: 0, latitude: 0, type: '3D' },

  // Tant qu'ils sont entre 0 et 1, ces points doivent être supprimés par le convexHull dans notre exemple
  { longitude: 0.1, latitude: 0.5, type: '3D' },
  { longitude: 0.4, latitude: 0.6, type: '3D' },
  { longitude: 0.9, latitude: 0.8, type: '3D' },
  { longitude: 0.6, latitude: 0.2, type: '3D' },

  { longitude: 0, latitude: 0, type: '3D' },
  { longitude: 0, latitude: 0, type: '3D' },
  { longitude: 0, latitude: 1, type: '3D' },
  { longitude: 0, latitude: 1, type: '3D' },
  { longitude: 0, latitude: 0, type: '3D' },

  { longitude: 1, latitude: 0, type: '3D' },
  { longitude: 1, latitude: 1, type: '3D' },
  { longitude: 1, latitude: 1, type: '3D' },
  { longitude: 1, latitude: 0, type: '3D' },
  { longitude: 1, latitude: 0, type: '3D' },

  { longitude: 0, latitude: 0, type: '3D' },
  { longitude: 1, latitude: 0, type: '3D' },
  { longitude: 1, latitude: 0, type: '3D' },
  { longitude: 0, latitude: 0, type: '3D' },
  { longitude: 0, latitude: 0, type: '3D' },

  { longitude: 0, latitude: 1, type: '3D' },
  { longitude: 0, latitude: 1, type: '3D' },
  { longitude: 1, latitude: 1, type: '3D' },
  { longitude: 1, latitude: 1, type: '3D' },
  { longitude: 0, latitude: 1, type: '3D' },
];

// Cube penché, forme une rampe (la face haute se construit entre (2 2 1) et (3 3 1))
export const POLYGON_3D_VALID_COMPLEX_LEANING_CUBE: SolidPolygon3d = {
  polygonFaces: [
    {
      polygon: [
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 2.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 3.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 1.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 1.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 3.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 3.0, latitude: 3.0, altitude: 1.0, type: '3D' },
        { longitude: 1.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 0.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 3.0, latitude: 3.0, altitude: 1.0, type: '3D' },
        { longitude: 2.0, latitude: 3.0, altitude: 1.0, type: '3D' },
        { longitude: 0.0, latitude: 1.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 2.0, latitude: 3.0, altitude: 1.0, type: '3D' },
        { longitude: 2.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 2.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 3.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 3.0, latitude: 3.0, altitude: 1.0, type: '3D' },
        { longitude: 2.0, latitude: 3.0, altitude: 1.0, type: '3D' },
        { longitude: 2.0, latitude: 2.0, altitude: 1.0, type: '3D' },
      ],
    },
  ],
};

export const POLYGON_3D_VALID_COMPLEX_LEANING_CUBE_RAW_PROJECTION: Coordinates[] = [
  { longitude: 0.0, latitude: 0.0, type: '2D' },
  { longitude: 1.0, latitude: 0.0, type: '2D' },
  { longitude: 1.0, latitude: 1.0, type: '2D' },
  { longitude: 0.0, latitude: 1.0, type: '2D' },
  { longitude: 0.0, latitude: 0.0, type: '2D' },

  { longitude: 0.0, latitude: 0.0, type: '2D' },
  { longitude: 2.0, latitude: 2.0, type: '2D' },
  { longitude: 3.0, latitude: 2.0, type: '2D' },
  { longitude: 1.0, latitude: 0.0, type: '2D' },
  { longitude: 0.0, latitude: 0.0, type: '2D' },

  { longitude: 1.0, latitude: 0.0, type: '2D' },
  { longitude: 3.0, latitude: 2.0, type: '2D' },
  { longitude: 3.0, latitude: 3.0, type: '2D' },
  { longitude: 1.0, latitude: 1.0, type: '2D' },
  { longitude: 1.0, latitude: 0.0, type: '2D' },

  { longitude: 0.0, latitude: 1.0, type: '2D' },
  { longitude: 1.0, latitude: 1.0, type: '2D' },
  { longitude: 3.0, latitude: 3.0, type: '2D' },
  { longitude: 2.0, latitude: 3.0, type: '2D' },
  { longitude: 0.0, latitude: 1.0, type: '2D' },

  { longitude: 0.0, latitude: 0.0, type: '2D' },
  { longitude: 0.0, latitude: 1.0, type: '2D' },
  { longitude: 2.0, latitude: 3.0, type: '2D' },
  { longitude: 2.0, latitude: 2.0, type: '2D' },
  { longitude: 0.0, latitude: 0.0, type: '2D' },

  { longitude: 2.0, latitude: 2.0, type: '2D' },
  { longitude: 3.0, latitude: 2.0, type: '2D' },
  { longitude: 3.0, latitude: 3.0, type: '2D' },
  { longitude: 2.0, latitude: 3.0, type: '2D' },
  { longitude: 2.0, latitude: 2.0, type: '2D' },
];

// Forme d'hexagone/cristal, vu de dessus ça a 6 faces
export const POLYGON_3D_VALID_HEXAGON: SolidPolygon3d = {
  polygonFaces: [
    {
      // Face basse
      polygon: [
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 2.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 2.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 2.0, altitude: 0.0, type: '3D' },
        { longitude: -1.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      //Face haute
      polygon: [
        { longitude: 0.0, latitude: 0.0, altitude: 1.0, type: '3D' },
        { longitude: 1.0, latitude: 0.0, altitude: 1.0, type: '3D' },
        { longitude: 2.0, latitude: 1.0, altitude: 1.0, type: '3D' },
        { longitude: 1.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 0.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: -1.0, latitude: 1.0, altitude: 1.0, type: '3D' },
        { longitude: 0.0, latitude: 0.0, altitude: 1.0, type: '3D' },
      ],
    },
    {
      // Face avant
      polygon: [
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 0.0, altitude: 1.0, type: '3D' },
        { longitude: 1.0, latitude: 0.0, altitude: 1.0, type: '3D' },
        { longitude: 1.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      // 2 faces droites
      polygon: [
        { longitude: 1.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 0.0, altitude: 1.0, type: '3D' },
        { longitude: 2.0, latitude: 1.0, altitude: 1.0, type: '3D' },
        { longitude: 2.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 0.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: 2.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 2.0, latitude: 1.0, altitude: 1.0, type: '3D' },
        { longitude: 1.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 1.0, latitude: 2.0, altitude: 0.0, type: '3D' },
        { longitude: 2.0, latitude: 1.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      // Face arrière
      polygon: [
        { longitude: 0.0, latitude: 2.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 2.0, altitude: 0.0, type: '3D' },
        { longitude: 1.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 0.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: 0.0, latitude: 2.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      // 2 faces gauche
      polygon: [
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
        { longitude: -1.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: -1.0, latitude: 1.0, altitude: 1.0, type: '3D' },
        { longitude: 0.0, latitude: 0.0, altitude: 1.0, type: '3D' },
        { longitude: 0.0, latitude: 0.0, altitude: 0.0, type: '3D' },
      ],
    },
    {
      polygon: [
        { longitude: -1.0, latitude: 1.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 2.0, altitude: 0.0, type: '3D' },
        { longitude: 0.0, latitude: 2.0, altitude: 1.0, type: '3D' },
        { longitude: -1.0, latitude: 1.0, altitude: 1.0, type: '3D' },
        { longitude: -1.0, latitude: 1.0, altitude: 0.0, type: '3D' },
      ],
    },
  ],
};
