export const colors = {
  transparent: 'transparent',
  neutral: {
    black: '#020617',
    white: '#FFFFFF',
    100: '#F0F5FB',
    200: '#CBD5E1',
    300: '#94A3B8',
    500: '#64748B',
    600: '#334155',
    700: '#283548',
    800: '#1E293B',
    '800-op70': '#1E293BB3',
    850: '#162131',
    900: '#0F172A',
    '900-op30': '#0F172A4D',
    '900-op70': '#0F172AB3',
  },
  alertHi: {
    100: '#FCE8E9',
    500: '#DE1A22',
    800: '#582534',
    900: '#380D0E',
  },
  alertMid: {
    500: '#F06710',
    800: '#5B3F36',
  },
  alertLo: {
    500: '#F1CD2D',
    800: '#5D5A37',
  },
  alertZero: {
    500: '#13A813',
    800: '#1B4F2F',
  },
  alertHs: {
    500: '#B18FE8',
    800: '#4A486F',
  },
  cyber: {
    500: '#1561D1',
    '500-op30': '#1561D14D',
    600: '#0B41AB',
    700: '#16326A',
    900: '#091938',
  },
  storm: {
    600: '#1A4686',
    '600-op15': '#1A468626',
    '600-op30': '#1A46864D',
  },
  sky: {
    500: '#4FA4E7',
    '500-op50': '#4FA4E780',
  },
  common: {
    red: '#DE1A22',
    darkRed: '#582534',
    orange: '#F06710',
    darkOrange: '#5B3F36',
    green: '#13A813',
    darkGreen: '#1B4F2F',
    blue: '#1561D1',
    darkBlue: '#16326A',
  },
} as const;
