import { useAppSelector } from '@hooks/redux.hooks';
import { selectHighestNotAckNotificationFamily } from '@redux/data/data.selectors';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { NotificationFamilyEnum } from '@/types/data/data.types';

export default function NotificationSounds() {
  const highestPriorityNotificationFamily = useAppSelector(selectHighestNotAckNotificationFamily, isEqual);
  const [userInteracted, setUserInteracted] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(new Audio());

  const getPriorityAlarmSound = useCallback(() => {
    switch (highestPriorityNotificationFamily) {
      case NotificationFamilyEnum.LAD:
        return '/audio/ladAlarm.mp3';
      case NotificationFamilyEnum.PERIMETER:
        return '/audio/perimAlarm.mp3';
      case NotificationFamilyEnum.TECHNICAL:
        return '/audio/technicalAlarm.mp3';
      default:
        return null;
    }
  }, [highestPriorityNotificationFamily]);

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
    };
    const audio = audioRef.current;
    audio.loop = false;
    window.addEventListener('click', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction);
      audio.pause();
      audio.loop = false;
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (highestPriorityNotificationFamily && !audio.loop && userInteracted) {
      const alarmSound = getPriorityAlarmSound();
      if (alarmSound) {
        audio.src = alarmSound;
        audio.currentTime = 0;
        audio.muted = false;
        audio.volume = 0.4;
        audio.loop = true;
        audio
          .play()
          .then(() => {
            setUserInteracted(true);
          })
          .catch(() => {
            setUserInteracted(false);
          });
      }
    } else if (!highestPriorityNotificationFamily && audio.loop) {
      audio.pause();
      audio.loop = false;
    }
  }, [getPriorityAlarmSound, highestPriorityNotificationFamily, userInteracted]);

  return <></>;
}
