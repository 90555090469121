import { Text, VStack } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { formatDate, formatDuration } from '@utils/date.utils';
import format from 'date-fns/format';
import { FormattedMessage } from 'react-intl';

import { NotificationTypeEnum } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';

type Props = {
  startTime: string;
  expiredTime: string | null;
  type: NotificationTypeEnum;
};

export default function NotificationCardDates({ startTime, expiredTime, type }: Readonly<Props>) {
  const situationTime = useAppSelector(selectSituationTime) ?? Date.now();

  const endTime = expiredTime ?? situationTime;
  const formattedStarDate = formatDate(startTime, DateTimeEnum.DATE);
  const formattedExpiredDate = formatDate(endTime, DateTimeEnum.DATE);
  const formattedStartTime = format(new Date(startTime), "HH':'mm':'ss");
  const formattedExpiredTime = format(new Date(endTime), "HH':'mm':'ss");

  const expiredSameDay = formattedStarDate === formattedExpiredDate;

  const expiredLabelKey = expiredSameDay ? 'expiredDateSameDay' : 'expiredDateMultipleDays';

  return (
    <VStack gap={1} paddingX={3} paddingTop={3} alignItems="start" width="227px" height="100%" flexGrow={1}>
      {type === NotificationTypeEnum.DRONE && (
        <>
          <Text textAlign="center" width="100%" paddingY={0.5} border="2px solid" borderColor="neutral.black">
            {formatDuration(startTime, endTime)}
          </Text>
          <Text color="neutral.300" whiteSpace="pre-line">
            <FormattedMessage
              id={`notification.${expiredTime ? expiredLabelKey : 'onGoingDate'}`}
              values={{
                startDate: formattedStarDate,
                startTime: formattedStartTime,
                endDate: formattedExpiredDate,
                endTime: formattedExpiredTime,
              }}
            />
          </Text>
        </>
      )}
    </VStack>
  );
}
