import { SensorTypeEnum } from '@/types/sensor/configuration.types';

import { TargetStatusEnum } from '../c2/c2.types';
import { Coordinates, Mark, TrackLight } from '../commons/commons.types';
import { ClassificationEnum } from '../sensor/classification.types';
import { ReliabilityEnum } from '../sensor/identification.types';
import { SensorStatusEnum } from '../sensor/status.types';
import { DroneClearance } from '../utm/drone.types';

export enum VerticalTrespassEnum {
  INSIDE_BOUNDARIES = 'INSIDE_BOUNDARIES',
  ABOVE_MAX_HEIGHT = 'ABOVE_MAX_HEIGHT',
  BELOW_MIN_HEIGHT = 'BELOW_MIN_HEIGHT',
}
export interface NotificationCriteria {
  site: string;
  to?: string;
  from?: string;
  identifier?: string;
  page?: number;
  size?: number;
  types?: NotificationTypeEnum[];
  unpaginated?: boolean;
  notificationStatus?: NotificationStatusEnum[];
  platforms?: string[];
}

export enum NotificationFamilyEnum {
  LAD = 'LAD',
  PERIMETER = 'PERIMETER',
  TECHNICAL = 'TECHNICAL',
}

export enum NotificationFilterEnum {
  LAD = 'LAD',
  PERIMETER = 'PERIMETER',
  LAD_TECHNICAL = 'LAD_TECHNICAL',
  PERIMETER_TECHNICAL = 'PERIMETER_TECHNICAL',
}

export enum NotificationTypeEnum {
  DRONE = 'DRONE',
  SENSOR = 'SENSOR',
  SEGMENT = 'SEGMENT',
}

export enum NotificationStatusEnum {
  NSTR = 'NSTR',
  RUNNING = 'RUNNING',
  EXPIRED = 'EXPIRED',
  TERMINATED = 'TERMINATED',
}

export enum DroneAlertLevelEnum {
  DRONE_CRITICAL = 'DRONE_CRITICAL',
  DRONE_WARNING = 'DRONE_WARNING',
  DRONE_INFO = 'DRONE_INFO',
}

export enum SensorAlertLevelEnum {
  SENSOR_DISCONNECTED = 'SENSOR_DISCONNECTED',
  SENSOR_FAILURE = 'SENSOR_FAILURE',
}

export enum SegmentAlertLevelEnum {
  PERIMETER_EFFRACTION = 'PERIMETER_EFFRACTION',
  SEGMENT_DISCONNECTED = 'SEGMENT_DISCONNECTED',
  SEGMENT_FAILURE = 'SEGMENT_FAILURE',
}

export type AlertLevelEnum = DroneAlertLevelEnum | SensorAlertLevelEnum | SegmentAlertLevelEnum;

export const criticalAlertLevel: AlertLevelEnum[] = [
  DroneAlertLevelEnum.DRONE_CRITICAL,
  SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
] as const;

export enum AlertTypeEnum {
  SENSOR_FAILURE = 'SENSOR_FAILURE',
  SEGMENT_FAILURE = 'SEGMENT_FAILURE',
  PERIMETER_EFFRACTION = 'PERIMETER_EFFRACTION',
  SPACE_VIOLATION = 'SPACE_VIOLATION',
  COLLISION = 'COLLISION',
}

export enum AlertContextEnum {
  OUTSIDE_FLIGHT_PLAN = 'OUTSIDE_FLIGHT_PLAN',
  FLIGHT_PLAN_VIOLATION = 'FLIGHT_PLAN_VIOLATION',
  NO_FLY_ZONE = 'NO_FLY_ZONE',
  NO_FLY_ZONE_3D = 'NO_FLY_ZONE_3D',
  COLLISION = 'COLLISION',
  SENSOR_FAILURE = 'SENSOR_FAILURE',
  SEGMENT_FAILURE = 'SEGMENT_FAILURE',
  PERIMETER_EFFRACTION = 'PERIMETER_EFFRACTION',
}

export enum AlertStatusEnum {
  PENDING = 'PENDING',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  CONFIRMED = 'CONFIRMED',
  ARCHIVED = 'ARCHIVED',
}

type BaseNotification = {
  id: number;
  site: string;
  identifier: string;
  position: Coordinates;
  startTime: string;
  expiredTime: string | null;
  alertCount: number;
  currentAlertCount: number;
  hasUnacknowledgedAlert: boolean;
  status: NotificationStatusEnum;
  marks: Mark[];
};

export type DroneNotificationLight = {
  type: NotificationTypeEnum.DRONE;
  worstAlertLevel: DroneAlertLevelEnum | null;
  currentWorstAlertLevel: DroneAlertLevelEnum | null;
  lastAlertLevel: DroneAlertLevelEnum | null;
  currentHeight: number;
  maxHeight: number;
  hasFlightPlan: boolean;
  trackIds: TrackLight[];
  classification: ClassificationEnum;
  targetStatus: TargetStatusEnum;
  reliability: ReliabilityEnum;
  clearance: DroneClearance | null;
  occurrenceCount: number;
} & BaseNotification;

export type DetectedDroneLight = {
  id: number;
  site: string;
  sensorType: SensorTypeEnum;
  collaborativeId: string;
  occurrences: number;
  lastOccurrenceTime: string;
  worstClassification: ClassificationEnum;
};

export type DetectedDrone = {
  notifications: DroneNotificationLight[];
} & DetectedDroneLight;

export type DroneNotification = {
  trajectory: Coordinates[];
  detectedDrones: DetectedDroneLight;
} & DroneNotificationLight;

export type SensorNotification = {
  type: NotificationTypeEnum.SENSOR;
  worstAlertLevel: SensorAlertLevelEnum;
  currentWorstAlertLevel: SensorAlertLevelEnum;
  lastAlertLevel: SensorAlertLevelEnum;
  sensorName: string;
  sensorType: SensorTypeEnum;
  sensorStatus: SensorStatusEnum;
} & BaseNotification;

export type SegmentNotification = {
  type: NotificationTypeEnum.SEGMENT;
  worstAlertLevel: SegmentAlertLevelEnum;
  currentWorstAlertLevel: SegmentAlertLevelEnum;
  lastAlertLevel: SegmentAlertLevelEnum;
  segmentName: string;
  cabinetName: string;
  segmentStatus: SensorStatusEnum;
} & BaseNotification;

export type NotificationType = DroneNotificationLight | SensorNotification | SegmentNotification;

type BaseAlert = {
  id: number;
  site: string;
  identifier: string;
  startTime: string;
  endTime: string | null;
  ackTime: string | null;
  ackUser: string | null;
  confirmationTime: string | null;
  confirmationUser: string | null;
  archivedTime: string | null;
  archivingUser: string | null;
  parentId: number;
  marks: Mark[];
  status: AlertStatusEnum;
};

export type SpaceViolationAlert = {
  type: AlertTypeEnum.SPACE_VIOLATION;
  context:
    | AlertContextEnum.OUTSIDE_FLIGHT_PLAN
    | AlertContextEnum.FLIGHT_PLAN_VIOLATION
    | AlertContextEnum.NO_FLY_ZONE
    | AlertContextEnum.NO_FLY_ZONE_3D;
  level: DroneAlertLevelEnum;
  zoneId: number;
  zoneName: string;
  heightMin: number;
  heightMax: number;
  verticalTrespass: VerticalTrespassEnum;
} & BaseAlert;

export type SensorFailureAlert = {
  type: AlertTypeEnum.SENSOR_FAILURE;
  context: AlertContextEnum.SENSOR_FAILURE;
  level: SensorAlertLevelEnum;
} & BaseAlert;

export type PerimeterEffractionAlert = {
  type: AlertTypeEnum.PERIMETER_EFFRACTION;
  context: AlertContextEnum.PERIMETER_EFFRACTION;
  level: SegmentAlertLevelEnum.PERIMETER_EFFRACTION;
} & BaseAlert;

export type SegmentFailureAlert = {
  type: AlertTypeEnum.SEGMENT_FAILURE;
  context: AlertContextEnum.SEGMENT_FAILURE;
  level: SegmentAlertLevelEnum.SEGMENT_FAILURE | SegmentAlertLevelEnum.SEGMENT_DISCONNECTED;
} & BaseAlert;

export type CollisionAlert = {
  type: AlertTypeEnum.COLLISION;
  context: AlertContextEnum.COLLISION;
  level: DroneAlertLevelEnum;
  targetId2: string;
  safetyDistance: number;
} & BaseAlert;

export type AlertType =
  | SpaceViolationAlert
  | SensorFailureAlert
  | PerimeterEffractionAlert
  | SegmentFailureAlert
  | CollisionAlert;

export type NotificationDetails = {
  notification: DroneNotification | SensorNotification | SegmentNotification;
  alerts: AlertType[];
};
