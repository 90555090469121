import { Box, Text } from '@chakra-ui/layout';
import { ReactNode } from 'react';

interface OwnProps {
  name?: string;
  children: ReactNode;
}

export default function PerimeterCameraWrapper({ name, children }: Readonly<OwnProps>) {
  return (
    <Box position="relative" width="100%" height="100%">
      {children}
      {name && (
        <Text
          position="absolute"
          fontSize="16px"
          fontWeight="regular"
          padding={1.5}
          top={2}
          left={2}
          backgroundColor="neutral.800-op70"
        >
          {name}
        </Text>
      )}
    </Box>
  );
}
