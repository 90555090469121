import { truncateMessage } from '@utils/locale.utils';
import { getSectionCenterCoordinates } from '@utils/map/automatonSection.utils';
import {
  getIconBasicInfoFromSensor,
  getIconBasicInfoFromSubSensorInfo,
  getSensorSpriteIconName,
  getSubSensorSpriteIconName,
} from '@utils/map/sensor.utils';
import {
  getSensorUniqueCode,
  getSubSensorConfigsFromParent,
  getSubsensorInfos,
  isSensorStatus,
} from '@utils/sensors/sensors.utils';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { Feature } from 'geojson';

import { Coordinates } from '@/types/commons/commons.types';
import { MapFilters } from '@/types/filters.types';
import {
  FeatureTypeEnum,
  SensorLikeDisplayedOnMap,
  SensorUniqueCodes,
  SplitMonitoringSensors,
  SubSensorInfo,
} from '@/types/map.types';
import {
  AutomatonCabinet,
  AutomatonConfiguration,
  AutomatonSection,
  SensorWithSubSensor,
} from '@/types/sensor/configuration.types';
import { MonitoringSensor, SensorStatusEnum } from '@/types/sensor/status.types';

export const TRUNCATE_SIZE_NAME = 10;

export function toSensorIconFeature(
  sensorUniqueCodes: SensorUniqueCodes,
  position: Coordinates,
  type: SensorLikeDisplayedOnMap,
  active: boolean,
  maintenance: boolean,
  sensorSpriteIconName: string,
  status?: SensorStatusEnum,
) {
  return {
    type: 'Feature',
    id: sensorUniqueCodes.sensorUniqueCode,
    properties: {
      featureType: FeatureTypeEnum.SENSOR_ICON,
      id: sensorUniqueCodes.sensorUniqueCode,
      value: sensorUniqueCodes,
      type,
      icon: sensorSpriteIconName,
      status: status ?? null,
      maintenance: maintenance,
      active: active,
    },
    geometry: {
      type: 'Point',
      coordinates: toLonLatArray(position, false)!,
    },
  } as Feature;
}

export function toSensorLabelFeature(
  sensorUniqueCodes: SensorUniqueCodes,
  position: Coordinates,
  name: string,
  type: SensorLikeDisplayedOnMap,
): Feature {
  return {
    type: 'Feature',
    properties: {
      id: sensorUniqueCodes.sensorUniqueCode,
      featureType: FeatureTypeEnum.SENSOR_LABEL,
      value: sensorUniqueCodes,
      type,
      textField: truncateMessage(name, TRUNCATE_SIZE_NAME),
      tooltipAnchor: 'pointer',
      showTooltip: name.length > TRUNCATE_SIZE_NAME,
    },
    geometry: {
      type: 'Point',
      coordinates: toLonLatArray(position, false)!,
    },
  } as Feature;
}

export function toSubSensorIconFeature(subsensorInfo: SubSensorInfo<SensorWithSubSensor>) {
  const { parentSensorConfig, subSensorConfig } = subsensorInfo;
  const subSensorSpriteIconName = getSubSensorSpriteIconName(subsensorInfo);
  const { active, maintenance, status } = getIconBasicInfoFromSubSensorInfo(subsensorInfo);
  return toSensorIconFeature(
    {
      sensorUniqueCode: getSensorUniqueCode(subSensorConfig),
      parentUniqueCode: getSensorUniqueCode(parentSensorConfig),
    },
    subSensorConfig.position,
    subSensorConfig.type,
    active,
    maintenance,
    subSensorSpriteIconName,
    status,
  );
}

const toSubSensorIconFeatures = (parentMonitoringSensors: MonitoringSensor<SensorWithSubSensor>[]): Feature[] => {
  return parentMonitoringSensors.flatMap(getSubsensorInfos).map(toSubSensorIconFeature);
};

const toSubSensorLabelFeatures = (parentMonitoringSensors: MonitoringSensor<SensorWithSubSensor>[]): Feature[] => {
  return parentMonitoringSensors.flatMap((parentMonitoringSensor) =>
    getSubSensorConfigsFromParent(parentMonitoringSensor.configuration).flatMap((subSensor) => {
      return toSensorLabelFeature(
        {
          sensorUniqueCode: getSensorUniqueCode(subSensor),
          parentUniqueCode: getSensorUniqueCode(parentMonitoringSensor),
        },
        subSensor.position,
        subSensor.name,
        subSensor.type,
      );
    }),
  );
};

export const toMonitoringSensorIconFeatures = ({
  regularSensors,
  withSubSensors,
}: SplitMonitoringSensors): Feature[] => {
  const regularSensorFeatures = regularSensors.map((monitoringSensor) => {
    const active = monitoringSensor.configuration.active;
    const maintenance = monitoringSensor.configuration.maintenance;
    const sensorPosition = monitoringSensor.configuration.sensorPosition;
    const type = monitoringSensor.configuration.type;
    const sensorStatus = isSensorStatus(monitoringSensor) && monitoringSensor.status;
    return toSensorIconFeature(
      {
        sensorUniqueCode: getSensorUniqueCode(monitoringSensor),
      },
      sensorPosition,
      type,
      active,
      maintenance,
      getSensorSpriteIconName(getIconBasicInfoFromSensor(monitoringSensor.configuration, sensorStatus || undefined)),
      sensorStatus || undefined,
    );
  });
  return [...regularSensorFeatures, ...toSubSensorIconFeatures(withSubSensors)];
};

export const toMonitoringSensorLabelFeatures = ({
  regularSensors,
  withSubSensors,
}: SplitMonitoringSensors): Feature[] => {
  const regularSensorFeatures = regularSensors.map((monitoringSensor) =>
    toSensorLabelFeature(
      {
        sensorUniqueCode: getSensorUniqueCode(monitoringSensor),
      },
      monitoringSensor.configuration.sensorPosition,
      monitoringSensor.configuration.name,
      monitoringSensor.configuration.type,
    ),
  );

  return [...regularSensorFeatures, ...toSubSensorLabelFeatures(withSubSensors)];
};

export const toPerimeterLinkFeatures = (
  automaton: AutomatonConfiguration,
  cabinet: AutomatonCabinet,
  sections: AutomatonSection[],
  mapFilters: MapFilters,
): Feature[] => {
  const automatonPosition = toLonLatArray(automaton.sensorPosition, false)!;
  const cabinetPosition = toLonLatArray(cabinet.position, false)!;
  const featureLinks: Feature[] = [];
  if (mapFilters.displayPerimeterSensorCabinet) {
    if (mapFilters.displayPerimeterSensorAutomaton) {
      featureLinks.push({
        type: 'Feature',
        properties: {
          featureType: FeatureTypeEnum.PERIMETER_LINK,
        },
        geometry: {
          type: 'LineString',
          coordinates: [automatonPosition, cabinetPosition],
        },
      });
    }
    if (mapFilters.displayPerimeterSensorSegment) {
      sections.forEach((section) => {
        const sectionCenter = toLonLatArray(getSectionCenterCoordinates(section), false)!;
        featureLinks.push({
          type: 'Feature',
          properties: {
            featureType: FeatureTypeEnum.PERIMETER_LINK,
          },
          geometry: {
            type: 'LineString',
            coordinates: [cabinetPosition, sectionCenter],
          },
        } as Feature);
      });
    }
  }

  return featureLinks;
};
