import { Box } from '@chakra-ui/layout';
import { memo } from 'react';

type Props = {
  hasControl: boolean;
  isSensorTracking?: boolean;
  isVideoTracking?: boolean;
};

function CameraBorder({ hasControl, isSensorTracking, isVideoTracking }: Readonly<Props>) {
  function getColor() {
    if (isVideoTracking) {
      return 'common.red';
    }
    if (isSensorTracking) {
      return 'common.blue';
    }
    if (hasControl) {
      return 'common.orange';
    }
    return 'transparent';
  }

  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      border="8px solid"
      borderColor={getColor()}
      zIndex={1}
      opacity={0.85}
      pointerEvents="none"
    />
  );
}

export default memo(CameraBorder);
