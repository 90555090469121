import { ProcedureModel } from '@/types/config/procedure.types';
import { GlobalScreenConfiguration } from '@/types/config/screenConfiguration.types';
import { DroneAlertLevelEnum } from '@/types/data/data.types';

import { Abstract, Coordinates, Coordinates3D, WithHeight, WithMargin } from '../commons/commons.types';
import { GridConfiguration, GridLayout } from './gridLayout.types';
import { Home } from './home.types';
import { MergingConfiguration } from './merging.types';
import { PointOfInterest } from './pointOfInterest.types';
import { RoundConfiguration } from './round.types';
import { NimbleConfiguration } from './videoServer.types';
import { VideoStream } from './videoStream.types';

export enum ZoneCategoryEnum {
  NIZ = 'NIZ',
  NFZ = 'NFZ',
  NFZ_3D = 'NFZ_3D',
  ZOI = 'ZOI',
  IMZ = 'IMZ',
  NNZ = 'NNZ',
}

export interface AbstractZone extends Abstract {
  id: number;
  code: string;
  name: string;
  site: string;
  active: boolean;
}

export interface AbstractZone2d extends AbstractZone {
  polygon: Coordinates[];
}

export interface AbstractZone3d extends AbstractZone {
  solidPolygon: SolidPolygon3d;
}

export interface SolidPolygon3d {
  polygonFaces: PolygonWithAltitude[];
}

export interface PolygonWithAltitude {
  polygon: Coordinates3D[];
}

export type ZoneWithHeight = AbstractZone2d & WithHeight;

export type ZoneWithMargin = AbstractZone2d & WithMargin;

export interface NoInitZone extends ZoneWithHeight {
  type: ZoneCategoryEnum.NIZ;
}

export interface NoFlyZone extends ZoneWithHeight {
  type: ZoneCategoryEnum.NFZ;
  level: DroneAlertLevelEnum;
}

export interface NoFlyZone3d extends AbstractZone3d {
  type: ZoneCategoryEnum.NFZ_3D;
  level: DroneAlertLevelEnum;
}

export interface ZoneOfInterest extends ZoneWithHeight {
  type: ZoneCategoryEnum.ZOI;
  color: string;
  nature: string;
}

export interface InitializationMaskingZone extends ZoneWithHeight, ZoneWithMargin {
  type: ZoneCategoryEnum.IMZ;
}

export interface NoNeutralizationZone extends AbstractZone2d {
  type: ZoneCategoryEnum.NNZ;
}

export type Zone =
  | NoFlyZone
  | InitializationMaskingZone
  | NoInitZone
  | ZoneOfInterest
  | NoNeutralizationZone
  | NoFlyZone3d;

export type Platform = {
  id: number;
  code: string;
  name: string;
  position: Coordinates;
  altitude: number;
  mapZoom: number; // Min 1, Max 17
};

export interface ConfigConfiguration {
  home: Home;
  site: string;
  noFlyZones: NoFlyZone[];
  noFlyZones3d: NoFlyZone3d[];
  initializationMaskingZones: InitializationMaskingZone[];
  noInitZones: NoInitZone[];
  zoneOfInterests: ZoneOfInterest[];
  noNeutralizationZones: NoNeutralizationZone[];
  pointOfInterests: PointOfInterest[];
  layouts: GridLayout[];
  gridConfigurations: GridConfiguration[];
  globalScreenConfigurations: GlobalScreenConfiguration[];
  videoStreams: VideoStream[];
  mergingConfiguration: MergingConfiguration;
  videoConfiguration: NimbleConfiguration;
  roundConfigurations: RoundConfiguration[];
  platforms: Platform[];
  procedureModels: ProcedureModel[];
}

export enum Zone2DTypeEnum {
  NIZ = 'niz',
  IMZ = 'imz',
  NFZ_INFO = 'observation',
  NFZ_WARNING = 'vigilance',
  NFZ_CRITICAL = 'instantDanger', // Valeurs importantes pour les clés de traductions du formulaire zone
}

export enum Zone3DTypeEnum {
  NFZ_INFO_3D = 'observation3d',
  NFZ_WARNING_3D = 'vigilance3d',
  NFZ_CRITICAL_3D = 'instantDanger3d',
}

export type ZoneFormTypeEnum = Zone2DTypeEnum | Zone3DTypeEnum;

export const zoneCategoryToTypeFormMapper = (zoneCat: ZoneCategoryEnum, zoneInfo: DroneAlertLevelEnum | undefined) => {
  switch (zoneCat) {
    case ZoneCategoryEnum.NFZ_3D:
      switch (zoneInfo) {
        case DroneAlertLevelEnum.DRONE_INFO:
          return Zone3DTypeEnum.NFZ_INFO_3D;
        case DroneAlertLevelEnum.DRONE_WARNING:
          return Zone3DTypeEnum.NFZ_WARNING_3D;
        case DroneAlertLevelEnum.DRONE_CRITICAL:
          return Zone3DTypeEnum.NFZ_CRITICAL_3D;
        // Shouldn't happen
        case undefined:
          return undefined;
      }
      break;
    case ZoneCategoryEnum.NFZ:
      switch (zoneInfo) {
        case DroneAlertLevelEnum.DRONE_INFO:
          return Zone2DTypeEnum.NFZ_INFO;
        case DroneAlertLevelEnum.DRONE_WARNING:
          return Zone2DTypeEnum.NFZ_WARNING;
        case DroneAlertLevelEnum.DRONE_CRITICAL:
          return Zone2DTypeEnum.NFZ_CRITICAL;
        // Shouldn't happen
        case undefined:
          return undefined;
      }
      break;
    case ZoneCategoryEnum.IMZ:
      return Zone2DTypeEnum.IMZ;
    case ZoneCategoryEnum.NIZ:
      return Zone2DTypeEnum.NIZ;
    //Not implemented yet
    case ZoneCategoryEnum.NNZ:
    case ZoneCategoryEnum.ZOI:
    default:
      return undefined;
  }
};

export type ZoneToEditType = NoInitZone | NoFlyZone | InitializationMaskingZone;

export const zoneLabelFromZone2DType: Record<Zone2DTypeEnum, string> = {
  [Zone2DTypeEnum.NIZ]: 'radarZone.niz',
  [Zone2DTypeEnum.IMZ]: 'radarZone.imz',
  [Zone2DTypeEnum.NFZ_INFO]: 'dangerZone.observation',
  [Zone2DTypeEnum.NFZ_WARNING]: 'dangerZone.vigilance',
  [Zone2DTypeEnum.NFZ_CRITICAL]: 'dangerZone.instantDanger',
};

export const SEE_POI = 'see_POI';
