import { Properties } from '@turf/helpers';
import { PERIMETER_CAMERA_FILL_COLOR, SELECTED_PERIMETER_CAMERA_FILL_COLOR } from '@utils/map/map.constants';
import { getCameraMiddlePointAzimuthLine, getSector, getSectorAzimuthLine } from '@utils/map/sector.utils';
import { getSensorUniqueKey } from '@utils/sensors/sensors.utils';
import { Feature, LineString, Polygon, Position } from 'geojson';

import { Coordinates } from '@/types/commons/commons.types';
import { FeatureTypeEnum } from '@/types/map.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';

function computeCameraSector(camera: PerimeterCamera): Feature<Polygon> | null {
  const bearing1 = camera.azimuth! - camera.fov! / 2;
  const bearing2 = camera.azimuth! + camera.fov! / 2;

  return getSector(camera.coordinates as Coordinates, bearing1, bearing2, camera.range!);
}

function computeCameraAzimuthLine(camera: PerimeterCamera): Feature<LineString, Properties> {
  return getSectorAzimuthLine(camera.coordinates as Coordinates, camera.range!, camera.azimuth!) as Feature<
    LineString,
    Properties
  >;
}

function computeCameraMiddlePointAzimuthLine(camera: PerimeterCamera): Position {
  return getCameraMiddlePointAzimuthLine(camera.coordinates as Coordinates, camera.range!, camera.azimuth!) as Position;
}

export const toPerimeterCameraLabelFeatures = (perimeterCameras: PerimeterCamera[]): Feature[] => {
  return perimeterCameras.map((perimeterCamera) => {
    const camStrobePoint = computeCameraMiddlePointAzimuthLine(perimeterCamera);
    return {
      type: 'Feature',
      properties: {
        id: perimeterCamera.uniqueKey,
        featureType: FeatureTypeEnum.PERIMETER_CAMERA_LABEL,
        value: perimeterCamera,
        textField: perimeterCamera.name,
      },
      geometry: {
        type: 'Point',
        coordinates: camStrobePoint,
      },
    } as unknown as Feature;
  });
};

export const toPerimeterCameraSectorFeatures = (
  perimeterCameras: PerimeterCamera[],
  selectedPerimeterCameraUniqueKeys: string[],
): Feature[] =>
  perimeterCameras
    .map((perimeterCamera) => {
      const sector = computeCameraSector(perimeterCamera);
      if (!sector) {
        return null;
      }
      const uniqueKey = getSensorUniqueKey(perimeterCamera);
      return {
        type: 'Feature',
        properties: {
          id: uniqueKey,
          featureType: FeatureTypeEnum.PERIMETER_CAMERA_SECTOR,
          value: perimeterCamera,
          color: selectedPerimeterCameraUniqueKeys.includes(uniqueKey)
            ? SELECTED_PERIMETER_CAMERA_FILL_COLOR
            : PERIMETER_CAMERA_FILL_COLOR,
        },
        geometry: {
          type: 'Polygon',
          coordinates: sector.geometry.coordinates,
        },
      } as Feature;
    })
    .filter((sector) => !!sector)
    .map((sector) => sector);

export const toPerimeterCameraAzimuthLineFeatures = (perimeterCameras: PerimeterCamera[]): Feature[] =>
  perimeterCameras.map((perimeterCamera) => {
    const azimuthLine = computeCameraAzimuthLine(perimeterCamera);
    return {
      type: 'Feature',
      properties: {
        id: perimeterCamera.uniqueKey,
        featureType: FeatureTypeEnum.PERIMETER_CAMERA_AZIMUTH,
        value: perimeterCamera,
        color: 'black',
      },
      geometry: {
        type: 'LineString',
        coordinates: azimuthLine.geometry.coordinates,
      },
    } as Feature;
  });
