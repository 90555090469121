import { Tab, TabList, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import {
  selectSensorConfigurationByUniqueCode,
  selectSensorOrSubSensorConfigurationByUniqueCodes,
  selectSensorOrSubSensorStatusByUniqueCodes,
} from '@redux/situation/monitoring.selectors';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PopupPositionData, SensorInfoData } from '@/types/map.types';
import { SensorConfiguration, SubSensorConfiguration } from '@/types/sensor/configuration.types';

import DraggableControl from '../../controls/DraggableControl';
import Commands from './command/Commands';
import Description from './description/Description';
import Maintenance from './maintenance/Maintenance';

function SensorInfo({
  featureValue: sensorUniqueCodes,
  top,
  left,
  onClose,
}: Readonly<SensorInfoData & PopupPositionData>) {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null);

  const sensorConfiguration = useSelectorWithReplayMode(
    selectSensorOrSubSensorConfigurationByUniqueCodes,
    sensorUniqueCodes,
  );

  const sensorStatus = useSelectorWithReplayMode(selectSensorOrSubSensorStatusByUniqueCodes, sensorUniqueCodes);

  const parentConfiguration = useSelectorWithReplayMode(
    selectSensorConfigurationByUniqueCode,
    sensorUniqueCodes.parentUniqueCode,
  );

  if (!sensorConfiguration) {
    onClose();
    return null;
  }

  const sensorType = parentConfiguration ? parentConfiguration.type : (sensorConfiguration as SensorConfiguration).type;
  const subSensorType = parentConfiguration ? (sensorConfiguration as SubSensorConfiguration).type : null;

  const commands = Commands({ sensorStatus, sensorUniqueCodes, type: subSensorType ?? sensorType });
  const maintenance = Maintenance({
    parentConfiguration,
    sensorConfiguration,
    sensorStatus,
    sensorUniqueCodes,
    type: subSensorType ?? sensorType,
  });

  return (
    <DraggableControl
      top={top}
      left={left}
      key={sensorUniqueCodes.sensorUniqueCode}
      offsetY={-200}
      width={489}
      label={formatMessage({ id: `sensors.type.${subSensorType ?? sensorType}` })}
      onClose={onClose}
    >
      <Tabs width="100%" height="max-content" variant="cyber" ref={ref}>
        <TabList>
          <Tab>
            <Text size="md">
              <FormattedMessage id="sensors.tab.description" />
            </Text>
          </Tab>
          {commands !== null && (
            <Tab>
              <Text size="md">
                <FormattedMessage id="sensors.tab.command" />
              </Text>
            </Tab>
          )}
          {maintenance !== null && (
            <Tab>
              <Text size="md" noOfLines={1} overflow="hidden" textOverflow="ellipsis">
                <FormattedMessage id="sensors.tab.maintenance" />
              </Text>
            </Tab>
          )}
        </TabList>

        <TabPanels height="100%">
          <Description
            parentConfiguration={parentConfiguration}
            sensorConfiguration={sensorConfiguration}
            sensorStatus={sensorStatus}
            sensorUniqueCodes={sensorUniqueCodes}
            subSensorType={subSensorType}
            sensorType={sensorType}
          />
          {commands}
          {maintenance}
        </TabPanels>
      </Tabs>
    </DraggableControl>
  );
}

export default SensorInfo;
