import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { ReactComponent as CommentsIcon } from '@assets/icons/24x24/ic-comments-24.svg';
import { ReactComponent as HistoryIcon } from '@assets/icons/24x24/ic-history-24.svg';
import { ReactComponent as ProcedureIcon } from '@assets/icons/24x24/ic-process-24.svg';
import {
  Box,
  Center,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectNotificationById } from '@redux/data/data.selectors';
import { getNotificationName } from '@utils/data/notification.utils';
import { FormattedMessage } from 'react-intl';

import { getNotificationIcon } from '../card/NotificationCardName';
import NotificationHistory from './NotificationHistory';

type Props = {
  selectedNotification: number | null;
};

export default function NotificationDetail({ selectedNotification }: Readonly<Props>) {
  const notification = useAppSelector((state) => selectNotificationById(state, selectedNotification ?? undefined));

  if (notification === undefined) {
    return (
      <Box
        width="466px"
        height="100%"
        flexShrink={0}
        backgroundColor="neutral.800-op70"
        borderLeft="2px solid"
        borderColor="neutral.black"
      >
        <Center width="100%" height="100%">
          <VStack gap={2} paddingTop="58px">
            <Icon width="194px" height="194px" as={EmptyPanelDroneIcon} />
            <Text
              fontSize="18px"
              variant="space"
              color="neutral.200"
              maxWidth="230px"
              textAlign="center"
              userSelect="none"
            >
              <FormattedMessage
                id={`components.notification.detail.${selectedNotification !== null ? 'notFound' : 'empty'}`}
              />
            </Text>
          </VStack>
        </Center>
      </Box>
    );
  }

  const notificationName = getNotificationName(notification);
  return (
    <Box
      width="466px"
      height="100%"
      flexShrink={0}
      backgroundColor={selectedNotification ? 'neutral.900' : 'neutral.800-op70'}
      borderLeft="2px solid"
      borderColor="neutral.black"
    >
      <VStack gap={0.25} height="100%" width="100%" backgroundColor="neutral.900">
        <HStack gap={2} height="128px" flexShrink={0} width="100%" paddingX={10} backgroundColor="neutral.700">
          {getNotificationIcon(notification)}
          <Tooltip label={notificationName}>
            <Text
              paddingX={0.25}
              noOfLines={1}
              maxWidth="100%"
              backgroundColor="neutral.black"
              textTransform="uppercase"
              lineHeight="18px"
              wordBreak="break-all"
            >
              {notificationName}
            </Text>
          </Tooltip>
        </HStack>
        <Tabs width="100%" height="100%" variant="neutral" gap={0.25} defaultIndex={1} isLazy>
          <TabList>
            <Tab isDisabled>
              <Icon as={ProcedureIcon} width="24px" height="24px" color="inherit" />
            </Tab>
            <Tab>
              <Icon as={HistoryIcon} width="24px" height="24px" color="inherit" />
            </Tab>
            <Tab isDisabled>
              <Icon as={CommentsIcon} width="24px" height="24px" color="inherit" />
            </Tab>
          </TabList>
          <TabPanels height="100%">
            <TabPanel />
            <NotificationHistory
              notificationId={notification.id}
              notificationType={notification.type}
              currentAlertCound={notification.currentAlertCount}
            />
            <TabPanel />
          </TabPanels>
        </Tabs>
      </VStack>
    </Box>
  );
}
