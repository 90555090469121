import './tooltip.scss';

import { useAppSelector } from '@hooks/redux.hooks';
import { useMapTooltip } from '@hooks/useMapTooltip';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectPopupControlByMapId, selectZoomByMapId } from '@redux/maps/maps.selectors';
import { selectMapFilterByKey } from '@redux/settings/settings.selectors';
import { selectTargets } from '@redux/situation/situation.selectors';
import { isAircraft, isDrone } from '@utils/target.utils';

import { Target } from '@/types/c2/c2.types';
import { FeatureTypeEnum, LayerNameEnum, PopupDataTypeEnum } from '@/types/map.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

import { useMapContext } from '../MapContext';
import TargetPermanentTooltip from './TargetPermanentTooltip';

const PERMANENT_TOOLTIP_ZOOM_LEVEL = 15;

function PermanentTooltips() {
  const { mapId } = useMapContext();
  const isAircraftShown = useAppSelector((state) => selectMapFilterByKey(state, 'displayTargetAircraft'));
  const isDroneShown = useAppSelector((state) => selectMapFilterByKey(state, 'displayTargetDrone'));
  const [position, featureToDisplay] = useMapTooltip([
    LayerNameEnum.TARGETS,
    LayerNameEnum.EVENTS,
    LayerNameEnum.SENSOR_LABELS,
    LayerNameEnum.NO_FLY_ZONE_FILLS,
  ]);
  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const showMeasureTooltip = currentPopupControl.type === PopupDataTypeEnum.MEASURE_INFO;

  const targets: Target<TargetLocation>[] = useSelectorWithReplayMode(selectTargets);
  const zoomLevel = useAppSelector((state) => selectZoomByMapId(state, mapId));
  if (showMeasureTooltip || !zoomLevel || zoomLevel <= PERMANENT_TOOLTIP_ZOOM_LEVEL) {
    return null;
  }

  const permanentTargets = targets
    .filter((target) => (isAircraftShown && isAircraft(target)) || (isDroneShown && isDrone(target)))
    .filter(
      (target) =>
        !position ||
        !featureToDisplay ||
        featureToDisplay.type !== FeatureTypeEnum.TARGET_ICON ||
        target.id !== featureToDisplay.data.id,
    );
  return (
    <>
      {permanentTargets.map((target) => (
        <TargetPermanentTooltip target={target} key={target.id} />
      ))}
    </>
  );
}

export default PermanentTooltips;
