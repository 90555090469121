import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectAllOngoingNotifications } from '@redux/data/data.selectors';
import {
  filterNotifications,
  isLadNotification,
  isLadTechnicalNotification,
  isPerimeterNotification,
  isPerimeterTechnicalNotification,
  sortNotificationByCriticality,
  sortNotificationByDanger,
} from '@utils/data/notification.utils';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NotificationFilterEnum } from '@/types/data/data.types';

import PanelHeader from '../../../common/layout/PanelHeader';
import Panel from '../Panel';
import NotificationDetails from './detail/NotificationDetails';
import LiveNotificationList from './LiveNotificationList';
import NotificationHeader from './NotificationHeader';

export enum NotificationSortType {
  CRITICALITY = 'CRITICALITY',
  DANGER = 'DANGER',
}

export default function NotificationsPanel() {
  const { formatMessage } = useIntl();
  const [selectedNotification, setSelectedNotification] = useState<number | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<NotificationFilterEnum[]>(
    Object.values(NotificationFilterEnum),
  );
  const [selectedSort, setSelectedSort] = useState<NotificationSortType>(NotificationSortType.CRITICALITY);
  const notifications = useAppSelector(selectAllOngoingNotifications);

  function getCount(value: string): number | undefined {
    switch (value) {
      case NotificationFilterEnum.LAD:
        return notifications.filter(isLadNotification).length;
      case NotificationFilterEnum.PERIMETER:
        return notifications.filter((notification) => isPerimeterNotification(notification)).length;
      case NotificationFilterEnum.LAD_TECHNICAL:
        return notifications.filter(isLadTechnicalNotification).length;
      case NotificationFilterEnum.PERIMETER_TECHNICAL:
        return notifications.filter((notification) => isPerimeterTechnicalNotification(notification)).length;
      default:
        return undefined;
    }
  }

  const filteredNotification = filterNotifications(notifications, selectedFilters).sort(
    (notification1, notification2) => {
      switch (selectedSort) {
        case NotificationSortType.CRITICALITY:
          return sortNotificationByCriticality(notification1, notification2);
        case NotificationSortType.DANGER:
          return sortNotificationByDanger(notification1, notification2);
      }
    },
  );

  return (
    <Panel
      width={1492}
      isEmpty={false}
      gap={0}
      header={
        <PanelHeader label={formatMessage({ id: 'components.notification.title' })} count={notifications.length} />
      }
    >
      <HStack gap={0} width="100%" height="100%">
        <VStack gap={0.25} width="100%" height="100%">
          <NotificationHeader
            getCount={getCount}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            canSort={notifications.length > 0}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          <Box height="100%" width="100%" backgroundColor="neutral.800-op70">
            {filteredNotification.length > 0 ? (
              <LiveNotificationList
                notificationIds={filteredNotification.map((notif) => notif.id)}
                selectedNotification={selectedNotification}
                setSelectedNotification={setSelectedNotification}
              />
            ) : (
              <Center width="100%" height="100%">
                <VStack gap={3}>
                  <EmptyPanelDroneIcon />
                  <Text
                    fontSize="18px"
                    variant="space"
                    color="neutral.200"
                    maxWidth="230px"
                    textAlign="center"
                    userSelect="none"
                  >
                    <FormattedMessage id="components.notification.empty" />
                  </Text>
                </VStack>
              </Center>
            )}
          </Box>
        </VStack>
        <NotificationDetails selectedNotification={selectedNotification} />
      </HStack>
    </Panel>
  );
}
