import { TargetStatusEnum } from '@/types/c2/c2.types';
import { MarkEnum } from '@/types/commons/commons.types';
import {
  AlertContextEnum,
  AlertStatusEnum,
  AlertTypeEnum,
  DroneAlertLevelEnum,
  DroneNotificationLight,
  NotificationStatusEnum,
  NotificationTypeEnum,
  SegmentAlertLevelEnum,
  SegmentNotification,
  SensorAlertLevelEnum,
  SensorFailureAlert,
  SensorNotification,
  SpaceViolationAlert,
  VerticalTrespassEnum,
} from '@/types/data/data.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';
import { SensorTypeEnum } from '@/types/sensor/configuration.types';
import { ReliabilityEnum } from '@/types/sensor/identification.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';
import { DroneClearance } from '@/types/utm/drone.types';

import { PLATFORM } from './config.constants';

export const DRONE_NOTIFICATION_WARNING: DroneNotificationLight = {
  id: 0,
  site: 'local',
  identifier: 'drone xyz',
  type: NotificationTypeEnum.DRONE,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:10:13.721Z',
  expiredTime: null,
  occurrenceCount: 12,
  alertCount: 3,
  currentAlertCount: 2,
  hasUnacknowledgedAlert: true,
  worstAlertLevel: DroneAlertLevelEnum.DRONE_WARNING,
  currentWorstAlertLevel: DroneAlertLevelEnum.DRONE_WARNING,
  lastAlertLevel: DroneAlertLevelEnum.DRONE_WARNING,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  currentHeight: 50,
  maxHeight: 150,
  targetStatus: TargetStatusEnum.ONGOING,
  classification: ClassificationEnum.SUSPECT,
  hasFlightPlan: true,
  trackIds: [
    {
      id: '0',
      sensorCode: 'code',
      sensorType: SensorTypeEnum.ADSB,
      appCode: 'sensor_local',
    },
  ],
  reliability: ReliabilityEnum.SURE,
  clearance: DroneClearance.FULL_CLEARANCE,
};

export const DRONE_NOTIFICATION_CRITICAL: DroneNotificationLight = {
  id: 1,
  site: 'local',
  identifier: 'drone xyz',
  occurrenceCount: 1,
  type: NotificationTypeEnum.DRONE,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:09:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 2,
  hasUnacknowledgedAlert: true,
  worstAlertLevel: DroneAlertLevelEnum.DRONE_CRITICAL,
  currentWorstAlertLevel: DroneAlertLevelEnum.DRONE_CRITICAL,
  lastAlertLevel: DroneAlertLevelEnum.DRONE_INFO,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  currentHeight: 50,
  maxHeight: 150,
  targetStatus: TargetStatusEnum.ONGOING,
  classification: ClassificationEnum.ENEMY,
  hasFlightPlan: true,
  trackIds: [
    {
      id: '0',
      sensorCode: 'code',
      sensorType: SensorTypeEnum.ADSB,
      appCode: 'sensor_local',
    },
  ],
  reliability: ReliabilityEnum.SURE,
  clearance: DroneClearance.NO_CLEARANCE,
};

export const DRONE_NOTIFICATION_INFO: DroneNotificationLight = {
  id: 2,
  site: 'local',
  identifier: 'drone xyz',
  type: NotificationTypeEnum.DRONE,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:08:13.721Z',
  expiredTime: null,
  occurrenceCount: 2,
  alertCount: 3,
  currentAlertCount: 2,
  hasUnacknowledgedAlert: true,
  worstAlertLevel: DroneAlertLevelEnum.DRONE_INFO,
  currentWorstAlertLevel: DroneAlertLevelEnum.DRONE_INFO,
  lastAlertLevel: DroneAlertLevelEnum.DRONE_INFO,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  currentHeight: 50,
  maxHeight: 150,
  targetStatus: TargetStatusEnum.ONGOING,
  classification: ClassificationEnum.WATCH,
  hasFlightPlan: true,
  trackIds: [
    {
      id: '0',
      sensorCode: 'code',
      sensorType: SensorTypeEnum.ADSB,
      appCode: 'sensor_local',
    },
  ],
  reliability: ReliabilityEnum.SURE,
  clearance: DroneClearance.FULL_CLEARANCE_WITHIN_FLIGHTPLAN,
};

export const SEGMENT_FAILURE_NOTIFICATION: SegmentNotification = {
  id: 4,
  site: 'local',
  identifier: 'failure x',
  type: NotificationTypeEnum.SEGMENT,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:07:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 1,
  hasUnacknowledgedAlert: false,
  segmentName: 'segmentA',
  cabinetName: 'cabinetA',
  worstAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  currentWorstAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  lastAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
    {
      id: 1,
      type: MarkEnum.PLATFORM,
      context: 'CDG1',
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  segmentStatus: SensorStatusEnum.DEGRADED,
};

export const PERIMETER_EFFRACTION_NOTIFICATION: SegmentNotification = {
  id: 3,
  site: 'local',
  identifier: 'effraction x',
  type: NotificationTypeEnum.SEGMENT,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:07:33.721Z',
  expiredTime: '2025-01-21T13:06:13.721Z',
  alertCount: 3,
  currentAlertCount: 1,
  hasUnacknowledgedAlert: false,
  segmentName: 'segmentA',
  cabinetName: 'cabinetA',
  worstAlertLevel: SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
  currentWorstAlertLevel: SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
  lastAlertLevel: SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
    {
      id: 1,
      type: MarkEnum.PLATFORM,
      context: 'CDG1',
    },
  ],
  status: NotificationStatusEnum.EXPIRED,
  segmentStatus: SensorStatusEnum.EFFRACTION,
};

export const OLD_PERIMETER_EFFRACTION_NOTIFICATION: SegmentNotification = {
  id: 5,
  site: 'local',
  identifier: 'effraction x',
  type: NotificationTypeEnum.SEGMENT,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T13:06:13.721Z',
  expiredTime: '2025-01-21T13:07:13.721Z',
  alertCount: 3,
  currentAlertCount: 1,
  hasUnacknowledgedAlert: false,
  segmentName: 'segmentA',
  cabinetName: 'cabinetA',
  worstAlertLevel: SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
  currentWorstAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  lastAlertLevel: SegmentAlertLevelEnum.SEGMENT_FAILURE,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.EXPIRED,
  segmentStatus: SensorStatusEnum.DEGRADED,
};

export const SENSOR_DISCONNECTED_NOTIFICATION: SensorNotification = {
  id: 6,
  site: 'local',
  identifier: 'disconnected x',
  sensorName: 'Aeroscope',
  type: NotificationTypeEnum.SENSOR,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T12:05:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 1,
  hasUnacknowledgedAlert: false,
  worstAlertLevel: SensorAlertLevelEnum.SENSOR_DISCONNECTED,
  currentWorstAlertLevel: SensorAlertLevelEnum.SENSOR_DISCONNECTED,
  lastAlertLevel: SensorAlertLevelEnum.SENSOR_DISCONNECTED,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  sensorType: SensorTypeEnum.AEROSCOPE,
  sensorStatus: SensorStatusEnum.DISCONNECTED,
};

export const SENSOR_FAILURE_NOTIFICATION: SensorNotification = {
  id: 7,
  site: 'local',
  identifier: 'failure x',
  sensorName: 'Aeroscope',
  type: NotificationTypeEnum.SENSOR,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T12:04:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 1,
  hasUnacknowledgedAlert: false,
  worstAlertLevel: SensorAlertLevelEnum.SENSOR_FAILURE,
  currentWorstAlertLevel: SensorAlertLevelEnum.SENSOR_FAILURE,
  lastAlertLevel: SensorAlertLevelEnum.SENSOR_FAILURE,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  sensorType: SensorTypeEnum.AEROSCOPE,
  sensorStatus: SensorStatusEnum.DEGRADED,
};

export const SEGMENT_DISCONNECTED_NOTIFICATION: SegmentNotification = {
  id: 8,
  site: 'local',
  identifier: 'disconnected x',
  type: NotificationTypeEnum.SEGMENT,
  position: { latitude: 48, longitude: 0, type: '2D' },
  startTime: '2025-01-20T12:03:13.721Z',
  expiredTime: null,
  alertCount: 3,
  currentAlertCount: 1,
  hasUnacknowledgedAlert: false,
  segmentName: 'segmentA',
  cabinetName: 'cabinetA',
  worstAlertLevel: SegmentAlertLevelEnum.SEGMENT_DISCONNECTED,
  currentWorstAlertLevel: SegmentAlertLevelEnum.SEGMENT_DISCONNECTED,
  lastAlertLevel: SegmentAlertLevelEnum.SEGMENT_DISCONNECTED,
  marks: [
    {
      id: 0,
      type: MarkEnum.PLATFORM,
      context: PLATFORM.code,
    },
    {
      id: 1,
      type: MarkEnum.PLATFORM,
      context: 'CDG1',
    },
  ],
  status: NotificationStatusEnum.RUNNING,
  segmentStatus: SensorStatusEnum.DISCONNECTED,
};

export const SPACE_VIOLATION_ALERT: SpaceViolationAlert = {
  id: 0,
  site: 'local',
  identifier: 'alert',
  startTime: new Date().toISOString(),
  type: AlertTypeEnum.SPACE_VIOLATION,
  context: AlertContextEnum.OUTSIDE_FLIGHT_PLAN,
  level: DroneAlertLevelEnum.DRONE_CRITICAL,
  endTime: null,
  ackTime: null,
  ackUser: null,
  confirmationTime: null,
  confirmationUser: null,
  archivedTime: null,
  archivingUser: null,
  parentId: 1,
  zoneId: 1,
  zoneName: 'Zone critique',
  heightMin: 0,
  heightMax: 100,
  marks: [],
  verticalTrespass: VerticalTrespassEnum.ABOVE_MAX_HEIGHT,
  status: AlertStatusEnum.ACKNOWLEDGED,
};

export const SENSOR_FAILURE_ALERT: SensorFailureAlert = {
  id: 1,
  site: 'local',
  identifier: 'alert',
  startTime: new Date().toISOString(),
  type: AlertTypeEnum.SENSOR_FAILURE,
  context: AlertContextEnum.SENSOR_FAILURE,
  level: SensorAlertLevelEnum.SENSOR_FAILURE,
  endTime: null,
  ackTime: null,
  ackUser: null,
  confirmationTime: null,
  confirmationUser: null,
  archivedTime: null,
  archivingUser: null,
  parentId: 1,
  marks: [],
  status: AlertStatusEnum.ACKNOWLEDGED,
};
