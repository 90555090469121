import { Text } from '@chakra-ui/layout';
import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import PanelHeader from '@components/common/layout/PanelHeader';
import useCheatCode from '@hooks/useCheatCode';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Panel from '../Panel';
import AppVersion from './AppVersion';
import Groups from './Groups';
import Profiles from './Profiles';
import Users from './Users';

export default function AdminPanel() {
  const { formatMessage } = useIntl();
  const [showVersion, setShowVersion] = useState(false);
  useCheatCode('toggleVersion', 'vvv'.split(''), () => setShowVersion((prev) => !prev));

  return (
    <Panel width={1492} header={<PanelHeader label={formatMessage({ id: 'components.sidebar.ADMIN' })} />}>
      <Tabs height="100%" width="100%" variant="cyber">
        <TabList>
          <Tab>
            <Text size="md">
              <FormattedMessage id="components.admin.profiles.title" />
            </Text>
          </Tab>
          <Tab>
            <Text size="md">
              <FormattedMessage id="components.admin.users.title" />
            </Text>
          </Tab>
          <Tab>
            <Text size="md">
              <FormattedMessage id="components.admin.groups.title" />
            </Text>
          </Tab>
        </TabList>

        <TabPanels height="100%" width="100%">
          <Profiles />
          <Users />
          <Groups />
        </TabPanels>
      </Tabs>
      {showVersion ? <AppVersion /> : <></>}
    </Panel>
  );
}
