import { ReactComponent as BalanceIcon } from '@assets/icons/32x32/ic-balance-32.svg';
import { ReactComponent as CaptureIcon } from '@assets/icons/32x32/ic-capture-32.svg';
import { ReactComponent as PolarityIcon } from '@assets/icons/32x32/ic-contrast-32.svg';
import { ReactComponent as GaugeIcon } from '@assets/icons/32x32/ic-elvAzim-32.svg';
import { ReactComponent as FactoryPositionIcon } from '@assets/icons/32x32/ic-factory-mode-32_TEMP.svg';
import { ReactComponent as AutoFocusIcon } from '@assets/icons/32x32/ic-focusAuto-32.svg';
import { ReactComponent as ManualFocusIcon } from '@assets/icons/32x32/ic-focusManual-32.svg';
import { ReactComponent as IsoIcon } from '@assets/icons/32x32/ic-iso-32.svg';
import { ReactComponent as JoystickIcon } from '@assets/icons/32x32/ic-joystick-32.svg';
import { ReactComponent as ParkingPositionIcon } from '@assets/icons/32x32/ic-parking-mode-32_TEMP.svg';
import { ReactComponent as PipIcon } from '@assets/icons/32x32/ic-pip-32.svg';
import { ReactComponent as RoundIcon } from '@assets/icons/32x32/ic-round-32.svg';
import { ReactComponent as CrosshairIcon } from '@assets/icons/32x32/ic-sight-32.svg';
import { ReactComponent as SyncIcon } from '@assets/icons/32x32/ic-sync-32.svg';
import { ReactComponent as SensorTrackingIcon } from '@assets/icons/32x32/ic-tracking-carto-32.svg';
import { ReactComponent as VideoTrackingIcon } from '@assets/icons/32x32/ic-tracking-video-32.svg';
import { ReactComponent as VirtualJoystickIcon } from '@assets/icons/32x32/ic-virtualJoystick-32.svg';
import { ReactComponent as ZoomInIcon } from '@assets/icons/32x32/ic-zoomIn-32.svg';
import { ReactComponent as ZoomOutIcon } from '@assets/icons/32x32/ic-zoomOut-32.svg';
import { HStack } from '@chakra-ui/layout';
import { ButtonGroup, Center, VStack } from '@chakra-ui/react';
import ButtonBar from '@components/common/inputs/buttons/ButtonBar';
import ButtonBarPanel from '@components/common/inputs/buttons/ButtonBarPanel';
import CustomSliderWithMark from '@components/common/inputs/CustomSliderWithMark';
import PanelHeader from '@components/common/layout/PanelHeader';
import MenuList from '@components/common/menu/MenuList';
import RoundContent from '@components/map/popup/round/RoundContent';
import { useAppSelector } from '@hooks/redux.hooks';
import { UseCameraCommandFunctions } from '@hooks/useCameraCommand';
import { useSelectorWithMapIdFromContext } from '@hooks/useSelectorWithMapIdFromContext';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { hasSensorRoleFromActiveProfile } from '@redux/authent/authent.selectors';
import { updateJoystickControlId } from '@redux/global/global.reducer';
import { selectJoystickControlId, selectJoystickControlledCam } from '@redux/global/global.selector';
import { selectCameraConfigurationByUniqueCode } from '@redux/sensors/sensors.selectors';
import { selectSelectedTarget } from '@redux/situation/situation.selectors';
import { modulo } from '@utils/math.utils';
import {
  CameraAvailableFeatures,
  cameraAvailableFeatures,
  subCamera,
  SubCameraType,
} from '@utils/sensors/camera/camera.constants';
import { createToast } from '@utils/toast.utils';
import { isEqual } from 'lodash';
import { Dispatch } from 'react';
import { FaSnowflake } from 'react-icons/fa';
import { useIntl } from 'react-intl';

import { IsoModeEnum, WhiteBalanceModeEnum } from '@/types/c2/c2.types';
import { ActionEnum } from '@/types/commons/commons.types';
import { CameraConfiguration } from '@/types/sensor/configuration.types';
import { SensorFamilyEnum } from '@/types/sensor/sensor.types';
import { CameraStatus, FocusModeEnum, TrackingStatusEnum, TrackingTypeEnum } from '@/types/sensor/status.types';

import { ControlState, GaugeStyleEnum } from './CameraControl';
import CameraTakeControlButton from './CameraTakeControlButton';

type Props = {
  cameraStatus: CameraStatus | null;
  selectedCameraCode: string | null;
  replayMode: boolean;
  controlState: ControlState;
  setControlState: Dispatch<Partial<ControlState>>;
  useCamera: UseCameraCommandFunctions;
  uniqueKey: string;
  downloadScreenshot: () => void;
};

export default function CameraLadButtons({
  cameraStatus,
  selectedCameraCode,
  replayMode,
  controlState,
  uniqueKey,
  useCamera,
  downloadScreenshot,
  setControlState,
}: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const updateJoystickControlIdWithDispatch = useWithDispatch(updateJoystickControlId);
  const joystickControlId = useAppSelector(selectJoystickControlId);
  const sensorAction = { action: ActionEnum.COMMAND, sensorFamily: SensorFamilyEnum.CAMERA };
  const accessCamCommand = useAppSelector((state) => hasSensorRoleFromActiveProfile(state, sensorAction));
  const controlledCamera = useAppSelector(selectJoystickControlledCam);
  const videoTrackingStateOfSelectedFlux =
    (controlState.displayedFlux &&
      cameraStatus?.trackingStatus.videoTrackingStatus.holopticsVideoTrackings[controlState.displayedFlux.cameraId]) ??
    TrackingStatusEnum.DISABLED;
  const cameraStoreConfig: CameraConfiguration | null = useAppSelector(
    (state) => selectCameraConfigurationByUniqueCode(state, selectedCameraCode),
    isEqual,
  );

  const cameraConfiguration = cameraStatus?.configuration ?? null;
  const cameraType = cameraStatus?.type ?? cameraStoreConfig?.type ?? null;

  const subCameraStatus =
    cameraStatus?.videoStatuses.find((videoStatus) => videoStatus.id === controlState.displayedFlux?.cameraId) ?? null;
  const subCameraType =
    cameraConfiguration &&
    subCameraStatus &&
    subCamera[cameraConfiguration.type].find((value) => value.fluxId === subCameraStatus.id);
  const isTracking =
    cameraStatus?.trackingStatus.globalTrackingState === TrackingStatusEnum.ACTIVE ||
    cameraStatus?.trackingStatus.globalTrackingState === TrackingStatusEnum.LOST;
  const sensorTrackingState =
    cameraStatus?.trackingStatus.sensorTrackingStatus.globalSensorTrackingState ?? TrackingStatusEnum.DISABLED;
  const isSensorTracking =
    isTracking &&
    cameraStatus.trackingStatus.globalTrackingType === TrackingTypeEnum.SENSOR &&
    sensorTrackingState === TrackingStatusEnum.ACTIVE;

  const selectedTarget = useSelectorWithMapIdFromContext(selectSelectedTarget);
  const availableFeatures = cameraType ? cameraAvailableFeatures[cameraType] : null;
  const isDisabled = !useCamera.canCommand || !useCamera.isSubCameraAvailable;

  function handleSensorTracking() {
    if (useCamera.canCommand && !replayMode && accessCamCommand) {
      if (!isSensorTracking && sensorTrackingState !== TrackingStatusEnum.PENDING) {
        if (selectedTarget) {
          useCamera.sensorTracking(selectedTarget, false);
        } else {
          createToast(formatMessage({ id: 'target.noSelected' }));
        }
      } else {
        useCamera.sensorTracking(null, false);
      }
    }
  }

  const isAutoFocusOn = subCameraStatus?.focusMode === FocusModeEnum.AUTO;

  function handleIsoClick(value: IsoModeEnum) {
    useCamera.setIso(value);
    setControlState({ iso: !controlState.iso });
  }

  function handleWhiteBalanceClick(value: WhiteBalanceModeEnum) {
    useCamera.setWhiteBalance(value);
    setControlState({ whiteBalance: !controlState.whiteBalance });
  }

  function handleOpenMenu(newState: Partial<ControlState>) {
    setControlState({ iso: false, whiteBalance: false, round: false, hybridFocus: false, ...newState });
  }

  function isFeatureActive(feature: keyof CameraAvailableFeatures, subCameraType?: SubCameraType): boolean {
    if (!availableFeatures) {
      return false;
    }

    const featureValue = availableFeatures[feature];

    if (featureValue === undefined) {
      return false;
    }

    if (typeof featureValue === 'boolean') {
      return featureValue;
    } else if (subCameraType !== undefined) {
      return featureValue.includes(subCameraType);
    }
    return false;
  }

  if (replayMode || !accessCamCommand || !selectedCameraCode) {
    return (
      <HStack gap={5}>
        {selectedCameraCode && (
          <CameraTakeControlButton
            cameraStatus={cameraStatus}
            replayMode={replayMode}
            selectedCameraCode={selectedCameraCode}
          />
        )}
        <ButtonGroup isAttached gap={0.25}>
          <ButtonBar
            label={formatMessage({ id: 'cameras.cameraMenu.capture' })}
            icon={CaptureIcon}
            size="sm"
            onClick={downloadScreenshot}
            isDisabled={cameraStatus === null}
          />
          <ButtonBar
            label={formatMessage({ id: 'cameras.cameraMenu.crosshair' })}
            icon={CrosshairIcon}
            size="sm"
            isActive={controlState.displayCross}
            isHidden={!selectedCameraCode}
            onClick={() => {
              setControlState({ displayCross: !controlState.displayCross });
            }}
          />
          <ButtonBar
            label={formatMessage({ id: 'cameras.cameraMenu.gaugeStyle' })}
            icon={GaugeIcon}
            size="sm"
            isHidden={!selectedCameraCode}
            onClick={() => {
              const styles = Object.values(GaugeStyleEnum);
              setControlState({
                gaugeStyle: styles[modulo(styles.indexOf(controlState.gaugeStyle) + 1, 4)],
              });
            }}
          />
        </ButtonGroup>
      </HStack>
    );
  }

  return (
    <HStack gap={4}>
      <ButtonBarPanel
        icon={PipIcon}
        isActive={controlState.pip}
        isDisabled={controlState.pipFlux.length === 0}
        onClick={() => setControlState({ pip: !controlState.pip })}
      />
      <CameraTakeControlButton
        useCamera={useCamera}
        cameraStatus={cameraStatus}
        selectedCameraCode={selectedCameraCode}
      />
      <ButtonGroup isAttached gap={0.25}>
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.sensorTracking' })}
          icon={SensorTrackingIcon}
          size="sm"
          variant="blue"
          isSelected={sensorTrackingState === TrackingStatusEnum.ACTIVE}
          isActive={sensorTrackingState === TrackingStatusEnum.PENDING}
          isDisabled={isDisabled}
          onClick={handleSensorTracking}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.videoTracking' })}
          icon={VideoTrackingIcon}
          size="sm"
          variant="red"
          isSelected={videoTrackingStateOfSelectedFlux === TrackingStatusEnum.ACTIVE}
          isActive={videoTrackingStateOfSelectedFlux === TrackingStatusEnum.PENDING}
          isDisabled={isDisabled}
          onClick={() => useCamera.videoBuffering()}
        />
        <ButtonBar
          label={formatMessage({ id: 'rounds.roundPopup' })}
          icon={RoundIcon}
          size="sm"
          hideTooltip={controlState.round}
          onClick={() => handleOpenMenu({ round: !controlState.round })}
          isHidden={!availableFeatures?.roundOn}
          isDisabled={!availableFeatures?.roundOn || !useCamera.canCommand}
          isActive={controlState.round}
        >
          {selectedCameraCode && controlState.round && (
            <VStack gap={0} height="fit-content" width="496px">
              <PanelHeader
                label={formatMessage({ id: 'rounds.roundPopup' })}
                onClose={() => handleOpenMenu({ round: false })}
              />
              <RoundContent sensorUniqueCodes={{ sensorUniqueCode: selectedCameraCode }} />
            </VStack>
          )}
        </ButtonBar>
      </ButtonGroup>
      <ButtonGroup isAttached gap={0.25}>
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.joystick' })}
          icon={JoystickIcon}
          size="sm"
          variant="orange"
          isSelected={joystickControlId === uniqueKey}
          isActive={controlledCamera.cameraUniqueCode === selectedCameraCode}
          onClick={() => updateJoystickControlIdWithDispatch(joystickControlId !== uniqueKey ? uniqueKey : null)}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.virtualJoystick' })}
          icon={VirtualJoystickIcon}
          size="sm"
          isActive={controlState.displayVirtualJoystick}
          onClick={() => {
            setControlState({ displayVirtualJoystick: !controlState.displayVirtualJoystick });
          }}
        />
      </ButtonGroup>
      <ButtonGroup isAttached gap={0.25}>
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.zoomIn' })}
          icon={ZoomInIcon}
          size="sm"
          onMouseDown={useCamera.zoomIn}
          onMouseUp={useCamera.zoomStop}
          isHidden={!isFeatureActive('zoom', subCameraType?.type)}
          isDisabled={!isFeatureActive('zoom', subCameraType?.type) || isDisabled}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.zoomOut' })}
          icon={ZoomOutIcon}
          size="sm"
          onMouseDown={useCamera.zoomOut}
          onMouseUp={useCamera.zoomStop}
          isHidden={!isFeatureActive('zoom', subCameraType?.type)}
          isDisabled={!isFeatureActive('zoom', subCameraType?.type) || isDisabled}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.zoomSync' })}
          icon={SyncIcon}
          size="sm"
          onClick={useCamera.zoomSync}
          isHidden={!isFeatureActive('zoomSync', subCameraType?.type)}
          isDisabled={!isFeatureActive('zoomSync', subCameraType?.type) || isDisabled}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.focusIn' })}
          size="sm"
          onMouseDown={useCamera.focusIn}
          onMouseUp={useCamera.focusStop}
          isHidden={!isFeatureActive('focus', subCameraType?.type)}
          isDisabled={!isFeatureActive('focus', subCameraType?.type) || isDisabled}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.focusOut' })}
          size="sm"
          onMouseDown={useCamera.focusOut}
          onMouseUp={useCamera.focusStop}
          isHidden={!isFeatureActive('focus', subCameraType?.type)}
          isDisabled={!isFeatureActive('focus', subCameraType?.type) || isDisabled}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.autoFocus' })}
          size="sm"
          icon={AutoFocusIcon}
          onClick={isAutoFocusOn ? useCamera.focusStop : useCamera.focusAuto}
          isActive={isAutoFocusOn}
          isHidden={!isFeatureActive('autoFocus', subCameraType?.type)}
          isDisabled={!isFeatureActive('autoFocus', subCameraType?.type) || isDisabled}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.hybridFocus' })}
          size="sm"
          icon={ManualFocusIcon}
          isActive={controlState.hybridFocus}
          isHidden={!isFeatureActive('hybridFocus', subCameraType?.type)}
          isDisabled={!isFeatureActive('hybridFocus', subCameraType?.type) || isDisabled}
          onClick={() => handleOpenMenu({ hybridFocus: !controlState.hybridFocus })}
        >
          {controlState.hybridFocus && (
            <Center
              height="119px"
              width="345px"
              backgroundColor="neutral.800"
              border="2px solid"
              borderColor="neutral.black"
              padding={4}
              paddingRight={5}
            >
              <CustomSliderWithMark
                value={subCameraStatus?.focusLevel ?? 5}
                onChange={(value) => useCamera.hybridFocus(value)}
              />
            </Center>
          )}
        </ButtonBar>
      </ButtonGroup>
      <ButtonGroup isAttached gap={0.25}>
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.iso' })}
          size="sm"
          icon={IsoIcon}
          isActive={controlState.iso}
          onClick={() => handleOpenMenu({ iso: !controlState.iso })}
          isHidden={!isFeatureActive('iso', subCameraType?.type)}
          isDisabled={!isFeatureActive('iso', subCameraType?.type) || !subCameraStatus || isDisabled}
        >
          {controlState.iso && (
            <MenuList
              parentLabel="cameras.cameraMenu.iso"
              handleClick={(_, value) => value?.onAction && value.onAction()}
              menuItems={Object.entries(IsoModeEnum).reduce(
                (accumulator, currentValue) => ({
                  ...accumulator,
                  [currentValue[1]]: {
                    label: `cameras.cameraMenu.${currentValue[0]}`,
                    onAction: () => handleIsoClick(currentValue[1]),
                  },
                }),
                {},
              )}
              depth={0}
              selectedOptions={[`${subCameraStatus?.iso}`]}
            />
          )}
        </ButtonBar>
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.whiteBalance' })}
          size="sm"
          icon={BalanceIcon}
          isActive={controlState.whiteBalance}
          onClick={() => handleOpenMenu({ whiteBalance: !controlState.whiteBalance })}
          isHidden={!isFeatureActive('whiteBalance', subCameraType?.type)}
          isDisabled={!isFeatureActive('whiteBalance', subCameraType?.type) || !subCameraStatus || isDisabled}
        >
          {controlState.whiteBalance && (
            <MenuList
              parentLabel="cameras.cameraMenu.whiteBalance"
              handleClick={(_, value) => value?.onAction && value.onAction()}
              menuItems={Object.entries(WhiteBalanceModeEnum).reduce(
                (accumulator, currentValue) => ({
                  ...accumulator,
                  [currentValue[1]]: {
                    label: `cameras.cameraMenu.${currentValue[0]}`,
                    onAction: () => handleWhiteBalanceClick(currentValue[1]),
                  },
                }),
                {},
              )}
              depth={0}
              selectedOptions={[`${subCameraStatus?.whiteBalance}`]}
            />
          )}
        </ButtonBar>
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.polarity' })}
          size="sm"
          icon={PolarityIcon}
          isActive={subCameraStatus?.isBlackHot}
          onClick={() => {
            useCamera.setPolarity(!subCameraStatus?.isBlackHot);
          }}
          isHidden={!isFeatureActive('polarity', subCameraType?.type)}
          isDisabled={!isFeatureActive('polarity', subCameraType?.type) || !subCameraStatus || isDisabled}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.deIcing' })}
          size="sm"
          icon={FaSnowflake}
          isActive={subCameraStatus?.isDeIcing}
          onClick={() => {
            useCamera.deIcing(!subCameraStatus?.isDeIcing);
          }}
          isHidden={!isFeatureActive('deIcing', subCameraType?.type)}
          isDisabled={!isFeatureActive('deIcing', subCameraType?.type) || !subCameraStatus || isDisabled}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.nuc' })}
          size="sm"
          onClick={() => useCamera.nuc()}
          isHidden={!isFeatureActive('nuc', subCameraType?.type)}
          isDisabled={!isFeatureActive('nuc', subCameraType?.type) || !subCameraStatus || isDisabled}
        />
      </ButtonGroup>
      <ButtonGroup isAttached gap={0.25}>
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.capture' })}
          icon={CaptureIcon}
          size="sm"
          onClick={downloadScreenshot}
          isDisabled={cameraStatus === null}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.crosshair' })}
          icon={CrosshairIcon}
          size="sm"
          isActive={controlState.displayCross}
          onClick={() => {
            setControlState({ displayCross: !controlState.displayCross });
          }}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.gaugeStyle' })}
          icon={GaugeIcon}
          size="sm"
          isHidden={!selectedCameraCode}
          onClick={() => {
            const styles = Object.values(GaugeStyleEnum);
            setControlState({ gaugeStyle: styles[modulo(styles.indexOf(controlState.gaugeStyle) + 1, 4)] });
          }}
        />
      </ButtonGroup>
      <ButtonGroup isAttached gap={0.25}>
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.factory' })}
          icon={FactoryPositionIcon}
          size="sm"
          onClick={useCamera.factoryPosition}
          isHidden={!isFeatureActive('factoryPosition', subCameraType?.type)}
          isDisabled={!isFeatureActive('factoryPosition', subCameraType?.type) || !subCameraStatus || isDisabled}
        />
        <ButtonBar
          label={formatMessage({ id: 'cameras.cameraMenu.parking' })}
          icon={ParkingPositionIcon}
          size="sm"
          onClick={useCamera.parkingPosition}
          isHidden={!isFeatureActive('parkingPosition', subCameraType?.type)}
          isDisabled={!isFeatureActive('parkingPosition', subCameraType?.type) || !subCameraStatus || isDisabled}
        />
      </ButtonGroup>
    </HStack>
  );
}
