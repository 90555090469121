import { SHOW_TARGET_ZOOM_EXCEPT_DRONE_AIRPLANE } from '@utils/map/mapZoom.constants';
import { AnyLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';

export const targetLayer: AnyLayer = {
  id: LayerNameEnum.TARGETS,
  type: 'symbol',
  source: SourceNameEnum.TARGETS,
  filter: [
    'all',
    ['==', ['get', 'featureType'], FeatureTypeEnum.TARGET_ICON],
    [
      'any',
      ['in', ['get', 'identification'], ['literal', [IdentificationEnum.DRONE, IdentificationEnum.AIRCRAFT]]],
      ['>=', ['zoom'], SHOW_TARGET_ZOOM_EXCEPT_DRONE_AIRPLANE],
    ],
  ],
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-size': 0.6,
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-pitch-alignment': 'viewport',
    'icon-rotation-alignment': 'viewport',
    'symbol-z-order': 'source',
  },
};

export const selectedTargetLayer: AnyLayer = {
  ...targetLayer,
  id: LayerNameEnum.TARGET_SELECTION,
  source: SourceNameEnum.SELECTION,
  filter: ['==', ['get', 'featureType'], FeatureTypeEnum.TARGET_ICON],
  layout: {
    ...targetLayer.layout,
    'icon-image': ['concat', ['get', 'icon'], '-halo'],
  },
};

export const targetCrossLayer: AnyLayer = {
  id: LayerNameEnum.TARGET_CROSSES,
  type: 'line',
  source: SourceNameEnum.TARGETS,
  filter: ['==', 'featureType', FeatureTypeEnum.TARGET_CROSS],
  layout: {
    visibility: 'visible',
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#FFFFFF',
    'line-width': 2,
    'line-opacity': ['get', 'opacity'],
  },
};

export const targetLinkLayer: AnyLayer = {
  id: LayerNameEnum.TARGET_LINKS,
  type: 'line',
  source: SourceNameEnum.TARGETS,
  minzoom: SHOW_TARGET_ZOOM_EXCEPT_DRONE_AIRPLANE,
  filter: ['==', 'featureType', FeatureTypeEnum.TARGET_LINK],
  layout: {
    visibility: 'visible',
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': ['get', 'color'],
    'line-width': 4,
    'line-opacity': 0.5,
  },
};

export const targetTrajectory: AnyLayer = {
  id: LayerNameEnum.TARGET_TRAJECTORIES,
  type: 'line',
  source: SourceNameEnum.TARGETS,
  filter: [
    'all',
    ['==', ['get', 'featureType'], FeatureTypeEnum.TARGET_TRAJECTORY],
    [
      'any',
      ['in', ['get', 'identification'], ['literal', [IdentificationEnum.DRONE, IdentificationEnum.AIRCRAFT]]],
      ['>=', ['zoom'], SHOW_TARGET_ZOOM_EXCEPT_DRONE_AIRPLANE],
    ],
  ],
  layout: {
    visibility: 'visible',
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': ['get', 'color'],
    'line-width': ['get', 'width'],
  },
};
