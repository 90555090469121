import { HStack } from '@chakra-ui/layout';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Coordinates, CoordinatesUnitEnum } from '@/types/commons/commons.types';

import CustomSelect from '../CustomStyledSelect';
import DDCoordinatesInput from './DDCoordinatesInput';
import DMSCoordinatesInput from './DMSCoordinatesInput';

type Props = {
  color?: string;
  coordinates: Coordinates;
  onLatitudeChange: (latitude: number | undefined) => void;
  onLongitudeChange: (longitude: number | undefined) => void;
};

export default function GPSCoordinateInput(props: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const [coordinatesUnit, setCoordinatesUnit] = useState<CoordinatesUnitEnum>(CoordinatesUnitEnum.DECIMAL_DEGREES);
  const coordinatesOptions = [
    { value: CoordinatesUnitEnum.DECIMAL_DEGREES, label: formatMessage({ id: 'common.units.DECIMAL_DEGREES' }) },
    { value: CoordinatesUnitEnum.DMS, label: formatMessage({ id: 'common.units.DMS' }) },
  ];
  return (
    <HStack gap={2} height="88px" width="606px" align="flex-start">
      <CustomSelect
        width={120}
        options={coordinatesOptions}
        isClearable={false}
        value={coordinatesUnit}
        onChange={(value) => {
          setCoordinatesUnit(value as CoordinatesUnitEnum);
        }}
      />
      {(() => {
        switch (coordinatesUnit) {
          case CoordinatesUnitEnum.DECIMAL_DEGREES:
            return <DDCoordinatesInput {...props} />;
          case CoordinatesUnitEnum.DMS:
            return <DMSCoordinatesInput {...props} />;
        }
      })()}
    </HStack>
  );
}
