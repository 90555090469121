import { Option } from '@components/common/inputs/CustomSelect';
import { appIntl } from '@components/locale/IntlGlobalProvider';

import { ExternalReplayStream, Stream } from '@/types/commons/commons.types';
import {
  CameraConfiguration,
  CameraLiteConfiguration,
  SensorConfiguration,
  SensorTypeEnum,
  SubCameraConfiguration,
  SubCameraLiteConfiguration,
} from '@/types/sensor/configuration.types';
import {
  CameraStatus,
  SensorStatus,
  SensorStatusEnum,
  TrackingStatusEnum,
  VideoStatus,
} from '@/types/sensor/status.types';

export function isCameraType(withType: SensorConfiguration | SensorStatus): boolean {
  return [SensorTypeEnum.HOLOPTICS, SensorTypeEnum.ZMER, SensorTypeEnum.ONVIF].includes(withType.type);
}

export function compareCameraRange(s1: VideoStatus, s2: VideoStatus): number {
  if (!s1.fov && !s2.fov) {
    return 0;
  }
  if (!s1.fov) {
    return 1;
  }
  if (!s2.fov) {
    return -1;
  }
  return s2.fov - s1.fov;
}

export function isHealthy(sensorStatus: SensorStatus): boolean {
  return (
    sensorStatus.status === SensorStatusEnum.HEALTHY ||
    sensorStatus.status === SensorStatusEnum.ROUND ||
    sensorStatus.status === SensorStatusEnum.TRACKING
  );
}

//Return the default flux used in cameraControl when a new configuration is selected
export function getDefaultFlux(cameraConfiguration: CameraConfiguration | null): SubCameraConfiguration | null {
  return cameraConfiguration?.subCameras.toSorted((camera1, camera2) => camera1.cameraId - camera2.cameraId)[0] ?? null;
}

export function calculateVerticalFov(fov?: number | null, resolutionX?: number, resolutionY?: number, precision = 1) {
  if (!fov || !resolutionX || !resolutionY) {
    return 0;
  }
  return Number((fov / (resolutionX / resolutionY)).toFixed(precision));
}

export function getNimbleStreamFromUrl(url: string | null) {
  if (!url) {
    return '';
  }
  if (url.startsWith('ws')) {
    return url;
  }

  return (
    (window['location'].protocol === 'https:' ? 'wss://' : 'ws://') +
    (import.meta.env.VITE_NIMBLE_URL?.split('://')[1] ?? window['location'].hostname) +
    url
  );
}

export function getNimbleStreamUrl(stream: Stream, replayMode = false) {
  const url = stream.url ?? stream.sourceUrl;
  const liveUrl = getNimbleStreamFromUrl(url);
  return replayMode ? liveUrl.replace('ws', 'http') : liveUrl;
}

export function getNimbleExternalReplayUrl(stream: ExternalReplayStream) {
  const url = stream.replayUrl ?? stream.sourceReplayUrl;
  return url && getNimbleStreamFromUrl(url);
}

export function cameraLiteToOption(
  camera: SubCameraLiteConfiguration | CameraLiteConfiguration,
  alternateName?: string,
): Option {
  return { label: camera.name ?? alternateName ?? 'Caméra supprimée', value: camera.code };
}

export function isCamInListByCode(
  cam: CameraLiteConfiguration | SubCameraLiteConfiguration | CameraConfiguration | SubCameraConfiguration,
  camList: (CameraLiteConfiguration | SubCameraLiteConfiguration | CameraConfiguration | SubCameraConfiguration)[],
): boolean {
  return camList.some((c) => c.code === cam.code);
}

export function getContextMenuCameraLabel(cameraStatus: CameraStatus) {
  const formatMessage = appIntl().formatMessage;
  if (cameraStatus.trackingStatus.globalTrackingState === TrackingStatusEnum.ACTIVE) {
    return formatMessage({ id: 'contextmenu.label.inTracking' }, { name: cameraStatus.configuration.name });
  }
  if (cameraStatus.roundOn) {
    return formatMessage({ id: 'contextmenu.label.inRound' }, { name: cameraStatus.configuration.name });
  }
  return cameraStatus.configuration.name;
}
