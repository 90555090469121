import { appIntl } from '@components/locale/IntlGlobalProvider';
import { Position } from '@turf/helpers';
import { automatonSectionColor, automatonSectionColorHexa, IconColorEnum } from '@utils/map/map.constants';
import { getTechnicalStatusName } from '@utils/map/sensor.utils';
import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { isEqual, uniqWith } from 'lodash';

import { AutomatonSectionDefenceCommand, AutomatonSegmentDefenceCommand } from '@/types/c2/c2.types';
import { Coordinates } from '@/types/commons/commons.types';
import {
  AutomatonCabinet,
  AutomatonConfiguration,
  AutomatonPortal,
  AutomatonSection,
  AutomatonSectionData,
  AutomatonSectionNames,
  AutomatonSectionStatus,
  AutomatonSegment,
  AutomatonSegmentFenceTypeEnum,
} from '@/types/sensor/configuration.types';
import {
  AutomatonSectionStatusEnum,
  AutomatonSegmentStatus,
  SensorStatus,
  SensorStatusEnum,
  SubSensorStatus,
} from '@/types/sensor/status.types';

export function getSectionName(section: AutomatonSection): string {
  return (section.upperSegment?.name ?? section.lowerSegment?.name) as string;
}

export function getSectionNameAndSegments(section: AutomatonSection): { name: string; segments: AutomatonSegment[] } {
  return { name: getSectionName(section), segments: Object.values(section).filter(Boolean) as AutomatonSegment[] };
}

export function getAutomatonSectionNames(sectionData: AutomatonSectionData): AutomatonSectionNames {
  return {
    automatonUniqueCode: getSensorUniqueCode(sectionData.automaton),
    cabinetUniqueCode: getSensorUniqueCode(sectionData.cabinet),
    sectionName: getSectionName(sectionData.section),
  };
}

export function getSectionStartAndEndPosition(section: AutomatonSection): {
  sectionStart: Position;
  sectionEnd: Position;
} {
  const segment = section.upperSegment ?? section.lowerSegment;
  const startPoint = segment.segmentPosition[0];
  const endPoint = segment.segmentPosition[1];
  return { sectionStart: toLonLatArray(startPoint, false)!, sectionEnd: toLonLatArray(endPoint, false)! };
}

export function getSectionCenterCoordinates(section: AutomatonSection): Coordinates {
  const segment = section.upperSegment ?? section.lowerSegment;
  return segment.position;
}

export function isSectionActive(
  automaton: AutomatonConfiguration,
  cabinet: AutomatonCabinet,
  section: AutomatonSection,
): boolean {
  if (!automaton.active || !cabinet.active) {
    return false;
  }

  if (!section.upperSegment) {
    return section.lowerSegment.active;
  }
  if (!section.lowerSegment) {
    return section.upperSegment.active;
  }
  return section.lowerSegment.active && section.upperSegment.active;
}

export function isSegmentActive(
  automaton: AutomatonConfiguration,
  cabinet: AutomatonCabinet,
  segment: AutomatonSegment | null,
): boolean {
  return automaton.active && cabinet.active && !!segment?.active;
}

export function isSectionInMaintenance(
  automaton: AutomatonConfiguration,
  cabinet: AutomatonCabinet,
  section: AutomatonSection,
): boolean {
  return (
    automaton.maintenance ||
    cabinet.maintenance ||
    !!section.lowerSegment?.maintenance ||
    !!section.upperSegment?.maintenance
  );
}

export function getSectionIconStatus(
  sectionData: AutomatonSectionData,
  sectionStatus: SensorStatusEnum | null,
): AutomatonSectionStatusEnum {
  const { automaton, cabinet, section } = sectionData;
  if (!isSectionActive(automaton, cabinet, section)) {
    return SensorStatusEnum.DISCONNECTED;
  } else if (isSectionInMaintenance(automaton, cabinet, section)) {
    return 'maintenance';
  } else if (!sectionStatus) {
    return SensorStatusEnum.DISCONNECTED;
  } else if (sectionStatus === SensorStatusEnum.DISCONNECTED) {
    return SensorStatusEnum.DEGRADED;
  }
  return sectionStatus as AutomatonSectionStatusEnum;
}

export function getSegmentStatus(
  segment: AutomatonSegment,
  sectionStatus: AutomatonSectionStatusEnum | null,
  automaton: AutomatonConfiguration,
  cabinet: AutomatonCabinet,
): AutomatonSectionStatusEnum {
  if (!automaton.active || !cabinet.active || !segment.active) {
    return SensorStatusEnum.DISCONNECTED;
  } else if (segment.maintenance) {
    return 'maintenance';
  } else if (!sectionStatus) {
    return SensorStatusEnum.DISCONNECTED;
  } else if (sectionStatus === SensorStatusEnum.DISCONNECTED) {
    return SensorStatusEnum.DEGRADED;
  }
  return sectionStatus;
}

export function getSectionLineColor(
  sectionData: AutomatonSectionData,
  sectionStatus: AutomatonSectionStatus | undefined,
  hexa = false,
): string | undefined {
  const { automaton, cabinet, section } = sectionData;
  if (!isSectionActive(automaton, cabinet, section)) {
    return hexa ? automatonSectionColorHexa.DISCONNECTED : automatonSectionColor.DISCONNECTED;
  } else if (isSectionInMaintenance(automaton, cabinet, section)) {
    return hexa ? automatonSectionColorHexa.DEGRADED : automatonSectionColor.DEGRADED;
  } else if (!sectionStatus) {
    return hexa ? automatonSectionColorHexa.DISCONNECTED : automatonSectionColor.DISCONNECTED;
  } else if (sectionStatus.status === SensorStatusEnum.DISCONNECTED) {
    return hexa ? automatonSectionColorHexa.DEGRADED : automatonSectionColor.DEGRADED;
  }
  return hexa ? automatonSectionColorHexa[sectionStatus.status] : automatonSectionColor[sectionStatus.status];
}

export function getSectionPerimeterCameraUniqueKeys(section: AutomatonSection) {
  return uniqWith(
    [section.lowerSegment, section.upperSegment]
      .filter(Boolean)
      .flatMap((segment) => segment!.perimeterCameraUniqueKeys),
    isEqual,
  );
}

export function getAutomatonPortal(segments: AutomatonSegment[]): AutomatonPortal | null {
  return (
    segments.find((segment) => segment.fenceType === AutomatonSegmentFenceTypeEnum.UPPER)?.portal ||
    segments.find((segment) => segment.fenceType === AutomatonSegmentFenceTypeEnum.LOWER)?.portal ||
    null
  );
}

export function getSectionStatus(segmentStatuses: SensorStatusEnum[]): SensorStatusEnum {
  if (segmentStatuses.includes(SensorStatusEnum.SLEEP)) {
    return SensorStatusEnum.SLEEP;
  } else if (segmentStatuses.includes(SensorStatusEnum.EFFRACTION)) {
    return SensorStatusEnum.EFFRACTION;
  } else if (
    segmentStatuses.includes(SensorStatusEnum.DEGRADED) ||
    segmentStatuses.includes(SensorStatusEnum.DISCONNECTED)
  ) {
    return SensorStatusEnum.DEGRADED;
  } else if (segmentStatuses.includes(SensorStatusEnum.HT_DETECTION)) {
    return SensorStatusEnum.HT_DETECTION;
  }
  return SensorStatusEnum.HEALTHY;
}

export function getAutomatonSectionSpriteIconName(iconStatus: AutomatonSectionStatusEnum): string {
  if (iconStatus === 'maintenance') {
    return `icSymb-tro-work-${IconColorEnum.ORANGE}`;
  } else {
    const technicalStatusName = getTechnicalStatusName(iconStatus);
    return `icSymb-tro${technicalStatusName}-${automatonSectionColor[iconStatus]}`;
  }
}

export function getPerimeterPortalSpriteIconNameFromSectionStatus(sectionStatus: AutomatonSectionStatusEnum): string {
  if (sectionStatus === 'maintenance') {
    return `icSymb-gate-${IconColorEnum.ORANGE}-L`;
  }
  return `icSymb-gate-${automatonSectionColor[sectionStatus as SensorStatusEnum]}-L`;
}

/**
 *
 * @param command The command to send, for a section or a segment
 * @param enableRepulsion False to disable the repulsion, true to enable it
 * @param sectionName Name of the section to display in the toast
 * @param isSegment true if the command is for a segment. False if it is for a section
 * @param postRepulsionCommand the repulsionCommand Mutation to use
 * @param fenceType If the command is for a segment, must include the type, LOWER or UPPER
 * @param automatonSite the site of the automaton which has the segment or section
 * @param automatonAppCode the app code of the automaton which has the segment or section
 */
export function sendRepulsionCommand(
  command: AutomatonSectionDefenceCommand | AutomatonSegmentDefenceCommand,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postRepulsionCommand: any,
  enableRepulsion: boolean,
  sectionName: string,
  isSegment: boolean,
  automatonSite: string,
  automatonAppCode: string,
  fenceType: AutomatonSegmentFenceTypeEnum = AutomatonSegmentFenceTypeEnum.UPPER,
) {
  let toastMessageSuccess: string;
  let toastMessageFail: string;

  if (!isSegment) {
    toastMessageSuccess = appIntl().formatMessage(
      { id: 'contextmenu.actions.sectionRepulsionToast' },
      {
        repulsion: enableRepulsion,
        success: true,
        sectionName: sectionName,
      },
    );
    toastMessageFail = appIntl().formatMessage(
      { id: 'contextmenu.actions.sectionRepulsionToast' },
      {
        repulsion: enableRepulsion,
        success: false,
        sectionName: sectionName,
      },
    );
  } else {
    toastMessageSuccess = appIntl().formatMessage(
      { id: 'contextmenu.actions.segmentRepulsionToast' },
      {
        repulsion: enableRepulsion,
        success: true,
        fenceType: fenceType === AutomatonSegmentFenceTypeEnum.UPPER ? 'upper' : 'lower',
        sectionName: sectionName,
      },
    );
    toastMessageFail = appIntl().formatMessage(
      { id: 'contextmenu.actions.segmentRepulsionToast' },
      {
        repulsion: enableRepulsion,
        success: false,
        fenceType: fenceType === AutomatonSegmentFenceTypeEnum.UPPER ? 'upper' : 'lower',
        sectionName: sectionName,
      },
    );
  }

  postRepulsionCommand({
    site: automatonSite,
    appCode: automatonAppCode,
    command,
  })
    .unwrap()
    .then(() => createToast(toastMessageSuccess, ToastStatusEnum.SUCCESS))
    .catch(() => createToast(toastMessageFail, ToastStatusEnum.ERROR));
}

export function isAutomatonSegmentStatus(
  status: SensorStatus | SubSensorStatus | AutomatonSegmentStatus,
): status is AutomatonSegmentStatus {
  return 'lowerFenceStatusAndState' in status && 'upperFenceStatusAndState' in status;
}
