import {
  ClusterColor,
  clusterLeavesLayer,
  clusterOverlay,
  clustersIconLayer,
  sensorIconLabelLayer,
  sensorIconLayer,
} from '@components/map/layers/sensors/sensor.layer';
import { ClusterProps, useClusterSelect } from '@hooks/useClusterSelect';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectSensorIconFeatures, selectSensorLabelFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { FeatureCollection, GeoJsonProperties } from 'geojson';
import { Layer, Source } from 'react-map-gl';
import Supercluster, { PointFeature } from 'supercluster';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';

function SensorLayer() {
  const sensorIconFeatures = useSelectorWithReplayMode(selectSensorIconFeatures);
  const sensorLabelFeatures = useSelectorWithReplayMode(selectSensorLabelFeatures);

  const pointFeatures: PointFeature<GeoJsonProperties>[] = sensorIconFeatures.filter(
    (feature): feature is PointFeature<GeoJsonProperties> => feature.geometry.type === 'Point',
  );

  const superclusterOptions: Supercluster.Options<GeoJsonProperties, ClusterProps> = {
    radius: 52,
    minZoom: 0,
    maxZoom: 23,
    minPoints: 2,
    map: (props) => {
      if (!props)
        return {
          [ClusterColor.PURPLE]: 0,
          [ClusterColor.ORANGE]: 0,
          [ClusterColor.GREEN]: 0,
          [ClusterColor.ORANGE_MAINTENANCE]: 0,
          [ClusterColor.GRAY]: 0,
          [ClusterColor.WHITE]: 0,
        };
      const { status, maintenance, active } = props;
      return {
        [ClusterColor.PURPLE]:
          active &&
          !maintenance &&
          [SensorStatusEnum.DISCONNECTED, SensorStatusEnum.WAITING, SensorStatusEnum.NODATA].includes(status)
            ? 1
            : 0,
        [ClusterColor.ORANGE]:
          active && !maintenance && [SensorStatusEnum.DEGRADED, SensorStatusEnum.DESYNCHRONIZED].includes(status)
            ? 1
            : 0,
        [ClusterColor.GREEN]: active && !maintenance && status === SensorStatusEnum.HEALTHY ? 1 : 0,
        [ClusterColor.ORANGE_MAINTENANCE]: active && maintenance ? 1 : 0,
        [ClusterColor.GRAY]: active && !maintenance && status === SensorStatusEnum.SLEEP ? 1 : 0,
        [ClusterColor.WHITE]: active === false ? 1 : 0,
      };
    },
    reduce: (accumulated: ClusterProps | null, props: Partial<ClusterProps> | null) => {
      if (!accumulated || !props) return;
      for (const key in props) {
        if (Object.hasOwn(props, key)) {
          const typedKey = key as keyof ClusterProps;
          accumulated[typedKey] = (accumulated[typedKey] || 0) + (props[typedKey] ?? 0);
        }
      }
    },
  };

  const [clusterState, leaveIds, clusters] = useClusterSelect(pointFeatures, superclusterOptions);

  const sensorFeatures: FeatureCollection = {
    type: 'FeatureCollection',
    features: clusterState.updatedClusters.length > 0 ? clusterState.updatedClusters : clusters,
  };

  if (!isFeatureCollection(sensorFeatures)) return null;

  return (
    <>
      <Source
        id={SourceNameEnum.SENSORS}
        type="geojson"
        data={sensorFeatures}
        tolerance={0}
        maxzoom={23}
        cluster
        generateId
      >
        <Layer key={LayerNameEnum.SENSOR_CLUSTERS} {...clustersIconLayer} />
        <Layer key={LayerNameEnum.SENSOR_ICONS} {...sensorIconLayer} />
      </Source>
      <Source
        id={SourceNameEnum.SENSORS_OVERLAY}
        type="geojson"
        data={sensorFeatures}
        tolerance={0}
        maxzoom={23}
        generateId
      >
        <Layer key={LayerNameEnum.SENSOR_CLUSTER_OVERLAY} {...clusterOverlay} />
        <Layer key={LayerNameEnum.SENSOR_CLUSTER_LEAVES} {...clusterLeavesLayer} />
      </Source>
      <Source
        id={SourceNameEnum.SENSOR_LABELS}
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: sensorLabelFeatures.filter((feature) => !leaveIds.includes(feature.properties?.id)),
        }}
        generateId
      >
        <Layer key={LayerNameEnum.SENSOR_LABELS} {...sensorIconLabelLayer} />
      </Source>
    </>
  );
}

export default SensorLayer;
