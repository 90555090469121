import { IsoModeEnum, WebRelayActuatorStateEnum, WhiteBalanceModeEnum } from '@/types/c2/c2.types';

import { Coordinates, Dictionary, DictionaryNum, SyriusException } from '../commons/commons.types';
import {
  AdsbConfiguration,
  AeroscopeConfiguration,
  ArdronisConfiguration,
  AutomatonConfiguration,
  AutomatonSegmentFenceTypeEnum,
  AvisoConfiguration,
  CameraConfiguration,
  CameraType,
  DroneShieldConfiguration,
  EquipmentConfiguration,
  EquipmentTypeEnum,
  GetSensorConfiguration,
  GroundStationConfiguration,
  HolodroneConfiguration,
  HolopticsConfiguration,
  OnvifConfiguration,
  SenidConfiguration,
  SensorConfiguration,
  SensorTypeEnum,
  SensorWithSubSensor,
  SkyEyeConfiguration,
  SpexerConfiguration,
  WebRelayConfiguration,
  XentaConfiguration,
  ZmerConfiguration,
} from './configuration.types';

export enum SensorStatusEnum {
  HEALTHY = 'HEALTHY',
  ROUND = 'ROUND',
  TRACKING = 'TRACKING',
  HT_DETECTION = 'HT_DETECTION',
  WAITING = 'WAITING',
  NODATA = 'NODATA',
  DESYNCHRONIZED = 'DESYNCHRONIZED',
  DEGRADED = 'DEGRADED',
  SLEEP = 'SLEEP',
  DISCONNECTED = 'DISCONNECTED',
  EFFRACTION = 'EFFRACTION',
}

export type AutomatonSectionStatusEnum =
  | SensorStatusEnum.DISCONNECTED
  | 'maintenance'
  | SensorStatusEnum.SLEEP
  | SensorStatusEnum.DEGRADED
  | SensorStatusEnum.EFFRACTION
  | SensorStatusEnum.HT_DETECTION
  | SensorStatusEnum.HEALTHY;

export enum AutomatonNodeStateEnum {
  MISSING_VALUE = 'MISSING_VALUE',
  TECHNICAL_DEFECT_ALARM_CAUSE = 'TECHNICAL_DEFECT_ALARM_CAUSE',
  EFFRACTION = 'EFFRACTION',
  BT_DETECTION = 'BT_DETECTION',
  OK = 'OK',
}

export enum AutomatonCabinetStateEnum {
  MISSING_VALUE = 'MISSING_VALUE',
  SLEEP = 'SLEEP',
  EFFRACTION = 'EFFRACTION',
  TECHNICAL_DEFECT = 'TECHNICAL_DEFECT',
  OK = 'OK',
}

export enum SubSensorStatusTypeEnum {
  AEROSCOPE_ANTENNA = 'AEROSCOPE_ANTENNA',
  ARDRONIS_ANTENNA = 'ARDRONIS_ANTENNA',
  AUTOMATON_CABINET = 'AUTOMATON_CABINET',
  AUTOMATON_SEGMENT = 'AUTOMATON_SEGMENT',
  HOLODRONE_ANTENNA = 'HOLODRONE_ANTENNA',
  SENID_ANTENNA = 'SENID_ANTENNA',
  SKY_EYE_ANTENNA = 'SKY_EYE_ANTENNA',
  SPEXER_RADAR = 'SPEXER_RADAR',
}

export enum OperatingStateEnum {
  STANDBY = 'Standby',
  ON = 'On',
  IBIT = 'Ibit',
  MAINTENANCE = 'Maintenance',
  UNKNOWN = 'Unknown',
}

export enum TransmitterStateEnum {
  ON = 'On',
  OFF = 'Off',
}

export enum SkyEyeStatusEnum {
  DISCONNECTED = 'disconnected',
  DETECTING = 'detecting',
  DEFENDING = 'defending',
  EXPIRED = 'expired',
}

enum CfarEnum {
  NOCFARSELECTED = 'NoCfarSelected',
  CFARTYPE1 = 'CfarType1',
  CFARTYPE2 = 'CfarType2',
  CFARTYPE3 = 'CfarType3',
  CFARTYPE4 = 'CfarType4',
  CFARTYPE5 = 'CfarType5',
}

enum FrequencyAgilityEnum {
  SINGLECHANNEL = 'SingleChannel',
  PSEUDORANDOMFREQUENCY = 'PseudoRandomFrequency',
}

enum RangeGateEnum {
  METER10 = 'Meter10',
  METER20 = 'Meter20',
  METER40 = 'Meter40',
  METER80 = 'Meter80',
}

enum TerrainEnum {
  GROUND = 'Ground',
  SEA = 'Sea',
  AIR = 'Air',
}

enum EtargetSpeedEnum {
  NOSPEEDSELECTED = 'NoSpeedSelected',
  SLOW = 'Slow',
  MEDIUM = 'Medium',
  FAST = 'Fast',
}

enum TxPowerEnum {
  PERC100_0DB = 'Perc100_0dB',
  PERC50_3DB = 'Perc50_3dB',
  PERC25_6DB = 'Perc25_6dB',
  PERC12_5_9DB = 'Perc12_5_9dB',
  TXBOOST = 'TxBoost',
}

enum DopplerAudioAntennaSelectionEnum {
  _1 = 'ANU_1',
  _2 = 'ANU_2',
  _3 = 'ANU_3',
}

enum BinaryIntegrationEnum {
  TWOOUTOFFOUR = 'TwoOutOfFour',
  TWOOUTOFTHREE = 'TwoOutOfThree',
}

enum BurstEnum {
  _64 = 'Pulses_64',
  _128 = 'Pulses_128',
  _256 = 'Pulses_256',
}

export enum CameraStateEnum {
  CONNECTED = '0',
  READY = '1',
  CONFIGURED = '2',
  TRACKING_PENDING = '3',
  TRACKING_ACTIVE = '4',
  TRACKING_LOST = '5',
  CLOSE = '6',
}

export enum SystemModeEnum {
  STANDBY = '0',
  MANUAL = '1',
  ACQUIRE = '2',
  TRACK = '3',
  SCAN = '4',
  PARK = '5',
  REMOTE_DESIGNATION = '6',
  WASH = '7',
  FACTORY = '8',
}

export enum TrackingTypeEnum {
  VIDEO,
  SENSOR,
}

export enum TrackingStatusEnum {
  DISABLED,
  PENDING,
  ACTIVE,
  LOST,
}

export enum VideoStateEnum {
  ON = '0',
  DEGRADED = '1',
  OFF = '2',
}

export enum ZoomModeEnum {
  MANUAL = '0',
  AUTO = '1',
}

export enum FocusModeEnum {
  MANUAL = 0,
  AUTO = 1,
}

export enum ExposureModeEnum {
  UNSET = 'UNSET',
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
  SHUTTER_PRIORITY = 'SHUTTER_PRIORITY',
  IRIS_PRIORITY = 'IRIS_PRIORITY',
  BRIGHT = 'BRIGHT',
}

export enum DetectionAlgorithmEnum {
  MANUAL = 'MANUAL',
  HOT_SPOT = 'HOT_SPOT',
  MOTION = 'MOTION',
  NAVAL = 'NAVAL',
}

export enum TrackingAlgorithmEnum {
  COMBINED = 'COMBINED',
  CORRELATION = 'CORRELATION',
  MTT = 'MTT',
  CENTROID = 'CENTROID',
  SCENELOCK = 'SCENELOCK',
}

export enum PrimaryObjectStatusEnum {
  INVALID = 'INVALID',
  VALID = 'VALID',
  COASTING = 'COASTING',
}

export enum PriorityModeEnum {
  NONE = 'NONE',
  SIZE = 'SIZE',
  BORESIGHT = 'BORESIGHT',
  COMBINED = 'COMBINED',
}

export enum PolarityEnum {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  BIPOLAR = 'BIPOLAR',
  AUTOMATIC = 'AUTOMATIC',
}

export enum TechnicalDefectEnum {
  CIRCUIT_BREAKER = 'Electrificateur.Sectionneur_Arret_Electrificateur',
  EMERGENCY_STOP = 'Coffret.Arret_Urgence',
}

export interface SensorError {
  time: string;
  error: SyriusException;
}

export interface SubSensorStatus {
  type: SubSensorStatusTypeEnum;
  uniqueKey: string;
  appCode: string;
  site: string;
  code: string;
  position: Coordinates;
  subSensorName: string;
  status: SensorStatusEnum;
}

export interface AutomatonSegmentFenceStatus extends SubSensorStatus {
  type: SubSensorStatusTypeEnum.AUTOMATON_SEGMENT;
  fenceType: AutomatonSegmentFenceTypeEnum;
  states: Dictionary<string[]>;
}

export interface AutomatonSegmentStatus {
  lowerFenceStatusAndState: AutomatonSegmentFenceStatus | null;
  upperFenceStatusAndState: AutomatonSegmentFenceStatus | null;
}

export interface AutomatonCabinetStatus extends SubSensorStatus {
  type: SubSensorStatusTypeEnum;
  cabinetIdentifier: number;
  cabinetStates: Partial<Record<AutomatonCabinetStateEnum, string[]>>;
  segmentStatuses: DictionaryNum<AutomatonSegmentStatus>;
}

export interface AeroscopeAntennaStatus extends SubSensorStatus {
  type: SubSensorStatusTypeEnum.AEROSCOPE_ANTENNA;
}

export interface ArdronisAntennaStatus extends SubSensorStatus {
  type: SubSensorStatusTypeEnum.ARDRONIS_ANTENNA;
}

export interface HolodroneAntennaStatus extends SubSensorStatus {
  type: SubSensorStatusTypeEnum.HOLODRONE_ANTENNA;
}

export interface SenidAntennaStatus extends SubSensorStatus {
  type: SubSensorStatusTypeEnum.SENID_ANTENNA;
}

export interface SkyEyeAntennaStatus extends SubSensorStatus {
  type: SubSensorStatusTypeEnum.SKY_EYE_ANTENNA;
}

export interface SpexerRadarStatus extends SubSensorStatus {
  type: SubSensorStatusTypeEnum.SPEXER_RADAR;
}

interface HighLevelSpexerPdState {
  operatingState: OperatingStateEnum;
  transmitterState: TransmitterStateEnum;
  preset: number;
}

interface SensorPDPutRequestEmconLockedChannels {
  _0: number[];
  _1: number[];
  _2: number[];
  _3: number[];
  _4: number[];
  _5: number[];
  _6: number[];
  _7: number[];
  _8: number[];
  _9: number[];
  _10: number[];
  _11: number[];
  _12: number[];
  _13: number[];
  _14: number[];
  _15: number[];
  _16: number[];
  _17: number[];
  _18: number[];
  _19: number[];
  _20: number[];
  _21: number[];
  _22: number[];
  _23: number[];
  _24: number[];
  _26: number[];
  _27: number[];
  _28: number[];
  _29: number[];
  _30: number[];
  _31: number[];
}

interface SensorTag {
  type: string;
  number: number;
}

interface SensorPDPutRequestSurveillanceAreasInner {
  origin: SensorTag;
  sector: SensorPDPutRequestSurveillanceAreasInnerSector;
}

interface SensorPDPutRequestSurveillanceAreasInnerSectorBeams {
  azimuth: number;
  elevation: number;
}

interface SensorPDPutRequestSurveillanceAreasInnerSector {
  name: SensorTag;
  azimuth: number;
  sectorWidth: number;
  sectorEnd: number;
  sectorStart: number;
  elevation: number;
  cfar: CfarEnum;
  audioGate: number;
  txChannel: number;
  frequencyAgility: FrequencyAgilityEnum;
  manualThreshold: number;
  minSpeed: number;
  prfStaggering: boolean;
  rangeGate: RangeGateEnum;
  terrain: TerrainEnum;
  etargetSpeed: EtargetSpeedEnum;
  trackIdentifier: number;
  txPower: TxPowerEnum;
  dopplerAudioAntennaSelection: DopplerAudioAntennaSelectionEnum;
  modeAntennaSelection: DopplerAudioAntennaSelectionEnum;
  binaryIntegration: BinaryIntegrationEnum;
  burst: BurstEnum;
  dopplerAudio: boolean;
  beams: SensorPDPutRequestSurveillanceAreasInnerSectorBeams;
}

interface SensorPDPutRequestPositionBehaviorFix {
  latitude: number;
  longitude: number;
  altitude: number;
}

interface SensorPDPutRequestPositionBehavior {
  fix: SensorPDPutRequestPositionBehaviorFix;
  useFromInu: boolean;
}

interface SensorPDPutRequestAttitudeBehaviorFix {
  boresight: number;
  tilt: number;
}

interface SensorPDPutRequestAttitudeBehaviorPedestal {
  northCorrection: number;
  parkPosition: boolean;
}

interface SensorPDPutRequestAttitudeBehavior {
  fix: SensorPDPutRequestAttitudeBehaviorFix;
  useFromInu: boolean;
  pedestal: SensorPDPutRequestAttitudeBehaviorPedestal;
}

interface SensorPDPutRequestInuValues {
  latitude: number;
  longitude: number;
  altitude: number;
  heading: number;
  pitch: number;
  roll: number;
  vnorth: number;
  veast: number;
  vdown: number;
}

interface FullSpexerPdState {
  operatingState: OperatingStateEnum;
  transmitterState: TransmitterStateEnum;
  dualBeam: boolean;
  inuValues: SensorPDPutRequestInuValues;
  attitudeBehavior: SensorPDPutRequestAttitudeBehavior;
  positionBehavior: SensorPDPutRequestPositionBehavior;
  surveillanceAreas: SensorPDPutRequestSurveillanceAreasInner[];
  emconLockedChannels: SensorPDPutRequestEmconLockedChannels;
}

export interface CameraToken {
  userName: string;
  cameraOperatorUuid: string;
  controlType: ControlType;
}

export enum ControlType {
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
}

export interface VideoBox {
  x: number;
  y: number;
  height: number;
  width: number;
}

export interface DetectedObject {
  id: string | null;
  box: VideoBox;
}

export interface VideoStatus {
  id: number;
  state: VideoStateEnum | null;
  zoom: number | null;
  numericalZoom: number | null;
  opticalZoom: number | null;
  fov: number | null;
  focus: number | null;
  zoomMode: ZoomModeEnum | null;
  focusMode: FocusModeEnum | null;
  exposureMode: ExposureModeEnum | null;
  iso: IsoModeEnum | null;
  whiteBalance: WhiteBalanceModeEnum | null;
  isBlackHot: boolean;
  focusLevel: number | null;
  isDeIcing: boolean;
}

export interface CameraTrackingStatus {
  globalTrackingType: TrackingTypeEnum | null;
  globalTrackingState: TrackingStatusEnum;
  videoTrackingStatus: VideoTrackingStatus;
  sensorTrackingStatus: SensorTrackingStatus;
}

export interface VideoTrackingStatus {
  detectedObjects: DetectedObject[];
  holopticsVideoTrackings: Record<number, TrackingStatusEnum>;
  detectionZone: VideoBox | null;
  currentTrackedObjectId: string | null;
  globalVideoTrackingState: TrackingStatusEnum;
}

export interface SensorTrackingStatus {
  currentTrackedObjectId: string | null;
  globalSensorTrackingState: TrackingStatusEnum;
}

export interface AbstractSensorStatus<T extends SensorConfiguration> {
  configuration: T;
  status: SensorStatusEnum;
  errors: SensorError[];
  lastMessageDate: string;
}

export interface AbstractSensorCameraStatus<T extends CameraConfiguration> extends AbstractSensorStatus<T> {
  state: CameraStateEnum;
  systemMode: SystemModeEnum;
  cameraToken: CameraToken | null;
  pan: number | null;
  tilt: number | null;
  roundOn: boolean;
  simpleRoundSpeed: number | null;
  trackingStatus: CameraTrackingStatus;
  videoStatuses: VideoStatus[];
}

export interface AbstractSensorWithSubSensorsStatus<
  SensorConfig extends SensorConfiguration,
  SubSensorStatu extends SubSensorStatus = SubSensorStatus,
> extends AbstractSensorStatus<SensorConfig> {
  // P is subSensor unique key
  subSensorStatuses: { [P in string]?: SubSensorStatu };
}

interface ArdronisAntennaConfiguration {
  stationId: number;
  name: string;
  networkAddress: string;
  position: Coordinates;
  altitude: number;
  direction: number;
  beamWidth: number;
}

export interface ExtendsAbstractSensorWithArdronisStatus<T extends ArdronisConfiguration>
  extends AbstractSensorWithSubSensorsStatus<T> {
  availableScenarios: string[];
  connectedStationsIds: number[];
  stationConfigurations: ArdronisAntennaConfiguration[];
}

export interface ExtendsAbstractSensorWithSpexerStatus<T extends SpexerConfiguration>
  extends AbstractSensorWithSubSensorsStatus<T> {
  highLevelSpexerPdState: HighLevelSpexerPdState | undefined;
  fullSpexerPdState: FullSpexerPdState | undefined;
}

interface ExtendsAbstractSensorWithSkyEyeStatus<T extends SkyEyeConfiguration>
  extends AbstractSensorWithSubSensorsStatus<T> {
  skyEyeStatus: SkyEyeStatusEnum;
}

interface ExtendsAbstractSensorWithZmerStatus<T extends ZmerConfiguration> extends AbstractSensorCameraStatus<T> {
  systemId: number;
  cameraToken: CameraToken | null;
  state: CameraStateEnum;
  on: boolean;
  azimuthRate: number;
  elevationRate: number;
  zoomRate: number;
  focusRate: number;
  autoFocusActivated: boolean;
  detectionAlgorithm: DetectionAlgorithmEnum | null;
  trackingAlgorithm: TrackingAlgorithmEnum | null;
  primaryObjectStatus: PrimaryObjectStatusEnum | null;
  priorityMode: PriorityModeEnum | null;
  polarity: PolarityEnum | null;
}

type ExtendsAbstractSensorWithOnvifStatus<T extends OnvifConfiguration> = AbstractSensorCameraStatus<T>;

type ExtendsAbstractSensorWithHolopticsStatus<T extends HolopticsConfiguration> = AbstractSensorCameraStatus<T>;

export interface AdsbSensorStatus extends AbstractSensorStatus<AdsbConfiguration> {
  type: SensorTypeEnum.ADSB;
}

export interface AeroscopeSensorStatus extends AbstractSensorWithSubSensorsStatus<AeroscopeConfiguration> {
  type: SensorTypeEnum.AEROSCOPE;
}

export interface ArdronisSensorStatus extends ExtendsAbstractSensorWithArdronisStatus<ArdronisConfiguration> {
  type: SensorTypeEnum.ARDRONIS;
}

export interface AutomatonSensorStatus
  extends AbstractSensorWithSubSensorsStatus<AutomatonConfiguration, AutomatonCabinetStatus> {
  type: SensorTypeEnum.AUTOMATON;
}

export interface AvisoSensorStatus extends AbstractSensorStatus<AvisoConfiguration> {
  type: SensorTypeEnum.AVISO;
}

export interface DroneShieldSensorStatus extends AbstractSensorStatus<DroneShieldConfiguration> {
  type: SensorTypeEnum.DRONE_SHIELD;
}

export interface GroundStationSensorStatus extends AbstractSensorStatus<GroundStationConfiguration> {
  type: SensorTypeEnum.GROUND_STATION;
}

export interface HolodroneSensorStatus extends AbstractSensorWithSubSensorsStatus<HolodroneConfiguration> {
  type: SensorTypeEnum.HOLODRONE;
}

export interface HolopticsSensorStatus extends ExtendsAbstractSensorWithHolopticsStatus<HolopticsConfiguration> {
  type: SensorTypeEnum.HOLOPTICS;
}

export interface OnvifSensorStatus extends ExtendsAbstractSensorWithOnvifStatus<OnvifConfiguration> {
  type: SensorTypeEnum.ONVIF;
}

export interface SenidSensorStatus extends AbstractSensorWithSubSensorsStatus<SenidConfiguration> {
  type: SensorTypeEnum.SENID;
}

export interface SkyEyeSensorStatus extends ExtendsAbstractSensorWithSkyEyeStatus<SkyEyeConfiguration> {
  type: SensorTypeEnum.SKY_EYE;
}

export interface SpexerSensorStatus extends ExtendsAbstractSensorWithSpexerStatus<SpexerConfiguration> {
  type: SensorTypeEnum.SPEXER;
  highLevelSpexerPdState: HighLevelSpexerPdState | undefined;
  fullSpexerPdState: FullSpexerPdState | undefined;
}

export enum XentaTransmitStateEnum {
  UNKNOWN = 'UNKNOWN',
  SECURITY_SAFE = 'SECURITY_SAFE',
  NOT_READY = 'NOT_READY',
  STANDBY = 'STANDBY',
  REDUCED_POWER = 'REDUCED_POWER',
  FULL_POWER = 'FULL_POWER',
}

export enum XentaMastMotionEnum {
  UNKNOWN = 'UNKNOWN',
  NOT_MOVING = 'NOT_MOVING',
  MOVING = 'MOVING',
}

export enum XentaCapabilityStatusTypeEnum {
  UNKNOWN = 'UNKNOWN',
  TRANSMITTER_STATE = 'TRANSMITTER_STATE',
  // Voir XentaCapabilityStatusType.java si besoin des autres valeurs
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPECIFIC_XENTA_CAPABILITY_STATUS = [
  XentaCapabilityStatusTypeEnum.TRANSMITTER_STATE,
] as const satisfies readonly XentaCapabilityStatusTypeEnum[];

export type SpecificXentaCapabilityStatus = (typeof SPECIFIC_XENTA_CAPABILITY_STATUS)[number];

export type GenericXentaCapabilityStatus = Exclude<XentaCapabilityStatusTypeEnum, SpecificXentaCapabilityStatus>;

export interface AbstractXentaCapabilityState<key extends XentaCapabilityStatusTypeEnum> {
  capabilityType: key;
}

export interface TransmitterState
  extends AbstractXentaCapabilityState<XentaCapabilityStatusTypeEnum.TRANSMITTER_STATE> {
  transmitState: XentaTransmitStateEnum;
}

export type XentaCapabilities = { [key in GenericXentaCapabilityStatus]?: AbstractXentaCapabilityState<key> } & {
  [XentaCapabilityStatusTypeEnum.TRANSMITTER_STATE]?: TransmitterState;
};

export interface XentaSensorStatus extends AbstractSensorStatus<XentaConfiguration> {
  type: SensorTypeEnum.XENTA;
  mastMotion: XentaMastMotionEnum;
  capabilities: XentaCapabilities;
}

export interface ZmerSensorStatus extends ExtendsAbstractSensorWithZmerStatus<ZmerConfiguration> {
  type: SensorTypeEnum.ZMER;
  charmConnected: boolean;
}

export type GetSensorStatus<SensorType extends SensorTypeEnum> = SensorType extends SensorTypeEnum.ADSB
  ? AdsbSensorStatus
  : SensorType extends SensorTypeEnum.AEROSCOPE
    ? AeroscopeSensorStatus
    : SensorType extends SensorTypeEnum.ARDRONIS
      ? ArdronisSensorStatus
      : SensorType extends SensorTypeEnum.AUTOMATON
        ? AutomatonSensorStatus
        : SensorType extends SensorTypeEnum.AVISO
          ? AvisoSensorStatus
          : SensorType extends SensorTypeEnum.DRONE_SHIELD
            ? DroneShieldSensorStatus
            : SensorType extends SensorTypeEnum.GROUND_STATION
              ? GroundStationSensorStatus
              : SensorType extends SensorTypeEnum.HOLODRONE
                ? HolodroneSensorStatus
                : SensorType extends SensorTypeEnum.HOLOPTICS
                  ? HolopticsSensorStatus
                  : SensorType extends SensorTypeEnum.ONVIF
                    ? OnvifSensorStatus
                    : SensorType extends SensorTypeEnum.SENID
                      ? SenidSensorStatus
                      : SensorType extends SensorTypeEnum.SKY_EYE
                        ? SkyEyeSensorStatus
                        : SensorType extends SensorTypeEnum.SPEXER
                          ? SpexerSensorStatus
                          : SensorType extends SensorTypeEnum.XENTA
                            ? XentaSensorStatus
                            : SensorType extends SensorTypeEnum.ZMER
                              ? ZmerSensorStatus
                              : never;

export type SensorStatus = GetSensorStatus<SensorTypeEnum>;

export type CameraStatus = Extract<SensorStatus, { type: CameraType }>;

export type GetSubSensorStatus<SensorType extends SensorWithSubSensor> = Exclude<
  GetSensorStatus<SensorType>['subSensorStatuses'][string],
  undefined
>;

export type InactiveMonitoringSensor<SensorType extends SensorTypeEnum = SensorTypeEnum> = SensorType extends unknown
  ? {
      configuration: GetSensorConfiguration<SensorType>;
    }
  : never;

export type MonitoringSensor<SensorType extends SensorTypeEnum = SensorTypeEnum> =
  | InactiveMonitoringSensor<SensorType>
  | GetSensorStatus<SensorType>;

export enum EquipmentConnectionStatusEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export interface AbstractEquipmentStatus<T extends EquipmentConfiguration> {
  configuration: T;
  connectionStatus: EquipmentConnectionStatusEnum;
}

export interface WebRelayStatus extends AbstractEquipmentStatus<WebRelayConfiguration> {
  type: EquipmentTypeEnum.WEB_RELAY;
  actuatorState: WebRelayActuatorStateEnum;
}

export type EquipmentStatus = WebRelayStatus;

export interface InactiveMonitoringEquipment {
  configuration: EquipmentConfiguration;
}

export type MonitoringEquipment = InactiveMonitoringEquipment | EquipmentStatus;
