import { useMapContext } from '@components/map/MapContext';
import HistoryPopup from '@components/map/popup/HistoryPopup';
import MeasureInfoPopup from '@components/map/popup/MeasureInfoPopup';
import ResetSituationPopup from '@components/map/popup/ResetSituationPopup';
import ShareTargetOnHoloviewPopup from '@components/map/popup/ShareTargetOnHoloviewPopup';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { closePopupByMapId, updateMenuNavigationStatus } from '@redux/maps/maps.reducer';
import { selectMapNavigationOptions, selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import { memo, useCallback } from 'react';

import { PopupDataTypeEnum } from '@/types/map.types';

import ConfirmationPopup from './ConfirmationPopup';
import EventFormPopup from './EventFormPopup';
import GpsQrCodePopup from './GpsQrCodePopup';
import { RoundPopup } from './round/RoundPopup';

function Popup() {
  const { mapId } = useMapContext();
  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const closePopupControl = useWithDispatch(closePopupByMapId);

  const selectedToolMenuOptions = useSelectorWithMapId(selectMapNavigationOptions);
  const dispatchSelectedOptions = useWithDispatch(updateMenuNavigationStatus);
  const disableMeasure = useCallback(() => {
    closePopupControl(mapId);
    const newToolMenuOptions = structuredClone(selectedToolMenuOptions ?? []);

    if (newToolMenuOptions.includes('azimuthAndDistance')) {
      newToolMenuOptions.splice(newToolMenuOptions.indexOf('azimuthAndDistance'), 1);
      dispatchSelectedOptions({ mapId, selectedOptions: newToolMenuOptions });
    }
  }, [closePopupControl, mapId, selectedToolMenuOptions, dispatchSelectedOptions]);

  if (!currentPopupControl.type) {
    return null;
  }

  //TODO Move all popup logic here
  switch (currentPopupControl.type) {
    case PopupDataTypeEnum.HISTORY:
      return (
        <HistoryPopup
          top={currentPopupControl.position.y}
          left={currentPopupControl.position.x}
          data={currentPopupControl.data}
          onClose={() => closePopupControl(mapId)}
        />
      );
    case PopupDataTypeEnum.EVENT:
      return (
        <EventFormPopup
          top={currentPopupControl.position.y}
          left={currentPopupControl.position.x}
          {...currentPopupControl.data}
          onClose={() => closePopupControl(mapId)}
        />
      );
    case PopupDataTypeEnum.RESET_SITUATION:
      return (
        <ResetSituationPopup
          top={currentPopupControl.position.y}
          left={currentPopupControl.position.x}
          onClose={() => closePopupControl(mapId)}
        />
      );
    case PopupDataTypeEnum.MEASURE_INFO:
      return <MeasureInfoPopup onClose={disableMeasure} />;
    case PopupDataTypeEnum.CONFIRMATION_POPUP:
      return (
        <ConfirmationPopup
          top={currentPopupControl.position.y}
          left={currentPopupControl.position.x}
          {...currentPopupControl.data}
          onClose={() => closePopupControl(mapId)}
        />
      );
    case PopupDataTypeEnum.SHARE_HOLOVIEW:
      return (
        <ShareTargetOnHoloviewPopup
          top={currentPopupControl.position.y}
          left={currentPopupControl.position.x}
          site={currentPopupControl.data.site}
          trackIds={currentPopupControl.data.trackIds}
          onClose={() => closePopupControl(mapId)}
        />
      );
    case PopupDataTypeEnum.GPS_QR_CODE:
      return (
        <GpsQrCodePopup
          top={currentPopupControl.position.y}
          left={currentPopupControl.position.x}
          {...currentPopupControl.data}
          onClose={() => closePopupControl(mapId)}
        />
      );
    case PopupDataTypeEnum.ROUND:
      return (
        <RoundPopup
          {...currentPopupControl.data}
          top={currentPopupControl.position.y}
          left={currentPopupControl.position.x}
          onClose={() => closePopupControl(mapId)}
        />
      );
    case PopupDataTypeEnum.INFO:
    case PopupDataTypeEnum.REPLAY:
    case PopupDataTypeEnum.VIDEO_EXPORT:
    case PopupDataTypeEnum.ZONE:
      return null;
  }
}

export default memo(Popup);
