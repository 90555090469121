import { SensorIconKeys } from '@assets/icons/70x70/sensor';
import { extendedSensorShortHand, SensorIconStatusFromSensorStatus, targetColor } from '@utils/map/map.constants';
import { getDroneCollaborativeId } from '@utils/target.utils';
import { capitalize } from 'lodash';

import {
  AlertLevelEnum,
  AlertType,
  AlertTypeEnum,
  criticalAlertLevel,
  DroneAlertLevelEnum,
  DroneNotificationLight,
  NotificationFilterEnum,
  NotificationType,
  NotificationTypeEnum,
  SegmentAlertLevelEnum,
  SegmentNotification,
  SensorAlertLevelEnum,
  SensorNotification,
  SpaceViolationAlert,
} from '@/types/data/data.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';
import { ExtendedSensor } from '@/types/sensor/configuration.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';
import { DroneClearance } from '@/types/utm/drone.types';

export function getSensorIconKeyByStatus(type: ExtendedSensor, sensorStatus: SensorStatusEnum): SensorIconKeys {
  return `${capitalize(extendedSensorShortHand[type])}${capitalize(SensorIconStatusFromSensorStatus[sensorStatus])}` as SensorIconKeys;
}

export function getWorstNotificationLevel(
  { currentWorstAlertLevel, lastAlertLevel, worstAlertLevel }: NotificationType,
  option?: { isArchived?: boolean; isToast?: boolean },
) {
  const { isArchived, isToast } = { isArchived: false, isToast: false, ...option };
  if (isArchived) {
    return worstAlertLevel;
  } else if (isToast) {
    return lastAlertLevel;
  } else {
    return currentWorstAlertLevel;
  }
}

export function isDroneNotification(notification: NotificationType): notification is DroneNotificationLight {
  return notification.type === NotificationTypeEnum.DRONE;
}

export function isSegmentNotification(notification: NotificationType): notification is SegmentNotification {
  return notification.type === NotificationTypeEnum.SEGMENT;
}

export function isSensorNotification(notification: NotificationType): notification is SensorNotification {
  return notification.type === NotificationTypeEnum.SENSOR;
}

export function isLadNotification(notification: NotificationType) {
  return isDroneNotification(notification);
}

export function isPerimeterNotification(notification: NotificationType, isArchived = false) {
  const level = getWorstNotificationLevel(notification, { isArchived });
  return isSegmentNotification(notification) && level === SegmentAlertLevelEnum.PERIMETER_EFFRACTION;
}

export function isLadTechnicalNotification(notification: NotificationType) {
  return isSensorNotification(notification);
}

export function isPerimeterTechnicalNotification(notification: NotificationType, isArchived = false) {
  const level = getWorstNotificationLevel(notification, { isArchived });
  return isSegmentNotification(notification) && level !== SegmentAlertLevelEnum.PERIMETER_EFFRACTION;
}

function getPriorityValue(notification: NotificationType): SensorStatusEnum | ClassificationEnum {
  switch (notification.type) {
    case NotificationTypeEnum.DRONE:
      return notification.classification;
    case NotificationTypeEnum.SENSOR:
      return notification.sensorStatus;
    case NotificationTypeEnum.SEGMENT:
      return notification.segmentStatus;
  }
}

export function sortNotificationByDanger(notification1: NotificationType, notification2: NotificationType) {
  const priorityOrder = [
    ClassificationEnum.ENEMY,
    SensorStatusEnum.EFFRACTION,
    SensorStatusEnum.DISCONNECTED,
    SensorStatusEnum.NODATA,
    SensorStatusEnum.WAITING,
    ClassificationEnum.SUSPECT,
    SensorStatusEnum.DEGRADED,
    SensorStatusEnum.DESYNCHRONIZED,
    ClassificationEnum.WATCH,
    ClassificationEnum.UNKNOWN,
    SensorStatusEnum.SLEEP,
    SensorStatusEnum.HT_DETECTION,
    SensorStatusEnum.TRACKING,
    SensorStatusEnum.ROUND,
    SensorStatusEnum.HEALTHY,
    ClassificationEnum.FRIEND,
    ClassificationEnum.UNCLASSIFIED,
  ];

  const priority1 = getPriorityValue(notification1);
  const priority2 = getPriorityValue(notification2);

  return priorityOrder.indexOf(priority1) - priorityOrder.indexOf(priority2);
}

export function sortNotificationByCriticality(notification1: NotificationType, notification2: NotificationType) {
  const priorityOrder = [
    DroneAlertLevelEnum.DRONE_CRITICAL,
    DroneAlertLevelEnum.DRONE_WARNING,
    DroneAlertLevelEnum.DRONE_INFO,
    SegmentAlertLevelEnum.PERIMETER_EFFRACTION,
    SegmentAlertLevelEnum.SEGMENT_DISCONNECTED,
    SensorAlertLevelEnum.SENSOR_DISCONNECTED,
    SegmentAlertLevelEnum.SEGMENT_FAILURE,
    SensorAlertLevelEnum.SENSOR_FAILURE,
    null,
  ];

  return (
    priorityOrder.indexOf(notification1.currentWorstAlertLevel) -
    priorityOrder.indexOf(notification2.currentWorstAlertLevel)
  );
}

export function filterNotifications(
  notifications: NotificationType[],
  filters: NotificationFilterEnum[],
): NotificationType[] {
  return notifications.filter((notification) => {
    const isLAD = filters.includes(NotificationFilterEnum.LAD) && isLadNotification(notification);
    const isPerimeter = filters.includes(NotificationFilterEnum.PERIMETER) && isPerimeterNotification(notification);
    const isLadTechnical =
      filters.includes(NotificationFilterEnum.LAD_TECHNICAL) && isLadTechnicalNotification(notification);
    const isPerimeterTechnical =
      filters.includes(NotificationFilterEnum.PERIMETER_TECHNICAL) && isPerimeterTechnicalNotification(notification);

    return isLAD || isPerimeter || isLadTechnical || isPerimeterTechnical;
  });
}

export function isSpaceViolationAlert(alert: AlertType): alert is SpaceViolationAlert {
  return alert.type === AlertTypeEnum.SPACE_VIOLATION;
}

export function isCriticalNotification(
  notification: NotificationType,
  opt?: { isArchived?: boolean; isToast?: boolean },
) {
  const level = getWorstNotificationLevel(notification, opt);
  return level !== null && criticalAlertLevel.includes(level);
}

type Weight = 500 | 800;

export function getAlertLevelColor(level: AlertLevelEnum | null, weight: Weight = 500) {
  if (level === null) {
    return targetColor[ClassificationEnum.UNKNOWN];
  }
  switch (level) {
    case DroneAlertLevelEnum.DRONE_INFO:
      return `var(--chakra-colors-alertLo-${weight})`;
    case DroneAlertLevelEnum.DRONE_WARNING:
    case SensorAlertLevelEnum.SENSOR_FAILURE:
    case SegmentAlertLevelEnum.SEGMENT_FAILURE:
      return `var(--chakra-colors-alertMid-${weight})`;
    case SegmentAlertLevelEnum.PERIMETER_EFFRACTION:
    case DroneAlertLevelEnum.DRONE_CRITICAL:
      return `var(--chakra-colors-alertHi-${weight})`;
    case SensorAlertLevelEnum.SENSOR_DISCONNECTED:
    case SegmentAlertLevelEnum.SEGMENT_DISCONNECTED:
      return `var(--chakra-colors-alertHs-${weight})`;
  }
}

export function getNotificationColor(
  notification: NotificationType,
  opt?: {
    weight?: Weight;
    isArchived?: boolean;
    isToast?: boolean;
  },
) {
  const { weight, ...option } = {
    weight: 500 as Weight,
    isArchived: false,
    isToast: false,
    ...opt,
  };
  const level = getWorstNotificationLevel(notification, option);
  return getAlertLevelColor(level, weight);
}

export function getClearanceColor(clearance: DroneClearance | null) {
  switch (clearance) {
    case DroneClearance.CLEARANCE_WITHIN_FLIGHTPLAN:
    case DroneClearance.FULL_CLEARANCE:
    case DroneClearance.FULL_CLEARANCE_WITHIN_FLIGHTPLAN:
      return `var(--chakra-colors-alertZero-500)`;
    case DroneClearance.NO_CLEARANCE:
      return `var(--chakra-colors-alertHi-500)`;
    default:
      return `var(--chakra-colors-neutral-300)`;
  }
}

export function getNotificationName(notification: NotificationType) {
  switch (notification.type) {
    case NotificationTypeEnum.SENSOR:
      return notification.sensorName;
    case NotificationTypeEnum.SEGMENT:
      return notification.segmentName;
    case NotificationTypeEnum.DRONE:
      return getDroneCollaborativeId(notification.trackIds);
  }
}
