import { centerMapByMapId, updateZoomByMapId } from '@redux/maps/maps.reducer';
import { updateReplayStartPosition, updateReplayStartTime, updateStatus } from '@redux/replay/replay.reducer';
import { selectReplayStartPosition, selectReplayStartTime } from '@redux/replay/replay.selectors';
import { RootState } from '@redux/store';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { MapIdEnum } from '@/types/map.types';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: updateStatus,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState;
    const replayStartTime = selectReplayStartTime(state);
    const replayStartPosition = selectReplayStartPosition(state);
    if (replayStartTime) {
      listenerApi.dispatch(updateReplayStartTime(null));
    }
    if (replayStartPosition) {
      listenerApi.dispatch(centerMapByMapId({ mapId: MapIdEnum.REPLAY, position: replayStartPosition }));
      listenerApi.dispatch(updateZoomByMapId({ mapId: MapIdEnum.REPLAY, zoom: 13 }));
      listenerApi.dispatch(updateReplayStartPosition(null));
    }
  },
});

export default listenerMiddleware;
