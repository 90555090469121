import { ReactComponent as EllipsisIcon } from '@assets/components/panel/notification/ellipsis.svg';
import { ReactComponent as DroneSureIcon } from '@assets/components/panel/notification/notification-drone-icon-sure.svg';
import { ReactComponent as DroneUnsureIcon } from '@assets/components/panel/notification/notification-drone-icon-unsure.svg';
import { ReactComponent as PerimeterAlertIcon } from '@assets/icons/54x54/sensor/effraction-M.svg';
import { ReactComponent as SegmentAlertIcon } from '@assets/icons/54x54/sensor/icSymb-tro.svg';
import { Center, HStack, Icon, Text, Tooltip, VStack } from '@chakra-ui/react';
import SensorIcon from '@components/map/infos/sensors/SensorIcon';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import {
  getNotificationColor,
  getNotificationName,
  getSensorIconKeyByStatus,
  getWorstNotificationLevel,
  isDroneNotification,
  isSensorNotification,
} from '@utils/data/notification.utils';
import { targetColor } from '@utils/map/map.constants';
import { sensorColorFromSensorStatus } from '@utils/sensors/configuration.constants';
import { getUniqueSensorShortHand } from '@utils/target.utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';

import { NotificationType, NotificationTypeEnum, SegmentAlertLevelEnum } from '@/types/data/data.types';
import { ReliabilityEnum } from '@/types/sensor/identification.types';

export function getNotificationIcon(notification: NotificationType, opt?: { isArchived?: boolean; isToast?: boolean }) {
  const { isArchived, isToast } = { isArchived: false, isToast: false, ...opt };
  switch (notification.type) {
    case NotificationTypeEnum.DRONE: {
      const isExpiredStyle = notification.expiredTime !== null && !isArchived;
      const color = targetColor[notification.classification];
      return (
        <Icon
          as={notification.reliability === ReliabilityEnum.SURE ? DroneSureIcon : DroneUnsureIcon}
          width="48px"
          height="48px"
          sx={{
            '#background': { fill: isExpiredStyle ? 'neutral.black' : color },
            '#line': { fill: isExpiredStyle ? color : 'neutral.black' },
          }}
        />
      );
    }
    case NotificationTypeEnum.SENSOR: {
      return (
        <SensorIcon
          size="sm"
          sensorIconName={getSensorIconKeyByStatus(notification.sensorType, notification.sensorStatus)}
        />
      );
    }
    case NotificationTypeEnum.SEGMENT: {
      const level = getWorstNotificationLevel(notification, { isArchived, isToast });
      const color = sensorColorFromSensorStatus[notification.segmentStatus];
      if (level === SegmentAlertLevelEnum.PERIMETER_EFFRACTION) {
        return <Icon as={PerimeterAlertIcon} width="48px" height="48px" color={color} />;
      } else {
        return <Icon as={SegmentAlertIcon} width="48px" height="48px" color={color} />;
      }
    }
  }
}

type Props = {
  notification: NotificationType;
  isArchived: boolean;
  isSelected?: boolean;
};

function NotificationCardName({ notification, isArchived, isSelected = false }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const platforms = useAppSelector(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(notification.marks)),
    shallowEqual,
  );
  const level = getWorstNotificationLevel(notification, { isArchived });
  const color = getNotificationColor(notification, { isArchived });

  const notificationName = getNotificationName(notification);
  const hasMorePlatform = platforms.length > 5;

  return (
    <HStack gap={2} paddingLeft={3} paddingRight={2} width="312px">
      {getNotificationIcon(notification, { isArchived })}
      <VStack gap={1} alignItems="start" width="100%">
        <Text backgroundColor="neutral.black" paddingX={0.5} color={color} lineHeight="18px">
          <FormattedMessage
            id={`notification.name.${level}`}
            values={{
              value: isSensorNotification(notification)
                ? formatMessage({ id: `sensors.type.${notification.sensorType}` })
                : undefined,
            }}
          />
        </Text>
        <Tooltip label={notificationName}>
          <Text
            paddingX={0.5}
            noOfLines={1}
            maxWidth="100%"
            backgroundColor="neutral.black"
            textTransform="uppercase"
            lineHeight="18px"
            wordBreak="break-all"
          >
            {notificationName}
          </Text>
        </Tooltip>
        <HStack maxWidth="100%" gap={0.5}>
          {platforms.slice(0, 5).map((platform) => (
            <Tooltip key={platform} label={platform}>
              <Text
                paddingX={0.25}
                backgroundColor={isSelected ? 'neutral.800' : 'neutral.600'}
                textTransform="uppercase"
                lineHeight="18px"
                color="neutral.white"
              >
                {platform.slice(0, 4)}
              </Text>
            </Tooltip>
          ))}
          {hasMorePlatform && (
            <Tooltip label={platforms.slice(5).join(', ')}>
              <Center
                width="15px"
                height="18px"
                backgroundColor={isSelected ? 'neutral.800' : 'neutral.600'}
                paddingTop="11px"
              >
                <Icon as={EllipsisIcon} width="8px" height="3px" color="neutral.white" />
              </Center>
            </Tooltip>
          )}
        </HStack>
        {isDroneNotification(notification) && (
          <HStack width="100%" gap={1}>
            {getUniqueSensorShortHand(notification.trackIds).map((sensor) => (
              <Text key={sensor} color="neutral.300">
                {sensor}
              </Text>
            ))}
          </HStack>
        )}
      </VStack>
    </HStack>
  );
}

export default NotificationCardName;
