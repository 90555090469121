import { SHOW_LAD_SENSOR_MIN_ZOOM } from '@utils/map/mapZoom.constants';
import { SENSOR_LABEL_TEXT_SIZE } from '@utils/sensors/configuration.constants';
import { CircleLayer, SymbolLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';
import { SENSOR_LABEL_ICON_OFFSET_PX, SENSOR_LABEL_TEXT_OFFSET_PX_L } from '@/types/sensor/sensor.types';

export enum ClusterColor {
  PURPLE = 'PURPLE',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
  ORANGE_MAINTENANCE = 'ORANGE_MAINTENANCE',
  GRAY = 'GRAY',
  WHITE = 'WHITE',
}

export const clustersIconLayer: SymbolLayer = {
  id: LayerNameEnum.SENSOR_CLUSTERS,
  type: 'symbol',
  source: SourceNameEnum.SENSORS,
  filter: ['any', ['!', ['has', 'selected']], ['==', ['get', 'selected'], false]],
  layout: {
    'text-field': [
      'case',
      ['all', ['>', ['get', ClusterColor.PURPLE], 0], ['has', ClusterColor.PURPLE]],
      ['to-string', ['get', 'point_count']],
      ['all', ['>', ['get', ClusterColor.ORANGE], 0], ['has', ClusterColor.ORANGE]],
      ['to-string', ['get', 'point_count']],
      ['all', ['>', ['get', ClusterColor.GREEN], 0], ['has', ClusterColor.GREEN]],
      ['to-string', ['get', 'point_count']],
      ['all', ['>', ['get', ClusterColor.ORANGE_MAINTENANCE], 0], ['has', ClusterColor.ORANGE_MAINTENANCE]],
      ['to-string', ['get', 'point_count']],
      ['all', ['>', ['get', ClusterColor.GRAY], 0], ['has', ClusterColor.GRAY]],
      ['to-string', ['get', 'point_count']],
      ['all', ['>', ['get', ClusterColor.WHITE], 0], ['has', ClusterColor.WHITE]],
      ['to-string', ['get', 'point_count']],
      '',
    ],
    'text-font': ['roboto'],
    'text-size': 24,
    'text-offset': [0, 0.1],
    'text-ignore-placement': true,
    'text-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
    'icon-anchor': 'center',
    'icon-image': [
      'case',
      ['>', ['get', ClusterColor.PURPLE], 0],
      'icSymb-cluster-pr',
      ['>', ['get', ClusterColor.ORANGE], 0],
      'icSymb-cluster-or',
      ['>', ['get', ClusterColor.GREEN], 0],
      'icSymb-cluster-gn',
      ['>', ['get', ClusterColor.ORANGE_MAINTENANCE], 0],
      'icSymb-cluster-or',
      ['>', ['get', ClusterColor.GRAY], 0],
      'icSymb-cluster-gy',
      ['>', ['get', ClusterColor.WHITE], 0],
      'icSymb-cluster-wh',
      'icSymb-cluster-wh',
    ],
  },
};

export const clusterOverlay: CircleLayer = {
  id: LayerNameEnum.SENSOR_CLUSTER_OVERLAY,
  type: 'circle',
  source: SourceNameEnum.SENSORS,
  filter: ['all', ['has', 'cluster'], ['==', ['get', 'selected'], true]],
  minzoom: 13,
  maxzoom: 23,
  paint: {
    'circle-color': 'black',
    'circle-opacity': 0.5,
    'circle-radius': ['coalesce', ['get', 'clusterRadius'], 48],
    'circle-stroke-width': 2,
    'circle-stroke-color': 'white',
  },
};

export const clusterLeavesLayer: SymbolLayer = {
  id: LayerNameEnum.SENSOR_CLUSTER_LEAVES,
  type: 'symbol',
  source: SourceNameEnum.SENSORS,
  filter: ['all', ['==', ['get', 'featureType'], FeatureTypeEnum.SENSOR_ICON], ['has', 'clusterFeature']],
  minzoom: 13,
  maxzoom: 23,
  layout: {
    'icon-image': ['concat', ['get', 'icon'], '-L'],
    'icon-size': 1,
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-allow-overlap': true,
    'text-ignore-placement': true,
    'symbol-z-order': 'viewport-y',
    'symbol-avoid-edges': true,
  },
};

export const sensorIconLayer: SymbolLayer = {
  id: LayerNameEnum.SENSOR_ICONS,
  type: 'symbol',
  source: SourceNameEnum.SENSORS,
  filter: [
    'all',
    ['==', ['get', 'featureType'], FeatureTypeEnum.SENSOR_ICON],
    ['!', ['has', 'cluster']],
    ['!', ['has', 'point_count']],
    ['!', ['has', 'clusterFeature']],
  ],
  layout: {
    'icon-image': [
      'step',
      ['zoom'],
      ['concat', ['get', 'icon'], '-S'],
      SHOW_LAD_SENSOR_MIN_ZOOM,
      ['concat', ['get', 'icon'], '-L'],
    ],
    'icon-size': 1,
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'symbol-z-order': 'source',
  },
};

export const selectedSensorIconLayer: SymbolLayer = {
  ...sensorIconLayer,
  id: LayerNameEnum.SENSOR_ICON_SELECTION,
  source: SourceNameEnum.SELECTION,
  layout: {
    ...sensorIconLayer.layout,
    'icon-image': [
      'step',
      ['zoom'],
      ['concat', ['get', 'icon'], '-S-halo'],
      SHOW_LAD_SENSOR_MIN_ZOOM,
      ['concat', ['get', 'icon'], '-L-halo'],
    ],
  },
};

export const sensorIconLabelLayer: SymbolLayer = {
  id: LayerNameEnum.SENSOR_LABELS,
  type: 'symbol',
  source: SourceNameEnum.SENSORS,
  filter: ['==', ['get', 'featureType'], FeatureTypeEnum.SENSOR_LABEL],
  minzoom: SHOW_LAD_SENSOR_MIN_ZOOM,
  layout: {
    'icon-image': 'icSymb-label',
    'icon-size': 1,
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-offset': SENSOR_LABEL_ICON_OFFSET_PX,
    'text-font': ['roboto'],
    'text-field': ['get', 'textField'],
    'text-size': SENSOR_LABEL_TEXT_SIZE,
    'text-allow-overlap': true,
    'text-ignore-placement': true,
    'text-offset': SENSOR_LABEL_TEXT_OFFSET_PX_L,
    'symbol-z-order': 'source',
  },
  paint: {
    'text-color': 'white',
  },
};

export const selectedSensorIconLabelLayer: SymbolLayer = {
  ...sensorIconLabelLayer,
  id: LayerNameEnum.SENSOR_LABEL_SELECTION,
  source: SourceNameEnum.SELECTION,
};
