import { NotificationCriteria, NotificationDetails, NotificationType } from '@/types/data/data.types';

import { DATA_URL, dataApi } from './data.api';

export const notificationApi = dataApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<NotificationType[], NotificationCriteria>({
      query: ({ site, to, from, identifier, page, types, unpaginated, notificationStatus, size }) => ({
        url: `${DATA_URL}/notifications`,
        params: {
          sites: [site],
          to,
          from,
          identifier,
          notificationStatus,
          page,
          size,
          types,
          unpaginated,
        },
      }),
    }),
    exportNotifications: builder.mutation<Blob, NotificationCriteria>({
      query: ({ site, to, from, page, size, types, unpaginated }) => ({
        url: `${DATA_URL}/notifications/export`,
        method: 'GET',
        params: {
          sites: [site],
          to,
          from,
          page,
          size,
          types,
          unpaginated,
        },
        responseHandler: (response) => response.text(),
        transformResponse: (response: { data: Blob }) => response.data,
      }),
    }),
    acknowledgeNotification: builder.mutation<NotificationType, number>({
      query: (id) => ({
        url: `${DATA_URL}/notifications/${id}/acknowledge`,
        method: 'PATCH',
      }),
    }),
    archiveNotification: builder.mutation<NotificationType, number>({
      query: (id) => ({
        url: `${DATA_URL}/notifications/${id}/archive`,
        method: 'PATCH',
      }),
    }),
    confirmNotification: builder.mutation<NotificationType, number>({
      query: (id) => ({
        url: `${DATA_URL}/notifications/${id}/confirm`,
        method: 'PATCH',
      }),
    }),
    totalOfNotifications: builder.query<number, NotificationCriteria>({
      query: ({ site, identifier, types, notificationStatus }) => ({
        url: `${DATA_URL}/notifications/count`,
        params: {
          sites: [site],
          identifier,
          notificationStatus,
          types,
        },
      }),
    }),
    getDetail: builder.query<NotificationDetails, { id: number; withArchivedAlerts: boolean }>({
      query: ({ id, withArchivedAlerts }) => ({
        url: `${DATA_URL}/notifications/${id}/detail`,
        params: {
          withArchivedAlerts,
        },
      }),
    }),
  }),
});

export const archiveNotification = notificationApi.endpoints.archiveNotification.initiate;
export const useGetNotificationsLazyQuery = notificationApi.endpoints.getNotifications.useLazyQuery;
export const {
  useAcknowledgeNotificationMutation,
  useArchiveNotificationMutation,
  useGetNotificationsQuery,
  useConfirmNotificationMutation,
  useTotalOfNotificationsQuery,
  useLazyGetDetailQuery,
  useGetDetailQuery,
} = notificationApi;
