import { useToast } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectAllOngoingNotifications } from '@redux/data/data.selectors';
import { deleteNotificationFromQueue } from '@redux/global/global.reducer';
import { selectNotificationQueue } from '@redux/global/global.selector';
import { notificationToastConfig } from '@utils/toast.utils';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import NotificationToast from './NotificationToast';

export function ToastNotificationContainer() {
  const notificationQueue = useAppSelector(selectNotificationQueue);
  const notifications = useAppSelector(selectAllOngoingNotifications);
  const removeNotificationFromQueue = useWithDispatch(deleteNotificationFromQueue);
  const toastIds = useRef<number[]>([]);
  const toast = useToast();

  const createToast = useCallback(
    (id: string | number, element?: ReactNode) => {
      toast({
        ...notificationToastConfig,
        id,
        render: () => element,
      });
    },
    [toast],
  );

  useEffect(() => {
    //If queue is not empty and max number of toast is not reached
    //create a toast with the next alert in queue and remove it from the queue
    if (notificationQueue.length > 0 && toastIds.current.length < 10) {
      const notification = notificationQueue[0];
      createToast(
        notification.id,
        <NotificationToast
          notification={notification}
          onClose={() => {
            toast.close(notification.id);
            toastIds.current = toastIds.current.filter((id) => id !== notification.id);
          }}
        />,
      );
      toastIds.current = toastIds.current.concat(notification.id);
      removeNotificationFromQueue(notification);
    }
  }, [notificationQueue, createToast, removeNotificationFromQueue, toast]);

  //If a notification is not found in the list or if it has no unacknowledged alert for more than 2s, close its toast
  useEffect(() => {
    const idsToRemove = toastIds.current.filter((id) => {
      const notification = notifications.find((notification) => notification.id === id);
      return !notification?.hasUnacknowledgedAlert;
    });
    if (idsToRemove.length > 0) {
      idsToRemove.forEach((id) => toast.close(id));
      toastIds.current = toastIds.current.filter((id) => !idsToRemove.includes(id));
    }
  }, [notifications, toast]);

  useEffect(() => {
    return () => {
      toastIds.current.forEach((id) => toast.close(id));
    };
  }, [toast]);

  return null;
}
