export const ZOOM_50_PERCENT = 11;
export const ZOOM_60_PERCENT = 13;
export const ZOOM_68_PERCENT = 15;
export const ZOOM_75_PERCENT = 17;
export const ZOOM_80_PERCENT = 18;
export const ZOOM_85_PERCENT = 19;

export const SHOW_TARGET_ZOOM_EXCEPT_DRONE_AIRPLANE = ZOOM_50_PERCENT;

export const SHOW_LAD_SENSOR_MIN_ZOOM = ZOOM_68_PERCENT;

export const SHOW_PERIMETER_SENSOR_MIN_ZOOM = ZOOM_80_PERCENT;

export const SHOW_AUTOMATON_SECTION_MIN_ZOOM = ZOOM_50_PERCENT;
export const SHOW_AUTOMATON_SECTION_MID_ZOOM = ZOOM_68_PERCENT;
export const SHOW_AUTOMATON_SECTION_MAX_ZOOM = ZOOM_80_PERCENT;

export const SHOW_PERIMETER_PORTAL_MIN_ZOOM = ZOOM_60_PERCENT;

export const SHOW_PERIMETER_CAMERA_MIN_ZOOM = ZOOM_75_PERCENT;
export const SHOW_PERIMETER_CAMERA_MAX_ZOOM = ZOOM_80_PERCENT;

export const SHOW_LAD_CAMERA_STROBE_MAX_ZOOM = ZOOM_85_PERCENT;

export const SHOW_RADAR_COVERAGE_MAX_ZOOM = ZOOM_85_PERCENT;

export const ROUND_SECTION_ZOOM_LIMIT_FOR_WIDTH = ZOOM_60_PERCENT;
