import { Tab, TabList, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveGroupsPlatforms } from '@redux/config/config.selectors';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Platform } from '@/types/config/config.types';

import Header from './Header';
import LadSearch from './LadSearch';

export type PlatformSearch = { platform: Platform; active: boolean }[];

export default function Search() {
  const { formatMessage } = useIntl();

  const platforms = useAppSelector(selectActiveGroupsPlatforms);
  const [platformSearch, setPlatformSearch] = useState<PlatformSearch>(
    platforms
      .map((platform) => ({ platform, active: true }))
      .concat({
        platform: {
          id: -1,
          code: '',
          name: formatMessage({ id: 'replay.search.header.withoutPlatform' }),
          position: { latitude: 0, longitude: 0, type: '2D' },
          altitude: 0,
          mapZoom: 1,
        },
        active: true,
      }),
  );

  return (
    <VStack gap={0} width="100%" height="100%">
      <Header platforms={platformSearch} onChange={(value) => setPlatformSearch(value)} />
      <Tabs width="100%" height="100%" variant="cyber" display="flex" flexDirection="column">
        <TabList flex="none">
          <Tab>
            <Text size="md">
              <FormattedMessage id="replay.search.ladSearch.title" />
            </Text>
          </Tab>
          <Tab>
            <Text size="md">
              <FormattedMessage id="replay.search.perim" />
            </Text>
          </Tab>
          <Tab>
            <Text size="md">
              <FormattedMessage id="replay.search.tech" />
            </Text>
          </Tab>
        </TabList>
        <TabPanels flex="1">
          <LadSearch
            platforms={platformSearch.filter((platform) => platform.active).map((platform) => platform.platform)}
          />
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
