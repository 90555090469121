import { SensorIconKeys } from '@assets/icons/70x70/sensor';
import {
  toAutomatonSectionIconFeatures,
  toAutomatonSectionLineFeatures,
} from '@components/map/layers/automatonSections/automatonSection.mapper';
import {
  toLadCameraAzimuthLineFeatures,
  toLadCameraSectorFeatures,
} from '@components/map/layers/ladCameras/ladCamera.mapper';
import {
  toPerimeterCameraAzimuthLineFeatures,
  toPerimeterCameraLabelFeatures,
  toPerimeterCameraSectorFeatures,
} from '@components/map/layers/perimeterCameras/perimeterCamera.mapper';
import { toRadarCoverageFeatures } from '@components/map/layers/radarCoverage/radarCoverage.mapper';
import {
  toMonitoringSensorIconFeatures,
  toMonitoringSensorLabelFeatures,
  toPerimeterLinkFeatures,
  toSensorIconFeature,
  toSensorLabelFeature,
  toSubSensorIconFeature,
} from '@components/map/layers/sensors/sensor.mapper';
import {
  selectSelectedCameraStreams,
  selectSelectedDoubtCheckPerimeterCameraUniqueKey,
  selectSelectedDoubtCheckSegmentName,
} from '@redux/global/global.selector';
import {
  selectDisplayedPerimeterLinksCodesByMapId,
  selectSelectedSensorUniqueCodesByMapId,
} from '@redux/maps/maps.selectors';
import { selectMapFilters } from '@redux/settings/settings.selectors';
import { selectTacticalSituation, selectTargetById } from '@redux/situation/situation.selectors';
import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { selectParameterWithReplayMode } from '@utils/common.utils';
import { getHaversineDistance } from '@utils/distance.utils';
import { getFilteredList } from '@utils/filter.utils';
import {
  getAutomatonPortal,
  getSectionName,
  getSectionPerimeterCameraUniqueKeys,
  getSectionStatus,
  isAutomatonSegmentStatus,
} from '@utils/map/automatonSection.utils';
import {
  getIconBasicInfoFromSensor,
  getSensorIconKey,
  getSensorSpriteIconName,
  isConfigDisplayedOnMap,
  isSensorDisplayedOnMap,
  isSensorWithSubSensors,
  splitSensors,
} from '@utils/map/sensor.utils';
import { isCameraType, isHealthy } from '@utils/sensors/camera/camera.utils';
import {
  getSectionUniqueCode,
  getSensorUniqueCode,
  getSubSensorConfigsFromParent,
  getSubSensorStatusesFromParent,
  hasSubSensorsWithCoordinates,
  isActiveAutomaton,
  isCabinetStatus,
  isRadar,
  isSubSensorUniqueCodes,
  toAutomatonSection,
} from '@utils/sensors/sensors.utils';
import { isValidCoordinates } from '@utils/validation/coordinates.utils';
import { Feature, FeatureCollection } from 'geojson';
import { chain, uniq } from 'lodash';

import { TacticalSituation } from '@/types/c2/c2.types';
import { Coordinates, Dictionary } from '@/types/commons/commons.types';
import { filterKeyToFeatureEnum, FilterTypeEnum, MapFilters } from '@/types/filters.types';
import { MapIdEnum, SensorUniqueCodes, SplitMonitoringSensors } from '@/types/map.types';
import {
  AutomatonCabinet,
  AutomatonConfiguration,
  AutomatonSectionData,
  AutomatonSectionStatus,
  AutomatonSegment,
  AutomatonSegmentCode,
  EquipmentTypeEnum,
  GetSensorConfiguration,
  SensorConfiguration,
  SensorLikeEnum,
  SensorTypeEnum,
  SensorWithSubSensor,
  SubCameraConfiguration,
  SubSensorConfiguration,
} from '@/types/sensor/configuration.types';
import { AppStatus, Monitoring, SensorsSpecificStatus } from '@/types/sensor/monitoring.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';
import {
  AutomatonCabinetStatus,
  AutomatonNodeStateEnum,
  AutomatonSegmentFenceStatus,
  AutomatonSegmentStatus,
  AutomatonSensorStatus,
  CameraStatus,
  CameraTrackingStatus,
  EquipmentStatus,
  GetSensorStatus,
  InactiveMonitoringEquipment,
  InactiveMonitoringSensor,
  MonitoringEquipment,
  MonitoringSensor,
  SensorStatus,
  SensorStatusEnum,
  SubSensorStatus,
} from '@/types/sensor/status.types';

const selectMonitoring = createSelector(
  selectTacticalSituation,
  (state: TacticalSituation | null): Monitoring | null => state?.monitoring ?? null,
);

export const selectTotalMemoryUsage = createSelector(
  selectMonitoring,
  (state: Monitoring | null): number | null => state?.totalMemoryUsage ?? null,
);

export const selectMonitoringSensors = createSelector(
  selectMonitoring,
  (state: Monitoring | null): SensorsSpecificStatus | null => state?.monitoringSensors ?? null,
);

export const selectAppStatuses = createSelector(
  selectMonitoring,
  (state: Monitoring | null): AppStatus[] => state?.appStatuses ?? [],
);

export const selectVersion = createSelector(
  selectAppStatuses,
  (statuses: AppStatus[]): string => statuses.find((ap) => ap.app === 'c2')?.appVersion ?? '',
);

export const selectActiveMonitoringSensors = createSelector(
  selectMonitoringSensors,
  (state: SensorsSpecificStatus | null): SensorStatus[] => state?.activeSensors ?? [],
);

export const selectActiveMonitoringSensorsWithValidPosition = createSelector(
  selectActiveMonitoringSensors,
  (sensorStatuses: SensorStatus[]): SensorStatus[] =>
    sensorStatuses.filter(
      (sensorStatus) =>
        hasSubSensorsWithCoordinates(sensorStatus.configuration) ||
        isValidCoordinates(sensorStatus.configuration.sensorPosition),
    ),
);

export const selectFilteredActiveMonitoringSensorsWithValidPosition = createSelector(
  [selectActiveMonitoringSensorsWithValidPosition, selectMapFilters],
  (sensorStatuses: SensorStatus[], mapFilters: MapFilters): SensorStatus[] =>
    getFilteredList(
      sensorStatuses,
      mapFilters,
      (status: SensorStatus, key: keyof MapFilters) =>
        filterKeyToFeatureEnum(key, FilterTypeEnum.C_UAS_SENSOR) !== status.type,
    ),
);

export const selectInactiveMonitoringSensors = createSelector(
  selectMonitoringSensors,
  (state: SensorsSpecificStatus | null): InactiveMonitoringSensor[] => state?.inactiveSensors ?? [],
);

export const selectInactiveMonitoringSensorsWithValidPosition = createSelector(
  selectInactiveMonitoringSensors,
  (inactiveSensors: InactiveMonitoringSensor[]): InactiveMonitoringSensor[] =>
    inactiveSensors.filter(
      (sensor) =>
        hasSubSensorsWithCoordinates(sensor.configuration) || isValidCoordinates(sensor.configuration.sensorPosition),
    ),
);

export const selectFilteredInactiveMonitoringSensorsWithValidPosition = createSelector(
  [selectInactiveMonitoringSensorsWithValidPosition, selectMapFilters],
  (inactiveSensors: InactiveMonitoringSensor[], mapFilters: MapFilters): InactiveMonitoringSensor[] =>
    getFilteredList(
      inactiveSensors,
      mapFilters,
      (sensor: InactiveMonitoringSensor, key: keyof MapFilters): boolean =>
        filterKeyToFeatureEnum(key, FilterTypeEnum.C_UAS_SENSOR) !== sensor.configuration.type,
    ),
);

export const selectFilteredMonitoringSensorsWithValidPosition = createSelector(
  [selectFilteredActiveMonitoringSensorsWithValidPosition, selectFilteredInactiveMonitoringSensorsWithValidPosition],
  (activeSensors, inactiveSensors): MonitoringSensor[] => [...activeSensors, ...inactiveSensors],
);

export const selectMonitoringSensorBySensorUniqueCode = createSelector(
  [selectFilteredMonitoringSensorsWithValidPosition, selectParameterWithReplayMode<string>],
  (monitoringSensors, sensorUniqueCode): MonitoringSensor | null =>
    monitoringSensors.find((sensor) => getSensorUniqueCode(sensor) === sensorUniqueCode) ?? null,
);

export const selectSensorRadarsConfiguration = createSelector(
  selectFilteredActiveMonitoringSensorsWithValidPosition,
  (sensors: SensorStatus[]): SensorConfiguration[] =>
    sensors.map((sensor) => sensor.configuration).filter((sensor) => isRadar(sensor)),
);

export const selectRadarCoverageFeatures = createSelector(
  selectSensorRadarsConfiguration,
  selectMapFilters,
  (sensor: SensorConfiguration[], mapFilters: MapFilters): Feature[] =>
    mapFilters.displaySensorInfoCoverage ? toRadarCoverageFeatures(sensor, mapFilters) : [],
);

export const selectRadarCoverageFeatureCollection = createSelector(
  [selectRadarCoverageFeatures],
  (sensorsStatusIcons: Feature[]): FeatureCollection => {
    return {
      type: 'FeatureCollection',
      features: sensorsStatusIcons,
    };
  },
);

export const selectSplitMonitoringSensors = createSelector(
  [selectFilteredMonitoringSensorsWithValidPosition, selectMapFilters],
  (monitoringSensors: MonitoringSensor[], mapFilters: MapFilters) =>
    splitSensors(
      monitoringSensors.filter((sensor) => sensor.configuration.type !== SensorTypeEnum.AUTOMATON),
      mapFilters,
    ),
);

export const selectSensorIconFeatures = createSelector(
  selectSplitMonitoringSensors,
  (sensors: SplitMonitoringSensors) => toMonitoringSensorIconFeatures(sensors),
);

export const selectSensorLabelFeatures = createSelector(
  selectSplitMonitoringSensors,
  (sensors: SplitMonitoringSensors): Feature[] => toMonitoringSensorLabelFeatures(sensors),
);

export const selectSensorFeatures = createSelector(
  [selectSensorIconFeatures, selectSensorLabelFeatures],
  (sensorIcons: Feature[], sensorLabels: Feature[]): Feature[] => [...sensorIcons, ...sensorLabels],
);

export const selectSplitPerimeterMonitoringSensors = createSelector(
  [selectFilteredMonitoringSensorsWithValidPosition, selectMapFilters],
  (monitoringSensors: MonitoringSensor[], mapFilters: MapFilters) =>
    splitSensors(
      monitoringSensors.filter((sensor) => sensor.configuration.type === SensorTypeEnum.AUTOMATON),
      mapFilters,
    ),
);

export const selectPerimeterSensorIconFeatures = createSelector(
  selectSplitPerimeterMonitoringSensors,
  (sensors: SplitMonitoringSensors) => toMonitoringSensorIconFeatures(sensors),
);

export const selectPerimeterSensorLabelFeatures = createSelector(
  selectSplitPerimeterMonitoringSensors,
  (sensors: SplitMonitoringSensors): Feature[] => toMonitoringSensorLabelFeatures(sensors),
);

export const selectPerimeterSensorFeatures = createSelector(
  [selectPerimeterSensorIconFeatures, selectPerimeterSensorLabelFeatures],
  (sensorIcons: Feature[], sensorLabels: Feature[]): FeatureCollection => {
    return {
      type: 'FeatureCollection',
      features: [...sensorIcons, ...sensorLabels],
    };
  },
);

export const selectAllSensorConfigurations = createSelector(
  [selectActiveMonitoringSensors, selectInactiveMonitoringSensors],
  (activeSensors: SensorStatus[], inactiveSensors: InactiveMonitoringSensor[]): SensorConfiguration[] => [
    ...activeSensors.map((status) => status.configuration),
    ...inactiveSensors.map((monitoringSensor) => monitoringSensor.configuration),
  ],
);

export const selectSensorConfigurationByUniqueCode = createSelector(
  [selectAllSensorConfigurations, selectParameterWithReplayMode<string | null>],
  (sensorConfigurations, uniqueCode): SensorConfiguration | null =>
    sensorConfigurations.find((sensor) => getSensorUniqueCode(sensor) === uniqueCode) ?? null,
);

export const selectSensorOrSubSensorConfigurationByUniqueCodes = createSelector(
  [selectAllSensorConfigurations, selectParameterWithReplayMode<SensorUniqueCodes>],
  (sensorConfigurations, uniqueCodes): SensorConfiguration | SubSensorConfiguration | null => {
    if (isSubSensorUniqueCodes(uniqueCodes)) {
      return (
        sensorConfigurations
          .filter(isSensorWithSubSensors)
          .flatMap((sensor) => getSubSensorConfigsFromParent(sensor))
          .find(
            (subSensorConfiguration) => getSensorUniqueCode(subSensorConfiguration) === uniqueCodes.sensorUniqueCode,
          ) ?? null
      );
    }
    return sensorConfigurations.find((sensor) => getSensorUniqueCode(sensor) === uniqueCodes.sensorUniqueCode) ?? null;
  },
);

export const selectSensorAndSubSensorStatuses = createSelector(
  [selectActiveMonitoringSensors],
  (sensorStatuses): { status: SensorStatus | SubSensorStatus | AutomatonSegmentStatus; uniqueCode: string }[] =>
    sensorStatuses.flatMap((status) => {
      if (isSensorWithSubSensors(status.configuration)) {
        return [
          { status, uniqueCode: getSensorUniqueCode(status) },
          ...getSubSensorStatusesFromParent(status).flatMap((subStatus) => {
            const result = {
              status: subStatus,
              uniqueCode: getSensorUniqueCode(subStatus),
            };
            if (isCabinetStatus(subStatus)) {
              const segmentStatuses = Object.values(subStatus.segmentStatuses)
                .filter((segmentStatus): segmentStatus is AutomatonSegmentStatus => segmentStatus !== undefined)
                .map((segmentStatus) => ({
                  status: segmentStatus,
                  uniqueCode: getSectionUniqueCode(segmentStatus),
                }));
              return [result, ...segmentStatuses];
            }
            return [result];
          }),
        ];
      }
      return [{ status, uniqueCode: getSensorUniqueCode(status) }];
    }),
);

export const selectSensorAndSubSensorStatusesByUniqueCode = createSelector(
  [selectSensorAndSubSensorStatuses, selectParameterWithReplayMode<string>],
  (sensorStatuses, code): SensorStatusEnum | null => {
    const statusInfo = sensorStatuses.find((sensorStatus) => sensorStatus.uniqueCode === code);
    if (!statusInfo) {
      return null;
    }
    const { status } = statusInfo;
    if (isAutomatonSegmentStatus(status)) {
      return getSectionStatus(
        Object.values(status)
          .filter(Boolean)
          .map((segment) => segment.status),
      );
    }
    return status.status;
  },
);

export const selectSensorStatusByUniqueCode = createSelector(
  [selectActiveMonitoringSensors, selectParameterWithReplayMode<string | null>],
  (sensorStatuses, code): SensorStatus | null =>
    sensorStatuses.find((sensorStatus) => getSensorUniqueCode(sensorStatus) === code) ?? null,
);

export const selectSubSensorStatusByUniqueCode = createSelector(
  [selectActiveMonitoringSensors, selectParameterWithReplayMode<string | null>],
  (sensorStatuses, code): SubSensorStatus | null =>
    sensorStatuses
      .flatMap((sensor) => getSubSensorStatusesFromParent(sensor))
      .find((subSensorStatus) => getSensorUniqueCode(subSensorStatus) === code) ?? null,
);

const selectStatusByUniqueCodes = (_: RootState, replay: boolean, uniqueCodes: SensorUniqueCodes) =>
  selectSensorStatusByUniqueCode(_, replay, uniqueCodes.sensorUniqueCode);
const selectSubStatusByUniqueCodes = (_: RootState, replay: boolean, uniqueCodes: SensorUniqueCodes) =>
  selectSubSensorStatusByUniqueCode(_, replay, uniqueCodes.sensorUniqueCode);

export const selectSensorOrSubSensorStatusByUniqueCodes = createSelector(
  [selectParameterWithReplayMode<SensorUniqueCodes>, selectStatusByUniqueCodes, selectSubStatusByUniqueCodes],
  (uniqueCodes, status, subStatus): SensorStatus | SubSensorStatus | null => {
    if ('parentUniqueCode' in uniqueCodes) {
      return subStatus;
    }
    return status;
  },
);

export const selectSelectedSensorFeaturesFromActiveMonitoringSensors = createSelector(
  [
    (state, mapId: MapIdEnum) =>
      selectFilteredActiveMonitoringSensorsWithValidPosition(state, mapId === MapIdEnum.REPLAY),
    selectSelectedSensorUniqueCodesByMapId,
  ],
  (sensorStatuses, selectedSensorUniqueCodes): Feature[] => {
    if (!selectedSensorUniqueCodes) {
      return [];
    }
    if (isSubSensorUniqueCodes(selectedSensorUniqueCodes)) {
      const parentSensorStatus = (sensorStatuses.find(
        (sensorStatus) => getSensorUniqueCode(sensorStatus) === selectedSensorUniqueCodes.parentUniqueCode,
      ) ?? null) as GetSensorStatus<SensorWithSubSensor> | null;
      const subSensorStatus =
        (parentSensorStatus &&
          getSubSensorStatusesFromParent(parentSensorStatus).find(
            (subSensorStatus) => getSensorUniqueCode(subSensorStatus) === selectedSensorUniqueCodes.sensorUniqueCode,
          )) ??
        null;

      const subSensorConfig =
        (parentSensorStatus &&
          getSubSensorConfigsFromParent(parentSensorStatus.configuration).find(
            (subSensor) => getSensorUniqueCode(subSensor) === selectedSensorUniqueCodes.sensorUniqueCode,
          )) ??
        null;

      if (!parentSensorStatus || !subSensorConfig) {
        return [];
      }

      return [
        toSubSensorIconFeature({
          parentSensorConfig: parentSensorStatus.configuration,
          subSensorConfig,
          subSensorStatus,
        }),
        toSensorLabelFeature(
          selectedSensorUniqueCodes,
          subSensorConfig.position,
          subSensorConfig.name,
          subSensorConfig.type,
        ),
      ];
    }

    const sensorStatus =
      sensorStatuses.find(
        (sensorStatus) => getSensorUniqueCode(sensorStatus) === selectedSensorUniqueCodes.sensorUniqueCode,
      ) ?? null;

    if (!sensorStatus || !isSensorDisplayedOnMap(sensorStatus)) {
      return [];
    }

    return [
      toSensorIconFeature(
        selectedSensorUniqueCodes,
        sensorStatus.configuration.sensorPosition,
        sensorStatus.configuration.type,
        sensorStatus.configuration.active,
        sensorStatus.configuration.maintenance,
        getSensorSpriteIconName(getIconBasicInfoFromSensor(sensorStatus.configuration, sensorStatus.status)),
        sensorStatus.status,
      ),
      toSensorLabelFeature(
        selectedSensorUniqueCodes,
        sensorStatus.configuration.sensorPosition,
        sensorStatus.configuration.name,
        sensorStatus.configuration.type,
      ),
    ];
  },
);

export const selectSelectedSensorFeaturesFromInactiveMonitoringSensors = createSelector(
  [
    (state, mapId: MapIdEnum) =>
      selectFilteredInactiveMonitoringSensorsWithValidPosition(state, mapId === MapIdEnum.REPLAY),
    selectSelectedSensorUniqueCodesByMapId,
  ],
  (inactiveMonitoringSensors, selectedSensorUniqueCodes): Feature[] => {
    if (!selectedSensorUniqueCodes) {
      return [];
    }
    if (isSubSensorUniqueCodes(selectedSensorUniqueCodes)) {
      const parentSensorConfig = (inactiveMonitoringSensors.find(
        (sensor) => getSensorUniqueCode(sensor) === selectedSensorUniqueCodes.parentUniqueCode,
      )?.configuration ?? null) as GetSensorConfiguration<SensorWithSubSensor> | null;

      const subSensorConfig =
        (parentSensorConfig &&
          (getSubSensorConfigsFromParent(parentSensorConfig) as SubSensorConfiguration[]).find(
            (subSensor) => getSensorUniqueCode(subSensor) === selectedSensorUniqueCodes.sensorUniqueCode,
          )) ??
        null;

      if (!parentSensorConfig || !subSensorConfig) {
        return [];
      }

      return [
        toSubSensorIconFeature({
          parentSensorConfig,
          subSensorConfig,
          subSensorStatus: null,
        }),
        toSensorLabelFeature(
          selectedSensorUniqueCodes,
          subSensorConfig.position,
          subSensorConfig.name,
          subSensorConfig.type,
        ),
      ];
    }

    const sensorConfiguration =
      inactiveMonitoringSensors.find(
        (sensor) => getSensorUniqueCode(sensor) === selectedSensorUniqueCodes.sensorUniqueCode,
      )?.configuration ?? null;

    if (!sensorConfiguration || !isConfigDisplayedOnMap(sensorConfiguration)) {
      return [];
    }

    return [
      toSensorIconFeature(
        selectedSensorUniqueCodes,
        sensorConfiguration.sensorPosition,
        sensorConfiguration.type,
        sensorConfiguration.active,
        sensorConfiguration.maintenance,
        getSensorSpriteIconName(getIconBasicInfoFromSensor(sensorConfiguration)),
      ),
      toSensorLabelFeature(
        selectedSensorUniqueCodes,
        sensorConfiguration.sensorPosition,
        sensorConfiguration.name,
        sensorConfiguration.type,
      ),
    ];
  },
);

export const selectSelectedSensorFeatures = createSelector(
  [selectSelectedSensorFeaturesFromActiveMonitoringSensors, selectSelectedSensorFeaturesFromInactiveMonitoringSensors],
  (
    selectedSensorFeaturesFromActiveMonitoringSensors,
    selectedSensorFeaturesFromInactiveMonitoringSensors,
  ): Feature[] => [
    ...selectedSensorFeaturesFromActiveMonitoringSensors,
    ...selectedSensorFeaturesFromInactiveMonitoringSensors,
  ],
);

export const selectAutomatonSegmentStatusByCode = createSelector(
  [selectActiveMonitoringSensors, selectParameterWithReplayMode<AutomatonSegmentCode>],
  (statuses, codes) => {
    if (!codes.segment) {
      return null;
    }
    const automatonStatus = (statuses.find((sensorStatus) => sensorStatus.configuration.code === codes.automaton) ??
      null) as AutomatonSensorStatus | null;
    const cabinetStatus =
      (automatonStatus &&
        (Object.values(automatonStatus.subSensorStatuses) as AutomatonCabinetStatus[]).find(
          (cabinet) => cabinet.code === codes.cabinet,
        )) ??
      null;

    return (
      (cabinetStatus &&
        Object.values(cabinetStatus.segmentStatuses)
          .flatMap((segmentStatus) => Object.values(segmentStatus as AutomatonSegmentStatus))
          .filter(Boolean)
          .find((segment) => segment.code.includes(codes.segment))?.status) ??
      null
    );
  },
);

export const selectAutomatonStatuses = createSelector(
  selectActiveMonitoringSensors,
  (statuses): AutomatonSensorStatus[] =>
    statuses.filter((sensorStatus) => sensorStatus.type === SensorTypeEnum.AUTOMATON),
);

export const selectAutomatonCabinetStatuses = createSelector(
  selectAutomatonStatuses,
  (automatons) =>
    automatons
      .flatMap((automaton) => Object.values(automaton.subSensorStatuses))
      .filter(Boolean) as AutomatonCabinetStatus[],
);

const selectSegmentStatuses = createSelector(
  selectAutomatonCabinetStatuses,
  (cabinets) =>
    cabinets.flatMap((cabinet) => Object.values(cabinet.segmentStatuses)).filter(Boolean) as AutomatonSegmentStatus[],
);

export const selectSectionsStatuses = createSelector(selectSegmentStatuses, (segments) =>
  segments.map((segment: AutomatonSegmentStatus) => {
    const fences: AutomatonSegmentFenceStatus[] = Object.values(segment).filter(Boolean);
    return {
      name: fences[0].subSensorName,
      status: getSectionStatus(fences.map((segment) => segment.status)),
      upperStates: segment.upperFenceStatusAndState?.states ?? {},
      lowerStates: segment.lowerFenceStatusAndState?.states ?? {},
    } as AutomatonSectionStatus;
  }),
);

export const selectSectionStatusByName = createSelector(
  [selectSectionsStatuses, selectParameterWithReplayMode<string>],
  (statuses, name): SensorStatusEnum | null => statuses.find((status) => status.name === name)?.status ?? null,
);

export const selectLowerStatesByName = createSelector(
  [selectSectionsStatuses, selectParameterWithReplayMode<string>],
  (statuses, name): Dictionary<AutomatonNodeStateEnum[]> | null =>
    statuses.find((status) => status.name === name)?.lowerStates ?? null,
);

export const selectUpperStatesByName = createSelector(
  [selectSectionsStatuses, selectParameterWithReplayMode<string>],
  (statuses, name): Dictionary<AutomatonNodeStateEnum[]> | null =>
    statuses.find((status) => status.name === name)?.upperStates ?? null,
);

export const selectCameraStatuses = createSelector(
  selectActiveMonitoringSensorsWithValidPosition,
  (state: SensorStatus[]): CameraStatus[] => state.filter((s) => isCameraType(s)).map((s) => s as CameraStatus),
);

export const selectFilteredHealthyCameraStatuses = createSelector(
  selectFilteredActiveMonitoringSensorsWithValidPosition,
  (state: SensorStatus[]): CameraStatus[] => state.filter((s) => isCameraType(s) && isHealthy(s)) as CameraStatus[],
);

export const selectHealthyCameraStatuses = createSelector(
  selectCameraStatuses,
  (cameraStatus: CameraStatus[]): CameraStatus[] => cameraStatus.filter((cs) => isHealthy(cs)),
);

export const selectTrackingStatesOfHealthyCameras = createSelector(
  selectHealthyCameraStatuses,
  (cameraStatuses: CameraStatus[]): { code: string; status: CameraTrackingStatus }[] =>
    cameraStatuses.map((status: CameraStatus) => ({ code: status.configuration.code, status: status.trackingStatus })),
);

export const selectLadCameraSectorFeatures = createSelector(
  [
    selectFilteredHealthyCameraStatuses,
    selectMapFilters,
    (state: RootState, replayMode?: boolean) => (replayMode ? [] : selectSelectedCameraStreams(state)),
  ],
  (
    cameraStatuses: CameraStatus[],
    mapFilters: MapFilters,
    selectedCameraStreams: SubCameraConfiguration[],
  ): Feature[] =>
    mapFilters.displaySensorInfoLadCameraFov ? toLadCameraSectorFeatures(cameraStatuses, selectedCameraStreams) : [],
);

export const selectLadCameraAzimuthLineFeatures = createSelector(
  selectFilteredHealthyCameraStatuses,
  selectMapFilters,
  (cameraStatuses: CameraStatus[], mapFilters: MapFilters): Feature[] =>
    mapFilters.displaySensorInfoLadCameraFov ? toLadCameraAzimuthLineFeatures(cameraStatuses) : [],
);

export const selectLadCameraFeatures = createSelector(
  [selectLadCameraSectorFeatures, selectLadCameraAzimuthLineFeatures],
  (sectors, azimuthLines): FeatureCollection => {
    return {
      type: 'FeatureCollection',
      features: [...sectors, ...azimuthLines],
    };
  },
);

export const selectClosestActiveHealthyCamera = createSelector(
  [
    (state: RootState, replayMode: boolean, point: Coordinates | null) => ({
      cameraStatus: selectHealthyCameraStatuses(state, replayMode),
      point: point,
    }),
  ],
  ({ cameraStatus, point }): CameraStatus | null =>
    cameraStatus.length > 0 && point
      ? cameraStatus
          .map((camera) => ({
            camera: camera,
            distance: getHaversineDistance(camera.configuration.sensorPosition, point),
          }))
          .reduce((p, c) => (p.distance < c.distance ? p : c)).camera
      : null,
);

export const selectCameraStatusByUniqueCode = createSelector(
  [
    (state: RootState, replayMode: boolean, uniqueCode: string | null) => {
      return { camerasStatus: selectCameraStatuses(state, replayMode), uniqueCode: uniqueCode };
    },
  ],
  ({ camerasStatus, uniqueCode }): CameraStatus | null =>
    camerasStatus.find((cs) => getSensorUniqueCode(cs.configuration) === uniqueCode) ?? null,
);

export const selectTrackingStatusByUniqueCode = createSelector(
  selectCameraStatusByUniqueCode,
  (camerasStatus): CameraTrackingStatus | null => camerasStatus?.trackingStatus ?? null,
);

export const selectTargetSensorsByTargetId = createSelector(
  [selectTargetById, selectActiveMonitoringSensors, selectAllSensorConfigurations],
  (target, activeSensors, sensorConfigurations) => {
    const sensorsInfos: { name: string; code: string; iconKey: SensorIconKeys }[] = [];
    target?.trackIds.forEach((track) => {
      const code = track.sensorCode;
      const sensorStatus = activeSensors.find((sensor) => sensor.configuration.code === code);
      const sensorConfiguration =
        sensorStatus?.configuration ?? sensorConfigurations.find((sensor) => sensor.code === code);

      if (
        sensorConfiguration &&
        !sensorsInfos.find((sensor) => sensor.code === getSensorUniqueCode(sensorConfiguration))
      ) {
        sensorsInfos.push({
          name: sensorConfiguration.name,
          code: getSensorUniqueCode(sensorConfiguration),
          iconKey: getSensorIconKey(
            sensorConfiguration.type,
            sensorStatus?.configuration.active,
            sensorStatus?.configuration.maintenance,
            sensorStatus?.status,
          ),
        });
      }
    });
    return sensorsInfos;
  },
);

export const selectActiveAutomatons = createSelector(selectActiveMonitoringSensors, (activeSensors) =>
  activeSensors.filter(isActiveAutomaton),
);

export const selectInactiveAutomatons = createSelector(selectInactiveMonitoringSensors, (inactiveSensors) =>
  inactiveSensors.filter((sensor) => sensor.configuration.type === SensorTypeEnum.AUTOMATON),
);

export const selectAllAutomatonConfigurations = createSelector(
  [selectActiveAutomatons, selectInactiveAutomatons],
  (activeAutomatons, inactiveAutomatons) =>
    [...activeAutomatons, ...inactiveAutomatons].map(
      (automaton) => automaton.configuration,
    ) as AutomatonConfiguration[],
);

export const selectAutomatonCabinetSegments = createSelector(
  selectAllAutomatonConfigurations,
  (automatons: AutomatonConfiguration[]): AutomatonSegment[] =>
    automatons.flatMap((automaton: AutomatonConfiguration) =>
      automaton.cabinets.flatMap((cabinet: AutomatonCabinet) => cabinet.segments),
    ),
);

export const selectAutomatonSections = createSelector(
  selectAllAutomatonConfigurations,
  (automatons: AutomatonConfiguration[]): AutomatonSectionData[] =>
    automatons.flatMap((automaton) =>
      automaton.cabinets.flatMap((cabinet) =>
        chain(cabinet.segments)
          .filter(Boolean)
          .groupBy('nodeId')
          .mapValues((segments) => {
            const section = toAutomatonSection(segments);
            const portal = getAutomatonPortal(segments);

            return {
              automaton,
              cabinet,
              section,
              portal,
              perimeterCameraUniqueKeys: getSectionPerimeterCameraUniqueKeys(section),
            };
          })
          .values()
          .value(),
      ),
    ),
);

export const selectAutomatonSectionByName = createSelector(
  [selectAutomatonSections, selectParameterWithReplayMode<string>],
  (automatonSections, sectionName) =>
    automatonSections.find(({ section }) => getSectionName(section) === sectionName) ?? null,
);

export const selectSectionByPerimeterCameraKey = createSelector(
  [selectAutomatonSections, selectParameterWithReplayMode<string>],
  (sections, perimeterCameraKey) =>
    sections
      .filter((sectionData) =>
        sectionData.perimeterCameraUniqueKeys.some((uniqueKey) => uniqueKey === perimeterCameraKey),
      )
      .map((sectionData) => {
        return getSectionName(sectionData.section);
      }),
);

export const selectFilteredAutomatonSections = createSelector(
  [selectAllAutomatonConfigurations, selectAutomatonSections, selectMapFilters],
  (
    automatons: AutomatonConfiguration[],
    sections: AutomatonSectionData[],
    mapFilters: MapFilters,
  ): AutomatonSectionData[] =>
    mapFilters['displayPerimeterSensorSegment']
      ? sections.filter(
          (
            section, //tester d'enlever le filter et le some pour voir si je trouve une différence
          ) =>
            automatons.some((automaton) => getSensorUniqueCode(section.automaton) === getSensorUniqueCode(automaton)),
        )
      : [],
);

export const selectAutomatonCabinetSectionsFeatures = createSelector(
  [selectFilteredAutomatonSections, selectSectionsStatuses],
  (sections, statuses) => {
    // TODO : refacto pour utiliser un seul objet
    const automatonSectionLineFeatures = toAutomatonSectionLineFeatures(sections, statuses);
    const automatonSectionIconsFeatures = toAutomatonSectionIconFeatures(sections, statuses);

    return {
      type: 'FeatureCollection',
      features: [...automatonSectionLineFeatures, ...automatonSectionIconsFeatures],
    };
  },
);

export const selectPerimeterLinkFeatures = createSelector(
  [
    (state, mapId: MapIdEnum) => selectSplitPerimeterMonitoringSensors(state, mapId === MapIdEnum.REPLAY),
    selectDisplayedPerimeterLinksCodesByMapId,
    (state, mapId: MapIdEnum) => selectAutomatonSections(state, mapId === MapIdEnum.REPLAY),
    selectMapFilters,
  ],
  (sensors: SplitMonitoringSensors, displayedPerimeterLinksCodes, automatonSections, mapFilters): Feature[] => {
    const automatonConfigurations = sensors.withSubSensors
      .map((monitoringSensor) => monitoringSensor.configuration)
      .filter((config): config is AutomatonConfiguration => config.type === SensorTypeEnum.AUTOMATON);
    if (displayedPerimeterLinksCodes) {
      const automaton = automatonConfigurations.find(
        (automaton) => getSensorUniqueCode(automaton) === displayedPerimeterLinksCodes.automatonUniqueCode,
      );

      if (automaton) {
        if (displayedPerimeterLinksCodes.cabinetUniqueCode) {
          const cabinet = automaton.cabinets.find(
            (cabinet) => getSensorUniqueCode(cabinet) === displayedPerimeterLinksCodes.cabinetUniqueCode,
          );

          const sections = automatonSections
            .filter((sectionData) => {
              if (displayedPerimeterLinksCodes.sectionName) {
                return getSectionName(sectionData.section) === displayedPerimeterLinksCodes.sectionName;
              }
              return getSensorUniqueCode(sectionData.cabinet) === displayedPerimeterLinksCodes.cabinetUniqueCode;
            })
            .map((sectionData) => sectionData.section);

          if (cabinet && sections.length > 0) {
            return toPerimeterLinkFeatures(automaton, cabinet, sections, mapFilters);
          }
        } else {
          return automaton.cabinets.flatMap((cabinet) => {
            const cabinetUniqueCode = getSensorUniqueCode(cabinet);
            const sections = automatonSections
              .filter((sectionData) => getSensorUniqueCode(sectionData.cabinet) === cabinetUniqueCode)
              .map((sectionData) => sectionData.section);
            if (sections.length > 0) {
              return toPerimeterLinkFeatures(automaton, cabinet, sections, mapFilters);
            }
            return [];
          });
        }
      }
    }
    return [];
  },
);
export const selectAllPerimeterCameras = createSelector(
  selectMonitoringSensors,
  (sensorsSpecificStatus): PerimeterCamera[] => sensorsSpecificStatus?.perimeterCameras ?? [],
);

export const selectAllValidPerimeterCameras = createSelector(
  selectAllPerimeterCameras,
  (perimeterCameras: PerimeterCamera[]): PerimeterCamera[] =>
    perimeterCameras.filter(
      (perimeterCamera) =>
        perimeterCamera.fov !== null &&
        perimeterCamera.range !== null &&
        perimeterCamera.azimuth !== null &&
        isValidCoordinates(perimeterCamera.coordinates),
    ),
);

export const selectAllValidFilteredPerimeterCameras = createSelector(
  [selectAllValidPerimeterCameras, selectMapFilters],
  (perimeterCameras: PerimeterCamera[], mapFilters: MapFilters): PerimeterCamera[] =>
    getFilteredList(
      perimeterCameras,
      mapFilters,
      (_, key: keyof MapFilters): boolean =>
        filterKeyToFeatureEnum(key, FilterTypeEnum.PERIMETER_SENSOR) !== SensorLikeEnum.PERIMETER_CAMERA,
    ),
);

export const selectPerimeterCameraUniqueKeysBySelectedDoubtCheckSegmentName = createSelector(
  [selectAutomatonCabinetSegments, selectSelectedDoubtCheckSegmentName],
  (segments, selectedSegmentName): string[] => {
    const selectedSegments = segments.filter((segment) => segment.name === selectedSegmentName);
    return uniq(selectedSegments.flatMap((s) => s.perimeterCameraUniqueKeys));
  },
);

export const selectSelectedPerimeterCameraUniqueKeys = createSelector(
  [selectSelectedDoubtCheckPerimeterCameraUniqueKey, selectPerimeterCameraUniqueKeysBySelectedDoubtCheckSegmentName],
  (selectedDoubtCheckPerimeterCameraUniqueKey, selectedSegmentCameraUniqueKeys): string[] => {
    if (selectedDoubtCheckPerimeterCameraUniqueKey) {
      return [selectedDoubtCheckPerimeterCameraUniqueKey];
    }

    return selectedSegmentCameraUniqueKeys;
  },
);

export const selectAllReplayActivePerimCameras = createSelector(
  selectAllValidPerimeterCameras,
  (perimeterCameras: PerimeterCamera[]): PerimeterCamera[] =>
    perimeterCameras.filter((pericam) => pericam.sourceReplayUrl !== null),
);

export const selectPerimeterCameraSectorFeatures = createSelector(
  [selectAllValidFilteredPerimeterCameras, selectSelectedPerimeterCameraUniqueKeys],
  (perimeterCameras: PerimeterCamera[], selectedPerimeterCameraUniqueKeys): Feature[] =>
    toPerimeterCameraSectorFeatures(perimeterCameras, selectedPerimeterCameraUniqueKeys),
);

export const selectPerimeterCameraAzimuthLineFeatures = createSelector(
  selectAllValidFilteredPerimeterCameras,
  (perimeterCameras: PerimeterCamera[]): Feature[] => toPerimeterCameraAzimuthLineFeatures(perimeterCameras),
);

export const selectPerimeterCameraLabelFeatures = createSelector(
  selectAllValidFilteredPerimeterCameras,
  (perimeterCameras: PerimeterCamera[]): Feature[] => toPerimeterCameraLabelFeatures(perimeterCameras),
);

export const selectPerimeterCameraFeatures = createSelector(
  [selectPerimeterCameraSectorFeatures, selectPerimeterCameraAzimuthLineFeatures, selectPerimeterCameraLabelFeatures],
  (sectors: Feature[], azimuthLines: Feature[], labels: Feature[]): FeatureCollection => ({
    type: 'FeatureCollection',
    features: [...sectors, ...azimuthLines, ...labels],
  }),
);

export const selectActiveMonitoringEquipments = createSelector(
  selectMonitoringSensors,
  (state: SensorsSpecificStatus | null): EquipmentStatus[] => state?.activeEquipments ?? [],
);

export const selectInactiveMonitoringEquipments = createSelector(
  selectMonitoringSensors,
  (state: SensorsSpecificStatus | null): InactiveMonitoringEquipment[] => state?.inactiveEquipments ?? [],
);

export const selectAllMonitoringEquipments = createSelector(
  [selectActiveMonitoringEquipments, selectInactiveMonitoringEquipments],
  (
    activeMonitoringEquipments: EquipmentStatus[],
    inactiveMonitoringEquipments: InactiveMonitoringEquipment[],
  ): MonitoringEquipment[] => {
    return [...activeMonitoringEquipments, ...inactiveMonitoringEquipments];
  },
);

const selectMonitoringEquipmentBySensorReferenceAndType = (
  _: RootState,
  replay: boolean,
  param: {
    sensorAppCode: string;
    sensorCode: string;
    equipmentType?: EquipmentTypeEnum;
  },
) => param;

export const selectAllMonitoringEquipmentsBySensorReference = createSelector(
  [selectAllMonitoringEquipments, selectMonitoringEquipmentBySensorReferenceAndType],
  (monitoringEquipments: MonitoringEquipment[], { sensorAppCode, sensorCode, equipmentType }): MonitoringEquipment[] =>
    monitoringEquipments.filter(
      (monitoringEquipment) =>
        // Since we only have WEB_RELAY equipment this condition is not useful but it'll be later
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (!equipmentType || monitoringEquipment.configuration.type === equipmentType) &&
        monitoringEquipment.configuration.sensorReferences.some(
          (sensorReference) =>
            sensorReference.sensorAppCode === sensorAppCode && sensorReference.sensorCode === sensorCode,
        ),
    ),
);
