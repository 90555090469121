import PanelHeader from '@components/common/layout/PanelHeader';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveGroupsPlatforms } from '@redux/config/config.selectors';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Platform } from '@/types/config/config.types';
import { SensorUniqueCodes } from '@/types/map.types';

import Panel from '../Panel';
import SensorDetail from './detail/SensorDetail';
import Header from './Header';
import MonitoringList from './list/MonitoringList';

export default function MonitoringPanel() {
  const { formatMessage } = useIntl();
  const platforms = useAppSelector(selectActiveGroupsPlatforms);
  const [selectedSensorCode, setSelectedSensorCode] = useState<SensorUniqueCodes | null>(null);

  const [platformFilters, setPlatformFilters] = useState<{ platform: Platform; active: boolean }[]>(
    platforms
      .map((platform) => ({ platform, active: true }))
      .concat({
        platform: {
          id: -1,
          code: '',
          name: formatMessage({ id: 'components.monitoring.header.withoutPlatform' }),
          position: { latitude: 0, longitude: 0, type: '2D' },
          altitude: 0,
          mapZoom: 1,
        },
        active: true,
      }),
  );

  return (
    <Panel
      width={1492}
      header={
        <PanelHeader
          label={formatMessage({ id: 'components.sidebar.MONITORING' })}
          component={
            selectedSensorCode === null ? (
              <Header platformFilters={platformFilters} setState={setPlatformFilters} />
            ) : undefined
          }
        />
      }
    >
      {selectedSensorCode === null ? (
        <MonitoringList platformFilters={platformFilters} setSelectedSensorCode={setSelectedSensorCode} />
      ) : (
        <SensorDetail sensorUniqueCodes={selectedSensorCode} setSelectedSensorCode={setSelectedSensorCode} />
      )}
    </Panel>
  );
}
