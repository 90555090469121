import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectZoomByMapId } from '@redux/maps/maps.selectors';
import { DEFAULT_BOUNDS } from '@utils/map/map.constants';
import { BBox, GeoJsonProperties } from 'geojson';
import { useMemo, useRef, useState } from 'react';
import { useMap } from 'react-map-gl';
import { deepEqual } from 'react-map-gl/dist/es5/utils/deep-equal';
import Supercluster, { PointFeature } from 'supercluster';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface UseSuperclusterArgs<P, C> {
  points: Supercluster.PointFeature<P>[];
  options?: Supercluster.Options<P, C>;
}

interface UseSuperclusterReturn {
  clusters: PointFeature<GeoJsonProperties>[];
  leaveIds: string[];
  supercluster: Supercluster<GeoJsonProperties, GeoJsonProperties> | null;
}

const useSupercluster = <
  P extends GeoJsonProperties = Supercluster.AnyProps,
  C extends GeoJsonProperties = Supercluster.AnyProps,
>({
  points,
  options,
}: UseSuperclusterArgs<P, C>): UseSuperclusterReturn => {
  const { current: currentMap } = useMap();
  const { mapId } = useMapContext();
  const memoizedOptions = useMemo(() => options, [options]);
  const superclusterRef = useRef<Supercluster<P, C> | null>(new Supercluster(memoizedOptions));
  const pointsRef = useRef<Supercluster.PointFeature<P>[]>(null);
  const [clusters, setClusters] = useState<PointFeature<GeoJsonProperties>[]>([]);

  const zoom = useAppSelector((state) => selectZoomByMapId(state, mapId)) ?? 22;
  const bounds = currentMap ? (currentMap.getBounds()?.toArray().flat() as BBox) : DEFAULT_BOUNDS;

  useDeepCompareEffect(() => {
    if (superclusterRef.current && !deepEqual(pointsRef.current, points)) {
      superclusterRef.current.load(points);
      pointsRef.current = points;
    }

    if (superclusterRef.current) {
      const clusters = superclusterRef.current.getClusters(bounds, zoom);
      setClusters(clusters);
    }
  }, [points, bounds, zoom]);

  const leaveIds = clusters.flatMap((cluster) => {
    if (cluster.properties?.cluster && superclusterRef.current) {
      return superclusterRef.current
        .getLeaves(cluster.properties.cluster_id)
        .map((leaf) => leaf.properties?.id)
        .filter(Boolean);
    }
    return [];
  });

  return { clusters, leaveIds: leaveIds, supercluster: superclusterRef.current };
};

export default useSupercluster;
