import { Center } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

type Props = {
  count?: number;
};

export default function Badge({ count }: Readonly<Props>) {
  if (count === undefined || count === 0) {
    return null;
  }
  return (
    <Center backgroundColor="alertHi.500" left="36px" height="20px" width="19px" position="absolute">
      <Text paddingX={0.5} fontSize="12px" fontWeight="medium">
        {count}
      </Text>
    </Center>
  );
}
