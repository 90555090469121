import { useMapMeasureState } from '@hooks/useMapMeasureState';
import { useMapSelectedPositionState } from '@hooks/useMapSelectedPositionState';
import { Position } from '@turf/helpers';
import { LngLat } from 'mapbox-gl';
import { createContext, PropsWithChildren, ReactElement, useContext, useMemo } from 'react';

import { Coordinates } from '@/types/commons/commons.types';
import { LayerNameEnum, MapIdEnum } from '@/types/map.types';

type MapContextProps = {
  mapId: MapIdEnum;
  contextMenuPosition?: LngLat | null;
  setContextMenuPosition?: (position: LngLat | null) => void;
  menuToDisplay?: ReactElement | null;
  setMenuToDisplay?: (menu: ReactElement | null) => void;
  selectableLayers: LayerNameEnum[];
};

type MapContextType = MapContextProps & {
  mousePosition: Coordinates | null;
  setMousePosition: (coordinates: Coordinates | null) => void;
  isClickEnabled: boolean;
  setIsClickEnabled: (isClickEnabled: boolean) => void;
  measurePoints: Position[];
  setMeasurePoints: (points: Position[]) => void;
  mouseSelectedPosition: Coordinates;
  isSelectionEnabled: boolean;
  setSelectionOn: (active: boolean) => void;
  setSelectedPosition: (position: Coordinates) => void;
};

const MapContext = createContext<MapContextType | null>(null);

export const MapContextProvider = ({
  mapId,
  contextMenuPosition,
  setContextMenuPosition,
  menuToDisplay,
  setMenuToDisplay,
  selectableLayers,
  children,
}: PropsWithChildren<MapContextProps>) => {
  const { state, setMousePosition, setIsClickEnabled, setMeasurePoints } = useMapMeasureState();
  const { positionState, setSelectionOn, setSelectedPosition } = useMapSelectedPositionState();

  const values = useMemo(
    () => ({
      mapId,
      contextMenuPosition,
      setContextMenuPosition,
      menuToDisplay,
      setMenuToDisplay,
      selectableLayers,
      ...positionState,
      setSelectionOn,
      setSelectedPosition,
      ...state,
      setMeasurePoints,
      setMousePosition,
      setIsClickEnabled,
    }),
    [
      mapId,
      contextMenuPosition,
      setContextMenuPosition,
      menuToDisplay,
      setMenuToDisplay,
      selectableLayers,
      positionState,
      setSelectionOn,
      setSelectedPosition,
      state,
      setMeasurePoints,
      setMousePosition,
      setIsClickEnabled,
    ],
  );

  return <MapContext value={values}>{children}</MapContext>;
};

export const useMapContext = (): MapContextType => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMapContext must be used within a MapContextProvider');
  }
  return context;
};
