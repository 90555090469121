import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';
import { appIntl } from '@components/locale/IntlGlobalProvider';

export enum ToastStatusEnum {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  NOTIFICATION = 'NOTIFICATION',
}

export const defaultToastConfig: UseToastOptions = {
  position: 'top-right',
  isClosable: true,
  duration: 4000,
  containerStyle: {
    margin: 0,
    marginTop: '10px',
    maxWidth: '360px',
  },
};

export const successToastConfig: UseToastOptions = {
  ...defaultToastConfig,
  status: 'success',
  duration: 4000,
};

export const infoToastConfig: UseToastOptions = {
  ...defaultToastConfig,
  status: 'info',
  duration: 4000,
};

export const warningToastConfig: UseToastOptions = {
  ...defaultToastConfig,
  status: 'warning',
  duration: 5000,
};

export const errorToastConfig: UseToastOptions = {
  ...defaultToastConfig,
  status: 'error',
  duration: 9000,
};

export const notificationToastConfig: UseToastOptions = {
  ...defaultToastConfig,
  duration: null,
};

export function createToast(message: string, type: ToastStatusEnum = ToastStatusEnum.INFO, id?: string | number): void {
  const { toast } = createStandaloneToast();
  switch (type) {
    case ToastStatusEnum.SUCCESS:
      toast({ ...successToastConfig, title: message, id });
      break;
    case ToastStatusEnum.ERROR:
      toast({ ...errorToastConfig, title: message, id });
      break;
    case ToastStatusEnum.WARNING:
      toast({ ...warningToastConfig, title: message, id });
      break;
    case ToastStatusEnum.INFO:
    default:
      toast({ ...infoToastConfig, title: message, id });
  }
}

export function createErrorToastFromTradId(tradId: string): void {
  createToast(appIntl().formatMessage({ id: tradId }), ToastStatusEnum.ERROR);
}
