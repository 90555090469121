import './players.scss';

import { Box } from '@chakra-ui/react';
import OvenPlayer from 'ovenplayer';
import { memo, useEffect, useRef } from 'react';

function initPlayer(id: string, src: string) {
  return OvenPlayer.create(id, {
    sources: [
      {
        label: id,
        type: 'webrtc',
        file: src,
      },
    ],
    autoStart: true,
    controls: false,
    showBigPlayButton: false,
    webrtcConfig: {
      timeoutMaxRetry: 5,
      connectionTimeout: 5000,
    },
  });
}

type Props = {
  id: string;
  src: string;
  isPaused?: boolean;
  className?: string;
};

function OvenMediaPlayer({ src, id, className }: Readonly<Props>) {
  const player = useRef(OvenPlayer);

  useEffect(() => {
    try {
      player.current = initPlayer(id, src);
    } catch (e) {
      console.error('Cannot init Player', e);
    }
    return () => {
      if (player.current) {
        try {
          OvenPlayer.removePlayer(player.current);
          console.log('Player Removed');
        } catch (e) {
          console.log('Player Error', e);
        }
      }
    };
  }, [src, id]);

  return (
    <Box width="100%" height="100%" className={`ovenplayer-${className}`}>
      <Box id={id} className="ovenplayer" minHeight="inherit" minWidth="inherit" />
    </Box>
  );
}

export default memo(OvenMediaPlayer);
