import { HStack, Text } from '@chakra-ui/react';
import CustomChipList from '@components/common/inputs/CustomChipList';
import { stringToOption } from '@utils/components/customChipList.utils';
import { Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';

import { NotificationFilterEnum } from '@/types/data/data.types';

import { NotificationSortType } from './NotificationsPanel';

type Props = {
  canSort: boolean;
  selectedFilters: NotificationFilterEnum[];
  selectedSort: NotificationSortType;
  setSelectedSort: Dispatch<NotificationSortType>;
  setSelectedFilters: Dispatch<NotificationFilterEnum[]>;
  getCount: (value: string) => number | undefined;
};

export default function NotificationHeader({
  canSort,
  selectedFilters,
  selectedSort,
  getCount,
  setSelectedFilters,
  setSelectedSort,
}: Readonly<Props>) {
  return (
    <HStack
      width="100%"
      height="56px"
      paddingY={1.5}
      paddingX={2}
      backgroundColor="neutral.800"
      flexShrink={0}
      justifyContent="space-between"
    >
      <HStack gap={1}>
        <Text color={canSort ? 'neutral.300' : 'neutral.white'}>
          <FormattedMessage id="components.notification.show" />
        </Text>
        <CustomChipList
          availableOptions={Object.values(NotificationFilterEnum).map((value) => ({
            ...stringToOption(value),
            count: getCount(value),
          }))}
          selectedOptions={Array.from(selectedFilters).map(stringToOption)}
          path="components.notification.types"
          onChange={(value: string[]) => setSelectedFilters(value as NotificationFilterEnum[])}
        />
      </HStack>
      <HStack gap={1}>
        <Text color={canSort ? 'neutral.300' : 'neutral.white'}>
          <FormattedMessage id="components.panel.sort" />
        </Text>
        <CustomChipList
          availableOptions={Object.values(NotificationSortType).map(stringToOption)}
          selectedOptions={[stringToOption(selectedSort)]}
          path="components.notification.sort"
          distinctValues={Object.values(NotificationSortType)}
          canUnselectDistinct
          onChange={(value: string[]) => setSelectedSort(value[0] as NotificationSortType)}
        />
      </HStack>
    </HStack>
  );
}
