import '@components/common/layout/scrollbar.scss';

import { ReactComponent as StatusEllipseIcon } from '@assets/icons/24x24/ic-status-ellipse-24.svg';
import { ReactComponent as StatusLosangeIcon } from '@assets/icons/24x24/ic-status-losange-24.svg';
import { Box, HStack, Icon, Spinner, Text, Tooltip, VStack } from '@chakra-ui/react';
import { getAlertLevelColor, isSpaceViolationAlert } from '@utils/data/notification.utils';
import { formatDate } from '@utils/date.utils';
import { FormattedMessage } from 'react-intl';

import { AlertType, NotificationTypeEnum } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';

type Props = {
  alerts: AlertType[];
  notificationType: NotificationTypeEnum;
  isLoading?: boolean;
  isArchived?: boolean;
};

export default function AlertList({
  alerts,
  notificationType,
  isLoading = false,
  isArchived = false,
}: Readonly<Props>) {
  if (isLoading) {
    return (
      <Box backgroundColor="neutral.900" paddingX={11} paddingY={3} width="100%" zIndex={3}>
        <Spinner size="sm" color="neutral.white" />
      </Box>
    );
  }

  return (
    <VStack
      backgroundColor="neutral.900"
      paddingX={isArchived ? 11 : 4}
      paddingY={3}
      width="100%"
      height="100%"
      minHeight={isArchived ? 'max-content' : undefined}
      alignItems="start"
      zIndex={3}
      overflowY="auto"
      userSelect="none"
      className="cockpit-notification-history-scrollbar"
      marginTop="-2px"
      divider={<Box height="2px" width="100%" borderColor="neutral.700" style={{ margin: '12px 0' }} />}
    >
      {alerts.length === 0 ? (
        <Text fontSize="16px">
          <FormattedMessage id="components.notification.noAlert" />
        </Text>
      ) : (
        alerts.map((alert) => {
          const color = getAlertLevelColor(alert.level);
          return (
            <HStack gap={0} key={alert.id} width="100%" flexWrap="nowrap" alignItems="start">
              <Icon
                as={notificationType === NotificationTypeEnum.DRONE ? StatusLosangeIcon : StatusEllipseIcon}
                width="24px"
                height="24px"
                color={color}
              />
              <VStack gap={0.5} marginLeft={1.5} alignItems="start">
                <Text size="md" width="236px" color={color}>
                  <FormattedMessage id={`alert.level.${alert.level}`} />
                </Text>
                {isSpaceViolationAlert(alert) && (
                  <Tooltip label={alert.zoneName} openDelay={300}>
                    <Text
                      fontSize="16px"
                      flexBasis="100%"
                      noOfLines={1}
                      overflow="hidden"
                      userSelect="text"
                      cursor="default"
                      textOverflow="ellipsis"
                      wordBreak="break-all"
                    >
                      {alert.zoneName}
                    </Text>
                  </Tooltip>
                )}
              </VStack>
              <Text marginLeft="auto" flexShrink={0} color="neutral.300">
                {formatDate(alert.startTime, DateTimeEnum.DATE)}
              </Text>
            </HStack>
          );
        })
      )}
    </VStack>
  );
}
