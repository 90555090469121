import { userLogout } from '@redux/authent/authent.reducer';
import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { HoloLog } from '@/types/c2/logs.type';
import { NotificationType } from '@/types/data/data.types';
import { SubCameraConfiguration } from '@/types/sensor/configuration.types';

import { logsAdapter, notificationAdapter } from './global.adapters';

export type ControlledCamera = { cameraUniqueCode: string | null; subCameraId: number | undefined };

export enum AVAILABLE_LANGUAGES {
  en = 'en',
  fr = 'fr',
}

export const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES.fr;

export type GlobalState = {
  selectedCameraCode: string | null;
  joystickControlId: string | null;
  joystickControlledCam: ControlledCamera;
  selectedDoubtCheckSegmentName: string | null;
  selectedDoubtCheckPerimeterCameraUniqueKey: string | null;
  notificationQueue: EntityState<NotificationType, number>;
  selectedCameraStreams: SubCameraConfiguration[];
  newLogBookEntry: boolean;
  logs: EntityState<HoloLog, string>;
  locale: AVAILABLE_LANGUAGES;
};

export const GLOBAL_INITIAL_STATE: GlobalState = {
  locale: DEFAULT_LANGUAGE,
  selectedCameraCode: null,
  joystickControlId: null,
  joystickControlledCam: { cameraUniqueCode: null, subCameraId: undefined },
  selectedDoubtCheckSegmentName: null,
  selectedDoubtCheckPerimeterCameraUniqueKey: null,
  notificationQueue: notificationAdapter.getInitialState(),
  selectedCameraStreams: [],
  newLogBookEntry: false,
  logs: logsAdapter.getInitialState(),
};

const global = createSlice({
  name: 'global',
  initialState: GLOBAL_INITIAL_STATE,
  reducers: {
    updateLocale(state: GlobalState, action: PayloadAction<AVAILABLE_LANGUAGES>) {
      if (Object.keys(AVAILABLE_LANGUAGES).includes(action.payload)) {
        state.locale = action.payload;
      }
    },
    updateSelectedCameraCode: (state, action: PayloadAction<string | null>) => {
      state.selectedCameraCode = action.payload;
    },
    updateJoystickControlId: (state, action: PayloadAction<string | null>) => {
      state.joystickControlId = action.payload;
      if (action.payload === null) {
        state.joystickControlledCam = { cameraUniqueCode: null, subCameraId: undefined };
      }
    },
    updateJoystickControlledCam: (state, action: PayloadAction<ControlledCamera>) => {
      state.joystickControlledCam = action.payload;
    },
    updateSelectedDoubtCheckSegmentName: (state, action: PayloadAction<string | null>) => {
      state.selectedDoubtCheckSegmentName = action.payload;
      state.selectedDoubtCheckPerimeterCameraUniqueKey = null;
    },
    updateSelectedDoubtCheckPerimeterCameraUniqueKey: (state, action: PayloadAction<string | null>) => {
      state.selectedDoubtCheckPerimeterCameraUniqueKey = action.payload;
      state.selectedDoubtCheckSegmentName = null;
    },
    addNotificationToQueue: ({ notificationQueue }, action: PayloadAction<NotificationType>) => {
      notificationAdapter.addOne(notificationQueue, action.payload);
    },
    deleteNotificationFromQueue: ({ notificationQueue }, action: PayloadAction<NotificationType>) => {
      notificationAdapter.removeOne(notificationQueue, action.payload.id);
    },
    updateNewLogBookEntry(state, action: PayloadAction<boolean>) {
      state.newLogBookEntry = action.payload;
    },
    addSelectedCameraStreams(state, action: PayloadAction<SubCameraConfiguration>) {
      state.selectedCameraStreams = [...state.selectedCameraStreams, action.payload];
    },
    removeSelectedCameraStreams(state, action: PayloadAction<SubCameraConfiguration>) {
      const index = state.selectedCameraStreams.findIndex((camera) => camera.id === action.payload.id);
      if (index > -1) {
        state.selectedCameraStreams = state.selectedCameraStreams.toSpliced(index, 1);
      }
    },
    updateLogs(state, action: PayloadAction<HoloLog[]>) {
      state.logs = logsAdapter.upsertMany(state.logs, action.payload);
    },
    addLog: ({ logs }, action: PayloadAction<HoloLog>) => {
      logsAdapter.addOne(logs, action.payload);
    },
    updateLog: ({ logs }, action: PayloadAction<HoloLog>) => {
      logsAdapter.upsertOne(logs, action.payload);
    },
    deleteLog: ({ logs }, action: PayloadAction<HoloLog>) => {
      logsAdapter.removeOne(logs, action.payload.code);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => GLOBAL_INITIAL_STATE);
  },
});

export const {
  updateSelectedCameraCode,
  updateJoystickControlId,
  updateSelectedDoubtCheckSegmentName,
  updateSelectedDoubtCheckPerimeterCameraUniqueKey,
  updateJoystickControlledCam,
  addNotificationToQueue,
  deleteNotificationFromQueue,
  updateNewLogBookEntry,
  addSelectedCameraStreams,
  removeSelectedCameraStreams,
  updateLogs,
  addLog,
  deleteLog,
  updateLog,
  updateLocale,
} = global.actions;
export default global.reducer;
