import { Box, Center, Spinner, TabPanel } from '@chakra-ui/react';
import { useGetDetailQuery } from '@services/data/notification.api';
import { memo, useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { NotificationTypeEnum } from '@/types/data/data.types';

import AlertList from '../card/AlertList';

type Props = {
  notificationId: number;
  notificationType: NotificationTypeEnum;
  currentAlertCound: number;
};
function NotificationHistory({ notificationId, notificationType, currentAlertCound }: Readonly<Props>) {
  const {
    data: notificationDetail,
    isFetching,
    refetch,
  } = useGetDetailQuery({ id: notificationId, withArchivedAlerts: false });

  useEffect(() => {
    refetch();
  }, [currentAlertCound, refetch]);

  return (
    <TabPanel height="100%">
      <Box width="100%" height="100%" overflow="hidden" display="table">
        {isFetching ? (
          <Center paddingY={2}>
            <Spinner size="md" color="neutral.white" />
          </Center>
        ) : (
          <AlertList alerts={notificationDetail?.alerts ?? []} notificationType={notificationType} />
        )}
      </Box>
    </TabPanel>
  );
}

export default memo(NotificationHistory, shallowEqual);
