import { Flex } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import NotificationSound from '@components/cockpit/panels/notification/NotificationSounds';
import MouseFocus from '@components/common/MouseFocus';
import ChangeShift from '@components/login/ChangeShift';
import JoystickControl from '@components/video/cameraControl/JoystickControl';
import { useAppSelector } from '@hooks/redux.hooks';
import { useAuth } from '@hooks/useAuth';
import { hasRole, hasRoles, selectActiveUser, selectIsChangingShift } from '@redux/authent/authent.selectors';
import { selectJoystickControlId } from '@redux/global/global.selector';
import { RootState } from '@redux/store';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetSituationDiffQuery } from '@services/c2/sse.api';
import { useState } from 'react';

import { RoleEnum } from '@/types/authent/roles.types';

import CockpitBackground from './CockpitBackground';
import AdminPanel from './panels/admin/AdminPanel';
import Chat from './panels/chat/Chat';
import LogBook from './panels/logbook/LogBook';
import MonitoringPanel from './panels/monitoring/MonitoringPanel';
import NotificationsPanel from './panels/notification/NotificationsPanel';
import Platforms from './panels/platform/Platforms';
import PostConfiguration from './panels/postConfiguration/PostConfiguration';
import ProfileUserParams from './panels/settings/ProfileUserParams';
import SystemConfiguration from './panels/sytemConfig/SystemConfiguration';
import CockpitSidebar from './sidebar/CockpitSidebar';

export enum CockpitPanelIntlKeysEnum {
  NOTIFICATION = 'NOTIFICATION',
  LOG_BOOK_AND_CHAT = 'LOG_BOOK_AND_CHAT',
  POST_CONFIGURATION = 'POST_CONFIGURATION',
  GRID_LAYOUT_CONFIGURATION = 'GRID_LAYOUT_CONFIGURATION',
  SYSTEM_CONFIGURATION = 'SYSTEM_CONFIGURATION',
  SETTINGS = 'SETTINGS',
  ADMIN = 'ADMIN',
  MONITORING = 'MONITORING',
}

const ROLES = [RoleEnum.ACCESS_MSG, RoleEnum.ACCESS_REPLAY];

export default function Cockpit() {
  const { token } = useAuth();
  const joystickControlId = useAppSelector(selectJoystickControlId);
  const canSeeNotifications = useAppSelector((state: RootState) => hasRole(state, RoleEnum.ACCESS_NOTIFICATION));
  const canAccessPlatform = useAppSelector((state: RootState) => hasRole(state, RoleEnum.ACCESS_PLATFORM));
  const isChangingShift = useAppSelector(selectIsChangingShift);
  const roleSet = useAppSelector((state: RootState) => hasRoles(state, ROLES));

  const { activeSite, activeGroup } = useAppSelector(selectActiveUser);

  const [openedMainPanel, setOpenedMainPanel] = useState<CockpitPanelIntlKeysEnum>(
    CockpitPanelIntlKeysEnum.NOTIFICATION,
  );

  useGetSituationDiffQuery(
    activeSite && token
      ? {
          site: activeSite.code,
          platforms: activeGroup ? activeGroup.platforms : [],
          token,
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <Flex height="100%" width="100%" gap={0} bgGradient="linear-gradient(180deg, #000000 0%, #041b2f 100%)">
      <CockpitBackground />
      {joystickControlId !== null && <JoystickControl />}
      <NotificationSound />
      <MouseFocus />
      {isChangingShift && <ChangeShift />}
      <CockpitSidebar state={openedMainPanel} setState={setOpenedMainPanel} />
      <Flex
        position="relative"
        width="calc(100% - 96px)"
        paddingTop={4}
        paddingRight={3}
        paddingBottom={3}
        gap={2}
        wrap="nowrap"
      >
        {openedMainPanel === CockpitPanelIntlKeysEnum.LOG_BOOK_AND_CHAT && (
          <>
            {roleSet?.has(RoleEnum.ACCESS_REPLAY) && <LogBook />}
            {roleSet?.has(RoleEnum.ACCESS_MSG) && <Chat />}
          </>
        )}
        {openedMainPanel === CockpitPanelIntlKeysEnum.NOTIFICATION && canSeeNotifications && <NotificationsPanel />}
        {openedMainPanel === CockpitPanelIntlKeysEnum.POST_CONFIGURATION && <PostConfiguration />}
        {openedMainPanel === CockpitPanelIntlKeysEnum.SYSTEM_CONFIGURATION && <SystemConfiguration />}
        {openedMainPanel === CockpitPanelIntlKeysEnum.SETTINGS && <ProfileUserParams />}
        {openedMainPanel === CockpitPanelIntlKeysEnum.MONITORING && <MonitoringPanel />}
        {openedMainPanel === CockpitPanelIntlKeysEnum.ADMIN && <AdminPanel />}
        <Box flexShrink={0} marginLeft="auto">
          {canAccessPlatform && <Platforms />}
        </Box>
      </Flex>
    </Flex>
  );
}
