import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import useSupercluster from '@hooks/useSuperCluster';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { closePopupByMapId, updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId, selectZoomByMapId } from '@redux/maps/maps.selectors';
import destination from '@turf/destination';
import { point } from '@turf/helpers';
import { Feature, GeoJsonProperties } from 'geojson';
import { isEqual } from 'lodash';
import { LngLat } from 'mapbox-gl';
import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import Supercluster, { PointFeature } from 'supercluster';

import { LayerNameEnum, PopupDataTypeEnum } from '@/types/map.types';

const CLUSTER_RADIUS_SCALE = 68;
const METERS_PER_PIXEL_AT_EQUATOR = 156543.03392;

export const calculateClusterRadius = (numLeaves: number): number => {
  const baseRadius = CLUSTER_RADIUS_SCALE * (1 + 1 / Math.sin(Math.PI / numLeaves));
  return Math.max(baseRadius, 5);
};

export type ClusterProps = {
  PURPLE: number;
  ORANGE: number;
  GREEN: number;
  ORANGE_MAINTENANCE: number;
  GRAY: number;
  WHITE: number;
};

export const calculateMetersPerPixel = (zoom: number, latitude: number) => {
  return (METERS_PER_PIXEL_AT_EQUATOR / Math.pow(2, zoom)) * Math.cos((latitude * Math.PI) / 180);
};

export const useClusterSelect = (
  points: PointFeature<GeoJsonProperties>[],
  superclusterOptions: Supercluster.Options<GeoJsonProperties, ClusterProps>,
): [
  {
    selectedCluster: GeoJsonProperties | null;
    updatedClusters: PointFeature<GeoJsonProperties>[];
  },
  (string | number | undefined)[],
  Feature[],
] => {
  const { current: map } = useMap();
  const { mapId } = useMapContext();
  const zoom = useAppSelector((state) => selectZoomByMapId(state, mapId), isEqual) ?? 22;
  const currentPopupControl = useAppSelector((state) => selectPopupControlByMapId(state, mapId));
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const closePopupControl = useWithDispatch(closePopupByMapId);

  const { clusters, supercluster } = useSupercluster({
    points: points,
    options: superclusterOptions,
  });
  const [clusterState, setClusterState] = useState({
    selectedCluster: null as GeoJsonProperties | null,
    updatedClusters: [] as PointFeature<GeoJsonProperties>[],
    currentZoom: zoom,
  });
  const leaveIds = clusters
    .flatMap((cluster) => {
      if (supercluster && cluster.properties?.cluster_id) {
        return supercluster.getLeaves(cluster.properties.cluster_id);
      }
      return [];
    })
    .map((feature) => feature.id);
  const areLeavesDisplayed = leaveIds.some((leaveId) =>
    clusterState.updatedClusters.map((feature) => feature.id).includes(leaveId),
  );

  const updateClusterState = useCallback((newState: Partial<typeof clusterState>) => {
    setClusterState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const calculateLeafPositions = (
    leaves: Supercluster.PointFeature<GeoJsonProperties>[],
    clusterPosition: mapboxgl.LngLat,
    clusterRadius: number,
    zoom: number,
  ): Supercluster.PointFeature<Supercluster.AnyProps>[] => {
    if (map) {
      const metersPerPixel = calculateMetersPerPixel(zoom, clusterPosition.lat);
      const angleStep = 360 / Math.max(leaves.length, 1);
      const radiusAdjustment = 4.0;
      const leafRadius = Math.max((clusterRadius * metersPerPixel) / radiusAdjustment, 10);
      const clusterCenter = point([clusterPosition.lng, clusterPosition.lat]);

      return leaves.map((leaf, index) => {
        const angle = angleStep * index;
        const leafPoint = destination(clusterCenter, leafRadius, angle, { units: 'meters' });
        const [lon, lat] = leafPoint.geometry.coordinates;

        return {
          ...leaf,
          geometry: {
            ...leaf.geometry,
            coordinates: [parseFloat(lon.toFixed(6)), parseFloat(lat.toFixed(6))],
          },
          properties: {
            ...leaf.properties,
            clusterFeature: true,
            value: {
              ...leaf.properties?.value,
              tempCoordinates: [parseFloat(lon.toFixed(6)), parseFloat(lat.toFixed(6))],
            },
          },
        };
      });
    }
    return [];
  };

  const updateClustersAndLeaves = (
    clickedFeature: PointFeature<GeoJsonProperties>,
    leaves: PointFeature<GeoJsonProperties>[],
    cluster_id: number,
  ) => {
    const clusterPosition = new LngLat(clickedFeature.geometry.coordinates[0], clickedFeature.geometry.coordinates[1]);
    const clusterRadius = calculateClusterRadius(leaves.length);

    const updatedClusters = clusters.map((feature) => ({
      ...feature,
      properties: {
        ...feature.properties,
        clusterRadius,
        selected: feature.id === cluster_id,
      },
    }));

    const updatedLeaves = calculateLeafPositions(leaves, clusterPosition, clusterRadius, zoom);
    setClusterState((prevState) => ({
      ...prevState,
      selectedCluster: clickedFeature,
      updatedClusters: [...updatedClusters, ...updatedLeaves],
      currentZoom: zoom,
    }));
  };

  const handleOpenInfo = useCallback(
    (event: any, properties: GeoJsonProperties) => { // eslint-disable-line
      if (properties) {
        const featureValue = JSON.parse(properties.value);
        const controlPosition =
          event.originalEvent instanceof MouseEvent
            ? { x: event.originalEvent.clientX, y: event.originalEvent.clientY }
            : { x: 0, y: 0 };
        updatePopupControl({
          mapId,
          popupControl: {
            type: PopupDataTypeEnum.INFO,
            open: true,
            position: controlPosition,
            data: {
              type: properties.featureType,
              featureValue: featureValue,
            },
          },
        });
      }
    },
    [updatePopupControl, mapId],
  );

  const updateClusterView = useCallback(
    (event?: any) => { // eslint-disable-line
      if (!map || !areLeavesDisplayed) return;
      const clickedFeature =
        event &&
        map.queryRenderedFeatures(event.point, {
          layers: [LayerNameEnum.SENSOR_CLUSTER_LEAVES, LayerNameEnum.SENSOR_CLUSTER_OVERLAY],
        })[0];
      if (clickedFeature?.properties && leaveIds.includes(clickedFeature.properties.id)) {
        handleOpenInfo(event!, clickedFeature.properties);
        return;
      }
      if (clusterState.currentZoom !== zoom || !clickedFeature) {
        updateClusterState({
          selectedCluster: null,
          updatedClusters: [],
          currentZoom: zoom,
        });
      }
      if (currentPopupControl.open) {
        closePopupControl(mapId);
      }
    },
    [
      areLeavesDisplayed,
      closePopupControl,
      clusterState.currentZoom,
      currentPopupControl.open,
      handleOpenInfo,
      leaveIds,
      map,
      mapId,
      updateClusterState,
      zoom,
    ],
  );

  const handleClick = (event: any) => { // eslint-disable-line
    if (!map || !supercluster || zoom < 13) return;
    const clickedFeature = event.features?.[0];
    if (!clickedFeature || clickedFeature.geometry.type !== 'Point') return;

    // Prevent click propagation, if a target is selected, do not select the cluster underneath
    const targetLayerFeatures = map.queryRenderedFeatures(event.point, { layers: [LayerNameEnum.TARGETS] });
    if (targetLayerFeatures.length) {
      return;
    }

    const { cluster_id } = clickedFeature.properties || {};
    const leaves = supercluster.getLeaves(cluster_id);

    if (leaves.length >= 2 && leaves.length <= 5) {
      updateClustersAndLeaves(clickedFeature as PointFeature<GeoJsonProperties>, leaves, cluster_id);
    }
  };

  useMapLayerMouseEvent('click', handleClick, LayerNameEnum.SENSOR_CLUSTERS);
  useMapLayerMouseEvent('click', updateClusterView);

  useEffect(() => {
    if (areLeavesDisplayed && clusterState.currentZoom !== zoom) {
      updateClusterView();
    }
  }, [areLeavesDisplayed, clusterState.currentZoom, map, updateClusterView, zoom]);

  return [clusterState, leaveIds, clusters];
};
