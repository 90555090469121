import { ReactComponent as ChatButtonIcon } from '@assets/components/panel/chat/chat-button.svg';
import { Box, HStack, IconButton, Text, useBoolean, VStack } from '@chakra-ui/react';
import CockpitCustomScrollbar from '@components/common/layout/CockpitCustomScrollbar';
import { useConst } from '@hooks/useConst';
import Scrollbars from 'rc-scrollbars';
import { KeyboardEvent, RefObject, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';

type Props = {
  scrollbarRef: RefObject<Scrollbars | null>;
  onSubmit: (message: string) => void;
};

const MAX_LENGTH = 500;

export function ChatFooter({ scrollbarRef, onSubmit }: Readonly<Props>) {
  const [isDisabled, setIsDisabled] = useBoolean(true);
  const [size, setSize] = useState<number>(0);
  const inputId = useConst(uuid());

  useEffect(() => {
    const input = document.getElementById(inputId) as HTMLDivElement;
    function updateButtonState() {
      if (isDisabled && input.innerText.trim() !== '' && input.innerText.length <= MAX_LENGTH) {
        setIsDisabled.off();
      } else if (!isDisabled && (input.innerText.trim() === '' || input.innerText.length > MAX_LENGTH)) {
        setIsDisabled.on();
      }
      setSize(input.textContent?.length ?? 0);
    }
    input.addEventListener('input', updateButtonState);
    return () => {
      input.removeEventListener('input', updateButtonState);
    };
  }, [inputId, isDisabled, setIsDisabled]);

  const handleOnClick = () => {
    const input = document.getElementById(inputId) as HTMLDivElement;
    onSubmit(input.innerText);
    input.innerText = '';
    input.dispatchEvent(new Event('input', { bubbles: true }));
    scrollbarRef.current?.scrollToBottom();
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey && !event.altKey && event.key === 'Enter' && !isDisabled) {
      handleOnClick();
    }
  };

  return (
    <VStack padding={3} gap={0.5} backgroundColor="neutral.800-op70" alignItems="start">
      <HStack border="1px solid" justifyContent="space-between" borderColor="neutral.300" height="106px" width="100%">
        <Box
          flexGrow={1}
          height="100%"
          padding={2}
          paddingRight={0}
          cursor="text"
          onClick={() => {
            const input = document.getElementById(inputId);
            if (input) {
              input.focus();
            }
          }}
        >
          <CockpitCustomScrollbar marginTop={0}>
            <Box
              fontFamily="roboto"
              whiteSpace="pre-wrap"
              contentEditable={navigator.userAgent.indexOf('Firefox') != -1 ? true : 'plaintext-only'}
              id={inputId}
              resize="none"
              fontSize="14px"
              lineHeight="24px"
              color="neutral.white"
              fontWeight="regular"
              style={{ caretColor: 'var(--chakra-colors-sky-500)' }}
              _focus={{ outline: 'none' }}
              onKeyDown={handleOnKeyDown}
            />
          </CockpitCustomScrollbar>
        </Box>
        <IconButton
          height="100%"
          width="44px"
          icon={<ChatButtonIcon />}
          isDisabled={isDisabled}
          borderRadius={0}
          backgroundColor="transparent"
          aria-label="send message"
          padding={0.25}
          color="sky.500"
          _hover={{ border: 'none' }}
          _active={{ border: 'none' }}
          _disabled={{ color: 'neutral.300', cursor: 'default', opacity: 0.5 }}
          onClick={handleOnClick}
        />
      </HStack>
      <HStack gap={0} marginLeft={2}>
        <Text
          fontWeight="medium"
          lineHeight="15px"
          padding={0.25}
          color={size <= MAX_LENGTH ? 'neutral.300' : 'neutral.white'}
          backgroundColor={size <= MAX_LENGTH ? 'transparent' : 'alertHi.500'}
        >
          {size}
        </Text>
        <Text fontWeight="medium" lineHeight="15px" color="neutral.300">
          <FormattedMessage id="components.chat.characters" values={{ maxLength: MAX_LENGTH }} />
        </Text>
      </HStack>
    </VStack>
  );
}
