import { Text, VStack } from '@chakra-ui/layout';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectTrackFromRawSituationByTrackLights } from '@redux/situation/situation.selectors';
import { getTargetHeight, getTargetInfoId, getTrackUniqueId } from '@utils/target.utils';
import { formatUnit, HeightUnits } from '@utils/unit.utils';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'react-map-gl';

import { Target } from '@/types/c2/c2.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

type Props = {
  target: Target<TargetLocation>;
};

function TargetPermanentTooltip({ target }: Readonly<Props>) {
  const height = getTargetHeight(target);
  const [long, lat] = toLonLatArray(target.lastPosition.location!.position, false)!;

  const tracks = useSelectorWithReplayMode(
    selectTrackFromRawSituationByTrackLights,
    target.trackIds.map(getTrackUniqueId),
  );

  const targetId = getTargetInfoId(tracks, target.identification.mainIdentification, target.id);

  return (
    <Popup
      className="mapbox-popup"
      offset={25}
      latitude={lat}
      longitude={long}
      closeButton={false}
      closeOnClick={false}
    >
      <VStack gap={0.5} alignItems="flex-start">
        {targetId !== target.id && <Text fontSize="16px">{targetId}</Text>}
        {height !== null && <Text fontSize="16px">{formatUnit(HeightUnits.METERS, height)}</Text>}

        <Text fontSize="16px">
          <FormattedMessage id={`target.reliability.${target.identification.reliability}`} />
        </Text>
      </VStack>
    </Popup>
  );
}

export default TargetPermanentTooltip;
