import { ReactComponent as EnemyClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-alertHigh.svg';
import { ReactComponent as WatchClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-alertLow.svg';
import { ReactComponent as SuspectClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-alertMid.svg';
import { ReactComponent as FriendClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-alertZero.svg';
import { ReactComponent as UnknownClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-Disabled.svg';
import { ReactComponent as BuildingSignIcon } from '@assets/icons/24x24/target-icSign/icSign-beacon-24.svg';
import { ReactComponent as BirdSignIcon } from '@assets/icons/24x24/target-icSign/icSign-birds-24.svg';
import { ReactComponent as ShipSignIcon } from '@assets/icons/24x24/target-icSign/icSign-boat-24.svg';
import { ReactComponent as GroundVehicleSignIcon } from '@assets/icons/24x24/target-icSign/icSign-car-24.svg';
import { ReactComponent as DroneSignIcon } from '@assets/icons/24x24/target-icSign/icSign-drone-24.svg';
import { ReactComponent as HelicopterSignIcon } from '@assets/icons/24x24/target-icSign/icSign-helico-24.svg';
import { ReactComponent as PersonSignIcon } from '@assets/icons/24x24/target-icSign/icSign-human-24.svg';
import { ReactComponent as AircraftSignIcon } from '@assets/icons/24x24/target-icSign/icSign-plane-24.svg';
import { ReactComponent as RemoteControlSignIcon } from '@assets/icons/24x24/target-icSign/icSign-remoteControl-24.svg';
import { ReactComponent as UnknownSignIcon } from '@assets/icons/24x24/target-icSign/icSign-unknown-24.svg';
import { ReactComponent as BuildingSureIcon } from '@assets/icons/70x70/target/icSymb-beacon-wh-L.svg';
import { ReactComponent as BuildingUnsureIcon } from '@assets/icons/70x70/target/icSymb-beacon-wh-L-notSafe.svg';
import { ReactComponent as BirdSureIcon } from '@assets/icons/70x70/target/icSymb-bird-wh-L.svg';
import { ReactComponent as BirdUnsureIcon } from '@assets/icons/70x70/target/icSymb-bird-wh-L-notSafe.svg';
import { ReactComponent as ShipSureIcon } from '@assets/icons/70x70/target/icSymb-boat-wh-L.svg';
import { ReactComponent as ShipUnsureIcon } from '@assets/icons/70x70/target/icSymb-boat-wh-L-notSafe.svg';
import { ReactComponent as DroneFriendSureIcon } from '@assets/icons/70x70/target/icSymb-drone-gn-L.svg';
import { ReactComponent as DroneFriendUnsureIcon } from '@assets/icons/70x70/target/icSymb-drone-gn-L-notSafe.svg';
import { ReactComponent as DroneUnknownSureIcon } from '@assets/icons/70x70/target/icSymb-drone-gy-L.svg';
import { ReactComponent as DroneUnknownUnsureIcon } from '@assets/icons/70x70/target/icSymb-drone-gy-L-notSafe.svg';
import { ReactComponent as DroneSuspectSureIcon } from '@assets/icons/70x70/target/icSymb-drone-or-L.svg';
import { ReactComponent as DroneSuspectUnsureIcon } from '@assets/icons/70x70/target/icSymb-drone-or-L-notSafe.svg';
import { ReactComponent as DroneEnemySureIcon } from '@assets/icons/70x70/target/icSymb-drone-rd-L.svg';
import { ReactComponent as DroneEnemyUnsureIcon } from '@assets/icons/70x70/target/icSymb-drone-rd-L-notSafe.svg';
import { ReactComponent as DroneWatchSureIcon } from '@assets/icons/70x70/target/icSymb-drone-yl-L.svg';
import { ReactComponent as DroneWatchUnsureIcon } from '@assets/icons/70x70/target/icSymb-drone-yl-L-notSafe.svg';
import { ReactComponent as HelicopterSureIcon } from '@assets/icons/70x70/target/icSymb-helico-wh-L.svg';
import { ReactComponent as HelicopterUnsureIcon } from '@assets/icons/70x70/target/icSymb-helico-wh-L-notSafe.svg';
import { ReactComponent as PersonSureIcon } from '@assets/icons/70x70/target/icSymb-human-wh-L.svg';
import { ReactComponent as PersonUnsureIcon } from '@assets/icons/70x70/target/icSymb-human-wh-L-notSafe.svg';
import { ReactComponent as AircraftSureIcon } from '@assets/icons/70x70/target/icSymb-plane-wh-L.svg';
import { ReactComponent as AircraftUnsureIcon } from '@assets/icons/70x70/target/icSymb-plane-wh-L-notSafe.svg';
import { ReactComponent as RemoteControlFriendSureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-gn-L.svg';
import { ReactComponent as RemoteControlFriendUnsureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-gn-L-notSafe.svg';
import { ReactComponent as RemoteControlUnknownSureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-gy-L.svg';
import { ReactComponent as RemoteControlUnknownUnsureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-gy-L-notSafe.svg';
import { ReactComponent as RemoteControlSuspectSureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-or-L.svg';
import { ReactComponent as RemoteControlSuspectUnsureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-or-L-notSafe.svg';
import { ReactComponent as RemoteControlEnemySureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-rd-L.svg';
import { ReactComponent as RemoteControlEnemyUnsureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-rd-L-notSafe.svg';
import { ReactComponent as RemoteControlWatchSureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-yl-L.svg';
import { ReactComponent as RemoteControlWatchUnsureIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-yl-L-notSafe.svg';
import { ReactComponent as UnknownSureIcon } from '@assets/icons/70x70/target/icSymb-unknown-wh-L.svg';
import { ReactComponent as UnknownUnsureIcon } from '@assets/icons/70x70/target/icSymb-unknown-wh-L-notSafe.svg';
import { ReactComponent as GroundVehicleSureIcon } from '@assets/icons/70x70/target/icSymb-vehicule-wh-L.svg';
import { ReactComponent as GroundVehicleUnsureIcon } from '@assets/icons/70x70/target/icSymb-vehicule-wh-L-notSafe.svg';
import { appIntl } from '@components/locale/IntlGlobalProvider';
import { extendedSensorShortHand, IconColorEnum } from '@utils/map/map.constants';
import { FunctionComponent, SVGProps } from 'react';

import { Target, TargetStatusEnum, Track } from '@/types/c2/c2.types';
import { TrackLight } from '@/types/commons/commons.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';
import { SensorTypeEnum } from '@/types/sensor/configuration.types';
import { IdentificationEnum, ReliabilityEnum } from '@/types/sensor/identification.types';
import {
  AbstractLocation,
  CollaborativeData,
  LocationTypeEnum,
  SpatialLocation,
  TargetLocation,
} from '@/types/sensor/sensor.types';

export function getTargetIdentification(target: Target<AbstractLocation>): IdentificationEnum {
  return target.identification.mainIdentification;
}

export function getTargetClassification(target: Target<AbstractLocation>): ClassificationEnum {
  return target.classification.manualClassification ?? target.classification.classification;
}

export function getTargetReliability(target: Target<AbstractLocation>): ReliabilityEnum {
  return target.identification.reliability;
}

export function getTargetStatus(target: Target<AbstractLocation>): TargetStatusEnum {
  return target.status;
}

export function isDrone(target: Target<AbstractLocation>): boolean {
  return getTargetIdentification(target) === IdentificationEnum.DRONE;
}

export function isRemoteControl(target: Target<AbstractLocation>): boolean {
  return getTargetIdentification(target) === IdentificationEnum.REMOTE_CONTROL;
}

export function isAircraft(target: Target<AbstractLocation>): boolean {
  return getTargetIdentification(target) === IdentificationEnum.AIRCRAFT;
}

export function isSure(target: Target<AbstractLocation>): boolean {
  return getTargetReliability(target) === ReliabilityEnum.SURE;
}

export function isSpatial(target: Target<AbstractLocation>): target is Target<SpatialLocation> {
  return target.lastPosition?.location?.type === LocationTypeEnum.SPATIAL;
}

export function getTargetAltitude(target: Target<AbstractLocation>): number | null {
  if (isSpatial(target)) {
    return target.lastPosition.location?.altitude ?? null;
  }
  return null;
}

export function getTargetHeight(target: Target<AbstractLocation>): number | null {
  if (isSpatial(target)) {
    return target.lastPosition.location?.height ?? null;
  }
  return null;
}

export function getTargetPriority(target: Target<AbstractLocation>): number {
  switch (getTargetClassification(target)) {
    case ClassificationEnum.FRIEND:
      return 0;
    case ClassificationEnum.UNCLASSIFIED:
      return 1;
    case ClassificationEnum.UNKNOWN:
      return 2;
    case ClassificationEnum.WATCH:
      return 3;
    case ClassificationEnum.SUSPECT:
      return 4;
    case ClassificationEnum.ENEMY:
      return 5;
  }
}

enum IconTargetNameEnum {
  BEACON = 'beacon',
  BIRD = 'bird',
  BOAT = 'boat',
  DRONE = 'drone',
  HELICO = 'helico',
  HUMAN = 'human',
  PLANE = 'plane',
  REMOTE_CONTROL = 'remoteControl',
  UNKNOWN = 'unknown',
  VEHICULE = 'vehicule',
}

const TargetIconStatusFromClassification: Record<ClassificationEnum, IconColorEnum> = {
  ENEMY: IconColorEnum.RED,
  SUSPECT: IconColorEnum.ORANGE,
  WATCH: IconColorEnum.YELLOW,
  FRIEND: IconColorEnum.GREEN,
  UNKNOWN: IconColorEnum.GRAY,
  UNCLASSIFIED: IconColorEnum.WHITE,
};

const TargetIconIdentification: Record<IdentificationEnum, IconTargetNameEnum> = {
  AIRCRAFT: IconTargetNameEnum.PLANE,
  BIRD: IconTargetNameEnum.BIRD,
  BUILDING: IconTargetNameEnum.BEACON,
  DRONE: IconTargetNameEnum.DRONE,
  GROUND_VEHICLE: IconTargetNameEnum.VEHICULE,
  HELICOPTER: IconTargetNameEnum.HELICO,
  PERSON: IconTargetNameEnum.HUMAN,
  REMOTE_CONTROL: IconTargetNameEnum.REMOTE_CONTROL,
  SHIP: IconTargetNameEnum.BOAT,
  UNKNOWN: IconTargetNameEnum.UNKNOWN,
};

export function getTargetIconName(target: Target<TargetLocation>): string {
  const identification = getTargetIdentification(target);
  const reliability = getTargetReliability(target);
  let classification;

  if (isDrone(target) || isRemoteControl(target)) {
    classification = getTargetClassification(target);
    //We don't want an unclassified drone or remote, so we replace it with unknown instead
    if (classification === ClassificationEnum.UNCLASSIFIED) {
      classification = ClassificationEnum.UNKNOWN;
    }
  } else {
    classification = ClassificationEnum.UNCLASSIFIED;
  }

  return `icSymb-${TargetIconIdentification[identification]}-${TargetIconStatusFromClassification[classification]}-L${
    reliability === ReliabilityEnum.UNSURE ? '-notSafe' : ''
  }`;
}

export function getDroneIcon(target: Target<TargetLocation>): FunctionComponent<SVGProps<SVGSVGElement>> {
  if (getTargetIdentification(target) === IdentificationEnum.DRONE) {
    switch (getTargetClassification(target)) {
      case ClassificationEnum.FRIEND:
        return isSure(target) ? DroneFriendSureIcon : DroneFriendUnsureIcon;
      case ClassificationEnum.WATCH:
        return isSure(target) ? DroneWatchSureIcon : DroneWatchUnsureIcon;
      case ClassificationEnum.SUSPECT:
        return isSure(target) ? DroneSuspectSureIcon : DroneSuspectUnsureIcon;
      case ClassificationEnum.ENEMY:
        return isSure(target) ? DroneEnemySureIcon : DroneEnemyUnsureIcon;
      case ClassificationEnum.UNKNOWN:
      case ClassificationEnum.UNCLASSIFIED:
        return isSure(target) ? DroneUnknownSureIcon : DroneUnknownUnsureIcon;
    }
  }
  return isSure(target) ? UnknownSureIcon : UnknownUnsureIcon;
}

export function getRemoteControlIcon(target: Target<TargetLocation>): FunctionComponent<SVGProps<SVGSVGElement>> {
  if (getTargetIdentification(target) === IdentificationEnum.REMOTE_CONTROL) {
    switch (getTargetClassification(target)) {
      case ClassificationEnum.FRIEND:
        return isSure(target) ? RemoteControlFriendSureIcon : RemoteControlFriendUnsureIcon;
      case ClassificationEnum.WATCH:
        return isSure(target) ? RemoteControlWatchSureIcon : RemoteControlWatchUnsureIcon;
      case ClassificationEnum.SUSPECT:
        return isSure(target) ? RemoteControlSuspectSureIcon : RemoteControlSuspectUnsureIcon;
      case ClassificationEnum.ENEMY:
        return isSure(target) ? RemoteControlEnemySureIcon : RemoteControlEnemyUnsureIcon;
      case ClassificationEnum.UNKNOWN:
      case ClassificationEnum.UNCLASSIFIED:
        return isSure(target) ? RemoteControlUnknownSureIcon : RemoteControlUnknownUnsureIcon;
    }
  }
  return isSure(target) ? UnknownSureIcon : UnknownUnsureIcon;
}

export function getTargetIcon(target: Target<TargetLocation>): FunctionComponent<SVGProps<SVGSVGElement>> {
  switch (getTargetIdentification(target)) {
    case IdentificationEnum.AIRCRAFT:
      return isSure(target) ? AircraftSureIcon : AircraftUnsureIcon;
    case IdentificationEnum.BIRD:
      return isSure(target) ? BirdSureIcon : BirdUnsureIcon;
    case IdentificationEnum.BUILDING:
      return isSure(target) ? BuildingSureIcon : BuildingUnsureIcon;
    case IdentificationEnum.GROUND_VEHICLE:
      return isSure(target) ? GroundVehicleSureIcon : GroundVehicleUnsureIcon;
    case IdentificationEnum.HELICOPTER:
      return isSure(target) ? HelicopterSureIcon : HelicopterUnsureIcon;
    case IdentificationEnum.PERSON:
      return isSure(target) ? PersonSureIcon : PersonUnsureIcon;
    case IdentificationEnum.SHIP:
      return isSure(target) ? ShipSureIcon : ShipUnsureIcon;
    case IdentificationEnum.UNKNOWN:
      return isSure(target) ? UnknownSureIcon : UnknownUnsureIcon;
    case IdentificationEnum.DRONE:
      return getDroneIcon(target);
    case IdentificationEnum.REMOTE_CONTROL:
      return getRemoteControlIcon(target);
  }
}

export function getIdentificationIcon(identification: IdentificationEnum): FunctionComponent<SVGProps<SVGSVGElement>> {
  switch (identification) {
    case IdentificationEnum.AIRCRAFT:
      return AircraftSignIcon;
    case IdentificationEnum.BIRD:
      return BirdSignIcon;
    case IdentificationEnum.BUILDING:
      return BuildingSignIcon;
    case IdentificationEnum.GROUND_VEHICLE:
      return GroundVehicleSignIcon;
    case IdentificationEnum.HELICOPTER:
      return HelicopterSignIcon;
    case IdentificationEnum.PERSON:
      return PersonSignIcon;
    case IdentificationEnum.SHIP:
      return ShipSignIcon;
    case IdentificationEnum.UNKNOWN:
      return UnknownSignIcon;
    case IdentificationEnum.DRONE:
      return DroneSignIcon;
    case IdentificationEnum.REMOTE_CONTROL:
      return RemoteControlSignIcon;
  }
}

export function getClassificationIcon(
  classification: Omit<ClassificationEnum, ClassificationEnum.UNCLASSIFIED>,
): FunctionComponent<SVGProps<SVGSVGElement>> {
  switch (classification) {
    case ClassificationEnum.ENEMY:
      return EnemyClassificationIcon;
    case ClassificationEnum.SUSPECT:
      return SuspectClassificationIcon;
    case ClassificationEnum.WATCH:
      return WatchClassificationIcon;
    case ClassificationEnum.FRIEND:
      return FriendClassificationIcon;
    case ClassificationEnum.UNKNOWN:
    default:
      return UnknownClassificationIcon;
  }
}

export function getTrackUniqueId(track: TrackLight): string {
  return `${track.id}-${track.sensorCode}-${track.appCode}`;
}

function getUniqueValues(
  tracks: Track<AbstractLocation>[],
  getValue: (c: CollaborativeData | undefined) => string | undefined,
) {
  const values = tracks
    .map((track) => getValue(track.lastPosition?.collaborativeData))
    .filter((track, i, self) => self.indexOf(track) == i)
    .filter((v) => v);
  return values.length > 0 ? values.join(' | ') : undefined;
}

export function getCollaborativeData(tracks: Track<AbstractLocation>[]): CollaborativeData {
  return {
    aeroscopeId: getUniqueValues(tracks, (c) => c?.aeroscopeId),
    aircraftId: getUniqueValues(tracks, (c) => c?.aircraftId),
    infodroneId: getUniqueValues(tracks, (c) => c?.infodroneId),
    vehicleId: getUniqueValues(tracks, (c) => c?.vehicleId),
    model: getUniqueValues(tracks, (c) => c?.model),
    fleet: getUniqueValues(tracks, (c) => c?.fleet),
  };
}

export function getDroneCollaborativeId(tracks: TrackLight[]): string {
  if (tracks.length === 0) {
    return appIntl().formatMessage({ id: 'target.unknown' });
  }
  return (
    tracks.find((track) => track.sensorType === SensorTypeEnum.HOLODRONE)?.id ??
    tracks.find((track) => track.sensorType === SensorTypeEnum.AEROSCOPE)?.id ??
    appIntl().formatMessage({ id: 'target.unknown' })
  );
}

export function getTargetInfoId(
  tracks: Track<AbstractLocation>[],
  mainIdentification: IdentificationEnum,
  targetId: string,
) {
  const collaborativeData = getCollaborativeData(tracks);
  let resultId: string | undefined;
  switch (mainIdentification) {
    case IdentificationEnum.DRONE:
    case IdentificationEnum.REMOTE_CONTROL:
      resultId = collaborativeData.infodroneId ?? collaborativeData.aeroscopeId;
      break;
    case IdentificationEnum.AIRCRAFT:
      resultId = collaborativeData.aircraftId;
      break;
    case IdentificationEnum.GROUND_VEHICLE:
      resultId = collaborativeData.vehicleId;
      break;
    default:
      resultId = targetId;
  }

  return resultId ?? targetId;
}

export function getUniqueSensorShortHand(tracks: TrackLight[]) {
  return Array.from(new Set(tracks.map((track) => extendedSensorShortHand[track.sensorType])));
}
