import { MapStateType, SelectedFeatureTypeEnum } from '@redux/maps/maps.reducer';
import { PARIS } from '@utils/map/map.constants';
import {
  POLYGON_3D_NOT_CLOSED,
  POLYGON_3D_VALID,
  POLYGON_3D_WITH_INVALID_POINT,
  POLYGON_NOT_CLOSED,
  POLYGON_VALID,
  POLYGON_VALID_COMPLEX,
  POLYGON_VALID_COMPLEX_MARGIN,
  POLYGON_WITH_INVALID_POINT,
} from '@utils/tests/map.constant';

import { LogTypeEnum, SystemActionEnum, SystemLog } from '@/types/c2/logs.type';
import { VideoProtocolEnum } from '@/types/commons/commons.types';
import {
  ConfigConfiguration,
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
  NoNeutralizationZone,
  Platform,
  SEE_POI,
  Zone2DTypeEnum,
  ZoneCategoryEnum,
  ZoneOfInterest,
} from '@/types/config/config.types';
import { GridConfiguration, GridItemComponentEnum, GridLayout } from '@/types/config/gridLayout.types';
import { Home } from '@/types/config/home.types';
import { MergingConfiguration, MergingTypeEnum } from '@/types/config/merging.types';
import { PointOfInterest } from '@/types/config/pointOfInterest.types';
import { ProcedureModel, ProcedureModelStateEnum, ProcedureModelStepType } from '@/types/config/procedure.types';
import { RoundConfiguration, RoundConfigurationStateEnum, RoundSection } from '@/types/config/round.types';
import { ContextEnum, GlobalScreenConfiguration, ScreenConfiguration } from '@/types/config/screenConfiguration.types';
import { NimbleConfiguration } from '@/types/config/videoServer.types';
import { VideoStream } from '@/types/config/videoStream.types';
import { DroneAlertLevelEnum, NotificationTypeEnum } from '@/types/data/data.types';
import { PopupDataTypeEnum } from '@/types/map.types';

export const GRID_LAYOUT: GridLayout = {
  id: 1,
  site: 'local',
  code: 'test',
  name: 'name',
  nbRow: 1,
  nbCol: 1,
  resolution: '16/9',
  items: [
    {
      i: '1',
      component: GridItemComponentEnum.PERIMETER_DOUBT_CHECK,
      x: 1,
      y: 1,
      w: 1,
      h: 1,
    },
  ],
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const GRID_LAYOUT2: GridLayout = {
  id: 2,
  site: 'local',
  code: 'test2',
  name: 'name',
  nbRow: 1,
  nbCol: 1,
  resolution: '16/9',
  items: [
    {
      i: '1',
      component: GridItemComponentEnum.TARGET_DOUBT_CHECK,
      x: 1,
      y: 1,
      w: 1,
      h: 1,
    },
  ],
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const GRID_CONFIGURATION: GridConfiguration = {
  id: 1,
  code: 'code',
  component: GridItemComponentEnum.MAP,
  x: 1,
  y: 1,
  h: 1,
  w: 1,
  innerGridHeight: 100,
  innerGridWidth: 100,
  isResizable: true,
  minH: 5,
  minW: 5,
};

export const SCREEN_CONFIGURATION: ScreenConfiguration = {
  code: 'sc',
  context: ContextEnum.LIVE,
  gridLayoutCode: 'test',
};

export const SCREEN_CONFIGURATION2: ScreenConfiguration = {
  code: 'sc',
  context: ContextEnum.DOUBT_LAD,
  gridLayoutCode: 'test2',
};

export const GLOBAL_SCREEN_CONFIGURATION: GlobalScreenConfiguration = {
  id: 1,
  code: 'code',
  name: 'name',
  site: 'local',
  screenConfigurations: [SCREEN_CONFIGURATION],
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
  position: { latitude: 0, longitude: 0, type: '2D' },
};

export const HOME: Home = {
  id: 1,
  name: 'home',
  position: PARIS,
  altitude: 50,
  mapZoom: 10,
  site: 'local',
  audioFile: [1, 2, 3],
  audioFileName: 'audio',
  volume: 3,
  mapBackgrounds: [],
  projectionTime: 2,
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const NO_FLY_ZONE_CRITICAL: NoFlyZone = {
  id: 1,
  name: 'nfz',
  code: 'code',
  site: 'local',
  type: ZoneCategoryEnum.NFZ,
  polygon: POLYGON_VALID,
  active: true,
  level: DroneAlertLevelEnum.DRONE_CRITICAL,
  minHeightFinite: true,
  maxHeightFinite: false,
  heightMin: 50,
  heightMax: 300,
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const NO_FLY_ZONE_WARNING: NoFlyZone = {
  ...NO_FLY_ZONE_CRITICAL,
  id: 2,
  name: 'nfz2',
  code: 'code2',
  level: DroneAlertLevelEnum.DRONE_WARNING,
};

export const NO_FLY_ZONE_INFO: NoFlyZone = {
  ...NO_FLY_ZONE_CRITICAL,
  id: 3,
  name: 'nfz3',
  code: 'code3',
  level: DroneAlertLevelEnum.DRONE_INFO,
};

export const NO_FLY_ZONE_INVALID: NoFlyZone = {
  ...NO_FLY_ZONE_CRITICAL,
  id: 5,
  name: 'nfz5',
  code: 'code5',
  polygon: POLYGON_WITH_INVALID_POINT,
};

export const NO_FLY_ZONE_NOT_CLOSED: NoFlyZone = {
  ...NO_FLY_ZONE_CRITICAL,
  id: 6,
  name: 'nfz6',
  code: 'code6',
  polygon: POLYGON_NOT_CLOSED,
};

export const NO_FLY_ZONE_3D_CRITICAL: NoFlyZone3d = {
  id: 1,
  name: 'nfz3d',
  code: 'code',
  site: 'local',
  type: ZoneCategoryEnum.NFZ_3D,
  solidPolygon: POLYGON_3D_VALID,
  active: true,
  level: DroneAlertLevelEnum.DRONE_CRITICAL,
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const NO_FLY_ZONE_3D_WARNING: NoFlyZone3d = {
  ...NO_FLY_ZONE_3D_CRITICAL,
  id: 2,
  name: 'nfz3d2',
  code: 'code2',
  level: DroneAlertLevelEnum.DRONE_WARNING,
};

export const NO_FLY_ZONE_3D_INFO: NoFlyZone3d = {
  ...NO_FLY_ZONE_3D_CRITICAL,
  id: 3,
  name: 'nfz3d3',
  code: 'code3',
  level: DroneAlertLevelEnum.DRONE_INFO,
};

export const NO_FLY_ZONE_3D_INVALID: NoFlyZone3d = {
  ...NO_FLY_ZONE_3D_CRITICAL,
  id: 5,
  name: 'nfz3d4',
  code: 'code5',
  solidPolygon: POLYGON_3D_WITH_INVALID_POINT,
};

export const NO_FLY_ZONE_3D_NOT_CLOSED: NoFlyZone3d = {
  ...NO_FLY_ZONE_3D_CRITICAL,
  id: 6,
  name: 'nfz3d5',
  code: 'code6',
  solidPolygon: POLYGON_3D_NOT_CLOSED,
};

export const INITIALIZATION_MASKING_ZONE: InitializationMaskingZone = {
  id: 1,
  name: 'imz',
  code: 'code',
  site: 'local',
  type: ZoneCategoryEnum.IMZ,
  polygon: POLYGON_VALID,
  active: true,
  marginSize: 50,
  margin: POLYGON_VALID,
  minHeightFinite: true,
  maxHeightFinite: false,
  heightMin: 50,
  heightMax: 300,
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const INITIALIZATION_MASKING_ZONE_INVALID: InitializationMaskingZone = {
  ...INITIALIZATION_MASKING_ZONE,
  id: 2,
  name: 'imz2',
  code: 'code2',
  polygon: POLYGON_WITH_INVALID_POINT,
};

export const INITIALIZATION_MASKING_ZONE_NOT_CLOSED: InitializationMaskingZone = {
  ...INITIALIZATION_MASKING_ZONE,
  id: 3,
  name: 'imz3',
  code: 'code3',
  polygon: POLYGON_NOT_CLOSED,
};

export const INITIALIZATION_MASKING_ZONE_COMPLEX: InitializationMaskingZone = {
  ...INITIALIZATION_MASKING_ZONE,
  id: 4,
  name: 'imz4',
  code: 'code4',
  polygon: POLYGON_VALID_COMPLEX,
  margin: POLYGON_VALID_COMPLEX_MARGIN,
};

export const NO_NEUTRALIZATION_ZONE: NoNeutralizationZone = {
  id: 1,
  name: 'nnz',
  code: 'code',
  site: 'local',
  type: ZoneCategoryEnum.NNZ,
  polygon: POLYGON_VALID,
  active: true,
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const ZONE_OF_INTEREST: ZoneOfInterest = {
  id: 1,
  name: 'zoi',
  code: 'code',
  site: 'local',
  type: ZoneCategoryEnum.ZOI,
  polygon: POLYGON_VALID,
  active: true,
  color: 'blue',
  nature: 'nature',
  minHeightFinite: true,
  maxHeightFinite: false,
  heightMin: 50,
  heightMax: 300,
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const NO_INITIALIZATION_ZONE: NoInitZone = {
  id: 1,
  name: 'nnz',
  code: 'code',
  site: 'local',
  type: ZoneCategoryEnum.NIZ,
  polygon: POLYGON_VALID,
  active: true,
  minHeightFinite: true,
  maxHeightFinite: false,
  heightMin: 50,
  heightMax: 300,
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const NO_INITIALIZATION_ZONE_INVALID_COORDINATES: NoInitZone = {
  ...NO_INITIALIZATION_ZONE,
  id: 2,
  name: 'nnz2',
  code: 'code2',
  polygon: POLYGON_WITH_INVALID_POINT,
};

export const NO_INITIALIZATION_ZONE_NOT_CLOSED: NoInitZone = {
  ...NO_INITIALIZATION_ZONE,
  id: 3,
  name: 'nnz3',
  code: 'code3',
  polygon: POLYGON_NOT_CLOSED,
};

export const POINT_OF_INTEREST: PointOfInterest = {
  id: 1,
  name: 'nnz',
  code: 'code',
  site: 'local',
  position: { latitude: 0, longitude: 0, type: '2D' },
  active: true,
  symbol: 'square',
  color: 'red',
  height: 50,
  nature: 'nature',
  permanent: true,
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const INACTIVE_POINT_OF_INTEREST: PointOfInterest = {
  id: 12,
  name: 'nnz2',
  code: 'code',
  site: 'local',
  position: { latitude: 3, longitude: 4, type: '2D' },
  active: false,
  symbol: 'square',
  color: 'blue',
  height: 50,
  nature: 'nature',
  permanent: true,
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const NIMBLE_CONFIGURATION: NimbleConfiguration = {
  maxStorageAge: 50,
};

export const MERGING_CONFIGURATION: MergingConfiguration = {
  id: 10,
  site: 'local',
  mergingTypesBySensorCode: {
    holodrone: {
      aeroscope: { mergingTypeEnum: MergingTypeEnum.COLLABORATIVE },
    },
  },
};

export const VIDEO_STREAM: VideoStream = {
  id: 10,
  site: 'local',
  name: 'name',
  code: 'code',
  sourceProtocol: VideoProtocolEnum.SLDP,
  sourceUrl: 'url',
  protocol: VideoProtocolEnum.SLDP,
  url: 'url',
  createdBy: '',
  creationTime: '',
  modifiedBy: '',
  modificationTime: '',
};

export const ROUND_SECTIONS: RoundSection[] = [
  {
    id: 17,
    positionDeparture: {
      id: 44,
      coordinates: {
        longitude: 2.208162,
        latitude: 48.787685,
        type: '2D',
      },
      tilt: 0,
      pan: 260.81,
      fov: 45,
      focus: 1,
    },
    positionArrival: {
      id: 43,
      coordinates: {
        longitude: 2.182327,
        latitude: 48.76738,
        type: '2D',
      },
      tilt: 0,
      pan: 12.88,
      fov: 45,
      focus: 1,
    },
    rank: 1,
    speed: 2,
    active: true,
    critical: false,
  },
  {
    id: 20,
    positionDeparture: {
      id: 50,
      coordinates: {
        longitude: 2.182327,
        latitude: 48.76738,
        type: '2D',
      },
      tilt: 0,
      pan: 184.27,
      fov: 45,
      focus: 1,
    },
    positionArrival: {
      id: 49,
      coordinates: {
        longitude: 2.200481,
        latitude: 48.757504,
        type: '2D',
      },
      tilt: 0,
      pan: 260.81,
      fov: 45,
      focus: 1,
    },
    rank: 4,
    speed: 2,
    active: true,
    critical: false,
  },
  {
    id: 19,
    positionDeparture: {
      id: 48,
      coordinates: {
        longitude: 2.200481,
        latitude: 48.757504,
        type: '2D',
      },
      tilt: 0,
      pan: 106.31,
      fov: 45,
      focus: 1,
    },
    positionArrival: {
      id: 47,
      coordinates: {
        longitude: 2.221122,
        latitude: 48.76574,
        type: '2D',
      },
      tilt: 0,
      pan: 184.27,
      fov: 45,
      focus: 1,
    },
    rank: 3,
    speed: 2,
    active: true,
    critical: false,
  },
  {
    id: 18,
    positionDeparture: {
      id: 46,
      coordinates: {
        longitude: 2.221122,
        latitude: 48.76574,
        type: '2D',
      },
      tilt: 0,
      pan: 12.88,
      fov: 45,
      focus: 1,
    },
    positionArrival: {
      id: 45,
      coordinates: {
        longitude: 2.208162,
        latitude: 48.787685,
        type: '2D',
      },
      tilt: 0,
      pan: 106.31,
      fov: 45,
      focus: 1,
    },
    rank: 2,
    speed: 2,
    active: true,
    critical: false,
  },
];

export const ROUND_CONFIGURATION: RoundConfiguration = {
  creationTime: '2024-05-28T15:56:29.009981Z',
  createdBy: null,
  modificationTime: '2024-05-28T15:56:29.009981Z',
  modifiedBy: null,
  id: 34,
  name: null,
  code: 'ronde_1',
  site: 'local',
  sensorCode: 'holoptics-simu2',
  sections: [
    {
      id: 16,
      positionDeparture: {
        id: 42,
        coordinates: {
          longitude: 2.200481,
          latitude: 48.757504,
          type: '2D',
        },
        tilt: 0,
        pan: 260.81,
        fov: 45,
        focus: 1,
      },
      positionArrival: {
        id: 41,
        coordinates: {
          longitude: 2.182327,
          latitude: 48.76738,
          type: '2D',
        },
        tilt: 0,
        pan: 184.27,
        fov: 45,
        focus: 1,
      },
      rank: 4,
      speed: 2,
      active: true,
      critical: false,
    },
    {
      id: 14,
      positionDeparture: {
        id: 38,
        coordinates: {
          longitude: 2.208162,
          latitude: 48.787685,
          type: '2D',
        },
        tilt: 0,
        pan: 106.31,
        fov: 45,
        focus: 1,
      },
      positionArrival: {
        id: 37,
        coordinates: {
          longitude: 2.221122,
          latitude: 48.76574,
          type: '2D',
        },
        tilt: 0,
        pan: 12.88,
        fov: 45,
        focus: 1,
      },
      rank: 2,
      speed: 2,
      active: true,
      critical: false,
    },
    {
      id: 13,
      positionDeparture: {
        id: 36,
        coordinates: {
          longitude: 2.182327,
          latitude: 48.76738,
          type: '2D',
        },
        tilt: 0,
        pan: 12.88,
        fov: 45,
        focus: 1,
      },
      positionArrival: {
        id: 35,
        coordinates: {
          longitude: 2.208162,
          latitude: 48.787685,
          type: '2D',
        },
        tilt: 0,
        pan: 260.81,
        fov: 45,
        focus: 1,
      },
      rank: 1,
      speed: 2,
      active: true,
      critical: false,
    },
    {
      id: 15,
      positionDeparture: {
        id: 40,
        coordinates: {
          longitude: 2.221122,
          latitude: 48.76574,
          type: '2D',
        },
        tilt: 0,
        pan: 184.27,
        fov: 45,
        focus: 1,
      },
      positionArrival: {
        id: 39,
        coordinates: {
          longitude: 2.200481,
          latitude: 48.757504,
          type: '2D',
        },
        tilt: 0,
        pan: 106.31,
        fov: 45,
        focus: 1,
      },
      rank: 3,
      speed: 2,
      active: true,
      critical: false,
    },
  ],
  active: true,
  state: RoundConfigurationStateEnum.DRAFT,
};

export const ROUND_CONFIGURATION2: RoundConfiguration = {
  creationTime: '2024-05-29T09:21:50.968311Z',
  createdBy: null,
  modificationTime: '2024-05-29T09:21:50.968311Z',
  modifiedBy: null,
  id: 37,
  name: null,
  code: 'ronde_2',
  site: 'local',
  sensorCode: 'holoptics-simu2',
  sections: ROUND_SECTIONS,
  active: false,
  state: RoundConfigurationStateEnum.COMPLETE,
};

export const ROUND_CONFIGURATIONS: RoundConfiguration[] = [ROUND_CONFIGURATION, ROUND_CONFIGURATION2];

export const PLATFORM: Platform = {
  id: 1,
  code: 'sensor-local',
  name: 'orly',
  altitude: 100,
  mapZoom: 9,
  position: {
    latitude: 48,
    longitude: 2,
    type: '2D',
  },
};

export const PLATFORM_NOT_IN_GROUP: Platform = {
  id: 1,
  code: 'code_not_in_group',
  name: 'orly2',
  altitude: 100,
  mapZoom: 9,
  position: {
    latitude: 48,
    longitude: 2,
    type: '2D',
  },
};

export const PROCEDURE_MODEL: ProcedureModel = {
  creationTime: null,
  createdBy: null,
  modificationTime: null,
  modifiedBy: null,
  id: 12,
  name: 'name',
  code: 'procedureModel',
  site: 'local',
  state: ProcedureModelStateEnum.USABLE,
  steps: [
    {
      id: 1,
      code: 'step1',
      type: ProcedureModelStepType.ACKNOWLEDGEMENT,
      mandatory: true,
    },
    {
      id: 2,
      code: 'step2',
      type: ProcedureModelStepType.CONFIRMATION,
      mandatory: false,
    },
  ],
  noFlyZones: [NO_FLY_ZONE_CRITICAL],
};

export const MAP: MapStateType = {
  mapId: 'map',
  popupControl: {
    open: true,
    position: { x: 50, y: 12 },
    type: PopupDataTypeEnum.HISTORY,
    data: { identifier: 'adsb-target', openingTime: new Date(), type: NotificationTypeEnum.DRONE },
  },
  view: {
    longitude: 2.349,
    latitude: 48.8647,
    zoom: 14,
    bearing: 0,
    pitch: 0,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  selectedFeatureKey: { type: SelectedFeatureTypeEnum.TARGET, selectedTargetId: 'target-id' },
  selectedDate: new Date(),
  displayedPerimeterLinksCodes: null,
  menuNavigation: [SEE_POI],
  drawInfo: {
    zoneType: undefined,
    polygonPoints: [],
    isDrawnOnto: false,
    editedZone: undefined,
  },
};

export const OTHER_MAP: MapStateType = {
  mapId: 'other-map',
  popupControl: {
    open: false,
    position: { x: 0, y: 0 },
    type: null,
    data: null,
  },
  view: {
    longitude: 2.349,
    latitude: 48.8647,
    zoom: 14,
    bearing: 0,
    pitch: 20,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  selectedFeatureKey: {
    type: SelectedFeatureTypeEnum.SENSOR,
    selectedSensorUniqueCodes: { sensorUniqueCode: 'sensorCode_appCode' },
  },
  selectedDate: null,
  displayedPerimeterLinksCodes: null,
  menuNavigation: [`see_${Zone2DTypeEnum.NIZ}`],
  drawInfo: {
    zoneType: Zone2DTypeEnum.NFZ_INFO,
    polygonPoints: [
      { longitude: 48.7196, latitude: 2.3071, type: '2D' },
      { longitude: 48.728, latitude: 2.3092, type: '2D' },
      { longitude: 48.7241, latitude: 2.3028, type: '2D' },
      { longitude: 48.7196, latitude: 2.3071, type: '2D' },
    ],
    isDrawnOnto: true,
    editedZone: NO_FLY_ZONE_CRITICAL,
  },
};

export const CONFIG_CONFIGURATION: ConfigConfiguration = {
  home: HOME,
  site: 'local',
  noFlyZones: [
    NO_FLY_ZONE_CRITICAL,
    NO_FLY_ZONE_INFO,
    NO_FLY_ZONE_INVALID,
    NO_FLY_ZONE_WARNING,
    NO_FLY_ZONE_NOT_CLOSED,
  ],
  noFlyZones3d: [
    NO_FLY_ZONE_3D_CRITICAL,
    NO_FLY_ZONE_3D_INFO,
    NO_FLY_ZONE_3D_INVALID,
    NO_FLY_ZONE_3D_WARNING,
    NO_FLY_ZONE_3D_NOT_CLOSED,
  ],
  initializationMaskingZones: [
    INITIALIZATION_MASKING_ZONE,
    INITIALIZATION_MASKING_ZONE_INVALID,
    INITIALIZATION_MASKING_ZONE_NOT_CLOSED,
  ],
  noInitZones: [NO_INITIALIZATION_ZONE_INVALID_COORDINATES, NO_INITIALIZATION_ZONE, NO_INITIALIZATION_ZONE_NOT_CLOSED],
  zoneOfInterests: [ZONE_OF_INTEREST],
  noNeutralizationZones: [NO_NEUTRALIZATION_ZONE],
  pointOfInterests: [POINT_OF_INTEREST],
  layouts: [GRID_LAYOUT],
  gridConfigurations: [GRID_CONFIGURATION],
  globalScreenConfigurations: [GLOBAL_SCREEN_CONFIGURATION],
  videoStreams: [VIDEO_STREAM],
  mergingConfiguration: MERGING_CONFIGURATION,
  videoConfiguration: NIMBLE_CONFIGURATION,
  roundConfigurations: ROUND_CONFIGURATIONS,
  platforms: [PLATFORM, PLATFORM_NOT_IN_GROUP],
  procedureModels: [PROCEDURE_MODEL],
};

export const SYSTEM_LOG: SystemLog = {
  id: 2,
  action: SystemActionEnum.NEW_ALERT,
  type: LogTypeEnum.SYSTEM,
  site: 'local',
  time: '2024-02-18T10:29:40.072',
  parameters: [],
  code: 'SYSTEM-2',
};
