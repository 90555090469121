import { HStack } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import CustomSelect from '@components/common/inputs/CustomStyledSelect';
import CameraControl, { ControlState, GaugeStyleEnum } from '@components/video/cameraControl/CameraControl';
import CameraLadButtons from '@components/video/cameraControl/CameraLadButtons';
import CameraPerimButtons from '@components/video/cameraControl/CameraPerimButtons';
import CameraPerimReplay from '@components/video/cameraControl/CameraPerimReplay';
import { useAppSelector, useAppStore } from '@hooks/redux.hooks';
import { useCameraCommand } from '@hooks/useCameraCommand';
import { selectSelectedCameraCode } from '@redux/global/global.selector';
import { selectReplayActiveCameraConfigurations } from '@redux/replay/replay.selectors';
import { selectAllActiveCamerasConfiguration } from '@redux/sensors/sensors.selectors';
import {
  selectAllReplayActivePerimCameras,
  selectCameraStatusByUniqueCode,
} from '@redux/situation/monitoring.selectors';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { getDefaultFlux } from '@utils/sensors/camera/camera.utils';
import { takeAndDownloadScreenshotFromPlayer } from '@utils/sensors/camera/screenshot.utils';
import { getSensorUniqueCode, getSensorUniqueKey } from '@utils/sensors/sensors.utils';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';

import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';
import { CameraStatus } from '@/types/sensor/status.types';

type CameraControlWrapperState = {
  selectedLadCameraCode: string | null;
  selectedPerimeterCameraUniqueKey: string | null;
};

type OwnProps = {
  isDynamic?: boolean;
  replayMode?: boolean;
  defaultSelectedCameraCode?: string | null;
  uniqueKey: string;
};

export default function CameraControlWrapper({
  isDynamic,
  defaultSelectedCameraCode = null,
  replayMode = false,
  uniqueKey,
}: Readonly<OwnProps>) {
  const store = useAppStore();
  const { formatMessage } = useIntl();

  const allActiveLadCameras = useAppSelector(selectAllActiveCamerasConfiguration);
  const allReplayActiveLadCameras = useAppSelector(selectReplayActiveCameraConfigurations);

  const allReplayActivePerimCameras: PerimeterCamera[] = useAppSelector((state) =>
    selectAllReplayActivePerimCameras(state, true),
  );
  const ladCamerasList = replayMode ? allReplayActiveLadCameras : allActiveLadCameras;
  const periCamerasList = replayMode ? allReplayActivePerimCameras : [];

  const [selectedCamera, setSelectedCamera] = useReducer(
    (state: CameraControlWrapperState, newState: Partial<CameraControlWrapperState>): CameraControlWrapperState => ({
      ...state,
      ...newState,
    }),
    {
      selectedLadCameraCode: replayMode ? null : defaultSelectedCameraCode,
      selectedPerimeterCameraUniqueKey: null,
    },
  );

  const globalSelectedCameraCode = useAppSelector(selectSelectedCameraCode);
  useEffect(() => {
    if (isDynamic) {
      setSelectedCamera({ selectedLadCameraCode: globalSelectedCameraCode ?? defaultSelectedCameraCode });
    }
  }, [globalSelectedCameraCode, isDynamic, defaultSelectedCameraCode]);

  const playerId = `control-player-${uniqueKey}`;
  const downloadScreenshot = useCallback(
    (cameraName?: string) => {
      const situationTime = selectSituationTime(store.getState(), replayMode);
      takeAndDownloadScreenshotFromPlayer(playerId, situationTime, cameraName);
    },
    [store, replayMode, playerId],
  );

  const displayPeriButtons = !!selectedCamera.selectedPerimeterCameraUniqueKey;

  // Variables pour les caméras LAD uniquement et leurs boutons
  const cameraStatus: CameraStatus | null = useAppSelector(
    (state) => selectCameraStatusByUniqueCode(state, replayMode, selectedCamera.selectedLadCameraCode),
    isEqual,
  );
  const cameraConfiguration = cameraStatus?.configuration ?? null;

  const [ladControlState, setLadControlState] = useReducer(
    (state: ControlState, newState: Partial<ControlState>): ControlState => ({
      ...state,
      ...newState,
    }),
    {
      pip: false,
      displayedFlux: getDefaultFlux(cameraConfiguration),
      pipFlux:
        cameraConfiguration?.subCameras.filter((sc) => sc.code !== getDefaultFlux(cameraConfiguration)?.code) ?? [],
      displayCross: true,
      displayVirtualJoystick: !replayMode,
      previousSelectedCameraCode: selectedCamera.selectedLadCameraCode,
      iso: false,
      hybridFocus: false,
      polarity: false,
      whiteBalance: false,
      round: false,
      simpleRoundSpeed: null,
      gaugeStyle: GaugeStyleEnum.GAUGE,
    },
  );

  const displayedVideoStatus = cameraStatus?.videoStatuses.find(
    (vs) => vs.id === ladControlState.displayedFlux?.cameraId,
  );
  const useCamera = useCameraCommand({
    cameraStatus: cameraStatus,
    openedSubCameraId: displayedVideoStatus?.id,
  });

  return (
    <Box
      backgroundColor="neutral.black"
      minHeight={0}
      minWidth={0}
      width="100%"
      height="100%"
      key={uniqueKey}
      position="relative"
      userSelect="none"
    >
      {selectedCamera.selectedLadCameraCode !== null ? (
        <CameraControl
          replayMode={replayMode}
          selectedCameraCode={selectedCamera.selectedLadCameraCode}
          uniqueKey={uniqueKey}
          cameraStatus={cameraStatus}
          controlState={ladControlState}
          setControlState={setLadControlState}
          useCamera={useCamera}
        />
      ) : (
        <CameraPerimReplay
          selectedPerimeterCameraUniqueKey={selectedCamera.selectedPerimeterCameraUniqueKey}
          playerId={playerId}
        />
      )}
      <HStack position="absolute" bottom={3} gap={1} width="100%" height="52px" alignItems="center" paddingX={2}>
        <CustomSelect
          isClearable
          menuPlacement="top"
          width={replayMode ? 400 : 375}
          size="md"
          key={selectedCamera.selectedLadCameraCode ?? undefined}
          value={selectedCamera.selectedLadCameraCode ?? undefined}
          placeholder={formatMessage({ id: `cameras.selectLADCamera` })}
          options={ladCamerasList.map((cam) => ({
            label: cam.name,
            value: getSensorUniqueCode(cam),
          }))}
          noOptionsMessage="cameras.noLADCamera"
          onChange={(value) =>
            setSelectedCamera({
              selectedLadCameraCode: value,
              selectedPerimeterCameraUniqueKey: null,
            })
          }
        />

        {replayMode && (
          <CustomSelect
            isClearable
            menuPlacement="top"
            width={400}
            size="md"
            key={selectedCamera.selectedPerimeterCameraUniqueKey ?? undefined}
            value={selectedCamera.selectedPerimeterCameraUniqueKey ?? undefined}
            placeholder={formatMessage({ id: `cameras.selectPerimeterCamera` })}
            options={periCamerasList.map((cam) => ({
              label: cam.name,
              value: getSensorUniqueKey(cam),
            }))}
            noOptionsMessage="cameras.noPerimCamera"
            onChange={(value) =>
              setSelectedCamera({
                selectedLadCameraCode: null,
                selectedPerimeterCameraUniqueKey: value,
              })
            }
          />
        )}

        {displayPeriButtons ? (
          <CameraPerimButtons
            selectedPerimeterCameraUniqueKey={selectedCamera.selectedPerimeterCameraUniqueKey}
            downloadScreenshot={downloadScreenshot}
          />
        ) : (
          <CameraLadButtons
            cameraStatus={cameraStatus}
            controlState={ladControlState}
            setControlState={setLadControlState}
            selectedCameraCode={selectedCamera.selectedLadCameraCode}
            useCamera={useCamera}
            uniqueKey={uniqueKey}
            replayMode={replayMode}
            downloadScreenshot={() => downloadScreenshot(cameraStatus?.configuration.name)}
          />
        )}
      </HStack>
    </Box>
  );
}
