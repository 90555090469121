import { appIntl } from '@components/locale/IntlGlobalProvider';

import { UserLight } from '@/types/authent/login.types';
import { HoloLog, LogAction, LogTypeEnum, SystemActionEnum } from '@/types/c2/logs.type';

function getActionLabel(action: LogAction, parameters: string[]): string {
  const params = { ...parameters } as { [p: number]: string };
  return appIntl().formatMessage({ id: `components.logbook.actions.${action}` }, params);
}

export function getLogContent(log: HoloLog): string {
  switch (log.type) {
    case LogTypeEnum.EVENT:
      return log.name;
    case LogTypeEnum.INSTRUCTION:
      return log.instruction;
    case LogTypeEnum.SYSTEM: {
      if (log.action === SystemActionEnum.NEW_ALERT) {
        return getActionLabel(log.action, [
          ...log.parameters.slice(0, 3),
          appIntl().formatMessage({ id: `components.logbook.new_alert.${log.parameters[3]}` }),
          ...log.parameters.slice(4),
        ]);
      } else {
        return getActionLabel(log.action, log.parameters);
      }
    }
    case LogTypeEnum.USER_ACTION:
      return getActionLabel(log.action, log.parameters);
    default:
      return '';
  }
}

export function getLogAuthor(log: HoloLog): UserLight | null {
  const defaultAuthor = (nameKey: string): UserLight => ({
    id: 0,
    name: appIntl().formatMessage({ id: nameKey }),
    firstName: '',
    login: '',
    email: '',
  });

  switch (log.type) {
    case LogTypeEnum.EVENT:
    case LogTypeEnum.INSTRUCTION:
    case LogTypeEnum.USER_ACTION:
      return log.author ?? defaultAuthor('authent.user.unknownUser');
    case LogTypeEnum.SYSTEM:
      return defaultAuthor('authent.user.system');
    default:
      return null;
  }
}
