import { MapEventType } from 'mapbox-gl';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl';

import { LayerNameEnum } from '@/types/map.types';

export const useMapLayerMouseEvent = (
  interactionType: MapEventType,
  listener: (event: any) => void, // eslint-disable-line
  layer?: LayerNameEnum | LayerNameEnum[],
) => {
  const { current: mapbox } = useMap();

  useEffect(() => {
    if (mapbox) {
      if (!layer) {
        mapbox.on(interactionType, listener);
      } else {
        mapbox.on(interactionType, layer, listener as never);
      }
    }
    return () => {
      if (mapbox) {
        if (!layer) {
          mapbox.off(interactionType, listener);
        } else {
          mapbox.off(interactionType, layer, listener as never);
        }
      }
    };
  }, [interactionType, layer, listener, mapbox]);
};
