import { codeExtractor, idExtractor } from '@utils/schema.utils';
import { schema } from 'normalizr';

import { DenormalizedProfile, GroupWithUsers, Profile } from '@/types/authent/groups.types';
import { User } from '@/types/authent/login.types';
import { Role } from '@/types/authent/roles.types';
import { Site } from '@/types/authent/sites.types';
import Entity = schema.Entity;

const roleSchema: Entity<Role> = new Entity('roles', {}, { idAttribute: (role) => role.roleName });

const fullProfileSchema: Entity<DenormalizedProfile> = new Entity(
  'profiles',
  { roles: [roleSchema] },
  { idAttribute: idExtractor<DenormalizedProfile> },
);

const profileSchema: Entity<Profile> = new Entity('profiles', {}, { idAttribute: idExtractor<Profile> });

const siteSchema: Entity<Site> = new Entity('sites', {}, { idAttribute: codeExtractor<Site> });

const groupSchema: Entity<GroupWithUsers> = new Entity(
  'groups',
  { profile: profileSchema },
  { idAttribute: codeExtractor<GroupWithUsers> },
);

const userSchema: Entity<User> = new Entity(
  'users',
  { activeSite: siteSchema, sites: [siteSchema] },
  { idAttribute: idExtractor<User> },
);

const authentSchema = {
  groups: [groupSchema],
  profiles: [fullProfileSchema],
  roles: [roleSchema],
  users: [userSchema],
  sites: [siteSchema],
};

export { authentSchema, groupSchema, fullProfileSchema, roleSchema, siteSchema, userSchema };
