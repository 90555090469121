export enum MergingTypeEnum {
  OK = 'OK',
  OK_IF_DIFFERENT_SUB_SENSORS = 'OK_IF_DIFFERENT_SUB_SENSORS',
  KO_IF_DIFFERENT_ID = 'KO_IF_DIFFERENT_ID',
  COLLABORATIVE = 'COLLABORATIVE',
  NEVER = 'NEVER',
}

export interface MergingType {
  mergingTypeEnum: MergingTypeEnum;
}

export interface MergingConfiguration {
  id: number;
  site: string;
  mergingTypesBySensorCode?: {
    [key: string]: {
      [key: string]: MergingType;
    };
  };
}
