import { FunctionComponent, SVGProps } from 'react';

import { TargetStatusEnum } from '@/types/c2/c2.types';
import { FeatureTypeEnum } from '@/types/map.types';
import { SensorLikeEnum, SensorTypeEnum, SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { FlightPlanStatusEnum } from '@/types/utm/flightPlan.types';

import { DroneAlertLevelEnum } from './data/data.types';
import { ClassificationEnum } from './sensor/classification.types';
import { IdentificationEnum, ReliabilityEnum } from './sensor/identification.types';
import { SensorFamilyEnum } from './sensor/sensor.types';
import { SubSensorStatusTypeEnum } from './sensor/status.types';

// A chaque fois qu'on veut coder le fonctionnement d'un nouveau filtre, rajouter ici et dans le record
// featureEnumToFiltersKey un element correspondant à l'information qu'on veut comparer avec le code qui s'occupe de
// l'affichage de la feature en question (exemple : situation.selectors s'occupe des zones et utilise un objet de type
// AlertLevelEnum pour gérer les no fly zones)

export enum FilterTriggerEnum {
  TARGET_UNKNOWN = IdentificationEnum.UNKNOWN,
  TARGET_BIRD = IdentificationEnum.BIRD,
  TARGET_SHIP = IdentificationEnum.SHIP,
  TARGET_PERSON = IdentificationEnum.PERSON,
  TARGET_GROUND_VEHICLE = IdentificationEnum.GROUND_VEHICLE,
  TARGET_HELICOPTER = IdentificationEnum.HELICOPTER,
  TARGET_AIRCRAFT = IdentificationEnum.AIRCRAFT,
  TARGET_REMOTE_CONTROL = IdentificationEnum.REMOTE_CONTROL,
  TARGET_DRONE = IdentificationEnum.DRONE,
  TARGET_BUILDING = IdentificationEnum.BUILDING,

  TARGET_CLASSIFICATION_FRIEND = ClassificationEnum.FRIEND,
  TARGET_CLASSIFICATION_UNKNOWN = ClassificationEnum.UNKNOWN,
  TARGET_CLASSIFICATION_WATCH = ClassificationEnum.WATCH,
  TARGET_CLASSIFICATION_SUSPECT = ClassificationEnum.SUSPECT,
  TARGET_CLASSIFICATION_ENEMY = ClassificationEnum.ENEMY,

  TARGET_TRAJECTORY_UNKNOWN = `TRAJECTORY_${IdentificationEnum.UNKNOWN}`,
  TARGET_TRAJECTORY_BIRD = `TRAJECTORY_${IdentificationEnum.BIRD}`,
  TARGET_TRAJECTORY_SHIP = `TRAJECTORY_${IdentificationEnum.SHIP}`,
  TARGET_TRAJECTORY_PERSON = `TRAJECTORY_${IdentificationEnum.PERSON}`,
  TARGET_TRAJECTORY_GROUND_VEHICLE = `TRAJECTORY_${IdentificationEnum.GROUND_VEHICLE}`,
  TARGET_TRAJECTORY_HELICOPTER = `TRAJECTORY_${IdentificationEnum.HELICOPTER}`,
  TARGET_TRAJECTORY_AIRCRAFT = `TRAJECTORY_${IdentificationEnum.AIRCRAFT}`,
  TARGET_TRAJECTORY_REMOTE_CONTROL = `TRAJECTORY_${IdentificationEnum.REMOTE_CONTROL}`,
  TARGET_TRAJECTORY_DRONE = `TRAJECTORY_${IdentificationEnum.DRONE}`,
  TARGET_TRAJECTORY_BUILDING = `TRAJECTORY_${IdentificationEnum.BUILDING}`,
  TARGET_TRAJECTORY_SELECTED = 'TRAJECTORY_TARGET_SELECTED',

  ZONE_OBSERVATION = DroneAlertLevelEnum.DRONE_INFO,
  ZONE_VIGILANCE = DroneAlertLevelEnum.DRONE_WARNING,
  ZONE_NO_INIT_ZONE = FeatureTypeEnum.NO_INIT_ZONE,
  ZONE_INIT_MASKING_ZONE = FeatureTypeEnum.INITIALIZATION_MASKING_ZONE,
  ZONE_CRITICAL = DroneAlertLevelEnum.DRONE_CRITICAL,

  FLIGHT_PLAN_ACTIVE = FlightPlanStatusEnum.ACTIVE,
  FLIGHT_PLAN_UPCOMING = FlightPlanStatusEnum.UPCOMING,

  TARGET_RELIABILITY_SURE = ReliabilityEnum.SURE,
  TARGET_RELIABILITY_UNSURE = ReliabilityEnum.UNSURE,

  TARGET_STATUS_LOST = TargetStatusEnum.LOST,

  C_UAS_ADSB = SensorTypeEnum.ADSB,
  C_UAS_AVISO = SensorTypeEnum.AVISO,
  C_UAS_AEROSCOPE = SensorTypeEnum.AEROSCOPE,
  C_UAS_SENID = SensorTypeEnum.SENID,
  C_UAS_SKYEYE = SensorTypeEnum.SKY_EYE,
  C_UAS_HOLODRONE = SensorTypeEnum.HOLODRONE,
  C_UAS_HOLOPTICS = SensorTypeEnum.HOLOPTICS,
  C_UAS_ARDRONIS = SensorTypeEnum.ARDRONIS,
  C_UAS_SPEXER = SensorTypeEnum.SPEXER,
  C_UAS_XENTA = SensorTypeEnum.XENTA,
  C_UAS_ZMER = SensorTypeEnum.ZMER,

  PERIMETER_SEGMENT = SubSensorStatusTypeEnum.AUTOMATON_SEGMENT,
  AUTOMATON_CABINET = SubSensorTypeEnum.AUTOMATON_CABINET,
  AUTOMATON = SensorTypeEnum.AUTOMATON,
  PERIMETER_CAMERA = SensorLikeEnum.PERIMETER_CAMERA,

  RF_DETECTION_DRONE_UAV = IdentificationEnum.DRONE,
  RF_DETECTION_REMOTE_CONTROL = IdentificationEnum.REMOTE_CONTROL,
  RF_DETECTION_UNKNOWN = IdentificationEnum.UNKNOWN,
}

export enum FilterTypeEnum {
  TARGET_TYPE = 'TARGET_TYPE',
  TARGET_CLASSIFICATION = 'TARGET_CLASSIFICATION',
  TARGET_TRAJECTORY = 'TARGET_TRAJECTORY',
  ZONE = 'ZONE',
  FLIGHT_PLAN = 'FLIGHT_PLAN',
  TARGET_RELIABILITY = 'TARGET_RELIABILITY',
  C_UAS_SENSOR = 'C-UAS_SENSOR',
  PERIMETER_SENSOR = 'PERIMETER_SENSOR',
  TARGET_STATUS = 'TARGET_STATUS',
  RF_DETECTION_TYPE = 'RF_DETECTION_TYPE',
}

export const filterTriggerToFilterKey: Record<FilterTypeEnum, Partial<Record<FilterTriggerEnum, keyof MapFilters>>> = {
  [FilterTypeEnum.TARGET_TYPE]: {
    [FilterTriggerEnum.TARGET_UNKNOWN]: 'displayTargetUnknown',
    [FilterTriggerEnum.TARGET_BIRD]: 'displayTargetBird',
    [FilterTriggerEnum.TARGET_SHIP]: 'displayTargetShip',
    [FilterTriggerEnum.TARGET_PERSON]: 'displayTargetPerson',
    [FilterTriggerEnum.TARGET_GROUND_VEHICLE]: 'displayTargetVehicle',
    [FilterTriggerEnum.TARGET_HELICOPTER]: 'displayTargetHelicopter',
    [FilterTriggerEnum.TARGET_AIRCRAFT]: 'displayTargetAircraft',
    [FilterTriggerEnum.TARGET_REMOTE_CONTROL]: 'displayTargetRemote',
    [FilterTriggerEnum.TARGET_DRONE]: 'displayTargetDrone',
    [FilterTriggerEnum.TARGET_BUILDING]: 'displayTargetTestBeacon',
  },
  [FilterTypeEnum.TARGET_CLASSIFICATION]: {
    [FilterTriggerEnum.TARGET_CLASSIFICATION_FRIEND]: 'displayDangerLevelAuthorized',
    [FilterTriggerEnum.TARGET_CLASSIFICATION_UNKNOWN]: 'displayDangerLevelUnknown',
    [FilterTriggerEnum.TARGET_CLASSIFICATION_WATCH]: 'displayDangerLevelFriend',
    [FilterTriggerEnum.TARGET_CLASSIFICATION_SUSPECT]: 'displayDangerLevelSuspect',
    [FilterTriggerEnum.TARGET_CLASSIFICATION_ENEMY]: 'displayDangerLevelEnemy',
  },
  [FilterTypeEnum.TARGET_TRAJECTORY]: {
    [FilterTriggerEnum.TARGET_TRAJECTORY_UNKNOWN]: 'displayTrajectoryUnknown',
    [FilterTriggerEnum.TARGET_TRAJECTORY_BIRD]: 'displayTrajectoryBird',
    [FilterTriggerEnum.TARGET_TRAJECTORY_SHIP]: 'displayTrajectoryShip',
    [FilterTriggerEnum.TARGET_TRAJECTORY_PERSON]: 'displayTrajectoryPerson',
    [FilterTriggerEnum.TARGET_TRAJECTORY_GROUND_VEHICLE]: 'displayTrajectoryVehicle',
    [FilterTriggerEnum.TARGET_TRAJECTORY_HELICOPTER]: 'displayTrajectoryHelicopter',
    [FilterTriggerEnum.TARGET_TRAJECTORY_AIRCRAFT]: 'displayTrajectoryAircraft',
    [FilterTriggerEnum.TARGET_TRAJECTORY_REMOTE_CONTROL]: 'displayTrajectoryRemoteControl',
    [FilterTriggerEnum.TARGET_TRAJECTORY_DRONE]: 'displayTrajectoryDrone',
    [FilterTriggerEnum.TARGET_TRAJECTORY_BUILDING]: 'displayTrajectoryTestBeacon',
    [FilterTriggerEnum.TARGET_TRAJECTORY_SELECTED]: 'displayTrajectoryPinnedTarget',
  },
  [FilterTypeEnum.ZONE]: {
    [FilterTriggerEnum.ZONE_OBSERVATION]: 'displayDangerZoneObservation',
    [FilterTriggerEnum.ZONE_VIGILANCE]: 'displayDangerZoneVigilance',
    [FilterTriggerEnum.ZONE_CRITICAL]: 'displayDangerZoneCritical',
    [FilterTriggerEnum.ZONE_NO_INIT_ZONE]: 'displayRadarZoneNoInitZone',
    [FilterTriggerEnum.ZONE_INIT_MASKING_ZONE]: 'displayRadarZoneInitMaskingZone',
  },
  [FilterTypeEnum.FLIGHT_PLAN]: {
    [FilterTriggerEnum.FLIGHT_PLAN_ACTIVE]: 'displayFlightplanActive',
    [FilterTriggerEnum.FLIGHT_PLAN_UPCOMING]: 'displayFlightplanUpcoming',
  },
  [FilterTypeEnum.TARGET_RELIABILITY]: {
    [FilterTriggerEnum.TARGET_RELIABILITY_SURE]: 'displayReliabilitySafe',
    [FilterTriggerEnum.TARGET_RELIABILITY_UNSURE]: 'displayReliabilityUnsafe',
  },
  [FilterTypeEnum.C_UAS_SENSOR]: {
    [FilterTriggerEnum.C_UAS_ADSB]: 'displayLadSensorAdsb',
    [FilterTriggerEnum.C_UAS_AVISO]: 'displayLadSensorAviso',
    [FilterTriggerEnum.C_UAS_AEROSCOPE]: 'displayLadSensorAeroscope',
    [FilterTriggerEnum.C_UAS_SKYEYE]: 'displayLadSensorSkyeye',
    [FilterTriggerEnum.C_UAS_HOLODRONE]: 'displayLadSensorHolodrone',
    [FilterTriggerEnum.C_UAS_HOLOPTICS]: 'displayLadSensorHoloptics',
    [FilterTriggerEnum.C_UAS_SPEXER]: 'displayLadSensorSpexer',
    [FilterTriggerEnum.C_UAS_XENTA]: 'displayLadSensorXenta',
    [FilterTriggerEnum.C_UAS_ARDRONIS]: 'displayLadSensorArdronis',
    [FilterTriggerEnum.C_UAS_SENID]: 'displayLadSensorSenid',
    [FilterTriggerEnum.C_UAS_ZMER]: 'displayLadSensorZmer',
  },
  [FilterTypeEnum.PERIMETER_SENSOR]: {
    [FilterTriggerEnum.PERIMETER_SEGMENT]: 'displayPerimeterSensorSegment',
    [FilterTriggerEnum.PERIMETER_CAMERA]: 'displayPerimeterSensorCamera',
  },
  [FilterTypeEnum.TARGET_STATUS]: {
    [FilterTriggerEnum.TARGET_STATUS_LOST]: 'displayOtherInfoDetectionTargetLost',
  },
  [FilterTypeEnum.RF_DETECTION_TYPE]: {
    [FilterTriggerEnum.RF_DETECTION_DRONE_UAV]: 'displayRfDetectionDroneUav',
    [FilterTriggerEnum.RF_DETECTION_REMOTE_CONTROL]: 'displayRfDetectionRemoteControl',
    [FilterTriggerEnum.RF_DETECTION_UNKNOWN]: 'displayRfDetectionUnknown',
  },
};

export function filterKeyToFeatureEnum(filterKey: keyof MapFilters, filterType: FilterTypeEnum): string | undefined {
  return (Object.keys(filterTriggerToFilterKey[filterType]) as Array<FilterTriggerEnum>).find(
    (keyTriggerEnum) => filterTriggerToFilterKey[filterType][keyTriggerEnum] === filterKey,
  );
}

export function filterKeyToFilterTypeEnum(filterKey: keyof MapFilters): FilterTypeEnum | undefined {
  return (Object.keys(filterTriggerToFilterKey) as Array<FilterTypeEnum>).find((keyTriggerEnum) =>
    Object.values(filterTriggerToFilterKey[keyTriggerEnum]).some((key) => key === filterKey),
  );
}

export type FilterOption = {
  key: keyof MapFilters;
  label: string;
  Image?: FunctionComponent<SVGProps<SVGSVGElement>>;
  sensorFamily?: SensorFamilyEnum;
};

export type MapFiltersDto = {
  // Target section
  displayTargetDrone?: boolean;
  displayTargetRemote?: boolean;
  displayTargetAircraft?: boolean;
  displayTargetHelicopter?: boolean;
  displayTargetBird?: boolean;
  displayTargetVehicle?: boolean;
  displayTargetShip?: boolean;
  displayTargetPerson?: boolean;
  displayTargetTestBeacon?: boolean;
  displayTargetUnknown?: boolean;

  // Trajectory section
  displayTrajectoryDrone?: boolean;
  displayTrajectoryRemoteControl?: boolean;
  displayTrajectoryAircraft?: boolean;
  displayTrajectoryHelicopter?: boolean;
  displayTrajectoryBird?: boolean;
  displayTrajectoryVehicle?: boolean;
  displayTrajectoryShip?: boolean;
  displayTrajectoryPerson?: boolean;
  displayTrajectoryTestBeacon?: boolean;
  displayTrajectoryUnknown?: boolean;
  displayTrajectoryPinnedTarget?: boolean;

  // RF detection section
  displayRfDetectionDroneUav?: boolean;
  displayRfDetectionRemoteControl?: boolean;
  displayRfDetectionUnknown?: boolean;

  // Reliability section
  displayReliabilitySafe?: boolean;
  displayReliabilityUnsafe?: boolean;

  // Danger level section
  displayDangerLevelAuthorized?: boolean;
  displayDangerLevelUnknown?: boolean;
  displayDangerLevelFriend?: boolean;
  displayDangerLevelSuspect?: boolean;
  displayDangerLevelEnemy?: boolean;

  // LAD sensor section
  displayLadSensorHolodrone?: boolean;
  displayLadSensorSenid?: boolean;
  displayLadSensorAeroscope?: boolean;
  displayLadSensorAdsb?: boolean;
  displayLadSensorArdronis?: boolean;
  displayLadSensorSkyeye?: boolean;
  displayLadSensorZmer?: boolean;
  displayLadSensorHoloptics?: boolean;
  displayLadSensorSpexer?: boolean;
  displayLadSensorXenta?: boolean;
  displayLadSensorAviso?: boolean;

  // Perimeter sensor section
  displayPerimeterSensorAutomaton?: boolean;
  displayPerimeterSensorCabinet?: boolean;
  displayPerimeterSensorSegment?: boolean;
  displayPerimeterSensorCamera?: boolean;

  // Sensor info section
  displaySensorInfoPosition?: boolean;
  displaySensorInfoCoverage?: boolean;
  displaySensorInfoPerimeterCameraFov?: boolean;
  displaySensorInfoLadCameraFov?: boolean;
  displaySensorInfoDisabledSensor?: boolean;

  // RadarZone section
  displayRadarZoneNoInitZone?: boolean;
  displayRadarZoneInitMaskingZone?: boolean;

  // DangerZine section
  displayDangerZoneObservation?: boolean;
  displayDangerZoneVigilance?: boolean;
  displayDangerZoneCritical?: boolean;

  // Flightplan section
  displayFlightplanActive?: boolean;
  displayFlightplanUpcoming?: boolean;

  // OtherInfo section
  displayOtherInfoEvent?: boolean;
  displayOtherInfoPoi?: boolean;
  displayOtherInfoZoi?: boolean;
  displayOtherInfoDetectionTargetLost?: boolean;

  // MapLayer section
  displayMapLayerAeronautics?: boolean;
};

export type MapFilters = {
  // Target section
  displayTargetDrone: boolean;
  displayTargetRemote: boolean;
  displayTargetAircraft: boolean;
  displayTargetHelicopter: boolean;
  displayTargetBird: boolean;
  displayTargetVehicle: boolean;
  displayTargetShip: boolean;
  displayTargetPerson: boolean;
  displayTargetTestBeacon: boolean;
  displayTargetUnknown: boolean;

  // Trajectory section
  displayTrajectoryDrone: boolean;
  displayTrajectoryRemoteControl: boolean;
  displayTrajectoryAircraft: boolean;
  displayTrajectoryHelicopter: boolean;
  displayTrajectoryBird: boolean;
  displayTrajectoryVehicle: boolean;
  displayTrajectoryShip: boolean;
  displayTrajectoryPerson: boolean;
  displayTrajectoryTestBeacon: boolean;
  displayTrajectoryUnknown: boolean;
  displayTrajectoryPinnedTarget: boolean;

  // RF detection section
  displayRfDetectionDroneUav: boolean;
  displayRfDetectionRemoteControl: boolean;
  displayRfDetectionUnknown: boolean;

  // Reliability section
  displayReliabilitySafe: boolean;
  displayReliabilityUnsafe: boolean;

  // Danger level section
  displayDangerLevelAuthorized: boolean;
  displayDangerLevelUnknown: boolean;
  displayDangerLevelFriend: boolean;
  displayDangerLevelSuspect: boolean;
  displayDangerLevelEnemy: boolean;

  // LAD sensor section
  displayLadSensorHolodrone: boolean;
  displayLadSensorSenid: boolean;
  displayLadSensorAeroscope: boolean;
  displayLadSensorAdsb: boolean;
  displayLadSensorArdronis: boolean;
  displayLadSensorSkyeye: boolean;
  displayLadSensorZmer: boolean;
  displayLadSensorHoloptics: boolean;
  displayLadSensorSpexer: boolean;
  displayLadSensorXenta: boolean;
  displayLadSensorAviso: boolean;

  // Perimeter sensor section
  displayPerimeterSensorAutomaton: boolean;
  displayPerimeterSensorCabinet: boolean;
  displayPerimeterSensorSegment: boolean;
  displayPerimeterSensorCamera: boolean;

  // Sensor info section
  displaySensorInfoPosition: boolean;
  displaySensorInfoCoverage: boolean;
  displaySensorInfoPerimeterCameraFov: boolean;
  displaySensorInfoLadCameraFov: boolean;
  displaySensorInfoDisabledSensor: boolean;

  // RadarZone section
  displayRadarZoneNoInitZone: boolean;
  displayRadarZoneInitMaskingZone: boolean;

  // DangerZone section
  displayDangerZoneObservation: boolean;
  displayDangerZoneVigilance: boolean;
  displayDangerZoneCritical: boolean;

  // Flightplan section
  displayFlightplanActive: boolean;
  displayFlightplanUpcoming: boolean;

  // OtherInfo section
  displayOtherInfoEvent: boolean;
  displayOtherInfoPoi: boolean;
  displayOtherInfoZoi: boolean;
  displayOtherInfoDetectionTargetLost: boolean;

  // MapLayer section
  displayMapLayerAeronautics: boolean;
};
