import { EventTooltipData } from '@components/map/tooltips/EventTooltip';
import { LabelTooltipData } from '@components/map/tooltips/LabelTooltip';
import { NoFlyZoneTooltipData } from '@components/map/tooltips/NoFlyZoneTooltip';
import { PoiTooltipData } from '@components/map/tooltips/PoiTooltip';
import { TargetTooltipData } from '@components/map/tooltips/TargetTooltip';

import { Coordinates, TrackLight } from './commons/commons.types';
import { NotificationTypeEnum } from './data/data.types';
import {
  AutomatonSectionNames,
  ExtendedSensor,
  GetSensorConfiguration,
  GetSubSensorConfiguration,
  SensorTypeEnum,
  SensorWithSubSensor,
  SubSensorTypeEnum,
} from './sensor/configuration.types';
import { PerimeterCamera } from './sensor/perimeterCamera.types';
import { GetSubSensorStatus, MonitoringSensor, SensorStatusEnum } from './sensor/status.types';

export enum MapIdEnum {
  LAD = 'LAD',
  LIVE = 'LIVE',
  REPLAY = 'REPLAY',
}

export enum FeatureTypeEnum {
  AUTOMATON_SECTION_ENDING = 'automaton-section-ending',
  AUTOMATON_SECTION_ICON = 'automaton-section-icon',
  AUTOMATON_SECTION_LABEL = 'automaton-section-label',
  AUTOMATON_SECTION_LINE = 'automaton-section-line',
  EVENT_ICON = 'event-icon',
  FLIGHT_PLAN = 'flight-plan',
  INITIALIZATION_MASKING_ZONE = 'initialization-masking-zone',
  INITIALIZATION_MASKING_ZONE_MARGIN = 'initialization-masking-zone-margin',
  LAD_CAMERA_AZIMUTH = 'lad-camera-azimuth',
  LAD_CAMERA_SECTOR = 'lad-camera-sector',
  MEASURE = 'measure',
  NO_INIT_ZONE = 'no-init-zone',
  NO_FLY_ZONE = 'no-fly-zone',
  NO_FLY_ZONE_3D = 'no-fly-zone-3d',
  PERIMETER_CAMERA_AZIMUTH = 'perimeter-camera-azimuth',
  PERIMETER_CAMERA_LABEL = 'perimeter-camera-label',
  PERIMETER_CAMERA_SECTOR = 'perimeter-camera-sector',
  PERIMETER_LINK = 'perimeter-link',
  PERIMETER_PORTAL = 'perimeter-portal',
  POI_ICON = 'point-of-interest-icon',
  RADAR_COVERAGE = 'radar-coverage',
  ROUND_SECTION_LINE = 'round-section-line',
  SENSOR_ICON = 'sensor-icon',
  SENSOR_LABEL = 'sensor-label',
  STROBE_AZIMUTH = 'strobe-azimuth',
  STROBE_SECTOR = 'strobe',
  TARGET_CROSS = 'target-cross',
  TARGET_ICON = 'target-icon',
  TARGET_LINK = 'target-link',
  TARGET_TRAJECTORY = 'target-trajectory',
}

export enum LayerNameEnum {
  AUTOMATON_SECTIONS = 'automaton-sections-layer',
  AUTOMATON_SECTION_EXTREMITIES = 'automaton-section-extremities-layer',
  AUTOMATON_SECTION_ICONS = 'automaton-section-icons-layer',
  AUTOMATON_SECTION_LABELS = 'automaton-section-label-layer',
  AUTOMATON_SECTION_OUTLINES = 'automaton-section-outlines-layer',
  DRAW_ZONE = 'draw-zone-layer',
  EVENTS = 'events-layer',
  FLIGHT_PLAN_FILLS = 'flight-plan-fills-layer',
  FLIGHT_PLAN_OUTLINES = 'flight-plan-outlines-layer',
  INITIALIZATION_MASKING_ZONE_FILLS = 'initialization-masking-zone-fills-layer',
  INITIALIZATION_MASKING_ZONE_MARGINS = 'initialization-masking-margins-layer',
  INITIALIZATION_MASKING_ZONE_MARGIN_OUTLINES = 'initialization-masking-margin-outlines-layer',
  INITIALIZATION_MASKING_ZONE_OUTLINES = 'initialization-masking-outlines-layer',
  LAD_CAMERA_AZIMUTHS = 'lad-camera-azimuths-layer',
  LAD_CAMERA_SECTORS = 'lad-camera-sectors-layer',
  LAD_CAMERA_SECTOR_OUTLINES = 'lad-camera-sector-outlines-layer',
  MEASURE_LINES = 'measure-lines-layer',
  MEASURE_POINTS = 'measure-points-layer',
  MEASURE_POINT_LABELS = 'measure-point-labels-layer',
  NO_FLY_ZONE_FILLS = 'no-fly-zone-fills-layer',
  NO_FLY_ZONE_OUTLINES = 'no-fly-zone-outlines-layer',
  NO_INIT_ZONE_FILLS = 'no-init-zone-fills-layer',
  NO_INIT_ZONE_OUTLINES = 'no-init-zone-outlines-layer',
  PERIMETER_CAMERA_AZIMUTHS = 'perimeter-camera-azimuths-layer',
  PERIMETER_CAMERA_LABELS = 'perimeter-camera-labels-layer',
  PERIMETER_CAMERA_SECTOR_FILLS = 'perimeter-camera-sector-fills-layer',
  PERIMETER_CAMERA_SECTOR_OUTLINES = 'perimeter-camera-sector-outlines-layer',
  PERIMETER_LINKS = 'perimeter-links-layer',
  PERIMETER_LINK_OUTLINES = 'perimeter-link-outlines-layer',
  PERIMETER_PORTALS = 'perimeter-portals-layer',
  PERIMETER_SENSOR_ICONS = 'perimeter-sensor-icons-layer',
  PERIMETER_SENSOR_LABELS = 'perimeter-sensor-labels-layer',
  POI = 'points-of-interest-layer',
  RADAR_COVERAGE = 'radar-coverage-layer',
  ROUND_SECTIONS = 'round-sections-layer',
  SENSOR_CLUSTERS = 'sensor-clusters-layer',
  SENSOR_CLUSTER_LEAVES = 'sensor-cluster-leaves',
  SENSOR_CLUSTER_OVERLAY = 'sensor-cluster-overlay',
  SENSOR_ICONS = 'sensor-icons-layer',
  SENSOR_ICON_SELECTION = 'sensor-icon-selection-layer',
  SENSOR_LABELS = 'sensor-labels-layer',
  SENSOR_LABEL_SELECTION = 'sensor-label-selection-layer',
  STROBE_AZIMUTHS = 'strobe-azimuths-layer',
  STROBE_SECTORS = 'strobe-sectors-layer',
  TARGETS = 'targets-layer',
  TARGET_CROSSES = 'target-cross-layer',
  TARGET_LINKS = 'target-links-layer',
  TARGET_SELECTION = 'target-selection-layer',
  TARGET_TRAJECTORIES = 'target-trajectories-layer',
}

export const zoneLayers = [
  LayerNameEnum.INITIALIZATION_MASKING_ZONE_FILLS,
  LayerNameEnum.INITIALIZATION_MASKING_ZONE_OUTLINES,
  LayerNameEnum.NO_FLY_ZONE_FILLS,
  LayerNameEnum.NO_FLY_ZONE_OUTLINES,
  LayerNameEnum.NO_INIT_ZONE_FILLS,
  LayerNameEnum.NO_INIT_ZONE_OUTLINES,
];

export enum SourceNameEnum {
  AUTOMATON_SECTIONS = 'automaton-sections-source',
  EVENTS = 'events-source',
  FLIGHT_PLANS = 'flight-plans-source',
  LAD_CAMERAS = 'lad-cameras-source',
  MEASURES = 'measures-source',
  PERIMETER_CAMERAS = 'perimeter-cameras-source',
  PERIMETER_SENSORS = 'perimeter-sensors-source',
  POIS = 'point-of-interest-source',
  RADARS = 'radars-source',
  ROUNDS = 'rounds-source',
  SELECTION = 'selection-source',
  SENSORS = 'sensors-source',
  SENSORS_OVERLAY = 'sensors-overlay-source',
  SENSOR_LABELS = 'sensor-labels-source',
  STROBES = 'strobes-source',
  TARGETS = 'targets-source',
  ZONES = 'zones-source',
}

export interface FeatureType {
  type: FeatureTypeEnum | SubSensorTypeEnum.AUTOMATON_CABINET;
}

export type AnyTooltipData =
  | TargetTooltipData
  | EventTooltipData
  | LabelTooltipData
  | NoFlyZoneTooltipData
  | PoiTooltipData;

// Adapter le code dans DimensionControl si vous rajoutez une 3ème valeur dans l'enum. Les boolean utilisés
// supposent que l'enum a que 2 valeurs
export enum Dimension {
  '2D' = '2D',
  '3D' = '3D',
}

export interface RoundInfoData extends FeatureType {
  type: FeatureTypeEnum.SENSOR_ICON;
  featureValue: SensorUniqueCodes;
}

export interface TargetInfoData extends FeatureType {
  type: FeatureTypeEnum.TARGET_ICON;
  targetId: number | string;
}

export interface AutomatonSectionInfoData extends FeatureType {
  type:
    | FeatureTypeEnum.AUTOMATON_SECTION_ENDING
    | FeatureTypeEnum.AUTOMATON_SECTION_ICON
    | FeatureTypeEnum.AUTOMATON_SECTION_LABEL
    | FeatureTypeEnum.AUTOMATON_SECTION_LINE;
  featureValue: AutomatonSectionNames;
}

export interface PerimeterCameraInfoData extends FeatureType {
  type: FeatureTypeEnum.PERIMETER_CAMERA_SECTOR | FeatureTypeEnum.PERIMETER_CAMERA_LABEL;
  featureValue: PerimeterCamera;
}

export type SensorUniqueCodes = { sensorUniqueCode: string; parentUniqueCode?: string };

export interface SensorInfoData extends FeatureType {
  type: FeatureTypeEnum.SENSOR_ICON | FeatureTypeEnum.SENSOR_LABEL | SubSensorTypeEnum.AUTOMATON_CABINET;
  featureValue: SensorUniqueCodes;
}

export interface StrobeInfoData extends FeatureType {
  type: FeatureTypeEnum.STROBE_SECTOR;
  targetId: number | string;
}

export interface PopupPositionData {
  top: number;
  left: number;
  onClose: () => void;
}

export type InfoData =
  | RoundInfoData
  | TargetInfoData
  | AutomatonSectionInfoData
  | PerimeterCameraInfoData
  | SensorInfoData
  | StrobeInfoData;

export type EventPopupData = {
  coordinates: Coordinates | null;
  date: string;
};

export type HistoryPopupData =
  | {
      identifier: string;
      collaborativeId?: string;
      sensorType?: never;
      type: NotificationTypeEnum.DRONE;
      openingTime: Date;
    }
  | {
      identifier: string;
      collaborativeId?: never;
      sensorType: ExtendedSensor;
      type: NotificationTypeEnum.SENSOR;
      openingTime: Date;
    }
  | {
      identifier: string;
      collaborativeId?: never;
      sensorType: SubSensorTypeEnum.AUTOMATON_SEGMENT;
      type: NotificationTypeEnum.SEGMENT;
      openingTime: Date;
    };

export type ShareHoloviewPopupData = {
  site: string;
  trackIds: TrackLight[];
};

export type GpsQrCodeData = {
  coordinates: Coordinates;
  datetime?: string;
};

export enum PopupDataTypeEnum {
  EVENT = 'EVENT',
  HISTORY = 'HISTORY',
  INFO = 'INFO',
  REPLAY = 'REPLAY',
  RESET_SITUATION = 'RESET_SITUATION',
  CONFIRMATION_POPUP = 'CONFIRMATION_POPUP',
  VIDEO_EXPORT = 'VIDEO_EXPORT',
  ROUND = 'ROUND',
  SHARE_HOLOVIEW = 'SHARE_HOLOVIEW',
  GPS_QR_CODE = 'GPS_QR_CODE',
  MEASURE_INFO = 'MEASURE_INFO',
  ZONE = 'ZONE',
}

export type PopupDataType =
  | {
      type: PopupDataTypeEnum.EVENT;
      data: EventPopupData;
    }
  | {
      type: PopupDataTypeEnum.HISTORY;
      data: HistoryPopupData;
    }
  | {
      type: PopupDataTypeEnum.INFO;
      data: InfoData;
    }
  | {
      type: PopupDataTypeEnum.SHARE_HOLOVIEW;
      data: ShareHoloviewPopupData;
    }
  | {
      type:
        | null
        | PopupDataTypeEnum.REPLAY
        | PopupDataTypeEnum.RESET_SITUATION
        | PopupDataTypeEnum.VIDEO_EXPORT
        | PopupDataTypeEnum.MEASURE_INFO;
      data: null;
    }
  | {
      type: PopupDataTypeEnum.CONFIRMATION_POPUP;
      data: {
        title: string;
        description: string;
        submitLabel: string;
        width?: number;
        height?: number;
        onSubmit: () => void;
      };
    }
  | {
      type: PopupDataTypeEnum.ROUND;
      data: RoundInfoData;
    }
  | {
      type: PopupDataTypeEnum.GPS_QR_CODE;
      data: GpsQrCodeData;
    }
  | {
      type: PopupDataTypeEnum.ZONE;
      data: null;
    };

export type PopupControlType = {
  open: boolean;
  position: { x: number; y: number };
} & PopupDataType;

export type MapBoxGeometry = {
  type: string;
  //coordinates MapBox : [[longitude, latitude]][]
  coordinates: [[number, number]][];
};

export type MapBoxDrawEvent = {
  id: string;
  properties: object;
  geometry: MapBoxGeometry;
};

export enum ZoneFormDisplayedFormat {
  HEIGHT = 'height',
  ALTITUDE = 'altitude',
}

export const SENSORS_NOT_DISPLAYED_ON_MAP = [
  SensorTypeEnum.ARDRONIS,
  SensorTypeEnum.SENID,
  SensorTypeEnum.SKY_EYE,
  SensorTypeEnum.SPEXER,
] as const satisfies readonly SensorTypeEnum[];
export type SensorNotDisplayedOnMap = (typeof SENSORS_NOT_DISPLAYED_ON_MAP)[number];

export type SensorDisplayedOnMap = Exclude<SensorTypeEnum, SensorNotDisplayedOnMap>;
export type SensorLikeDisplayedOnMap = Exclude<ExtendedSensor, SensorNotDisplayedOnMap | 'AUTOMATON_SEGMENT'>;

export type SplitMonitoringSensors = {
  regularSensors: MonitoringSensor<SensorDisplayedOnMap>[];
  withSubSensors: MonitoringSensor<SensorWithSubSensor>[];
};

export type SubSensorInfo<Sensor extends SensorWithSubSensor> = {
  parentSensorConfig: GetSensorConfiguration<Sensor>;
  subSensorConfig: GetSubSensorConfiguration<Sensor>;
  subSensorStatus: GetSubSensorStatus<Sensor> | null;
};

export type SensorLikeIconBasicInfo = {
  active: boolean;
  maintenance: boolean;
  type: SensorLikeDisplayedOnMap;
  status: SensorStatusEnum | undefined;
};
