import { selectActiveUser } from '@redux/authent/authent.selectors';
import { updateNotifications } from '@redux/data/data.reducer';
import { selectAllOngoingNotifications, selectHasBeenInit } from '@redux/data/data.selectors';
import { addNotificationToQueue, updateSelectedDoubtCheckSegmentName } from '@redux/global/global.reducer';
import { selectAutomatedLad, selectContext } from '@redux/settings/settings.selectors';
import { RootState } from '@redux/store';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { settingApi } from '@services/config/setting.api';
import { isSegmentNotification } from '@utils/data/notification.utils';

import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { NotificationType, SegmentAlertLevelEnum } from '@/types/data/data.types';
import { RoutesEnum } from '@/types/routes.types';

export const dataMiddleware = createListenerMiddleware();

function extractNotificationsWithNewAlert(originalState: RootState, nextNotifications: NotificationType[]) {
  const currentNotifications = selectAllOngoingNotifications(originalState);
  return nextNotifications.filter((nextNotification) => {
    const oldAlertcount = currentNotifications.find(
      (currentNotification) => currentNotification.id === nextNotification.id,
    )?.alertCount;
    return !oldAlertcount || nextNotification.alertCount - oldAlertcount > 0;
  });
}

dataMiddleware.startListening({
  actionCreator: updateNotifications,
  effect: async ({ payload: nextNotifications }, listenerApi) => {
    const originalState = listenerApi.getOriginalState() as RootState;
    const hasBeenInit = selectHasBeenInit(originalState);

    // This middleware should be executed a single time for each situation and
    // for the first situation received we don't want to execute logic based on old alerts
    if (!hasBeenInit || sessionStorage.getItem('route') !== RoutesEnum.C2) {
      return;
    }
    const newState = listenerApi.getState() as RootState;

    const notificationsWithNewAlert = extractNotificationsWithNewAlert(originalState, nextNotifications);

    notificationsWithNewAlert.forEach((newNotification) => {
      listenerApi.dispatch(addNotificationToQueue(newNotification));
    });

    const isLADAutomated = selectAutomatedLad(newState);
    const isLive = selectContext(newState) === ContextEnum.LIVE;
    if (isLADAutomated && isLive) {
      const activeUser = selectActiveUser(newState);
      const perimeterNotificationWithNewAlert = notificationsWithNewAlert
        .filter(isSegmentNotification)
        .filter((newNotification) => newNotification.lastAlertLevel === SegmentAlertLevelEnum.PERIMETER_EFFRACTION);
      if (perimeterNotificationWithNewAlert.length > 0) {
        listenerApi.dispatch(
          settingApi.endpoints.patchContext.initiate({
            login: activeUser.login,
            context: ContextEnum.DOUBT_PERIM,
          }),
        );
        listenerApi.dispatch(updateSelectedDoubtCheckSegmentName(perimeterNotificationWithNewAlert[0].segmentName));
      }
    }
  },
});
