import { ReactComponent as Holosafe } from '@assets/holosafe-logo.svg';
import { ReactComponent as HolosafeFull } from '@assets/holosafe-logo-full.svg';
import { ReactComponent as ArrowIcon24 } from '@assets/icons/24x24/ic-arrow-24.svg';
import { ReactComponent as ConfigInstallIcon32 } from '@assets/icons/32x32/icMenu-configInstall-32.svg';
import { ReactComponent as DirectAccessIcon32 } from '@assets/icons/32x32/icMenu-directAccess-32.svg';
import { ReactComponent as GroupIcon32 } from '@assets/icons/32x32/icMenu-group-32.svg';
import { ReactComponent as LogBookIcon32 } from '@assets/icons/32x32/icMenu-mainCourante-32.svg';
import { ReactComponent as MonitoringIcon32 } from '@assets/icons/32x32/icMenu-monitoring-32.svg';
import { ReactComponent as NotificationIcon32 } from '@assets/icons/32x32/icMenu-notification-32.svg';
import { ReactComponent as ReturningVacationIcon32 } from '@assets/icons/32x32/icMenu-returningVacation-32.svg';
import { ReactComponent as ProfileIcon32 } from '@assets/icons/32x32/icMenu-user-32.svg';
import { ReactComponent as WorkStationIcon32 } from '@assets/icons/32x32/icMenu-workstation-32.svg';
import { useBoolean } from '@chakra-ui/hooks';
import { Icon } from '@chakra-ui/icon';
import { Box, Center, Divider, HStack, VStack } from '@chakra-ui/layout';
import MenuList from '@components/common/menu/MenuList';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateIsChangingShift } from '@redux/authent/authent.reducer';
import {
  hasActionRoleOnSensors,
  hasRoles,
  selectActiveUser,
  selectGlobalScreenConfigurationCode,
  selectIsChangingShift,
} from '@redux/authent/authent.selectors';
import { selectNewMessageEntry } from '@redux/chat/chat.selectors';
import { GlobalScreenConfigurationSelectors } from '@redux/config/config.selectors';
import { selectOngoingNotificationsCount } from '@redux/data/data.selectors';
import { selectNewLogBookEntry } from '@redux/global/global.selector';
import { selectContext } from '@redux/settings/settings.selectors';
import { RootState } from '@redux/store';
import { usePatchContextMutation } from '@services/config/setting.api';
import { Dispatch } from 'react';
import { useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { ActionEnum } from '@/types/commons/commons.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';

import { CockpitPanelIntlKeysEnum } from '../Cockpit';
import CockpitSidebarButton from './CockpitSidebarButton';

type Props = {
  state: CockpitPanelIntlKeysEnum;
  setState: Dispatch<CockpitPanelIntlKeysEnum>;
};

const ROLES = [
  RoleEnum.ACCESS_MSG,
  RoleEnum.ACCESS_GLOBAL_SCREEN_CONFIGURATION,
  RoleEnum.ACCESS_NOTIFICATION,
  RoleEnum.ACCESS_LAYOUT,
  RoleEnum.ACCESS_REPLAY,
  RoleEnum.ADMIN,
];

export default function CockpitSidebar({ state, setState }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const roleSet = useAppSelector((state: RootState) => hasRoles(state, ROLES));
  const hasAnyMaintenanceRole: boolean = useAppSelector((state: RootState) =>
    hasActionRoleOnSensors(state, ActionEnum.MAINTAIN),
  );
  const notificationsCount = useAppSelector(selectOngoingNotificationsCount);
  const newMessageEntry = useAppSelector(selectNewMessageEntry);
  const newLog = useAppSelector(selectNewLogBookEntry);
  const isChangingShift = useAppSelector(selectIsChangingShift);
  const currentContext = useAppSelector(selectContext);
  const { login } = useAppSelector(selectActiveUser);
  const currentGlobalScreenConfigurationCode = useAppSelector(selectGlobalScreenConfigurationCode);

  const globalScreenConfiguration =
    useAppSelector((state) =>
      GlobalScreenConfigurationSelectors.selectGlobalScreenConfigurationByCode(
        state,
        currentGlobalScreenConfigurationCode,
      ),
    ) ?? null;

  const updateIschangingShiftWithDispatch = useWithDispatch(updateIsChangingShift);
  const [patchContext] = usePatchContextMutation();

  const [maximised, setMaximised] = useBoolean(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useBoolean(false);

  function handlePatchContext(context: ContextEnum) {
    patchContext({ login: login, context })
      .unwrap()
      .then(() => setIsContextMenuOpen.off());
  }

  const contextList = Object.values(ContextEnum).filter((context) =>
    globalScreenConfiguration?.screenConfigurations.some((sc) => sc.context === context && sc.gridLayoutCode),
  );

  const menuItems = contextList.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: {
        label: `screenConfiguration.context.${currentValue}`,
        isDisabled: currentContext === currentValue,
        isActive: currentContext === currentValue,
        onAction: () => handlePatchContext(currentValue),
      },
    }),
    {},
  );

  return (
    <>
      {maximised && <Box width="96px" position="relative" height="100%" flexShrink={0} />}
      <VStack
        width={maximised ? '338px' : '96px'}
        height="100%"
        padding={3}
        paddingLeft={3}
        paddingTop={6}
        zIndex={10}
        gap={5}
        flexShrink={0}
        position={maximised ? 'absolute' : 'relative'}
        backgroundColor="neutral.black"
      >
        <VStack gap={5}>
          {contextList.length > 0 && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={isContextMenuOpen}
              icon={DirectAccessIcon32}
              label={formatMessage({ id: `components.sidebar.directAccess` })}
              onClick={setIsContextMenuOpen.toggle}
            >
              {isContextMenuOpen && globalScreenConfiguration && (
                <Box position="absolute" left="calc(100% + var(--chakra-space-3))" top="21px">
                  <MenuList
                    parentLabel="components.sidebar.directAccess"
                    handleClick={(_, value) => value?.onAction && value.onAction()}
                    menuItems={menuItems}
                    depth={0}
                    selectedOptions={[`${currentContext}`]}
                  />
                </Box>
              )}
            </CockpitSidebarButton>
          )}
          {roleSet?.has(RoleEnum.ACCESS_NOTIFICATION) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state === CockpitPanelIntlKeysEnum.NOTIFICATION}
              badge={notificationsCount}
              icon={NotificationIcon32}
              label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.NOTIFICATION}` })}
              onClick={() => setState(CockpitPanelIntlKeysEnum.NOTIFICATION)}
            />
          )}
          {(roleSet?.has(RoleEnum.ACCESS_REPLAY) || roleSet?.has(RoleEnum.ACCESS_MSG)) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state === CockpitPanelIntlKeysEnum.LOG_BOOK_AND_CHAT}
              icon={LogBookIcon32}
              badge={
                (roleSet.has(RoleEnum.ACCESS_MSG) && newMessageEntry) || (roleSet.has(RoleEnum.ACCESS_REPLAY) && newLog)
              }
              label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.LOG_BOOK_AND_CHAT}` })}
              onClick={() => setState(CockpitPanelIntlKeysEnum.LOG_BOOK_AND_CHAT)}
            />
          )}
          {hasAnyMaintenanceRole && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state === CockpitPanelIntlKeysEnum.MONITORING}
              icon={MonitoringIcon32}
              label={formatMessage({
                id: `components.sidebar.${CockpitPanelIntlKeysEnum.MONITORING}`,
              })}
              onClick={() => setState(CockpitPanelIntlKeysEnum.MONITORING)}
            />
          )}
        </VStack>
        <Center width="100%">
          <Divider borderColor="neutral.700" borderBottomWidth="2px" />
        </Center>
        <VStack gap={5}>
          {(roleSet?.has(RoleEnum.ACCESS_LAYOUT) || roleSet?.has(RoleEnum.ACCESS_GLOBAL_SCREEN_CONFIGURATION)) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state === CockpitPanelIntlKeysEnum.POST_CONFIGURATION}
              icon={WorkStationIcon32}
              label={formatMessage({
                id: `components.sidebar.${CockpitPanelIntlKeysEnum.POST_CONFIGURATION}`,
              })}
              onClick={() => setState(CockpitPanelIntlKeysEnum.POST_CONFIGURATION)}
            />
          )}
          {roleSet?.has(RoleEnum.ADMIN) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state === CockpitPanelIntlKeysEnum.SYSTEM_CONFIGURATION}
              icon={ConfigInstallIcon32}
              label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.SYSTEM_CONFIGURATION}` })}
              onClick={() => setState(CockpitPanelIntlKeysEnum.SYSTEM_CONFIGURATION)}
            />
          )}
          {roleSet?.has(RoleEnum.ADMIN) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state === CockpitPanelIntlKeysEnum.ADMIN}
              icon={GroupIcon32}
              label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.ADMIN}` })}
              onClick={() => setState(CockpitPanelIntlKeysEnum.ADMIN)}
            />
          )}
        </VStack>
        <Center width="100%">
          <Divider borderColor="neutral.700" borderBottomWidth="2px" />
        </Center>
        <VStack gap={5} alignItems="start">
          <CockpitSidebarButton
            isMaximised={maximised}
            isActive={state === CockpitPanelIntlKeysEnum.SETTINGS}
            icon={ProfileIcon32}
            label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.SETTINGS}` })}
            onClick={() => setState(CockpitPanelIntlKeysEnum.SETTINGS)}
          />
          <CockpitSidebarButton
            isMaximised={maximised}
            icon={ReturningVacationIcon32}
            isActive={isChangingShift}
            label={formatMessage({ id: `components.sidebar.changeShift` })}
            onClick={() => updateIschangingShiftWithDispatch(true)}
          />
        </VStack>
        <Center width="100%">
          <Divider borderColor="neutral.700" borderBottomWidth="2px" />
        </Center>
        {maximised ? (
          <HStack gap={4} width="100%" paddingLeft="4.5px" marginTop="auto">
            <Icon as={Holosafe} width="39px" height="28px" />
            <Icon as={HolosafeFull} height="28px" width="196px" />
          </HStack>
        ) : (
          <Icon as={Holosafe} width="39px" height="28px" alignSelf="center" marginTop="auto" />
        )}
        <Icon
          as={ArrowIcon24}
          color="neutral.500"
          alignSelf="start"
          marginLeft={1.5}
          transform={maximised ? 'rotate(180deg)' : undefined}
          width="24px"
          height="24px"
          _hover={{
            color: 'sky.500',
          }}
          onClick={setMaximised.toggle}
        />
      </VStack>
    </>
  );
}
