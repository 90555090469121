import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Input, Text } from '@chakra-ui/react';
import ErrorContainer from '@components/common/form/ErrorContainer';
import CustomChipList from '@components/common/inputs/CustomChipList';
import CustomInputNumber from '@components/common/inputs/CustomInputNumber';
import CustomSelect, { Option } from '@components/common/inputs/CustomStyledSelect';
import CustomSwitch from '@components/common/inputs/CustomSwitch';
import { MapCustomScrollbar } from '@components/common/layout/MapCustomScrollbar';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CoordinatesUnitEnum } from '@/types/commons/commons.types';
import { Zone2DTypeEnum, zoneLabelFromZone2DType } from '@/types/config/config.types';
import { ZoneFormDisplayedFormat } from '@/types/map.types';

import { ZoneForm } from './ZoneFormPopup';

type Props = {
  touched: FormikTouched<ZoneForm>;
  errors: FormikErrors<ZoneForm>;
  values: ZoneForm;
  isZoneTypeDisabled?: boolean;
  unit: CoordinatesUnitEnum;
  setUnit: Dispatch<SetStateAction<CoordinatesUnitEnum>>;
  handleHeightToAltitudeChange: () => void;
  setFieldValue: (
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<ZoneForm>>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<ZoneForm>>;
};

export default function LeftSideForm({
  touched,
  errors,
  values,
  isZoneTypeDisabled,
  unit,
  setUnit,
  handleHeightToAltitudeChange,
  setFieldTouched,
  setFieldValue,
}: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const chipOptions = Object.values(ZoneFormDisplayedFormat).map((key) => ({
    label: formatMessage({ id: `zone.${key}` }),
    value: key,
  }));

  function handleUnitChange(unit: string) {
    if (unit === CoordinatesUnitEnum.DECIMAL_DEGREES || unit === CoordinatesUnitEnum.DMS) {
      setUnit(unit);
    }
  }

  return (
    <Box height="100%" width="360px" flexShrink={0} backgroundColor="neutral.700">
      <MapCustomScrollbar height="100%" thumbColor="neutral.500" marginTop={1} marginBottom={1} margin={1}>
        <VStack height="100%" width="100%" padding={3} paddingRight={4} gap={3} alignItems="start">
          <FormControl isRequired>
            <VStack width="100%" gap={1} alignItems="start">
              <FormLabel color="neutral.300" fontWeight="medium" fontSize="16px" pointerEvents="none">
                <FormattedMessage id="zone.name" />
              </FormLabel>
              <Field
                as={Input}
                id="zoneName"
                placeholder={formatMessage({ id: 'zone.name' })}
                name="zoneName"
                height="48px"
                type="text"
                isInvalid={touched.zoneName && !!errors.zoneName}
              />
              <ErrorContainer error={errors.zoneName} touched={touched.zoneName} />
            </VStack>
          </FormControl>
          <FormControl id="zoneType" isRequired isInvalid={!!errors.zoneType}>
            <VStack width="100%" gap={1} alignItems="start">
              <Text color="neutral.300" size="md" pointerEvents="none">
                <FormattedMessage id="zone.type" />
              </Text>
              <CustomSelect
                isClearable={false}
                isDisabled={isZoneTypeDisabled}
                menuPlacement="bottom"
                variant="dark"
                width={312}
                options={Object.values(Zone2DTypeEnum).map((key) => ({
                  label: formatMessage({ id: `filters.${zoneLabelFromZone2DType[key]}` }),
                  value: key,
                }))}
                formatOptionLabel={(option: Option) => <Text>{option.label}</Text>}
                onChange={(value) => setFieldValue('zoneType', value)}
                isInvalid={!!errors.zoneType}
                value={values.zoneType}
              />
            </VStack>
          </FormControl>
          {values.zoneType === Zone2DTypeEnum.IMZ && (
            <FormControl isRequired>
              <VStack width="100%" gap={1} alignItems="start">
                <Text color="neutral.300" fontWeight="medium" fontSize="16px" pointerEvents="none">
                  <FormattedMessage id="zone.meter" values={{ label: formatMessage({ id: 'zone.marginSize' }) }} />
                </Text>
                <Field
                  as={CustomInputNumber}
                  id="marginSize"
                  name="marginSize"
                  width="64px"
                  height="48px"
                  min={0}
                  isInvalid={touched.marginSize && !!errors.marginSize}
                  onChange={async (value?: number) => {
                    await setFieldTouched('marginSize', true);
                    await setFieldValue('marginSize', value ?? '');
                  }}
                />
                <ErrorContainer error={errors.marginSize} touched={touched.marginSize} />
              </VStack>
            </FormControl>
          )}
          <VStack width="100%" gap={1} alignItems="start">
            <Text color="neutral.300" size="md" pointerEvents="none">
              <FormattedMessage id="zone.format" />
            </Text>
            <CustomSelect
              value={unit}
              width={136}
              menuPlacement="bottom"
              options={Object.values(CoordinatesUnitEnum).map((key) => ({
                label: formatMessage({ id: `common.units.${key}` }),
                value: key,
              }))}
              isClearable={false}
              onChange={handleUnitChange}
            />
          </VStack>
          <CustomChipList
            availableOptions={chipOptions}
            selectedOptions={chipOptions.filter((option) => option.value === values.displayedFormat)}
            distinctValues={[ZoneFormDisplayedFormat.ALTITUDE, ZoneFormDisplayedFormat.HEIGHT]}
            onChange={(value) => value[0] !== values.displayedFormat && handleHeightToAltitudeChange()}
            size="lg"
            variant="filled"
          />
          <HStack width="100%" gap={2} alignItems="center">
            <CustomSwitch
              isChecked={values.isZoneActive}
              onClick={() => {
                setFieldValue('isZoneActive', !values.isZoneActive);
              }}
            />
            <Text size="md" pointerEvents="none">
              <FormattedMessage id="zone.activate" />
            </Text>
          </HStack>
          <HStack width="100%" gap={2} alignItems="center">
            <CustomSwitch isChecked={true} isDisabled />
            <Text color="neutral.300" size="md" pointerEvents="none">
              <FormattedMessage id="zone.constantHeights" />
            </Text>
          </HStack>
        </VStack>
      </MapCustomScrollbar>
    </Box>
  );
}
