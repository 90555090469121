import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';
import { getTargetClassification, isDrone, isRemoteControl } from '@utils/target.utils';
import { FeatureCollection } from 'geojson';
import mapboxgl, { MapboxGeoJSONFeature } from 'mapbox-gl';

import { Target } from '@/types/c2/c2.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';
import {
  AnyTooltipData,
  FeatureTypeEnum,
  LayerNameEnum,
  MapIdEnum,
  PopupControlType,
  PopupDataTypeEnum,
  SourceNameEnum,
} from '@/types/map.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

import { targetColor } from './map.constants';

export function isFeatureCollection(data: unknown): data is FeatureCollection {
  return typeof data === 'object' && data !== null && 'type' in data && 'features' in data;
}

export function getFeatureId(feature: MapboxGeoJSONFeature): string | number | null {
  if (!feature.properties) {
    return null;
  }
  const data = JSON.parse(feature.properties.value);
  switch (feature.source) {
    case SourceNameEnum.TARGETS:
    case SourceNameEnum.STROBES:
      return data.id;
    case SourceNameEnum.PERIMETER_CAMERAS:
      return data.code;
    case SourceNameEnum.RADARS:
      return getSensorUniqueCode(data);
    case SourceNameEnum.AUTOMATON_SECTIONS:
    case SourceNameEnum.PERIMETER_SENSORS:
    case SourceNameEnum.SENSORS:
    case SourceNameEnum.SENSOR_LABELS:
    case SourceNameEnum.SENSORS_OVERLAY:
      return data;
    default:
      return null;
  }
}

export function getTargetColor(target: Target<TargetLocation>): string {
  if (isDrone(target) || isRemoteControl(target)) {
    return targetColor[getTargetClassification(target)];
  }
  return targetColor[ClassificationEnum.UNCLASSIFIED];
}

export function getAvailableLayers(map: mapboxgl.Map, layers: LayerNameEnum | LayerNameEnum[]): LayerNameEnum[] {
  const availableLayers = map.getStyle()?.layers.map((layer) => layer.id) ?? [];

  if (Array.isArray(layers)) {
    return layers.filter((layer) => availableLayers.includes(layer));
  } else {
    return availableLayers.includes(layers) ? [layers] : [];
  }
}

export function getMapIdFromContext(context: ContextEnum): MapIdEnum {
  switch (context) {
    case ContextEnum.REPLAY:
      return MapIdEnum.REPLAY;
    case ContextEnum.DOUBT_LAD:
      return MapIdEnum.LAD;
    case ContextEnum.LIVE:
    default:
      return MapIdEnum.LIVE;
  }
}

export function getMapIdFromReplayMode(replayMode: boolean, currentContext: ContextEnum): MapIdEnum {
  return replayMode ? MapIdEnum.REPLAY : getMapIdFromContext(currentContext);
}

export function shouldHideTooltip(popup: PopupControlType | null, tooltip?: AnyTooltipData): boolean {
  if (!popup || !tooltip) {
    return false;
  }

  return (
    popup.type === PopupDataTypeEnum.INFO &&
    popup.data.type === FeatureTypeEnum.TARGET_ICON &&
    tooltip.type === FeatureTypeEnum.TARGET_ICON &&
    tooltip.data.id === popup.data.targetId
  );
}
