import ZoneContextMenu from '@components/map/contextMenu/ZoneContextMenu';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import { selectZoneViewMode } from '@redux/maps/maps.selectors';
import { booleanPointInPolygon } from '@turf/turf';
import { Polygon } from 'geojson';
import { GeoJSONFeature } from 'mapbox-gl';
import { useCallback, useState } from 'react';
import { useMap } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export const useZoneContextMenu = () => {
  const { current: map } = useMap();
  const { mapId, contextMenuPosition, setContextMenuPosition, setMenuToDisplay } = useMapContext();
  const viewMode = useAppSelector((state) => selectZoneViewMode(state, mapId));
  const [selectedFeature, setSelectedFeature] = useState<GeoJSONFeature>();
  const layers = [
    LayerNameEnum.NO_INIT_ZONE_FILLS,
    LayerNameEnum.NO_INIT_ZONE_OUTLINES,
    LayerNameEnum.NO_FLY_ZONE_FILLS,
    LayerNameEnum.NO_FLY_ZONE_OUTLINES,
    LayerNameEnum.INITIALIZATION_MASKING_ZONE_FILLS,
    LayerNameEnum.INITIALIZATION_MASKING_ZONE_OUTLINES,
  ];

  const handleClick = useCallback(
    (event: any) => { // eslint-disable-line
      if (viewMode) {
        if (map && event.features !== undefined) {
          const feature = event.features[0];
          setSelectedFeature(feature);
          if (selectedFeature && feature.id !== selectedFeature.id) {
            map.getMap().setFeatureState({ source: SourceNameEnum.ZONES, id: selectedFeature.id } as GeoJSONFeature, {
              isSelected: false,
            });
          }
          map.getMap().setFeatureState(
            { source: SourceNameEnum.ZONES, id: feature.id },
            {
              isSelected: viewMode,
            },
          );
          if (setMenuToDisplay) {
            setMenuToDisplay(
              <ZoneContextMenu
                selectedZone={JSON.parse(feature.properties?.value)}
                clickPosition={event.point}
                onClose={() => setContextMenuPosition && setContextMenuPosition(null)}
              />,
            );
          }
        }
      }
    },
    [map, selectedFeature, setContextMenuPosition, setMenuToDisplay, viewMode],
  );

  if (map && selectedFeature) {
    const isInsidePolygon = contextMenuPosition
      ? booleanPointInPolygon(contextMenuPosition.toArray(), selectedFeature.geometry as Polygon)
      : false;

    if (!isInsidePolygon) {
      map.getMap().setFeatureState({ source: SourceNameEnum.ZONES, id: selectedFeature.id } as GeoJSONFeature, {
        isSelected: false,
      });
    }
  }

  useMapLayerMouseEvent('contextmenu', handleClick, layers);
};
