import { VStack } from '@chakra-ui/layout';
import MenuList from '@components/common/menu/MenuList';
import { MeasureOriginEnum, useCommonContextMenuItems } from '@hooks/useCommonContextMenuItems';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateSelectedDoubtCheckPerimeterCameraUniqueKey } from '@redux/global/global.reducer';
import { getSensorUniqueKey } from '@utils/sensors/sensors.utils';

import { MenuItems } from '@/types/menu.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';

interface PerimeterCameraContextMenuProps {
  perimeterCamera: PerimeterCamera;
  onClose: () => void;
}

function PerimeterCameraContextMenu({ onClose, perimeterCamera }: Readonly<PerimeterCameraContextMenuProps>) {
  const selectDoubtCheckPerimeterCamera = useWithDispatch(updateSelectedDoubtCheckPerimeterCameraUniqueKey);

  const handleActivate = () => {
    onClose();
    selectDoubtCheckPerimeterCamera(getSensorUniqueKey(perimeterCamera));
  };

  const commonMenuItems = useCommonContextMenuItems({
    closeContextMenu: onClose,
    position: perimeterCamera.coordinates,
    measureOrigin: MeasureOriginEnum.CAMERA,
  });

  const menuItems: MenuItems = {
    openCameraStream: {
      label: 'contextmenu.actions.openCameraStream',
      onAction: handleActivate,
    },
    ...commonMenuItems,
  };

  return (
    <VStack gap={0}>
      <MenuList
        menuItems={menuItems}
        depth={0}
        handleClick={(_, value) => value?.onAction && value.onAction()}
        selectedOptions={[]}
      />
    </VStack>
  );
}

export default PerimeterCameraContextMenu;
