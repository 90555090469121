import { ReactComponent as EmptyPanelDroneIcon } from '@assets/components/panel/notification/empty-panel-drone-icon.svg';
import { Box, Center, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  width: number;
  header: ReactNode;
  gap?: number;
  children?: ReactNode;
  footer?: ReactNode;
  subHeader?: ReactNode;
  isEmpty?: boolean;
  emptyLabel?: string;
};

export default function Panel({
  width,
  header,
  subHeader,
  children,
  footer,
  gap = 0.25,
  isEmpty = false,
  emptyLabel,
}: Readonly<Props>) {
  return (
    <VStack backgroundColor="transparent" width={width} gap={0} height="100%" alignItems="start" flexShrink={0}>
      {header}
      <VStack gap={gap} width="100%" height="100%" alignItems="start" marginTop={0.25}>
        {subHeader}
        {children && !isEmpty ? (
          <Box width="100%" flexGrow={1}>
            {children}
          </Box>
        ) : (
          <Center width="100%" height="100%" backgroundColor="neutral.800-op70" position="relative">
            <VStack gap={3}>
              <EmptyPanelDroneIcon />
              <Text
                fontSize="18px"
                variant="space"
                color="neutral.200"
                maxWidth="230px"
                textAlign="center"
                userSelect="none"
              >
                {emptyLabel}
              </Text>
            </VStack>
          </Center>
        )}
      </VStack>
      {footer && (
        <Box width="100%" height="fit-content" flexShrink={0} marginTop={0.25}>
          {footer}
        </Box>
      )}
    </VStack>
  );
}
