import { Text, VStack } from '@chakra-ui/layout';
import { FormattedMessage } from 'react-intl';

import { NoFlyZone, NoFlyZone3d } from '@/types/config/config.types';
import { FeatureType, FeatureTypeEnum } from '@/types/map.types';

export interface NoFlyZoneTooltipData extends FeatureType {
  type: FeatureTypeEnum.NO_FLY_ZONE | FeatureTypeEnum.NO_FLY_ZONE_3D;
  data: NoFlyZone | NoFlyZone3d;
}

function ZoneTooltip(props: Readonly<NoFlyZoneTooltipData>) {
  return (
    <VStack gap={0.5} alignItems="flex-start">
      <Text fontSize="16px">{props.data.name}</Text>
      <Text fontSize="16px">
        <FormattedMessage id={`noFlyZone.${props.data.level}`} />
      </Text>
    </VStack>
  );
}

export default ZoneTooltip;
