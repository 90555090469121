import '@components/common/layout/scrollbar.scss';

import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { NotificationCard } from '@components/cockpit/panels/notification/card/NotificationCard';
import { useGetNotificationsLazyQuery } from '@services/data/notification.api';
import { DRONE_NOTIFICATION_CRITICAL } from '@utils/tests/data.constants';
import { CSSProperties, memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { NotificationCriteria, NotificationType } from '@/types/data/data.types';

type Props = {
  notifications: NotificationType[];
  criteria: NotificationCriteria;
};

function Notifications({ notifications, criteria }: Readonly<Props>) {
  const [triggerCallGetNotifications] = useGetNotificationsLazyQuery();
  const [localNotifications, setLocalNotifications] = useState<NotificationType[]>([DRONE_NOTIFICATION_CRITICAL]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    return (
      <NotificationCard
        style={style}
        isArchived
        archivedNotification={localNotifications[index]}
        isLast={index === notifications.length - 1}
      />
    );
  };

  useEffect(() => {
    setLocalNotifications(notifications);
  }, [notifications]);

  const loadMoreItems = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const newItems = await triggerCallGetNotifications({
        ...criteria,
        page: Math.ceil(localNotifications.length / (criteria.size ?? 50)) + 1,
      })
        .unwrap()
        .then((result) => result);

      setLocalNotifications((prevItems) => [...prevItems, ...newItems]);
      if (newItems.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error loading items', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <VStack width="100%" height="100%" gap={0.25} marginTop={0.25} backgroundColor="neutral.black" flexGrow={1}>
      <HStack flexShrink={0} paddingY="18px" paddingX={3} backgroundColor="neutral.850" width="100%">
        <Text>
          <FormattedMessage id="replay.search.result" values={{ count: localNotifications.length }} />
        </Text>
      </HStack>
      <Box width="100%" paddingBottom={3} backgroundColor="neutral.900" flexGrow={1}>
        <AutoSizer style={{ height: '100%' }} disableWidth>
          {({ height }) => (
            <InfiniteLoader
              isItemLoaded={(index) => !!localNotifications[index]}
              loadMoreItems={loadMoreItems}
              itemCount={hasMore ? localNotifications.length + 1 : localNotifications.length}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  width="100%"
                  itemKey={(index) => localNotifications[index].id}
                  height={height}
                  className="map-panel-scrollbar"
                  itemCount={localNotifications.length}
                  itemSize={136}
                  itemData={localNotifications}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {Row}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Box>
    </VStack>
  );
}

export default memo(Notifications, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
