import { ROUND_SECTION_ZOOM_LIMIT_FOR_WIDTH } from '@utils/map/mapZoom.constants';
import { ROUND_SECTION_WIDTH_SCALING_FACTOR } from '@utils/sensors/configuration.constants';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { Feature, Geometry } from 'geojson';

import { RoundSection } from '@/types/config/round.types';
import { FeatureTypeEnum } from '@/types/map.types';

export function getFeatureScaledWidthFromZoom(width: number, zoom: number | undefined) {
  if (!zoom || zoom > ROUND_SECTION_ZOOM_LIMIT_FOR_WIDTH) return width;
  return +((zoom * width) / ROUND_SECTION_WIDTH_SCALING_FACTOR).toFixed(0);
}

export const toRoundSectionLineFeatures = (roundSections: RoundSection[], zoom?: number): Feature[] =>
  roundSections
    .map((section) => {
      const startPoint = section.positionDeparture.coordinates;
      const endPoint = section.positionArrival.coordinates;
      const segmentStart = toLonLatArray(startPoint, false)!;
      const segmentEnd = toLonLatArray(endPoint, false)!;

      const segmentColor = 'blue';

      const mainLineGeometry: Geometry = {
        type: 'LineString',
        coordinates: [segmentStart, segmentEnd],
      };

      return [
        {
          type: 'Feature',
          properties: {
            id: section.id,
            featureType: FeatureTypeEnum.ROUND_SECTION_LINE,
            value: section,
            whiteBg: 'true',
            width: getFeatureScaledWidthFromZoom(14, zoom),
          },
          geometry: mainLineGeometry,
        },
        {
          type: 'Feature',
          properties: {
            id: section.id,
            featureType: FeatureTypeEnum.ROUND_SECTION_LINE,
            value: section,
            color: segmentColor,
            width: getFeatureScaledWidthFromZoom(8, zoom),
          },
          geometry: mainLineGeometry,
        },
      ] as Feature[];
    })
    .flat();
