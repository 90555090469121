import { ReactComponent as StatisticsIcon } from '@assets/icons/40x40/ic-statistics-40.svg';
import { HStack, VStack } from '@chakra-ui/layout';
import { TabPanel, Text } from '@chakra-ui/react';
import DataSheetCardLink from '@components/common/layout/DataSheetCardLink';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { selectSituationTime, selectTrackFromRawSituationByTrackLights } from '@redux/situation/situation.selectors';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import { formatDuration, timeDuration } from '@utils/date.utils';
import { getCollaborativeData, getTrackUniqueId, isDrone } from '@utils/target.utils';
import { isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { Target } from '@/types/c2/c2.types';
import { NotificationTypeEnum } from '@/types/data/data.types';
import { PopupDataTypeEnum } from '@/types/map.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

import InfoValue from '../InfoValue';

type Props = {
  target: Target<TargetLocation>;
  position: { x: number; y: number };
};
export default function TargetLadInfo({ target, position }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const { mapId } = useMapContext();
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const tracks = useSelectorWithReplayMode(
    selectTrackFromRawSituationByTrackLights,
    target.trackIds.map(getTrackUniqueId),
  );
  const model = getCollaborativeData(tracks).model;

  const situationTime = useSelectorWithReplayMode(selectSituationTime);
  const timeSinceLastDetection = timeDuration(target.detectionEnd, situationTime);

  const isDroneTarget = isDrone(target);

  function handlePopup() {
    if (isDroneTarget) {
      updatePopupControl({
        mapId,
        popupControl: {
          type: PopupDataTypeEnum.HISTORY,
          data: {
            identifier: target.id,
            type: NotificationTypeEnum.DRONE,
            openingTime: situationTime ? new Date(situationTime) : new Date(),
          },
          open: true,
          position,
        },
      });
    }
  }

  const platformsNames = useAppSelector(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(target.marks)),
    isEqual,
  );

  return (
    <TabPanel>
      <VStack gap={0.25} backgroundColor="neutral.900">
        <VStack padding={4} gap={1} backgroundColor="neutral.800" width="100%" alignItems="start">
          <Text size="md" color="neutral.300">
            <FormattedMessage id="targetInfos.platforms" />
          </Text>
          <VStack gap={1}>
            {platformsNames.length === 0 ? (
              <Text size="lg" backgroundColor="neutral.black" paddingX={0.5}>
                <FormattedMessage id="targetInfos.noPlatform" />
              </Text>
            ) : (
              platformsNames.map((platform) => (
                <Text size="lg" backgroundColor="neutral.black" paddingX={0.5} key={platform}>
                  {platform}
                </Text>
              ))
            )}
          </VStack>
        </VStack>
        <VStack padding={4} gap={3} backgroundColor="neutral.800" width="100%">
          <InfoValue
            label={formatMessage({ id: 'targetInfos.status' })}
            value={formatMessage({ id: 'global.noInformation' })}
          />
          <HStack width="100%" gap={4} alignItems="start">
            <InfoValue
              label={formatMessage({ id: 'targetInfos.firstDetection' })}
              value={
                situationTime
                  ? formatMessage({ id: 'global.ago' }, { time: formatDuration(target.detectionStart, situationTime) })
                  : formatMessage({ id: 'global.noInformation' })
              }
            />
            <InfoValue
              label={formatMessage({ id: 'targetInfos.lastDetection' })}
              value={
                situationTime
                  ? formatMessage(
                      { id: timeSinceLastDetection > 1000 ? 'global.ago' : 'targetInfos.justNow' },
                      { time: formatDuration(target.detectionEnd, situationTime) },
                    )
                  : formatMessage({ id: 'global.noInformation' })
              }
            />
          </HStack>
        </VStack>
        <VStack padding={4} gap={3} backgroundColor="neutral.800" width="100%">
          <InfoValue
            label={formatMessage({ id: 'targetInfos.zone' })}
            value={formatMessage({ id: 'global.noInformation' })}
          />
          <InfoValue
            label={formatMessage({ id: 'targetInfos.alertStatus' })}
            value={formatMessage({ id: 'global.noInformation' })}
          />
          {model && <InfoValue label={formatMessage({ id: 'targetInfos.collaborative.model' })} value={model} />}
        </VStack>
        {isDroneTarget && (
          <VStack padding={2} gap={0.25} backgroundColor="neutral.900" width="100%">
            <HStack gap={4} paddingX={4} paddingY={3} width="100%" backgroundColor="neutral.800">
              <InfoValue
                label={formatMessage({ id: 'targetInfos.alreadySeen' })}
                value={formatMessage({ id: 'global.noInformation' })}
              />
              <InfoValue
                label={formatMessage({ id: 'targetInfos.maxAlert' })}
                value={formatMessage({ id: 'global.noInformation' })}
              />
            </HStack>
            <DataSheetCardLink
              label={formatMessage({ id: 'targetInfos.seeHistory' })}
              icon={StatisticsIcon}
              onClick={handlePopup}
            />
          </VStack>
        )}
      </VStack>
    </TabPanel>
  );
}
