import 'mapbox-gl/dist/mapbox-gl.css';

import { HStack } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react';
import CoordinatesControl from '@components/map/controls/CoordinatesControl';
import ScaleControl from '@components/map/controls/ScaleControl';
import { MeasureLayer } from '@components/map/layers/draw/measure/MeasureLayer';
import { useMapContext } from '@components/map/MapContext';
import Popup from '@components/map/popup/Popup';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useWithDispatch } from '@hooks/useWithDispatch';
import {
  closePopupByMapId,
  deselectFeatureByMapId,
  hidePerimeterLinksByMapId,
  LIVE,
  switchDrawModeByMapId,
  updateDrawPolygonsData,
  updateDrawZoneTypeSelected,
  updateMap,
  updateMenuNavigationStatus,
} from '@redux/maps/maps.reducer';
import {
  selectMapDrawModeByMapId,
  selectMapDrawPolygonsPoints,
  selectMapDrawZoneType,
  selectMapEditPolygonsInfo,
  selectPopupControlByMapId,
  selectViewByMapId,
} from '@redux/maps/maps.selectors';
import { selectMapBackground } from '@redux/settings/settings.selectors';
import { AUTOMATON_SECTION_LAYER_NAMES } from '@utils/map/map.constants';
import { RequestParameters, ResourceType, TransformRequestFunction } from 'mapbox-gl';
import { PropsWithChildren, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import {
  AttributionControlProps,
  FullscreenControl,
  FullscreenControlProps,
  Map,
  MapRef,
  ScaleControlProps,
  ViewState,
} from 'react-map-gl';

import { LayerNameEnum, PopupDataTypeEnum } from '@/types/map.types';

import Info from './infos/Info';
import MapTime from './MapTime';
import ZoneFormPopup from './popup/zone/ZoneFormPopup';
import PermanentTooltips from './tooltips/PermanentTooltips';
import Tooltip from './tooltips/Tooltip';

const MAPBOX_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
const BASE_BACKGROUND_MAP_URL = import.meta.env.VITE_BASE_BACKGROUND_MAP_URL;
const DEV = import.meta.env.DEV;

export interface MapboxMapProps {
  attribution?: ReactElement<AttributionControlProps>;
  contextMenu?: ReactElement;
  fullscreen?: ReactElement<FullscreenControlProps>;
  navigation?: ReactElement;
  scale?: ReactElement<ScaleControlProps>;
  zoom?: ReactElement;
}

export default function MapboxMap({
  // attribution = <AttributionControl />,
  contextMenu = <></>,
  fullscreen = <FullscreenControl />,
  scale = <ScaleControl />,
  children,
}: PropsWithChildren<MapboxMapProps>) {
  const { mapId } = useMapContext();
  const mapRef = useRef<MapRef>(null);
  // const mapStyle = useAppSelector((s: RootState) => s.maps.style);
  const mapView = useSelectorWithMapId(selectViewByMapId);
  const mapStyle = useAppSelector(selectMapBackground);

  // handle drawing side effects
  const drawMode = useSelectorWithMapId(selectMapDrawModeByMapId);
  const drawnPoints = useSelectorWithMapId(selectMapDrawPolygonsPoints);
  const editedZone = useSelectorWithMapId(selectMapEditPolygonsInfo);
  const zoneType = useSelectorWithMapId(selectMapDrawZoneType);
  const { onClose } = useDisclosure();
  const dispatchSelectedOptions = useWithDispatch(updateMenuNavigationStatus);
  const dispatchSwitchDrawModeByMapId = useWithDispatch(switchDrawModeByMapId);
  const dispatchUpdateDrawPolygonsData = useWithDispatch(updateDrawPolygonsData);
  const dispatchZoneType = useWithDispatch(updateDrawZoneTypeSelected);

  const updateOne = useWithDispatch(updateMap);
  const [cursor, setCursor] = useState<string>('auto');

  const deselectFeature = useWithDispatch(deselectFeatureByMapId);
  const hidePerimeterLinks = useWithDispatch(hidePerimeterLinksByMapId);
  const closePopupControl = useWithDispatch(closePopupByMapId);
  const currentPopupControl = useAppSelector((state) => selectPopupControlByMapId(state, mapId));
  const interactiveLayers = [
    LayerNameEnum.PERIMETER_CAMERA_SECTOR_FILLS,
    ...AUTOMATON_SECTION_LAYER_NAMES,
    LayerNameEnum.PERIMETER_SENSOR_ICONS,
    LayerNameEnum.PERIMETER_SENSOR_LABELS,
    LayerNameEnum.SENSOR_ICONS,
    LayerNameEnum.SENSOR_LABELS,
    LayerNameEnum.SENSOR_CLUSTERS,
    LayerNameEnum.SENSOR_CLUSTER_LEAVES,
    LayerNameEnum.TARGETS,
  ];

  useEffect(() => {
    if (drawMode && drawnPoints && drawnPoints.length < 3) {
      setCursor('crosshair');
    } else {
      setCursor('auto');
    }
  }, [drawMode, drawnPoints]);

  const handleMove = useCallback(
    (event: { viewState: unknown }) => {
      updateOne({ id: mapId, changes: { view: event.viewState as ViewState } });
    },
    [mapId, updateOne],
  );

  // Transform sprite source because mapbox only accept url
  function handleTransformRequest(url: string, resourceType: ResourceType): RequestParameters | undefined {
    if (resourceType === 'Tile') {
      if (url.includes('${BASE_URL}')) {
        return {
          url: url.replace('${BASE_URL}', DEV ? BASE_BACKGROUND_MAP_URL : `${window['location'].origin}`),
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
        } as RequestParameters;
      }
      return { url: url };
    } else if (resourceType === 'SpriteJSON' || resourceType === 'SpriteImage' || resourceType === 'Glyphs') {
      return {
        url: url.replace('data:/', ''),
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      } as RequestParameters;
    }
  }

  function handleClick() {
    //deselect the feature when clicking on the map
    deselectFeature(mapId);
    hidePerimeterLinks(mapId);
    //close all info popup when clicking on the map
    if (currentPopupControl.type === PopupDataTypeEnum.INFO) {
      closePopupControl(mapId);
    }
  }

  function handleContextMenu() {
    hidePerimeterLinks(mapId);
  }

  const handleDrawnZoneFormClose = () => {
    dispatchSelectedOptions({ mapId, selectedOptions: [] });
    dispatchSwitchDrawModeByMapId({ mapId, isDrawable: false });
    dispatchUpdateDrawPolygonsData({ mapId, points: [] });
    dispatchZoneType({ mapId, zoneType: undefined });
    onClose();
  };

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  return (
    <Map
      {...mapView}
      ref={mapRef}
      id={mapId}
      reuseMaps
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      style={{ width: '100%', height: '100%' }}
      onMove={handleMove}
      mapStyle={mapStyle}
      attributionControl={false}
      transformRequest={handleTransformRequest as TransformRequestFunction}
      terrain={{
        source: 'raster-dem',
        exaggeration: 0.5,
      }}
      cursor={cursor}
      interactiveLayerIds={interactiveLayers}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      minZoom={0}
      maxZoom={22}
      preserveDrawingBuffer
    >
      {/*attribution*/}
      <MapTime />
      {fullscreen}

      {children}

      <HStack gap={2} position="absolute" right={0} bottom={0} height="40px" margin={2} alignItems="stretch">
        {scale}
        <CoordinatesControl
          initialCoordinates={{
            latitude: mapView?.latitude ?? LIVE.view.latitude,
            longitude: mapView?.longitude ?? LIVE.view.longitude,
            type: '2D',
          }}
        />
      </HStack>
      <PermanentTooltips />
      <Tooltip />
      <Info />
      <Popup />
      {drawnPoints && (drawnPoints.length > 3 || editedZone) && (
        <ZoneFormPopup
          points={drawnPoints}
          editedZone={editedZone}
          zoneType={zoneType}
          onClose={handleDrawnZoneFormClose}
        />
      )}
      {contextMenu}
      <MeasureLayer />
    </Map>
  );
}
