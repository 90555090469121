import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import DraggableControl from '@components/map/controls/DraggableControl';
import InfoValue from '@components/map/infos/InfoValue';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { selectSectionByPerimeterCameraKey } from '@redux/situation/monitoring.selectors';
import { AngleUnits, DistanceUnits, formatUnit } from '@utils/unit.utils';
import { formatCoordinatesToString } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { FormattedMessage, useIntl } from 'react-intl';

import { PerimeterCameraInfoData, PopupPositionData } from '@/types/map.types';

export default function PerimeterCameraInfo({
  featureValue,
  top,
  left,
  onClose,
}: Readonly<PerimeterCameraInfoData & PopupPositionData>) {
  const { formatMessage } = useIntl();
  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);
  const sections = useSelectorWithReplayMode(selectSectionByPerimeterCameraKey, featureValue.uniqueKey);
  const notSpecified = formatMessage({ id: 'global.noInformation' });

  return (
    <DraggableControl
      top={top}
      left={left}
      height={740}
      label={formatMessage({ id: 'perimeterCameras.perimeterCameraInfo' })}
      onClose={onClose}
    >
      <VStack backgroundColor="neutral.700" width="100%" gap={0.25}>
        <Box padding={4} width="100%">
          <HStack gap={4} width="100%">
            <VStack gap={4} flexGrow={1} alignItems="start">
              <InfoValue label={formatMessage({ id: 'automatonSegments.name' })} value={featureValue.name} />
              <InfoValue label={formatMessage({ id: 'automatonSegments.operationalState' })} value={notSpecified} />
              <VStack width="100%" gap={1} alignItems="start">
                <Text size="md" color="neutral.300">
                  <FormattedMessage id="perimeterCameras.fov" />
                </Text>
                <HStack>
                  <Text size="md" color="neutral.300">
                    <FormattedMessage id="perimeterCameras.angle" />
                  </Text>
                  <Text size="md">{formatUnit(AngleUnits.TURN, featureValue.fov)}</Text>
                </HStack>
                <HStack>
                  <Text size="md" color="neutral.300">
                    <FormattedMessage id="perimeterCameras.range" />
                  </Text>
                  <Text size="md">{formatUnit(DistanceUnits.METERS, featureValue.range)}</Text>
                </HStack>
              </VStack>
              <VStack width="100%" gap={1} alignItems="start">
                <Text size="md" color="neutral.300">
                  <FormattedMessage id="perimeterCameras.sections" />
                </Text>
                {sections.map((section) => (
                  <Text key={section} size="md">
                    {section}
                  </Text>
                ))}
              </VStack>
            </VStack>
          </HStack>
        </Box>
        <Box padding={4} width="100%" backgroundColor="neutral.800">
          <VStack gap={3} flexGrow={1} alignItems="start">
            <InfoValue
              label={formatMessage({ id: 'perimeterCameras.airportZone' })}
              value={featureValue.airportZone ?? notSpecified}
            />
            <InfoValue
              label={formatMessage({ id: 'perimeterCameras.stateZone' })}
              value={featureValue.stateOwnedZone ?? notSpecified}
            />
            <InfoValue
              label={formatMessage({ id: 'perimeterCameras.sector' })}
              value={featureValue.sector ?? notSpecified}
            />
            <InfoValue label={formatMessage({ id: 'perimeterCameras.grid' })} value={notSpecified} />
            {featureValue.coordinates !== null && (
              <InfoValue
                label={formatMessage({ id: 'perimeterCameras.latLong' })}
                value={formatCoordinatesToString(
                  new DmsCoordinates(featureValue.coordinates.latitude, featureValue.coordinates.longitude),
                  userSettingsUnit,
                )}
              />
            )}
          </VStack>
        </Box>
        <Box padding={4} width="100%" backgroundColor="neutral.800">
          <InfoValue label={formatMessage({ id: 'perimeterCameras.technicalContact' })} value={notSpecified} />
        </Box>
      </VStack>
    </DraggableControl>
  );
}
