import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { HStack, VStack } from '@chakra-ui/layout';
import { Button, Input, Text, useBoolean } from '@chakra-ui/react';
import ErrorsContainer from '@components/common/form/ErrorsContainer';
import { useAppSelector } from '@hooks/redux.hooks';
import { RoleSelectors, selectAllProfilesWithoutTech } from '@redux/authent/authent.selectors';
import {
  useCreateProfileMutation,
  useDeleteProfileMutation,
  useUpdateProfileMutation,
} from '@services/authent/profile.api';
import { getBasicRoles } from '@utils/authent/roles.utils';
import { createErrorMessage } from '@utils/common.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Profile as ProfileType } from '@/types/authent/groups.types';

import Profile from './Profile';

type Props = {
  selectedProfileId: number | null;
  setSelectedProfileId: (selectedProfile: number | null) => void;
};

export default function ViewProfiles({ selectedProfileId, setSelectedProfileId }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const profiles = useAppSelector(selectAllProfilesWithoutTech);
  const allRoles = useAppSelector(RoleSelectors.selectAllRoles);
  const [newProfileName, setNewProfileName] = useState<string>('');
  const [createProfile, { isError, error }] = useCreateProfileMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [editMode, setEditMode] = useBoolean(false);
  const [deleteProfile] = useDeleteProfileMutation();

  const selectedProfile = profiles.find((profile) => profile.id === selectedProfileId);

  const emptyProfile: Partial<ProfileType> = {
    name: newProfileName,
    roles: [],
  };

  function onReset(): void {
    setNewProfileName('');
    setSelectedProfileId(null);
    setEditMode.off();
  }

  function onSubmit(values: Partial<ProfileType>) {
    if (editMode) {
      updateProfile({ ...selectedProfile, name: values.name } as ProfileType)
        .unwrap()
        .then(() => {
          onReset();
          createToast(
            formatMessage({ id: 'components.admin.profiles.message.update' }, { name: values.name }),
            ToastStatusEnum.SUCCESS,
          );
        });
    } else {
      createProfile({ ...values, roles: getBasicRoles(allRoles).map((role) => role.roleName) } as Partial<ProfileType>)
        .unwrap()
        .then(() => {
          onReset();
          createToast(
            formatMessage({ id: 'components.admin.profiles.message.add' }, { name: values.name }),
            ToastStatusEnum.SUCCESS,
          );
        });
    }
  }

  function onDeleteClick() {
    if (selectedProfile) {
      deleteProfile(selectedProfile.id)
        .unwrap()
        .then(() => {
          onReset();
          createToast(
            formatMessage({ id: 'components.admin.profiles.message.delete' }, { name: selectedProfile.name }),
            ToastStatusEnum.SUCCESS,
          );
        })
        .catch((error) => {
          createToast(formatMessage({ id: createErrorMessage(error) }), ToastStatusEnum.ERROR);
        });
    }
  }

  return (
    <VStack alignItems="start" gap={2}>
      <Text color="neutral.black">
        <FormattedMessage id="components.admin.profiles.subTitle" />
      </Text>
      <Formik initialValues={emptyProfile} enableReinitialize validateOnBlur onSubmit={onSubmit}>
        {({ handleSubmit, errors, touched, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            <FormControl id="name" isInvalid={touched.name && !!errors.name} isReadOnly={selectedProfile && !editMode}>
              <VStack alignItems="left" gap={0}>
                <FormLabel fontWeight="600" fontSize="17px" color="neutral.black" gap={0}>
                  <FormattedMessage id="components.admin.profiles.newName" />
                </FormLabel>
                <HStack gap={3}>
                  <Field
                    as={Input}
                    id="profile_name"
                    name="name"
                    type="text"
                    height="30px"
                    width="300px"
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Button type="submit" isDisabled={!dirty || !isValid}>
                    {editMode ? (
                      <FormattedMessage id="global.rename" />
                    ) : (
                      <FormattedMessage id="components.admin.profiles.add" />
                    )}
                  </Button>
                </HStack>
                <ErrorsContainer
                  errors={errors}
                  touched={touched}
                  width="100%"
                  height="52px"
                  apiError={isError ? formatMessage({ id: createErrorMessage(error) }) : undefined}
                />
              </VStack>
            </FormControl>
          </form>
        )}
      </Formik>

      <VStack alignItems="start">
        <FormLabel fontWeight="600" fontSize="17px" color="neutral.black">
          <FormattedMessage id="components.admin.profiles.exist" />
        </FormLabel>
        <HStack alignItems="start" gap={3}>
          <VStack
            width="300px"
            border="1px solid"
            padding={0.5}
            alignItems="start"
            gap={0.25}
            height="200px"
            overflowY="auto"
          >
            {profiles.map((profile) => (
              <Profile
                key={profile.id}
                profile={profile}
                isSelected={selectedProfile?.id === profile.id}
                onClick={() => {
                  setEditMode.off();
                  if (selectedProfile === profile) {
                    onReset();
                  } else {
                    setSelectedProfileId(profile.id);
                    setNewProfileName(profile.name);
                  }
                }}
              />
            ))}
          </VStack>
          <VStack>
            <Button isDisabled={!selectedProfile} width="100%" onClick={setEditMode.toggle}>
              {editMode ? <FormattedMessage id="global.cancel" /> : <FormattedMessage id="global.rename" />}
            </Button>
            <Button width="100%" isDisabled={!selectedProfile} onClick={onDeleteClick}>
              <FormattedMessage id="global.delete" />
            </Button>
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  );
}
