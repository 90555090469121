import { Icon } from '@chakra-ui/icon';
import { HStack } from '@chakra-ui/layout';
import { Text, Tooltip } from '@chakra-ui/react';
import { FunctionComponent, ReactNode, SVGProps } from 'react';

import Badge from './Badge';

type Props = {
  isActive: boolean;
  isMaximised: boolean;
  label: string;
  badge?: number | boolean;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  children?: ReactNode;
  onClick: () => void;
};

export default function CockpitSidebarButton({
  isActive,
  isMaximised,
  label,
  badge = false,
  icon,
  children,
  onClick,
}: Readonly<Props>) {
  const color = isActive ? 'sky.500' : 'neutral.300';
  return (
    <HStack
      backgroundColor="transparent"
      width={isMaximised ? '282px' : '32px'}
      marginLeft={isMaximised ? 1 : 0}
      height="32px"
      userSelect="none"
      position="relative"
      _hover={{ cursor: 'pointer' }}
      gap={0}
      role="group"
      onClick={onClick}
    >
      <Tooltip label={label} isDisabled={isMaximised} placement="right" openDelay={300}>
        <Icon as={icon} width="32px" height="32px" color={color} _groupHover={{ color: 'sky.500' }} />
      </Tooltip>
      {badge !== false && <Badge count={typeof badge === 'number' ? badge : undefined} />}
      {isMaximised && (
        <Text
          fontSize="16px"
          fontWeight={isActive ? 'medium' : 'regular'}
          color={color}
          noOfLines={2}
          marginLeft={4}
          width="100%"
          _groupHover={{ color: 'sky.500' }}
        >
          {label}
        </Text>
      )}
      {children}
    </HStack>
  );
}
