import { Box, HStack, VStack } from '@chakra-ui/layout';
import { TabPanel, Text, Tooltip } from '@chakra-ui/react';
import InfoValue from '@components/map/infos/InfoValue';
import AutomatonSectionIcon from '@components/map/infos/perimeter/AutomatonSectionIcon';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectAutomatonSectionLadCameraConfigurationByUniqueKey } from '@redux/sensors/sensors.selectors';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { selectAllPerimeterCameras, selectAutomatonSegmentStatusByCode } from '@redux/situation/monitoring.selectors';
import {
  getSectionCenterCoordinates,
  getSectionIconStatus,
  getSectionName,
  getSegmentStatus,
} from '@utils/map/automatonSection.utils';
import { formatCoordinatesToString } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { FormattedMessage, useIntl } from 'react-intl';

import { AutomatonSectionData, AutomatonSegment } from '@/types/sensor/configuration.types';
import { AutomatonSectionStatusEnum, SensorStatusEnum } from '@/types/sensor/status.types';

type Props = {
  status: SensorStatusEnum | null;
  automatonSectionData: AutomatonSectionData;
  upperSegment: AutomatonSegment | null;
  lowerSegment: AutomatonSegment | null;
  lowerStatus: AutomatonSectionStatusEnum | null;
  isUnderMaintenance: boolean;
};
export default function AutomatonSectionDescriptionInfo({
  status,
  automatonSectionData,
  upperSegment,
  lowerSegment,
  lowerStatus,
  isUnderMaintenance,
}: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const { automaton, cabinet, section, perimeterCameraUniqueKeys } = automatonSectionData;

  const sectionName = getSectionName(section);
  const sectionCenter = getSectionCenterCoordinates(section);

  const featureStatus = getSectionIconStatus(automatonSectionData, status);

  const upperStatus: AutomatonSectionStatusEnum | null = useSelectorWithReplayMode(selectAutomatonSegmentStatusByCode, {
    automaton: automaton.code,
    cabinet: cabinet.code,
    segment: upperSegment?.code,
  });
  const upperStatusValue = upperSegment && getSegmentStatus(upperSegment, upperStatus, automaton, cabinet);
  const lowerStatusValue = lowerSegment && getSegmentStatus(lowerSegment, lowerStatus, automaton, cabinet);

  const perimeterCameras = useSelectorWithReplayMode(selectAllPerimeterCameras);

  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);
  const ladCamera = useAppSelector((state) => selectAutomatonSectionLadCameraConfigurationByUniqueKey(state, section));

  const notSpecified = formatMessage({ id: 'global.noInformation' });

  return (
    <TabPanel>
      <VStack gap={0.25} backgroundColor="neutral.900">
        <HStack width="100%" gap={3} backgroundColor="neutral.700" paddingX={4} paddingY={3}>
          <Box flexShrink={0}>
            <AutomatonSectionIcon status={featureStatus} />
          </Box>
          <VStack width="100%" gap={0.5} alignItems="start">
            <Text paddingX={0.5} paddingY={0.25} size="lg" backgroundColor="neutral.black" noOfLines={2}>
              {sectionName}
            </Text>
            <Text fontSize="18px">
              <FormattedMessage id={`automatonSegments.status.${featureStatus.toLowerCase()}`} />
            </Text>
          </VStack>
        </HStack>
        <HStack width="100%" gap={4} backgroundColor="neutral.700" padding={4}>
          <VStack gap={1} alignItems="start">
            <Text size="md" color="neutral.300">
              <FormattedMessage id="automatonSegments.detailBySection" />
            </Text>
            <HStack gap={1}>
              <Text width="44px" size="md" color="neutral.300">
                <FormattedMessage id="automatonSegments.upper" />
              </Text>
              <Text size="lg">
                {upperStatusValue ? (
                  <FormattedMessage
                    id={`automatonSegments.status.${
                      isUnderMaintenance ? 'maintenance' : upperStatusValue.toLowerCase()
                    }`}
                  />
                ) : (
                  <FormattedMessage id="global.noInformation" />
                )}
              </Text>
            </HStack>
            <HStack gap={1}>
              <Text width="44px" size="md" color="neutral.300">
                <FormattedMessage id="automatonSegments.lower" />
              </Text>
              <Text size="lg">
                {lowerStatusValue ? (
                  <FormattedMessage
                    id={`automatonSegments.status.${
                      isUnderMaintenance ? 'maintenance' : lowerStatusValue.toLowerCase()
                    }`}
                  />
                ) : (
                  <FormattedMessage id="global.noInformation" />
                )}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <VStack width="100%" padding={4} gap={3} backgroundColor="neutral.800">
          <InfoValue label={formatMessage({ id: 'automatonSegments.grids' })} value={notSpecified} />
          <InfoValue
            label={formatMessage({ id: 'automatonSegments.latLong' })}
            value={formatCoordinatesToString(
              new DmsCoordinates(sectionCenter.latitude, sectionCenter.longitude),
              userSettingsUnit,
            )}
          />
        </VStack>
        <VStack width="100%" gap={3} padding={4} backgroundColor="neutral.800">
          <HStack width="100%" gap={2} alignItems="start">
            <VStack gap={3} width="50%" alignItems="start">
              <InfoValue label={formatMessage({ id: 'automatonSegments.electrifier' })} value={cabinet.name} />
              <InfoValue label={formatMessage({ id: 'automatonSegments.automaton' })} value={automaton.name} />
            </VStack>
            <VStack width="50%" gap={1} alignItems="start">
              <Text size="md" color="neutral.300">
                <FormattedMessage id="automatonSegments.attachedPerimeterCameras" />
              </Text>
              {perimeterCameraUniqueKeys.length === 0 ? (
                <Text size="lg">
                  <FormattedMessage id="automatonSegments.doubtCheck.noCameraLinkedToFence" />
                </Text>
              ) : (
                <VStack alignItems="start" gap={1} maxWidth="100%">
                  {perimeterCameraUniqueKeys.map((uniqueKey) => {
                    const label = perimeterCameras.find((camera) => camera.uniqueKey === uniqueKey)?.name ?? uniqueKey;
                    return (
                      <Tooltip label={label} key={uniqueKey} placement="right">
                        <Text size="lg" width="100%" noOfLines={2} textOverflow="ellipsis" overflow="hidden">
                          {label}
                        </Text>
                      </Tooltip>
                    );
                  })}
                </VStack>
              )}
            </VStack>
          </HStack>
          <InfoValue
            label={formatMessage({ id: 'automatonSegments.attachedLadCamera' })}
            value={ladCamera !== null ? ladCamera.name : formatMessage({ id: 'automatonSegments.noAttachedLadCamera' })}
          />
        </VStack>
      </VStack>
    </TabPanel>
  );
}
