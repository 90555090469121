import { AnyLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export const roundSectionLayer: AnyLayer = {
  id: LayerNameEnum.ROUND_SECTIONS,
  type: 'line',
  source: SourceNameEnum.ROUNDS,
  filter: ['==', 'featureType', FeatureTypeEnum.ROUND_SECTION_LINE],
  paint: {
    'line-color': ['get', 'color'],
    'line-opacity': 0.7,
    'line-width': 2,
    'line-dasharray': [2, 6],
  },
};
