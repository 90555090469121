import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const cyber = definePartsStyle({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tablist: {
    backgroundColor: 'cyber.700',
    color: 'neutral.700',
    cursor: 'default',
    border: 'none',
  },
  tab: {
    borderBottom: '3px solid',
    borderColor: 'cyber.600',
    backgroundColor: 'cyber.700',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: '20px',
    paddingBottom: '20px',
    fontSize: '16px',
    fontWeight: 'medium',
    lineHeight: '125%',
    color: 'neutral.white',
    flexGrow: 1,
    _focusVisible: {
      boxShadow: 'none !important',
    },
    _selected: {
      backgroundColor: 'cyber.600',
      borderColor: 'cyber.500',
    },
    _hover: {
      backgroundColor: 'cyber.500',
      borderColor: 'sky.500',
      cursor: 'pointer',
      _selected: {
        backgroundColor: 'cyber.600',
        borderColor: 'cyber.500',
        cursor: 'default',
      },
    },
    _disabled: {
      backgroundColor: 'neutral.850',
      borderColor: 'cyber.700',
      color: 'neutral.600',
      cursor: 'default',
      _hover: {
        backgroundColor: 'neutral.850',
        borderColor: 'cyber.700',
        cursor: 'default',
        _selected: {
          cursor: 'default',
        },
      },
    },
  },
  tabpanels: {
    padding: 0,
    background: 'neutral.700',
  },
  tabpanel: {
    padding: 0,
    background: 'neutral.700',
  },
});

const neutral = definePartsStyle({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tablist: {
    backgroundColor: 'neutral.900',
    cursor: 'default',
    gap: 0.25,
    border: 'none',
  },
  tab: {
    borderBottom: '4px solid',
    borderColor: 'neutral.700',
    backgroundColor: 'neutral.700',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: '20px',
    paddingBottom: '20px',
    fontSize: '16px',
    fontWeight: 'medium',
    lineHeight: '125%',
    color: 'neutral.300',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    _focusVisible: {
      boxShadow: 'none !important',
    },
    _selected: {
      backgroundColor: 'cyber.700',
      borderColor: 'cyber.500',
      color: 'neutral.white',
    },
    _hover: {
      backgroundColor: 'neutral.600',
      borderColor: 'neutral.600',
      color: 'sky.500',
      cursor: 'pointer',
      _selected: {
        backgroundColor: 'cyber.700',
        borderColor: 'cyber.500',
        color: 'neutral.white',
        cursor: 'default',
      },
    },
    _disabled: {
      backgroundColor: 'neutral.700',
      borderColor: 'neutral.700',
      color: 'neutral.300',
      cursor: 'default',
      _hover: {
        backgroundColor: 'neutral.700',
        borderColor: 'neutral.700',
        color: 'neutral.300',
        cursor: 'default',
        _selected: {
          cursor: 'default',
        },
      },
    },
  },
  tabpanels: {
    padding: 0,
    backgroundColor: 'neutral.900',
  },
  tabpanel: {
    padding: 0,
    backgroundColor: 'transparent',
  },
});

export const tabsTheme = defineMultiStyleConfig({
  variants: { cyber, neutral },
});
