import { AutomatonNodeStateEnum, GetSensorStatus, SensorStatusEnum } from '@/types/sensor/status.types';

import { Abstract, Coordinates, Dictionary, Mark, Stream } from '../commons/commons.types';
import { ModuleType } from '../utm/drone.types';
import { ClassificationEnum } from './classification.types';

export enum SensorTypeEnum {
  ADSB = 'ADSB',
  AEROSCOPE = 'AEROSCOPE',
  ARDRONIS = 'ARDRONIS',
  AUTOMATON = 'AUTOMATON',
  AVISO = 'AVISO',
  DRONE_SHIELD = 'DRONE_SHIELD',
  GROUND_STATION = 'GROUND_STATION',
  HOLODRONE = 'HOLODRONE',
  HOLOPTICS = 'HOLOPTICS',
  ONVIF = 'ONVIF',
  SENID = 'SENID',
  SKY_EYE = 'SKY_EYE',
  SPEXER = 'SPEXER',
  XENTA = 'XENTA',
  ZMER = 'ZMER',
}

export const enum SensorLikeEnum {
  PERIMETER_CAMERA = 'PERIMETER_CAMERA',
}

export enum SubSensorTypeEnum {
  ARDRONIS_ANTENNA = 'ARDRONIS_ANTENNA',
  AUTOMATON_CABINET = 'AUTOMATON_CABINET',
  AUTOMATON_SEGMENT = 'AUTOMATON_SEGMENT',
  SENID_ANTENNA = 'SENID_ANTENNA',
  SKY_EYE_ANTENNA = 'SKY_EYE_ANTENNA',
  SPEXER_RADAR = 'SPEXER_RADAR',
}

export type ExtendedSensor = SensorTypeEnum | SensorLikeEnum | SubSensorTypeEnum;

export type CameraType = SensorTypeEnum.HOLOPTICS | SensorTypeEnum.ONVIF | SensorTypeEnum.ZMER;

export enum SpexerVersionEnum {
  SPEXER_500 = 'SPEXER_500',
  SPEXER_2000 = 'SPEXER_2000',
}

export enum DroneTypeEnum {
  BOREAL = 'BOREAL',
  RSV = 'RSV',
}

export interface PortConfiguration {
  port: number;
}

export interface HostConfiguration {
  host: string;
}

export interface RemoteHostConfiguration {
  remoteHost: string | null;
  remotePort: number | null;
}

export interface AbstractCameraConfiguration {
  vxMin: number;
  vxMax: number;
  vyMin: number;
  vyMax: number;
}

export interface AsterixConfiguration {
  sac: string;
  sic: string;
}

export interface AmqpConfiguration {
  vHost: string;
  username: string | null;
  password: string | null;
}

export interface CommonSubSensorConfiguration {
  id: number;
  uniqueKey: string;
  sensorId: number;
  name: string;
  code: string;
  site: string;
  appCode: string;
  active: boolean;
  maintenance: boolean;
  sensorColor: string;
  altitude: number | null;
  airportZone: string | null;
  stateOwnedZone: string | null;
  sector: string | null;
  infrastructure: string | null;
}

export interface PositionSubSensor extends CommonSubSensorConfiguration {
  position: Coordinates;
}

export interface ArdronisAntenna extends PositionSubSensor {
  stationId: number;
  type: SubSensorTypeEnum.ARDRONIS_ANTENNA;
  range: number;
}

export interface AutomatonCabinet extends PositionSubSensor {
  nodeId: number;
  type: SubSensorTypeEnum.AUTOMATON_CABINET;
  segments: AutomatonSegment[];
}

export enum AutomatonSegmentFenceTypeEnum {
  LOWER = 'LOWER',
  UPPER = 'UPPER',
}

export interface AutomatonPortal {
  id: number;
  appCode: string;
  code: string;
  name: string;
  position: Coordinates;
}

export interface UniqueKey {
  appCode: string;
  code: string;
  site: string;
}

export interface AutomatonSegment extends PositionSubSensor, Abstract {
  nodeId: number;
  type: SubSensorTypeEnum.AUTOMATON_SEGMENT;
  consigned: boolean;
  fenceType: AutomatonSegmentFenceTypeEnum;
  segmentPosition: [Coordinates, Coordinates];
  perimeterCameraUniqueKeys: string[];
  ladCameraUniqueKey: string | null;
  portal: AutomatonPortal | null;
  zone: string;
}

export type AutomatonSection =
  | {
      lowerSegment: AutomatonSegment;
      upperSegment: AutomatonSegment;
    }
  | {
      lowerSegment?: never;
      upperSegment: AutomatonSegment;
    }
  | {
      lowerSegment: AutomatonSegment;
      upperSegment?: never;
    };

export interface AutomatonSectionStatus {
  name: string;
  status: SensorStatusEnum;
  upperStates: Dictionary<AutomatonNodeStateEnum[]>;
  lowerStates: Dictionary<AutomatonNodeStateEnum[]>;
}

export interface AutomatonSegmentCode {
  automaton: string;
  cabinet: string;
  segment: string;
}

export interface AutomatonSegmentData extends AutomatonSegmentCode {
  perimeterCameras: string | null;
  ladCameraUniqueKey: string | null;
}

export interface AutomatonSectionData {
  automaton: AutomatonConfiguration;
  cabinet: AutomatonCabinet;
  section: AutomatonSection;
  portal: AutomatonPortal | null;
  perimeterCameraUniqueKeys: string[];
}

export interface AutomatonSectionNames {
  automatonUniqueCode: string;
  cabinetUniqueCode: string;
  sectionName: string;
}

export interface JammingModule extends CommonSubSensorConfiguration {
  moduleType: ModuleType;
}

export interface SkyEyeAntenna extends PositionSubSensor {
  type: SubSensorTypeEnum.SKY_EYE_ANTENNA;
  range: number;
  antennaUid: string;
}

export interface SenidAntenna extends PositionSubSensor {
  type: SubSensorTypeEnum.SENID_ANTENNA;
  range: number;
  antennaUid: string;
}

export interface SubCameraConfiguration extends CommonSubSensorConfiguration, Stream {
  cameraId: number;
  minViewAngle: number;
  maxViewAngle: number;
  resolutionX: number;
  resolutionY: number;
}

//TODO handle AutomatonSegments properly
export type SubSensorConfiguration = SenidAntenna | SkyEyeAntenna | SpexerRadar | ArdronisAntenna | AutomatonCabinet;

export interface CommonConfiguration extends Abstract {
  id: number;
  site: string;
  appCode: string;
  uniqueKey: string;
  marks: Mark[];
  code: string;
  name: string;
  sensorPosition: Coordinates;
  sensorAltitude: number;
  timeoutThreshold: number;
  trackDeleteTime: number;
  trackLostTime: number;
  defaultTargetClassification: ClassificationEnum;
  saveRawData: boolean;
  sensorColor: string;
  active: boolean;
  maintenance: boolean;
  airportZone: string | null;
  stateOwnedZone: string | null;
  sector: string | null;
  infrastructure: string | null;
}

export interface AdsbConfiguration extends CommonConfiguration, HostConfiguration, PortConfiguration {
  type: SensorTypeEnum.ADSB;
  /** In meters. */
  filterAreaRadius: number;
  /** In meters AMSL. */
  filterAreaMaxAltitude: number;
  /** In kilometers per hour. */
  filterMaxSpeed: number;
  /** Code of the time zone. */
  timeZone: string;
}

export interface AeroscopeConfiguration extends CommonConfiguration {
  type: SensorTypeEnum.AEROSCOPE;
  /** Code of the time zone. */
  timeZone: string;
  username: string;
  password: string;
  controlUrl: string;
  /** Interval (in milliseconds) between 2 calls to the Aeroscope to refresh the security token. */
  tokenRefresh: number;
  /** Interval (in milliseconds) between 2 calls to the Aeroscope to retrieve the drones. */
  dataRefresh: number;
}

export interface ArdronisConfiguration extends CommonConfiguration {
  type: SensorTypeEnum.ARDRONIS;
  eventPort: number;
  eventChannel: string;
  commandPort: number;
  antennas: ArdronisAntenna[];
}

export interface AutomatonConfiguration extends CommonConfiguration, HostConfiguration, PortConfiguration {
  type: SensorTypeEnum.AUTOMATON;
  username: string;
  password: string;
  cabinets: AutomatonCabinet[];
}

export interface AvisoConfiguration
  extends CommonConfiguration,
    HostConfiguration,
    PortConfiguration,
    AsterixConfiguration {
  type: SensorTypeEnum.AVISO;
  /** In meters AMSL. */
  filterAreaMaxAltitude: number;
  /** In meters. */
  filterAreaRadius: number;
  /** In kilometers per hour. */
  filterMaxSpeed: number;
  /** East distance in meters to apply to coordinates (can be negative) */
  xoffset: number;
  /** North distance in meters to apply to coordinates (can be negative) */
  yoffset: number;
}

export interface DroneShieldConfiguration extends CommonConfiguration, HostConfiguration, PortConfiguration {
  type: SensorTypeEnum.DRONE_SHIELD;
  range: number;
  orientation: number;
  aperture: number;
  accuracy: number | null;
}

export interface GroundStationConfiguration extends CommonConfiguration, PortConfiguration, RemoteHostConfiguration {
  type: SensorTypeEnum.GROUND_STATION;
  droneType: DroneTypeEnum;
}

export interface HolodroneConfiguration extends CommonConfiguration, HostConfiguration, PortConfiguration {
  type: SensorTypeEnum.HOLODRONE;
  holodroneId: number;
}

export interface HolopticsConfiguration
  extends CommonConfiguration,
    AbstractCameraConfiguration,
    HostConfiguration,
    PortConfiguration {
  type: SensorTypeEnum.HOLOPTICS;
  subCameras: SubCameraConfiguration[];
  apiKey: string;
  panOffset: number | null;
  tiltOffset: number | null;
  range: number;
}

export interface OnvifConfiguration
  extends CommonConfiguration,
    AbstractCameraConfiguration,
    HostConfiguration,
    PortConfiguration {
  type: SensorTypeEnum.ONVIF;
  subCameras: SubCameraConfiguration[];
  dataRefresh: number;
  username: string | null;
  password: string | null;
}

export interface SenidConfiguration
  extends CommonConfiguration,
    HostConfiguration,
    PortConfiguration,
    AmqpConfiguration {
  type: SensorTypeEnum.SENID;
  antennas: SenidAntenna[];
}

export interface SkyEyeConfiguration extends CommonConfiguration, HostConfiguration, PortConfiguration {
  type: SensorTypeEnum.SKY_EYE;
  username: string;
  password: string;
  /** Interval (in milliseconds) between 2 calls to the Sky Eye to retrieve the drones. */
  dataRefresh: number;
  timeZone: string;
  antennas: SkyEyeAntenna[];
}

export interface SpexerConfiguration extends CommonConfiguration, HostConfiguration, PortConfiguration {
  type: SensorTypeEnum.SPEXER;
  version: SpexerVersionEnum;
  radars: SpexerRadar[];
}

export interface SpexerRadar extends CommonRadarConfiguration {
  radarId: number;
  type: SubSensorTypeEnum.SPEXER_RADAR;
}

export interface XentaConfiguration
  extends CommonConfiguration,
    PortConfiguration,
    RemoteHostConfiguration,
    AsterixConfiguration,
    CommonRadarConfiguration {
  type: SensorTypeEnum.XENTA;
}

export interface CommonRadarConfiguration extends PositionSubSensor {
  aperture: number;
  blindRadius: number;
  intermediateRadius: number;
  endDetectionRadius: number;
  orientation?: number;
}

export interface ZmerConfiguration
  extends CommonConfiguration,
    AbstractCameraConfiguration,
    RemoteHostConfiguration,
    PortConfiguration {
  type: SensorTypeEnum.ZMER;
  subCameras: SubCameraConfiguration[];
  zoomSynchronization: boolean | null;
  commandHost: string | null;
  commandPort: number | null;
  charmUsername: string | null;
  charmPassword: string | null;
}

export const SENSORS_WITH_SUBSENSOR = [
  SensorTypeEnum.ARDRONIS,
  SensorTypeEnum.AUTOMATON,
  SensorTypeEnum.SENID,
  SensorTypeEnum.SKY_EYE,
  SensorTypeEnum.SPEXER,
] as const satisfies readonly SensorTypeEnum[];

export type SensorWithSubSensor = (typeof SENSORS_WITH_SUBSENSOR)[number];

export type CameraConfiguration = Extract<SensorConfiguration, { type: CameraType }>;

export type SubCameraLiteConfiguration = {
  site: string;
  name: string;
  code: string;
};

export type CameraLiteConfiguration = {
  type: CameraType;
  name: string | null;
  code: string;
  site: string;
  subCameras: SubCameraLiteConfiguration[];
};

export type GetSensorConfiguration<SensorType extends SensorTypeEnum> = GetSensorStatus<SensorType>['configuration'];

export type SensorConfiguration = GetSensorConfiguration<SensorTypeEnum>;

/* eslint-disable prettier/prettier */
export type GetSubSensorConfiguration<SensorType extends SensorWithSubSensor> =
    SensorType extends SensorTypeEnum.ARDRONIS ? GetSensorConfiguration<SensorTypeEnum.ARDRONIS>['antennas'][number] :
        SensorType extends SensorTypeEnum.AUTOMATON ? GetSensorConfiguration<SensorTypeEnum.AUTOMATON>['cabinets'][number] :
            SensorType extends SensorTypeEnum.SENID ? GetSensorConfiguration<SensorTypeEnum.SENID>['antennas'][number] :
                SensorType extends SensorTypeEnum.SKY_EYE ? GetSensorConfiguration<SensorTypeEnum.SKY_EYE>['antennas'][number] :
                    SensorType extends SensorTypeEnum.SPEXER ? GetSensorConfiguration<SensorTypeEnum.SPEXER>['radars'][number] :
                        never

/* eslint-enable prettier/prettier */

export enum EquipmentTypeEnum {
  WEB_RELAY = 'WEB_RELAY',
}

export enum EquipmentStateEnum {}

export interface SensorConfigurationReference {
  id: number;
  sensorAppCode: string;
  sensorSite: string;
  sensorCode: string;
}

export interface CommonEquipmentConfiguration extends Abstract {
  id: number;
  uniqueKey: string;
  appCode: string;
  site: string;
  active: boolean;
  code: string;
  name: string;
  host: string;
  states: EquipmentStateEnum[];
  sensorReferences: SensorConfigurationReference[];
  marks: Mark[];
}

export enum WebRelayTypeEnum {
  WR_10 = 'WR_10',
  WR_X400 = 'WR_X400',
  WR_X_WR = 'WR_X_WR',
}

export interface WebRelayConfiguration extends CommonEquipmentConfiguration {
  type: EquipmentTypeEnum.WEB_RELAY;
  webRelayType: WebRelayTypeEnum;
  actuator: number | null;
}

export type EquipmentConfiguration = WebRelayConfiguration;
