import PoiContextMenu from '@components/map/contextMenu/PoiContextMenu';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import { selectPoiViewMode } from '@redux/maps/maps.selectors';
import { useCallback } from 'react';
import { useMap } from 'react-map-gl';

import { LayerNameEnum } from '@/types/map.types';

export const usePoiContextMenu = () => {
  const { current: map } = useMap();
  const { mapId, setContextMenuPosition, setMenuToDisplay } = useMapContext();
  const viewMode = useAppSelector((state) => selectPoiViewMode(state, mapId));
  const layers = [LayerNameEnum.POI];

  const handleClick = useCallback(
    (event: any) => { // eslint-disable-line
      if (viewMode) {
        if (map && event.features !== undefined) {
          const feature = event.features[0];
          if (setMenuToDisplay) {
            setMenuToDisplay(
              <PoiContextMenu
                selectedPoi={JSON.parse(feature.properties?.value)}
                clickPosition={event.point}
                onClose={() => setContextMenuPosition && setContextMenuPosition(null)}
              />,
            );
          }
        }
      }
    },
    [map, setContextMenuPosition, setMenuToDisplay, viewMode],
  );

  useMapLayerMouseEvent('contextmenu', handleClick, layers);
};
