import { ReactComponent as ConfirmedIcon32 } from '@assets/icons/32x32/ic-confirmed-32.svg';
import { ReactComponent as ConfirmedDoubleIcon } from '@assets/icons/32x32/ic-confirmedDouble-32.svg';
import { ReactComponent as StopIcon32 } from '@assets/icons/32x32/ic-stop-32.svg';
import { Box, Center, Icon, Spinner, Text, VStack } from '@chakra-ui/react';
import {
  useAcknowledgeNotificationMutation,
  useArchiveNotificationMutation,
  useConfirmNotificationMutation,
} from '@services/data/notification.api';
import { isEqual } from 'lodash';
import { Dispatch, memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { NotificationType } from '@/types/data/data.types';

import { ConfirmState } from './NotificationCard';

type Props = {
  notification: NotificationType;
  confirmState: ConfirmState;
  setConfirmState: Dispatch<ConfirmState>;
};

function NotificationCardProcedure({ notification, confirmState, setConfirmState }: Readonly<Props>) {
  const [acknowledgeAlert] = useAcknowledgeNotificationMutation();
  const [confirmAlert, { isLoading: isLoadingConfirm }] = useConfirmNotificationMutation();
  const [archiveAlert] = useArchiveNotificationMutation();
  const [isLoadingAck, setIsLoadingAck] = useState<boolean>(false);
  const [isLoadingArchive, setIsLoadingArchive] = useState<boolean>(false);
  const [prevCount, setPrevCount] = useState<number>(notification.alertCount);

  useEffect(() => {
    if (isLoadingAck && !notification.hasUnacknowledgedAlert) {
      setIsLoadingAck(false);
    }
  }, [isLoadingAck, notification.hasUnacknowledgedAlert]);

  useEffect(() => {
    if (prevCount !== notification.alertCount) {
      if (isLoadingArchive) {
        setIsLoadingArchive(false);
        setConfirmState(null);
      }
      setPrevCount(notification.alertCount);
    }
  }, [isLoadingArchive, notification.alertCount, prevCount, setConfirmState]);

  if (confirmState !== null) {
    return (
      <VStack
        gap={1}
        justifyContent="space-around"
        width="156px"
        height="100%"
        paddingX={2}
        paddingY="14px"
        backgroundColor="neutral.900"
      >
        <Center
          width="100%"
          height="38px"
          paddingY={0.5}
          paddingX={1.5}
          border="2px solid #002A7C"
          backgroundColor="cyber.600"
          cursor="pointer"
          onClick={(e) => {
            if (confirmState === 'confirm') {
              confirmAlert(notification.id).finally(() => setConfirmState(null));
            } else {
              setIsLoadingArchive(true);
              archiveAlert(notification.id).catch(() => {
                setIsLoadingArchive(false);
              });
            }
            e.stopPropagation();
          }}
        >
          {isLoadingConfirm || isLoadingArchive ? (
            <Spinner size="sm" color="neutral.300" />
          ) : (
            <Text variant="space">
              <FormattedMessage id={`components.notification.${confirmState}`} />
            </Text>
          )}
        </Center>
        <Center
          width="100%"
          paddingY="6px"
          paddingX="14px"
          height="38px"
          border="2px solid"
          borderColor="cyber.600"
          boxSizing="border-box"
          cursor="pointer"
          onClick={(e) => {
            setConfirmState(null);
            e.stopPropagation();
          }}
        >
          <Text variant="space">
            <FormattedMessage id="global.cancel" />
          </Text>
        </Center>
      </VStack>
    );
  }

  if (notification.hasUnacknowledgedAlert) {
    return (
      <Center
        width="56px"
        height="100%"
        role="group"
        onClick={(e) => {
          setIsLoadingAck(true);
          acknowledgeAlert(notification.id)
            .unwrap()
            .catch(() => setIsLoadingAck(false));
          e.stopPropagation();
        }}
      >
        {isLoadingAck ? (
          <Spinner size="sm" color="neutral.300" />
        ) : (
          <Icon
            as={ConfirmedIcon32}
            width="24px"
            height="24px"
            color="neutral.300"
            _groupHover={{ color: 'var(--chakra-colors-sky-500)' }}
          />
        )}
      </Center>
    );
  } else {
    return (
      <VStack gap={0}>
        <Center
          width="56px"
          height="55px"
          role="group"
          onClick={(e) => {
            setConfirmState('confirm');
            e.stopPropagation();
          }}
        >
          <Icon
            as={ConfirmedDoubleIcon}
            width="24px"
            height="24px"
            color="neutral.300"
            _groupHover={{ color: 'var(--chakra-colors-sky-500)' }}
          />
        </Center>
        <Box height="2px" width="24px" bg="neutral.900" />
        <Center
          width="56px"
          height="55px"
          role="group"
          onClick={(e) => {
            setConfirmState('archive');
            e.stopPropagation();
          }}
        >
          <Icon
            as={StopIcon32}
            width="24px"
            height="24px"
            color="neutral.300"
            _groupHover={{ color: 'var(--chakra-colors-sky-500)' }}
          />
        </Center>
      </VStack>
    );
  }
}

export default memo(NotificationCardProcedure, isEqual);
