import { Grid, GridItem } from '@chakra-ui/layout';
import Player from '@components/common/video/Player';
import { getNimbleStreamUrl } from '@utils/sensors/camera/camera.utils';
import { memo } from 'react';

import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';

import PerimeterCameraWrapper from './PerimeterCameraWrapper';
import { PerimeterGridProps } from './PerimeterGrid';

interface CamGridProps extends PerimeterGridProps {
  cameras: PerimeterCamera[];
  onPlayerDoubleClick: () => void;
}

function CamGrid({ innerGridHeight, innerGridWidth, uniqueKey, cameras, onPlayerDoubleClick }: Readonly<CamGridProps>) {
  return (
    <Grid
      templateColumns={`repeat(${innerGridWidth}, 1fr)`}
      templateRows={`repeat(${innerGridHeight}, 1fr)`}
      minHeight={0}
      minWidth={0}
      width="100%"
      height="100%"
      id={`cam-grid-${uniqueKey}`}
    >
      {cameras.map((camera, index) => {
        return (
          <GridItem key={`${uniqueKey}-${index}`} minHeight={0} minWidth={0} onDoubleClick={onPlayerDoubleClick}>
            <PerimeterCameraWrapper name={camera.name}>
              <Player id={`player-${uniqueKey}-${index}`} src={getNimbleStreamUrl(camera)} />
            </PerimeterCameraWrapper>
          </GridItem>
        );
      })}
    </Grid>
  );
}

export default memo(CamGrid);
