import { Text, VStack } from '@chakra-ui/layout';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectTrackFromRawSituationByTrackLights } from '@redux/situation/situation.selectors';
import { getRoundedValue } from '@utils/math.utils';
import { getTargetHeight, getTargetInfoId, getTrackUniqueId, getUniqueSensorShortHand } from '@utils/target.utils';
import { convertSpeedUnitFromMPerS, formatUnit, HeightUnits, SpeedUnits } from '@utils/unit.utils';
import { FormattedMessage } from 'react-intl';

import { Target } from '@/types/c2/c2.types';
import { FeatureType, FeatureTypeEnum } from '@/types/map.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';
import { AbstractLocation, PlanarLocation } from '@/types/sensor/sensor.types';

export interface TargetTooltipData extends FeatureType {
  type: FeatureTypeEnum.TARGET_ICON;
  data: Target<AbstractLocation>;
}

function TargetTooltip(props: Readonly<TargetTooltipData>) {
  const { id, identification, classification, lastPosition, trackIds } = props.data;

  const tracks = useSelectorWithReplayMode(selectTrackFromRawSituationByTrackLights, trackIds.map(getTrackUniqueId));

  const sensorCodes = getUniqueSensorShortHand(trackIds);

  const collaborativeId = getTargetInfoId(tracks, identification.mainIdentification, id);

  const height = getTargetHeight(props.data);

  return (
    <VStack gap={0.5} alignItems="flex-start">
      {collaborativeId !== id && <Text fontSize="16px">{collaborativeId}</Text>}
      {height !== null && <Text fontSize="16px">{formatUnit(HeightUnits.METERS, height)}</Text>}
      <Text fontSize="16px">
        <FormattedMessage id={`target.reliability.${props.data.identification.reliability}`} />
      </Text>
      <Text fontSize="16px">
        <FormattedMessage id={`target.classification.${classification.classification}`} />
      </Text>
      {lastPosition !== null &&
        (identification.mainIdentification === IdentificationEnum.DRONE ||
          identification.mainIdentification === IdentificationEnum.AIRCRAFT) && (
          <Text fontSize="16px">
            {formatUnit(
              SpeedUnits.KNOTS,
              getRoundedValue(
                convertSpeedUnitFromMPerS(SpeedUnits.KNOTS, (lastPosition.location as PlanarLocation).speed, 0),
              ),
            )}{' '}
            (
            {formatUnit(
              SpeedUnits.KILOMETERS_PER_HOUR,
              getRoundedValue(
                convertSpeedUnitFromMPerS(
                  SpeedUnits.KILOMETERS_PER_HOUR,
                  (lastPosition.location as PlanarLocation).speed,
                  0,
                ),
              ),
            )}
            )
          </Text>
        )}
      <Text fontSize="16px">{sensorCodes.join(' ')}</Text>
    </VStack>
  );
}

export default TargetTooltip;
