import { ReactComponent as ArrowFilledIcon } from '@assets/icons/24x24/ic-arrowTriangleFilled-24.svg';
import { Icon } from '@chakra-ui/icon';
import { Box } from '@chakra-ui/layout';
import { Center, HStack, Text, VStack } from '@chakra-ui/react';
import { FunctionComponent, memo, ReactElement, ReactNode, SVGProps, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import CustomCheckbox from '../inputs/CustomCheckbox';

type DropOption<T> = {
  value: T;
  label: string;
};

type WrapperType =
  | { icon: FunctionComponent<SVGProps<SVGSVGElement>>; label?: never }
  | { icon?: never; label: string };

type Props<T> = {
  options: DropOption<T>[];
  values: Set<T>;
  padding?: number | string;
  getOptionIcon?: (value: T) => FunctionComponent<SVGProps<SVGSVGElement>>;
  onChange: (values: Set<T>) => void;
} & WrapperType;

function DropSelect<T>({ label, options, values, padding = '6px', onChange, getOptionIcon, icon }: Readonly<Props<T>>) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const allActive = values.size === options.length;
  const allInactive = values.size === 0;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    }

    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function getWrapper(children: ReactNode) {
    if (icon) {
      const iconColor = open ? 'neutral.white' : 'neutral.300';
      return (
        <HStack
          ref={menuRef}
          gap={1}
          paddingLeft={1}
          paddingRight={0.5}
          paddingY={padding}
          border="2px solid"
          borderColor="cyber.600"
          backgroundColor={open ? 'sky.500' : 'transparent'}
          cursor="pointer"
          position="relative"
          onClick={(e) => {
            setOpen((prev) => !prev);
            e.stopPropagation();
          }}
        >
          <Icon as={icon} width="24px" height="24px" color={iconColor} />
          <Icon
            as={ArrowFilledIcon}
            width="24px"
            height="24px"
            transform={open ? 'rotate(-90deg)' : 'rotate(90deg)'}
            color={iconColor}
          />
          {open && children}
        </HStack>
      );
    } else {
      return (
        <HStack
          ref={menuRef}
          gap={0.25}
          flexShrink={0}
          width="100%"
          border="2px solid"
          borderColor="neutral.black"
          backgroundColor="neutral.black"
          position="relative"
          userSelect="none"
          cursor="pointer"
          role="group"
          onClick={(e) => {
            setOpen((prev) => !prev);
            e.stopPropagation();
          }}
        >
          <HStack
            gap={1}
            paddingLeft={2}
            paddingRight={1.5}
            paddingY={1.5}
            backgroundColor="neutral.900"
            width="100%"
            height="100%"
          >
            <Text fontSize="16px">
              <FormattedMessage id={label} values={{ count: values.size === options.length ? true : values.size }} />
            </Text>
            <Text size="md" color="sky.500">
              {values.size}
            </Text>
          </HStack>
          <Center
            border="2px solid"
            borderColor={open ? 'cyber.500' : 'neutral.800'}
            padding={1}
            backgroundColor={open ? 'cyber.500' : 'neutral.black'}
            _groupHover={{
              borderColor: open ? 'cuber.500' : 'sky.500',
            }}
          >
            <Icon
              as={ArrowFilledIcon}
              width="24px"
              height="24px"
              color={open ? 'neutral.white' : 'neutral.300'}
              transform={open ? 'rotate(-90deg)' : 'rotate(90deg)'}
            />
          </Center>
          {open && children}
        </HStack>
      );
    }
  }

  return getWrapper(
    <Box
      position="absolute"
      border="2px solid"
      width="max-content"
      borderColor="neutral.black"
      padding={2}
      backgroundColor="neutral.600"
      left="-2px"
      cursor="default"
      zIndex={999}
      top="50px"
      onClick={(e) => e.stopPropagation()}
    >
      <VStack gap={2} alignItems="start">
        <HStack gap={1}>
          <Text
            paddingX={1}
            paddingY="1px"
            width="max-content"
            border="2px solid"
            borderColor={allActive ? 'neutral.500' : 'cyber.500'}
            color={allActive ? 'neutral.500' : 'neutral.white'}
            cursor={allActive ? 'default' : 'pointer'}
            noOfLines={1}
            onClick={() => !allActive && onChange(new Set(options.map((option) => option.value)))}
          >
            <FormattedMessage id="global.all" />
          </Text>
          <Text
            paddingX={1}
            paddingY="1px"
            border="2px solid"
            borderColor={allInactive ? 'neutral.500' : 'cyber.500'}
            color={allInactive ? 'neutral.500' : 'neutral.white'}
            cursor={allInactive ? 'default' : 'pointer'}
            noOfLines={1}
            onClick={() => {
              !allInactive && onChange(new Set());
            }}
          >
            <FormattedMessage id="global.none" />
          </Text>
        </HStack>
        {options.map((option) => (
          <CustomCheckbox
            isChecked={values.has(option.value)}
            key={`${option.value}`}
            onChange={() => {
              const newSet = new Set(values);
              newSet.has(option.value) ? newSet.delete(option.value) : newSet.add(option.value);
              onChange(newSet);
            }}
          >
            <HStack gap={1} marginLeft={1}>
              {getOptionIcon ? <Icon as={getOptionIcon(option.value)} width="24px" height="24px" /> : null}
              <Text noOfLines={1} wordBreak="break-all">
                {option.label}
              </Text>
            </HStack>
          </CustomCheckbox>
        ))}
      </VStack>
    </Box>,
  );
}
export default memo(DropSelect) as unknown as <T>(props: Props<T>) => ReactElement;
