import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { NotificationType } from '@/types/data/data.types';

import { userLogout } from '../authent/authent.reducer';
import { notificationAdapter } from './data.adapters';

export type DataState = {
  ongoingNotifications: EntityState<NotificationType, number>;
  hasBeenInit: boolean;
};

export const DATA_INITIAL_STATE: DataState = {
  ongoingNotifications: notificationAdapter.getInitialState(),
  hasBeenInit: false,
};

const dataReducers = createSlice({
  name: 'data',
  initialState: DATA_INITIAL_STATE,
  reducers: {
    updateNotifications(state, action: PayloadAction<NotificationType[]>) {
      state.ongoingNotifications = notificationAdapter.setAll(state.ongoingNotifications, action.payload);
      if (!state.hasBeenInit) {
        state.hasBeenInit = true;
      }
    },
    updateNotification: ({ ongoingNotifications }, action: PayloadAction<NotificationType>) => {
      notificationAdapter.upsertOne(ongoingNotifications, action.payload);
    },
    deleteNotification: ({ ongoingNotifications }, action: PayloadAction<NotificationType>) => {
      notificationAdapter.removeOne(ongoingNotifications, action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => DATA_INITIAL_STATE);
  },
});

export default dataReducers.reducer;

export const { updateNotifications, updateNotification, deleteNotification } = dataReducers.actions;
