import { Abstract } from '@/types/commons/commons.types';
import { NoFlyZone } from '@/types/config/config.types';

export enum ProcedureModelStateEnum {
  USABLE = 'USABLE',
  DRAFT = 'DRAFT',
}

export enum ProcedureModelStepType {
  ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
  CONFIRMATION = 'CONFIRMATION',
  PHONE_CALL = 'PHONE_CALL',
  EVENT_CREATION = 'EVENT_CREATION',
  CLOSING = 'CLOSING',
}

export interface ProcedureModel extends Abstract {
  id: number;
  code: string;
  name: string;
  site: string;
  state: ProcedureModelStateEnum;
  steps: ProcedureModelStep[];
  noFlyZones: NoFlyZone[];
}

export interface ProcedureModelStep {
  id: number;
  code: string;
  mandatory: boolean;
  type: ProcedureModelStepType;
}
