import { useAppSelector } from '@hooks/redux.hooks';
import { useCameraCommand } from '@hooks/useCameraCommand';
import { selectJoystickControlledCam } from '@redux/global/global.selector';
import { selectCameraStatusByUniqueCode } from '@redux/situation/monitoring.selectors';
import { debounce, isEqual, throttle } from 'lodash';
import { useEffect, useState } from 'react';
import GamePad from 'react-gamepad';

import { CameraStatus, FocusModeEnum } from '@/types/sensor/status.types';

interface PadState {
  connected: boolean;

  axis: {
    [key: string]: number;
  };
}

const defaultPadState = {
  connected: false,

  axis: {},
};

export default function JoystickControl() {
  const [padState, setPadState] = useState<PadState | null>(defaultPadState);
  const [isZooming, setIsZooming] = useState<'in' | 'out' | null>(null);

  const controlledCamera = useAppSelector(selectJoystickControlledCam);
  const cameraStatus: CameraStatus | null = useAppSelector(
    (state) => selectCameraStatusByUniqueCode(state, false, controlledCamera.cameraUniqueCode),
    isEqual,
  );

  const subCameraStatus =
    cameraStatus?.videoStatuses.find((videoStatus) => videoStatus.id === controlledCamera.subCameraId) ?? null;
  const isAutoFocusOn = subCameraStatus?.focusMode === FocusModeEnum.AUTO;

  const useCamera = useCameraCommand({
    cameraStatus: cameraStatus,
    openedSubCameraId: controlledCamera.subCameraId,
  });

  const axisZoomName = ['RightStickX'];
  const axisMoveName = ['Axis0', 'Axis1', 'Axis9', 'LeftStickX', 'LeftStickY'];

  const onMoveEnd = debounce(() => {
    useCamera.move({ panSpeed: 0, tiltSpeed: 0 });
  }, 100);

  const onAxisChange = (axis: string, value: number, originalValue: number, padState: PadState) => {
    if (!useCamera.canCommand) {
      return;
    }

    // Zoom
    if (axisZoomName.includes(axis)) {
      if (value > 0 && isZooming !== 'in') {
        setIsZooming('in');
        useCamera.zoomIn();
      } else if (value < 0 && isZooming !== 'out') {
        setIsZooming('out');
        useCamera.zoomOut();
      } else if (value === 0) {
        setIsZooming(null);
        useCamera.zoomStop();
      }
    }

    // azimuth & site
    if (axisMoveName.includes(axis)) {
      if (padState.axis[axis]) {
        // TODO: verifier avec le "vrai" joystick pour les axes, une fois commandé (voir avec Jonathan)
        const dx: number = padState.axis['LeftStickX'] ? padState.axis.LeftStickX : padState.axis.Axis0;
        const dy: number = padState.axis['LeftStickY'] ? padState.axis.LeftStickY : padState.axis.Axis1;
        useCamera.moveJoystick(dx, dy);
      } else {
        onMoveEnd();
      }
    }
  };

  const updateAxis = throttle((axisName: string | null, originalValue: number | null | undefined) => {
    if (padState && axisName && originalValue !== undefined && originalValue !== null && !isNaN(originalValue)) {
      const invert = axisName.startsWith('-');
      const value = originalValue * (invert ? -1 : 1);

      if (invert) {
        axisName = axisName.substring(1);
      }

      if (+padState.axis[axisName] !== value) {
        const previousValue = padState.axis[axisName];
        padState.axis[axisName] = value;
        setPadState(padState);
        onAxisChange(axisName, value, previousValue, padState);
      }
    }
  }, 100);

  const axisIndexToAxisName = (index: number) => {
    return `Axis${index}`;
  };

  const updateAllAxisValue = (gamepad: Gamepad) => {
    for (let i = 0; i < gamepad.axes.length; ++i) {
      const axisName = axisIndexToAxisName(i);

      updateAxis(axisName, gamepad.axes[i]);
    }
  };

  const handleButton = (buttonName: string, pressed: boolean) => {
    switch (buttonName) {
      case 'LT':
      case 'DPadLeft':
        pressed ? useCamera.focusIn() : useCamera.focusStop();
        break;
      case 'RT':
      case 'DPadRight':
        pressed ? useCamera.focusOut() : useCamera.focusStop();
        break;
      case 'RB':
      case 'DPadDown':
        if (pressed) {
          isAutoFocusOn ? useCamera.focusStop() : useCamera.focusAuto();
        }
        break;
      case 'LB':
      case 'DPadUp':
        if (pressed) {
          useCamera.videoBuffering();
        }
        break;
      default:
        console.log(`${buttonName} is ${pressed ? 'pressed' : 'not pressed'}.`);
    }
  };

  const updateGamePad = (gamepadIndex: number) => {
    if (window.isSecureContext) {
      const gamepad = navigator.getGamepads().find((gp) => gp?.index === gamepadIndex);
      gamepad && updateAllAxisValue(gamepad);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    let move = null;

    if (!useCamera.canCommand || event.repeat || !cameraStatus?.configuration) {
      return;
    }

    const speedFactor = 0.1;
    const maxPanSpeed = cameraStatus.configuration.vxMax;
    const maxTiltSpeed = cameraStatus.configuration.vyMax;

    switch (event.key) {
      case 'ArrowUp':
        move = { panSpeed: 0, tiltSpeed: speedFactor * maxTiltSpeed };
        break;
      case 'ArrowDown':
        move = { panSpeed: 0, tiltSpeed: -speedFactor * maxTiltSpeed };
        break;
      case 'ArrowLeft':
        move = { panSpeed: -speedFactor * maxPanSpeed, tiltSpeed: 0 };
        break;
      case 'ArrowRight':
        move = { panSpeed: speedFactor * maxPanSpeed, tiltSpeed: 0 };
        break;
      case '+':
        useCamera.zoomIn();
        break;
      case '-':
        useCamera.zoomOut();
        break;
      default:
        break;
    }
    move && useCamera.move(move);
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (!useCamera.canCommand) {
      return;
    }
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
        useCamera.move({ panSpeed: 0, tiltSpeed: 0 });
        break;
      case '+':
      case '-':
        useCamera.zoomStop();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    updateGamePad(0);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      // force stop move on joystickControl removed
      if (!useCamera.canCommand && cameraStatus && !cameraStatus.roundOn) {
        useCamera.move({ panSpeed: 0, tiltSpeed: 0 });
        useCamera.zoomStop();
      }
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCamera]);

  return (
    <GamePad
      onConnect={updateGamePad}
      onButtonChange={handleButton}
      onDisconnect={updateGamePad}
      onAxisChange={updateAxis}
    >
      <div className="gamepad"></div>
    </GamePad>
  );
}
