import { ReactComponent as PlayerPause } from '@assets/components/button/icPlayer-pause.svg';
import { ReactComponent as PlayerPlay } from '@assets/components/button/icPlayer-play.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/cameras/gauge-arrow-left.svg';
import { ReactComponent as ArrowLeft } from '@assets/icons/cameras/gauge-arrow-right.svg';
import { AbsoluteCenter } from '@chakra-ui/layout';
import { ButtonGroup } from '@chakra-ui/react';
import ButtonBar from '@components/common/inputs/buttons/ButtonBar';
import PerimeterGridTitle from '@components/video/PerimeterGridTitle';
import { useAppSelector } from '@hooks/redux.hooks';
import { PerimeterCameraSelectors, selectAutomatonCabinetSegments } from '@redux/sensors/sensors.selectors';
import _, { groupBy, isEqual } from 'lodash';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';

import CamGrid from './CamGrid';

const BASIC_ROTATION_INTERVAL = 15_000;

export interface PerimeterGridProps {
  uniqueKey: string;
  innerGridWidth: number;
  innerGridHeight: number;
}

function PerimeterGrid({ uniqueKey, innerGridWidth, innerGridHeight }: Readonly<PerimeterGridProps>) {
  const [zoneIndex, setZoneIndex] = useState<number>(0);

  const [chunkIndex, setChunkIndex] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  const perimeterCameras: PerimeterCamera[] = useAppSelector(
    PerimeterCameraSelectors.selectAllPerimeterCameras,
    isEqual,
  );
  const segments = useAppSelector(selectAutomatonCabinetSegments, isEqual);

  const segmentByZone = useMemo(() => groupBy(segments, 'zone'), [segments]);

  const chunkedPerimeterCamerasGroupedByZone = useMemo(
    () =>
      Object.entries(segmentByZone)
        .map(([zone, seg]) => {
          const allPerimeterCamerasInZoneWithDuplicationUniqueKeys = seg.flatMap((sg) => sg.perimeterCameraUniqueKeys);
          return {
            zone: zone,
            chunkedPerimCams: _.chunk(
              perimeterCameras.filter((perimeterCamera) =>
                allPerimeterCamerasInZoneWithDuplicationUniqueKeys.includes(perimeterCamera.uniqueKey),
              ),
              innerGridHeight * innerGridWidth,
            ),
          };
        })
        .filter((zoneChunks) => zoneChunks.chunkedPerimCams.length > 0),
    [segmentByZone, perimeterCameras, innerGridHeight, innerGridWidth],
  );

  const goPreviousChunk = useCallback(() => {
    if (chunkIndex === 0) {
      setZoneIndex((index) => (index === 0 ? chunkedPerimeterCamerasGroupedByZone.length - 1 : index - 1));
      setChunkIndex(chunkedPerimeterCamerasGroupedByZone[zoneIndex - 1].chunkedPerimCams.length - 1);
    } else {
      setChunkIndex((index) => index - 1);
    }
  }, [chunkIndex, chunkedPerimeterCamerasGroupedByZone, zoneIndex]);

  const goNextChunk = useCallback(() => {
    if (chunkIndex === chunkedPerimeterCamerasGroupedByZone[zoneIndex].chunkedPerimCams.length - 1) {
      setZoneIndex((index) => (index === chunkedPerimeterCamerasGroupedByZone.length - 1 ? 0 : index + 1));
      setChunkIndex(0);
    } else {
      setChunkIndex((index) => index + 1);
    }
  }, [chunkIndex, chunkedPerimeterCamerasGroupedByZone, zoneIndex]);

  const camerasChunk = useMemo(() => {
    return chunkedPerimeterCamerasGroupedByZone[zoneIndex].chunkedPerimCams[chunkIndex] ?? [];
  }, [chunkedPerimeterCamerasGroupedByZone, zoneIndex, chunkIndex]);

  const interval = useRef<NodeJS.Timeout | undefined>(undefined);

  useLayoutEffect(() => {
    interval.current = setInterval(
      goNextChunk,
      BASIC_ROTATION_INTERVAL +
        5_000 * Math.max(chunkedPerimeterCamerasGroupedByZone[zoneIndex].chunkedPerimCams[chunkIndex].length - 3, 0),
    );
    return () => clearInterval(interval.current);
  }, [chunkIndex, chunkedPerimeterCamerasGroupedByZone, goNextChunk, zoneIndex]);

  function handlePlayButtonClick(flag: boolean) {
    setIsPlaying(flag);
    if (!flag) {
      clearInterval(interval.current);
    } else {
      interval.current = setInterval(
        goNextChunk,
        BASIC_ROTATION_INTERVAL +
          5_000 * Math.max(chunkedPerimeterCamerasGroupedByZone[zoneIndex].chunkedPerimCams[chunkIndex].length - 3, 0),
      );
    }
  }

  return (
    <>
      <PerimeterGridTitle zone={chunkedPerimeterCamerasGroupedByZone[zoneIndex].zone} />
      <CamGrid
        innerGridHeight={innerGridHeight}
        innerGridWidth={innerGridWidth}
        uniqueKey={uniqueKey}
        cameras={camerasChunk}
        onPlayerDoubleClick={() => handlePlayButtonClick(false)}
      />
      <AbsoluteCenter>
        <ButtonGroup>
          <ButtonBar label="previous" icon={ArrowRight} hideTooltip onClick={goPreviousChunk} />
          <ButtonBar
            label="player"
            icon={isPlaying ? PlayerPause : PlayerPlay}
            hideTooltip
            onClick={() => handlePlayButtonClick(!isPlaying)}
          />
          <ButtonBar label="next" icon={ArrowLeft} hideTooltip onClick={goNextChunk} />
        </ButtonGroup>
      </AbsoluteCenter>
    </>
  );
}

export default PerimeterGrid;
